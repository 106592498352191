import { Skeleton } from "primereact/skeleton"
import React from "react"

export default function ApproveRequestShopLoading() {
  return (
    <div className="h-full w-full">
      <div className="flex h-full w-full flex-col">
        <div className="flex w-full flex-col gap-3">
          <div className="w-full">
            <Skeleton height="4rem" className="mb-2"></Skeleton>
          </div>
          {/* Shop info */}
          <div className="flex w-full gap-3">
            <div className="h-3 w-1/3">
              <Skeleton height="3rem"></Skeleton>
            </div>
            <div className="flex w-full flex-col gap-5 md:w-2/3">
              <div className="flex w-full flex-col gap-1">
                <Skeleton height="1rem" width="15rem"></Skeleton>
                <Skeleton height="2rem"></Skeleton>
              </div>
              <div className="flex w-full flex-col gap-1">
                <Skeleton height="1rem" width="15rem"></Skeleton>
                <Skeleton height="2rem"></Skeleton>
              </div>
              <div className="flex w-full flex-col gap-1">
                <Skeleton height="1rem" width="15rem"></Skeleton>
                <Skeleton height="2rem"></Skeleton>
              </div>
              <div className="flex w-full flex-col gap-1">
                <Skeleton height="1rem" width="15rem"></Skeleton>
                <Skeleton height="2rem"></Skeleton>
              </div>
              <div className="flex w-full flex-col gap-1">
                <Skeleton height="1rem" width="15rem"></Skeleton>
                <Skeleton height="2rem"></Skeleton>
              </div>
            </div>
          </div>
          {/* User info */}
          <div className="flex w-full gap-3">
            <div className="h-3 w-1/3">
              <Skeleton height="3rem"></Skeleton>
            </div>
            <div className="flex w-full flex-col gap-5 md:w-2/3">
              <div className="flex w-full flex-col gap-1">
                <Skeleton height="1rem" width="15rem"></Skeleton>
                <Skeleton height="2rem"></Skeleton>
              </div>
              <div className="flex w-full gap-3">
                <div className="flex w-full flex-col gap-1">
                  <Skeleton height="1rem" width="15rem"></Skeleton>
                  <Skeleton height="2rem"></Skeleton>
                </div>
                <div className="flex w-full flex-col gap-1">
                  <Skeleton height="1rem" width="15rem"></Skeleton>
                  <Skeleton height="2rem"></Skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
