export function convertDateString(str?: string, isTime?: boolean) {
  if (!str) return ""
  const date = new Date(str)
  const dateStr =
    ("00" + date.getDate()).slice(-2) +
    "/" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear()

  const timeStr =
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2)

  return isTime ? `${dateStr} ${timeStr}` : dateStr
}
