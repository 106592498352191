import React from "react"
import i18n from "src/locales"
import bannerBackground from "src/assets/images/request-open-banner.svg"
import bannerBackgroundVi from "src/assets/images/request-open-restaurant-vi.png"

const HeaderView = () => {
  const language = i18n.language

  return (
    <div className="rounded w-full rounded-3 border-gray-200 bg-white p-3">
      {language === "vi" ? (
        <img
          src={bannerBackgroundVi}
          alt="bannerBackgroundVi"
          className="h-[156px] w-full"
        />
      ) : (
        <img
          src={bannerBackground}
          alt="bannerBackground"
          className="h-[156px] w-full"
        />
      )}
    </div>
  )
}

export default HeaderView
