import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "./index.scss"
import { Pagination, Navigation, Autoplay } from "swiper/modules"
import { ImgCustom } from "../common"
import { LocationViewType, PopularLocationProps } from "./types"
import { foodHomePageService } from "src/services"
import { useNavigate } from "react-router-dom"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { IMAGE_TYPE } from "src/constants/common"
import i18n from "src/locales"

const LocationViewItem = (props: LocationViewType) => {
  const { district_name, imgUrl, location, restaurant_id } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div
      className="relative flex h-[380px] cursor-pointer rounded-3"
      onClick={() => navigate(`/food/restaurant/${restaurant_id}`)}
    >
      <div className="h-full w-full rounded-4">
        <ImgCustom
          className="h-full w-full rounded-4"
          url={imgUrl}
          alt="imgFoodItemView"
          type={IMAGE_TYPE.WEB}
        />
      </div>
      <div className="absolute bottom-0 flex w-full items-center  rounded-b-4 bg-gray-600 bg-opacity-20 px-3 pb-4px pt-2px text-white backdrop-blur-[1px]">
        <div className="flex flex-col">
          <span className="leading-32 w-fit cursor-pointer text-20 font-semibold">
            {district_name}
          </span>
          <div className="h-[2px] w-full bg-white"></div>
          <span className="w-fit text-14 font-medium leading-24">{`${location} ${capitalizedFirst(t("global.locations"))}`}</span>
        </div>
      </div>
    </div>
  )
}

const PopularLocationView = (props: PopularLocationProps) => {
  const { cityId } = props
  const [location, setLocation] = React.useState<LocationViewType[]>([])
  const { t } = useTranslation()
  React.useEffect(() => {
    if (cityId) {
      const result = [] as LocationViewType[]
      foodHomePageService
        .getPopularLocation(cityId, i18n.language)
        .then((res) => {
          if (res?.data?.data) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data?.districts?.forEach((i: any) => {
              if(i?.background?.id && i?.locations) {
                result.push({
                  district_id: i?.district_id,
                  district_name: i?.district_name,
                  imgUrl: i?.background?.id,
                  location: i?.locations,
                  restaurant_id: i?.restaurant_id,
                })
              }
            })
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLocation(result)
        })
    }
  }, [cityId, i18n.language])

  if (!cityId) return null

  if (location?.length == 0) return null

  return (
    <div className="flex flex-col" id="popularLocation">
      <span className="text-20 font-semibold leading-30 text-gray-900">
        {capitalizedFirst(t("food-home-page.popular-location-title"))}
      </span>
      {/* <span className="font-18 mt-2px font-medium leading-28 text-gray-500">
        {capitalizedFirst(t("food-home-page.popular-location-info"))}
      </span> */}
      <div className="mt-2 rounded-4 bg-white p-2">
        <Swiper
          slidesPerView={4}
          spaceBetween={10}
          navigation={true}
          pagination={true}
          autoplay
          modules={[Navigation, Pagination, Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 4,
            },
          }}
        >
          {location.map((i, index) => (
            <SwiperSlide style={{ width: "360px" }} key={index}>
              <LocationViewItem {...i} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default React.memo(PopularLocationView)
