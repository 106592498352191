import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { CancelModalType } from "./types"
import { Dialog } from "primereact/dialog"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { InputTextarea } from "primereact/inputtextarea"
import "./index.scss"
import { useToast } from "src/hooks/use-toast"
import {
  ReservationCancelControllerApi,
  CancelReasonTypeControllerApi,
  ReservationCancelBuilderDTO,
} from "@soctrip/axios-food-order-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import i18n from "src/locales"

export default function CancelReservationModal(props: CancelModalType) {
  const { id, isShowModalCancel, closeModal, refetch } = props
  const { t } = useTranslation()
  const showToast = useToast()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [reasonData, setReasonData] = useState<any[]>()

  const formik = useFormik({
    initialValues: {
      reason: null,
      note: "",
    },

    validate: (data) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors: any = {}
      if (!data.reason) {
        errors.reason = capitalizedFirst(t("global.required-message"))
      }

      return errors
    },
    onSubmit: (data) => {
      data.reason && handleSubmitCancel()
    },
  })

  const handleGetReasonData = async () => {
    await new CancelReasonTypeControllerApi(config(SERVICE.FOOD_ORDER))
      .getAlCancelReasonTypes(0, 100, i18n.language)
      .then((response) => {
        setReasonData(response?.data?.data?.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (isShowModalCancel) {
      handleGetReasonData()
    }
  }, [isShowModalCancel])

  const handleSubmitCancel = async () => {
    const note = formik?.values?.note
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = formik?.values?.reason
    const dto: ReservationCancelBuilderDTO = {
      reservation_id: id,
      reason_type_id: data?.id,
      note: note,
    }
    await new ReservationCancelControllerApi(config(SERVICE.FOOD_ORDER))
      .createNew(dto)
      .then(() => {
        handleCloseModal()
        showToast({
          detail: capitalizedFirst(t("Change status successfully")),
          severity: "success",
        })
        if (refetch) {
          refetch()
        }
      })
      .catch((error) => {
        showToast({
          detail: capitalizedFirst(t(error?.response?.data?.error?.message)),
          severity: "error",
        })
        handleCloseModal()
      })
  }

  const handleCloseModal = () => {
    formik.resetForm()
    closeModal()
  }

  const isFormFieldInvalid = (name: string) => {
    return !!(
      formik?.touched[`${name}` as keyof typeof formik.touched] &&
      formik?.errors[`${name}` as keyof typeof formik.errors]
    )
  }

  const getFormErrorMessage = (name: string) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error text-12">
        {formik?.errors[`${name}` as keyof typeof formik.errors]}
      </small>
    ) : (
      <small className="p-error">&nbsp;</small>
    )
  }

  const headerCustom = (
    <div className="flex items-center text-18 font-semibold text-gray-900">
      {capitalizedFirst(t("reservation.cancel-reservation"))}
    </div>
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reasonOptionTemplate = (option: any) => {
    return (
      <div
        className={`flex items-center hover:text-blue-500 ${
          formik?.values?.reason === option ? "text-blue-600" : "text-gray-700"
        }`}
      >
        <div>{option.name}</div>
      </div>
    )
  }

  return (
    <div>
      <Dialog
        header={headerCustom}
        visible={isShowModalCancel}
        onHide={handleCloseModal}
        className="w-[608px]"
        id="cancel-modal"
      >
        <div className="w-full p-0">
          <form onSubmit={formik.handleSubmit} className="flex w-full flex-col">
            <div className="flex w-full flex-col gap-4px">
              <div className="flex items-center gap-4px">
                <div className="text-14 font-medium leading-5 text-gray-700">
                  {capitalizedFirst(t("reservation.cancel-reason"))}
                </div>
                <div className="text-14 font-medium text-red-600">*</div>
              </div>
              <div className="flex w-full flex-col gap-2px">
                <Dropdown
                  name="reason"
                  value={formik.values.reason}
                  options={reasonData}
                  optionLabel="name"
                  placeholder={capitalizedFirst(
                    t("reservation.select-cancel-reason")
                  )}
                  itemTemplate={reasonOptionTemplate}
                  className={classNames({
                    "p-invalid flex h-[44px] w-full items-center":
                      isFormFieldInvalid("reason"),
                  })}
                  style={{ height: "44px", alignItems: "center", font: "14px" }}
                  onChange={(e: DropdownChangeEvent) => {
                    formik.setFieldValue("reason", e.value)
                  }}
                />
                {getFormErrorMessage("reason")}
              </div>
            </div>
            <div className="mt-4px flex w-full flex-col gap-4px">
              <div className="text-14 font-medium leading-5 text-gray-700">
                {capitalizedFirst(t("global.note"))}
              </div>
              <InputTextarea
                name="note"
                rows={3}
                cols={30}
                className="w-full"
                autoResize
                value={formik.values.note}
                onChange={(e) => {
                  formik.setFieldValue("note", e.target.value)
                }}
              />
            </div>

            <div className="mt-5 flex w-full items-center justify-end gap-3">
              <button
                onClick={handleCloseModal}
                type="button"
                className="rounded-3 border  border-gray-300 px-20px py-2 text-14 font-semibold text-gray-700"
              >
                {capitalizedFirst(t("global.cancel"))}
              </button>
              <button
                type="submit"
                className={`rounded-3 border border-red-600 bg-red-600 px-20px py-2 text-14 font-semibold text-white ${
                  formik?.values?.reason !== null ? "opacity-90" : "opacity-25"
                }`}
              >
                {capitalizedFirst(t("reservation.cancel-reservation"))}
              </button>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  )
}
