export interface UserFormType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataForm: any
}
export interface RestaurantType {
  id: string
  name: string
}

export interface ShopInformationFormType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataForm: any
  typePage: string
  restaurantType: RestaurantType[]
}
export interface InputFormType {
  typePage: string
  updateDateComplete: (data: DataCompleteType) => void
}
export interface CompleteFormType {
  dataComplete: DataCompleteType | undefined
  typePage: string
}
export interface DataCompleteType {
  shop: {
    name: string
    address: string
  }
  user: {
    name: string
    email: string
    phone: string
  }
  category?: string[]
}

export interface AdvanceInformationFormType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataForm: any
}
export interface WorkingDayType {
  name: string
  value: string
}
export interface FileType {
  id: string
  extension: string
  original_name: string
  type: string
  size: number
  description: string
  signature: string
}

export interface AddressType {
  id: string
  name: string
  code_name: string
  postal_code: string
}

export interface DocumentType {
  id: string
  name: string
  size: string
  file: File
  data: FileType | null
}

export enum TypeEnum {
  business = "ENTERPRISE",
  personal = "INDIVIDUAL",
}

export enum RestaurantCreateDTOCertificateTypeEnum {
  ENTERPRISE = "ENTERPRISE",
  INDIVIDUAL = "INDIVIDUAL"
}

export interface CurrencyType {
  code: string;
  id: string;
  name: string;
}
