import React, { useEffect, useState } from "react"
import FoodCardView from "./food-card-view"
import { RecommendProps } from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { FOOD_CARD_TYPE } from "src/constants/common"
import { getBloomFilter } from "src/services/bookmark-service"

const RecommendView = (props: RecommendProps) => {
  const { recommendData } = props
  const { t } = useTranslation()
  const [bloomData, setBloomData] = useState<string>("")
  // get bloom data
  const handleGetBloomData = async () => {
    await getBloomFilter()
      .then((response) => {
        setBloomData(response?.data?.data?.bloom_filter)
      })
      .catch((error) => error)
  }

  useEffect(() => {
    handleGetBloomData()
  }, [])

  if (recommendData?.length === 0) {
    return
  }

  return (
    <div className="flex flex-col">
      <span className="text-20 font-semibold leading-30 text-gray-900">
        {capitalizedFirst(t("food-home-page.recommend-title"))}
      </span>
      {/* <span className="mt-2px text-18 font-medium leading-28 text-gray-500">
        {capitalizedFirst(t("food-home-page.recommend-info", { city: city }))}
      </span> */}

      <div className="mt-4 grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {recommendData?.map((item, index) => (
          <div className="" key={index}>
            <FoodCardView
              isShowPrice={true}
              isAddressView={true}
              isFoodType={true}
              type={FOOD_CARD_TYPE.FOOD}
              bloom_filter={bloomData}
              handleReload={handleGetBloomData}
              {...item}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(RecommendView)
