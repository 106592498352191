import { InputText } from "primereact/inputtext"
import React, { useRef, useState, KeyboardEvent, useEffect } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { useClickOutside } from "src/hooks/use-click-outside"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import {
  Dropdown,
  DropdownChangeEvent,
  DropdownProps,
} from "primereact/dropdown"
import locationIcon from "../../assets/images/location-icon.svg"
import { Location, RadiusType } from "./types"
import LocationOptionTemplate from "./location-option-template"
import { getCountryDetails } from "src/services/filter-restaurant-service"
import { getRestaurantPreflightSearch } from "src/services/restaurant-service"
import { getKeywords } from "src/utils/get-keyword-suggestion"
import { radius } from "src/constants/common"

export default function SearchViewCustom() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams("")
  const searchRef = useRef(null)
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [isSuggestionModal, setIsSuggestionModal] = useState(false)
  const [selectedSugIdx, setSelectedSugIdx] = useState(-1)
  const [isSearching, setIsSearching] = useState(false)
  const [keyword, setKeyword] = useState(searchParams.get("keyword") ?? "")
  const [selectLocation, setSelectLocation] = useState<Location | null>()
  const [keywordDebounce] = useDebounce(keyword, 700)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [provinces, setProvinces] = useState<any[]>([])
  const [isGetNearYou, setIsGetNearYou] = useState<boolean>(false)
  const [selectedRadius, setSelectedRadius] = useState<RadiusType | null>({
    name: "7km +",
    code: 100,
  })
  const [searchValue, setSearchValue] = useState(
    searchParams.get("keyword") ?? ""
  )

  useEffect(() => {
    setIsSearching(true)
    setSelectedSugIdx(-1)
    async function fetchSuggestions() {
      try {
        const res = await getRestaurantPreflightSearch(keywordDebounce)
        const data: string[] = res?.data?.data?.data
        if (data) {
          const keywords = getKeywords(data, keywordDebounce)
          setSuggestions(keywords)
        }
        setIsSearching(false)
      } catch (error) {
        setIsSearching(false)
      }
    }
    fetchSuggestions()
  }, [keywordDebounce])

  useClickOutside(searchRef, () => setIsSuggestionModal(false))

  const getCountryDetailData = async () => {
    await getCountryDetails()
      .then((response) => {
        setProvinces(response?.data?.data?.provinces)
      })
      .catch((err) => err)
  }

  const handleSearch = (suggestion?: string) => {
    if (suggestion) {
      setKeyword(suggestion)
    }
    const path =
      keywordDebounce || suggestion
        ? `/food/search?keyword=${
          suggestion || keywordDebounce
        }#isGetNearYou=${isGetNearYou}#radius=${selectedRadius?.code}`
        : `/food/search#isGetNearYou=${isGetNearYou}#radius=${selectedRadius?.code}`
    if (selectLocation) {
      navigate(path, {
        state: { provinceSelected: selectLocation },
      })
    } else {
      navigate(path)
    }
    setIsSuggestionModal(false)
    setSearchValue(suggestion || keywordDebounce)
  }

  const handleEnterKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isSearching) return
    if (e.key === "ArrowDown") {
      const index =
        selectedSugIdx == suggestions.length - 1 ? 0 : selectedSugIdx + 1
      setSelectedSugIdx(index)
    } else if (e.key === "ArrowUp") {
      const index =
        selectedSugIdx == 0 ? suggestions.length - 1 : selectedSugIdx - 1
      setSelectedSugIdx(index)
    } else if (e.key === "Enter") {
      if (selectedSugIdx !== -1) {
        handleSearch(suggestions[selectedSugIdx])
      } else {
        handleSearch()
      }
    }
  }

  const handleSelectLocation = (e: DropdownChangeEvent) => {
    setIsGetNearYou(false)
    setSelectLocation(e.value)
  }

  const handleResetLocation = () => {
    setIsGetNearYou(false)
    setSelectLocation(null)
  }

  useEffect(() => {
    getCountryDetailData()
  }, [])

  const handleGetNearYou = () => {
    setIsGetNearYou(true)
    setSelectLocation(null)
  }

  useEffect(() => {
    if (isGetNearYou) {
      setKeyword("")
    }
  }, [isGetNearYou])

  useEffect(() => {
    setSelectLocation(location?.state?.provinceSelected)
  }, [location])

  const highlightMatchedText = (text: string, keyword: string) => {
    const startIndex = text
      .toLocaleLowerCase()
      .indexOf(keyword.toLocaleLowerCase())
    if (startIndex === -1) {
      return text
    }

    const endIndex = startIndex + keyword.length
    const prefix = text.slice(0, startIndex)
    const match = text.slice(startIndex, endIndex)
    const suffix = text.slice(endIndex)

    return (
      <>
        {prefix}
        <strong className="font-semibold text-black">{match}</strong>
        {suffix}
      </>
    )
  }

  const SelectedLocationTemplate = (option: Location, props: DropdownProps) => {
    // eslint-disable-next-line react/prop-types
    const placeholder = props?.placeholder
    if (selectLocation) {
      return (
        <div className="flex items-center gap-2">
          <img src={locationIcon} alt="location-icon" />
          <div className="text-14 font-medium leading-5 text-gray-900">
            {selectLocation.province_name}
          </div>
        </div>
      )
    }

    if (isGetNearYou) {
      return (
        <div className="flex items-center gap-2">
          <img src={locationIcon} alt="location-icon" />
          <div className="text-14 font-medium leading-5 text-gray-900">
            {capitalizedFirst(t("search-filter-page.restaurants-near-you"))}
          </div>
        </div>
      )
    }

    return (
      <span className="text-16 font-medium leading-6 text-gray-500">
        {placeholder}
      </span>
    )
  }

  const FilterTemplate = () => {
    const { t } = useTranslation()
    const handleTranslate = (text: string) => {
      return t(text)
    }
    return (
      <div className="flex flex-col">
        <div className="border-b border-gray-200 p-2 text-18 font-semibold leading-7 text-gray-700">
          {capitalizedFirst(
            handleTranslate("search-filter-page.some-famous-locations")
          )}
        </div>
        <div className="flex items-center justify-between">
          <div
            onClick={handleGetNearYou}
            className="flex w-full cursor-pointer items-center gap-3 stroke-gray-700 px-3 py-2 text-gray-900 hover:stroke-blue-700 hover:text-blue-700"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M16.6667 10.0001C16.6667 13.682 13.6819 16.6667 10 16.6667M16.6667 10.0001C16.6667 6.31818 13.6819 3.33341 10 3.33341M16.6667 10.0001H18.3334M10 16.6667C6.31812 16.6667 3.33335 13.682 3.33335 10.0001M10 16.6667V18.3334M3.33335 10.0001C3.33335 6.31818 6.31812 3.33341 10 3.33341M3.33335 10.0001H1.66669M10 3.33341V1.66675M12.5 10.0001C12.5 11.3808 11.3807 12.5001 10 12.5001C8.61931 12.5001 7.50002 11.3808 7.50002 10.0001C7.50002 8.61937 8.61931 7.50008 10 7.50008C11.3807 7.50008 12.5 8.61937 12.5 10.0001Z" />
              </g>
              <defs>
                <clipPath id="clip0_4824_97590">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <p className="text-14 font-medium leading-5">
              {capitalizedFirst(
                handleTranslate("search-filter-page.restaurants-near-you")
              )}
            </p>
          </div>
          <div
            onClick={handleResetLocation}
            className="cursor-pointer px-3 text-14 font-semibold leading-5 text-blue-600"
          >
            Reset
          </div>
        </div>
      </div>
    )
  }

  const RadiusOptionTemplate = (option: RadiusType) => {
    return (
      <div
        onClick={() => setSelectedRadius(option)}
        className="flex cursor-pointer items-center justify-between gap-1"
      >
        <p className="text-14 font-medium text-gray-700">
          {capitalizedFirst(t(option.name))}
        </p>
        {option?.code === selectedRadius?.code && (
          <span className="pi pi-check text-blue-600"></span>
        )}
      </div>
    )
  }

  return (
    <div className="w-full">
      <div
        ref={searchRef}
        className="relative z-10 flex flex-col md:flex-row h-[50px] w-full items-center justify-between rounded-3 border border-gray-200 bg-white focus:border-gray-300 gap-1"
      >
        <div className="p-input-icon-left flex-1  rounded-3 bg-white outline-none w-full lg:w-2/3">
          <i className="pi pi-search text-blue-600" />
          <InputText
            disabled={isGetNearYou}
            onKeyDown={handleEnterKeyDown}
            onClick={() => setIsSuggestionModal(true)}
            value={keyword}
            onChange={(e) => {
              setKeyword(e?.target?.value)
              setIsSuggestionModal(true)
            }}
            placeholder={capitalizedFirst(
              t("search-filter-page.search-for-restaurant")
            )}
            className="h-full w-full rounded-3 border-none px-6 outline-none active:border-none"
          />
        </div>

        <div className="mx-1 h-[28px] w-[2px] bg-gray-300 hidden md:flex" ></div>
        <div className="flex h-full w-full md:w-1/3 items-center justify-between lg:w-1/3">
          <div className="relative flex h-full w-full items-center gap-4px border-gray-200">
            <Dropdown
              value={selectLocation}
              onChange={(e: DropdownChangeEvent) => handleSelectLocation(e)}
              options={provinces}
              optionLabel="name"
              placeholder={capitalizedFirst(
                t("search-filter-page.select-location")
              )}
              valueTemplate={SelectedLocationTemplate}
              itemTemplate={LocationOptionTemplate}
              filterTemplate={FilterTemplate}
              filter
              className={
                "flex h-[40px] w-[100%] items-center rounded-3 border-none text-16 font-normal leading-24 text-gray-900 outline-none"
              }
            />
            {isGetNearYou ? (
              <div
                id="radius-dropdown"
                className="absolute right-[25%] flex items-center"
              >
                <Dropdown
                  className="flex h-30px items-center rounded-5 text-center text-12 placeholder:text-12"
                  placeholder="Select radius"
                  optionLabel="name"
                  itemTemplate={RadiusOptionTemplate}
                  options={radius}
                  value={selectedRadius}
                />
              </div>
            ) : null}
            <div className="flex items-center justify-end">
              <button
                type="button"
                onClick={() => handleSearch()}
                className="mr-0px md:mr-4px h-[40px] min-w-[80px] truncate rounded-3 bg-blue-600 px-3 py-10px text-center text-14 font-semibold leading-20 text-white hover:bg-blue-500"
              >
                {capitalizedFirst(t("global.search"))}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute left-0 right-0 top-[52px] z-[1] rounded-3 bg-white shadow">
        {suggestions.length > 0 && isSuggestionModal && (
          <ul className="py-3">
            {suggestions.map((suggestion, index) => {
              return (
                <li
                  key={index}
                  onClick={() => handleSearch(suggestion)}
                  className={`max-w-full cursor-pointer truncate px-3 py-[4px] text-14 text-gray-700 hover:bg-gray-200 ${
                    selectedSugIdx === index ? "bg-gray-100" : ""
                  }`}
                >
                  {highlightMatchedText(suggestion, searchValue)}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </div>
  )
}
