import React from "react"
import { ReservationDetailsInfoProps } from "./type"
import { copyToClipboard } from "src/utils/copyToClipboard"
import { convertTimeCommon } from "src/utils/time-utils"
import { useTranslation } from "react-i18next"
import { convertCurrency } from "src/utils/convert-currency"
import { capitalizedFirst } from "src/utils/capitalized-first-character"

export default function ReservationInformation({
  reservations,
}: ReservationDetailsInfoProps) {
  const { t } = useTranslation()
  const time = reservations?.reservation_time

  return (
    <div className="w-full rounded-3 bg-white">
      <div className="flex w-full flex-col">
        <div className="flex h-[60px] w-full items-center border-b border-gray-100 md:px-3">
          <div className="text-18 font-semibold leading-7 text-gray-900">
            {capitalizedFirst(t("reservation.reservation-information"))}
          </div>
        </div>
        <div className="flex w-full flex-col gap-3 py-3 md:px-3">
          <div className="flex w-full flex-col gap-3 sm:flex-row sm:items-start sm:justify-between">
            <div className="flex flex-col gap-3 md:w-1/4">
              {/* reservation ID */}
              <div className="flex flex-col gap-4px ">
                <div className="text-14 font-medium leading-5 text-gray-500">
                  {capitalizedFirst(t("reservation.reservation-id"))}
                </div>
                <div className="flex items-center gap-1">
                  <div className="text-14 font-normal leading-5 text-gray-900">
                    {reservations?.code}
                  </div>
                  <div
                    onClick={() => copyToClipboard(reservations?.code)}
                    className="cursor-pointer"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_7909_10010)">
                        <g clipPath="url(#clip1_7909_10010)">
                          <path
                            d="M6.66699 6.66675V4.33341C6.66699 3.39999 6.66699 2.93328 6.84865 2.57676C7.00844 2.26316 7.2634 2.00819 7.57701 1.8484C7.93353 1.66675 8.40024 1.66675 9.33366 1.66675H15.667C16.6004 1.66675 17.0671 1.66675 17.4236 1.8484C17.7372 2.00819 17.9922 2.26316 18.152 2.57676C18.3337 2.93328 18.3337 3.39999 18.3337 4.33341V10.6667C18.3337 11.6002 18.3337 12.0669 18.152 12.4234C17.9922 12.737 17.7372 12.992 17.4236 13.1518C17.0671 13.3334 16.6004 13.3334 15.667 13.3334H13.3337M4.33366 18.3334H10.667C11.6004 18.3334 12.0671 18.3334 12.4236 18.1518C12.7372 17.992 12.9922 17.737 13.152 17.4234C13.3337 17.0669 13.3337 16.6002 13.3337 15.6667V9.33341C13.3337 8.39999 13.3337 7.93328 13.152 7.57676C12.9922 7.26316 12.7372 7.00819 12.4236 6.8484C12.0671 6.66675 11.6004 6.66675 10.667 6.66675H4.33366C3.40024 6.66675 2.93353 6.66675 2.57701 6.8484C2.2634 7.00819 2.00844 7.26316 1.84865 7.57676C1.66699 7.93328 1.66699 8.39999 1.66699 9.33341V15.6667C1.66699 16.6002 1.66699 17.0669 1.84865 17.4234C2.00844 17.737 2.2634 17.992 2.57701 18.1518C2.93353 18.3334 3.40024 18.3334 4.33366 18.3334Z"
                            stroke="#175CD3"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_7909_10010">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                        <clipPath id="clip1_7909_10010">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>

              {/* Note */}
              <div className="flex w-full flex-col gap-4px">
                <div className="text-14 font-medium leading-5 text-gray-500">
                  {capitalizedFirst(t("purchase-history.note"))}
                </div>
                <div className="text-14 font-normal leading-5 text-gray-700">
                  {reservations?.note}
                </div>
              </div>
            </div>
            {/* status */}
            <div className="flex flex-col gap-4px md:w-1/4">
              <div className="text-14 font-medium leading-5 text-gray-500">
                {capitalizedFirst(t("purchase-history.status"))}
              </div>
              <div className="flex items-center">
                <div
                  className="rounded-3 px-6px py-2px text-12 font-normal leading-5"
                  style={{
                    backgroundColor: reservations?.status?.bg_color,
                    color: reservations?.status?.color,
                  }}
                >
                  {capitalizedFirst(
                    t(
                      `purchase-history.order-status-tabs.${reservations?.status?.name}`
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 md:w-1/4">
              {/* reservation time */}
              <div className="flex flex-col gap-4px">
                <div className="text-14 font-medium leading-5 text-gray-500">
                  {capitalizedFirst(t("reservation.reservation-time"))}
                </div>
                <div className="flex items-center text-14 font-normal text-gray-700">
                  {convertTimeCommon(
                    new Date(time as unknown as string) as unknown as string, true
                  )}
                </div>
              </div>
              {/* reservation time */}
              <div className="flex flex-col gap-4px">
                <div className="text-14 font-medium leading-5 text-gray-500">
                  {capitalizedFirst(t("reservation.reservation-deposit"))}
                </div>
                <div className="flex items-center gap-4px text-14 font-normal text-gray-700">
                  <span className="text-sm">{convertCurrency(reservations?.deposit)}</span>
                  {reservations?.deposit as number > 0 ? (<span className="rounded-[100px] inline-block px-1 py-2px text-12 font-medium text-emerald-600 bg-emerald-50">
                    {capitalizedFirst(
                      t(
                        "purchase-history.paid"
                      )
                    )}
                  </span>):null}
                </div>
              </div>
            </div>
            {/* Guest */}
            <div className="flex flex-col gap-4px md:w-1/4">
              <div className="text-14 font-medium leading-5 text-gray-500">
                {capitalizedFirst(t("reservation.guest"))}
              </div>
              <div className="flex items-center text-14 font-normal text-gray-700">
                {reservations?.number_guests}
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}
