import React, { useState } from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { FooterViewType } from "../types"
import { VoucherControllerApi } from "@soctrip/axios-restaurant-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { useToast } from "src/hooks/use-toast"
import { useNavigate } from "react-router-dom"
import { ROUTERS } from "src/constants/router"
import BookingModal from "src/components/bookingModal"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"

const FooterView = (props: FooterViewType) => {
  const { t } = useTranslation()
  const { isSuccess, restaurantId, orderId } = props
  const [isShowBookModal, setIsShowBookModal] = useState<boolean>(false)
  const authStore = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  const showToast = useToast()

  const goToHome = () => {
    navigate(ROUTERS.FOOD)
  }
  const repay = () => {
    if (!orderId) return

    new VoucherControllerApi(config(SERVICE.RESTAURANT))
      .vouchersIdRepayPut(orderId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data) {
          const paymentUrl = `${res.data.data?.payment_url}?paymentToken=${res.data.data?.token}`
          if (paymentUrl) window.open(paymentUrl, "_self")
        } else {
          showToast({
            detail: "Repay failed. Please try again!",
            severity: "error",
          })
        }
      })
      .catch(() => {
        showToast({
          detail: "Repay failed. Please try again!",
          severity: "error",
        })
      })
  }

  const goToMyVoucher = () => {
    navigate(`/food/${ROUTERS.ORDERS}?tabState=VOUCHER`)
  }

  return (
    <div className=" mt-4 flex items-center justify-between px-4">
      {authStore?.value ? (
        <div className="flex items-center gap-[12px] text-[14px] font-semibold leading-[20px]">
          <button
            onClick={() => setIsShowBookModal(true)}
            className={`rounded-3 border border-gray-300  px-[20px] py-[12px] text-gray-900 ${
              isSuccess ? "hidden" : ""
            }`}
          >
            {capitalizedFirst(t("buy-voucher.footer-create-reservation"))}
          </button>
        </div>
      ) : null}
      <div className="flex items-center gap-[12px] text-[14px] font-semibold leading-[20px]">
        {!isSuccess ? (
          <>
            <button
              onClick={goToHome}
              className="rounded-3 border border-gray-300  px-[20px] py-[12px]"
            >
              {capitalizedFirst(t("buy-voucher.footer-go-home"))}
            </button>
            <button
              onClick={repay}
              className="rounded-3 bg-blue-600 px-[20px] py-[12px] text-white hover:bg-blue-500"
            >
              {capitalizedFirst(t("buy-voucher.footer-repay"))}
            </button>
          </>
        ) : (
          <>
            <button
              onClick={goToMyVoucher}
              className="rounded-3 border border-gray-300  px-[20px] py-[12px] text-gray-900 "
            >
              {capitalizedFirst(t("buy-voucher.footer-go-voucher"))}
            </button>
            <button
              onClick={goToHome}
              className="rounded-3 bg-blue-600 px-[20px] py-[12px] text-white hover:bg-blue-500"
            >
              {capitalizedFirst(t("buy-voucher.footer-go-home"))}
            </button>
          </>
        )}
      </div>
      {restaurantId &&  authStore?.value  ? (
        <BookingModal
          isShowModalBooking={isShowBookModal}
          restaurantId={restaurantId}
          closeModal={() => setIsShowBookModal(false)}
        />
      ) : null}
    </div>
  )
}

export default FooterView
