import React, { useEffect, useState } from "react"

import { handleCheckBloomFilter } from "src/utils/common"
import { FoodDetailActionsProps } from "./props"
import "./food-detail-actions.scss"
import ShareToFeed from "src/components/restaurant-view/share-to-feed"
import {
  FOOD_BOOKMARK_CATEGORY,
  SERVICE,
  SHARE_TYPE,
} from "src/constants/common"
import { StockControllerApi } from "@soctrip/food-service"
import { config } from "src/config/interceptors"
import { bookmarkProduct, deleteBookmark } from "src/services/bookmark-service"
import { StockDetailType } from "src/components/order-cart-view/type"
import { useToast } from "src/hooks/use-toast"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { convertCurrency } from "src/utils/convert-currency"

export default function FoodDetailActions(props: FoodDetailActionsProps) {
  const {
    food,
    updateSelectedVariant,
    updateStockData,
    bloom_filter,
    handleReload,
  } = props
  const [isOpenShare, setIsOpenShare] = useState<boolean>(false)
  const authStore = useSelector((state: RootState) => state.auth)
  const showToast = useToast()

  const [selectedVariant] = useState<number[]>(
    Array(food.prices.length).fill(0)
  )
  const [, setIsOutOfStock] = useState<boolean>(false)
  const [stockData, setStockData] = useState<StockDetailType | undefined>(
    undefined
  )
  const [, setIsNoLimit] = useState<boolean>(false)

  const handleShareToFeed = () => {
    if (authStore?.value) {
      setIsOpenShare(true)
    } else {
      showToast({ detail: "Please login before bookmark", severity: "warn" })
    }
  }

  useEffect(() => {
    if (selectedVariant.length && food?.prices.length) {
      const variationSelectedFist =
        food.prices[0].variations?.[selectedVariant[0]]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let variationSelectedSecond: any = undefined
      if (food.prices[1]) {
        variationSelectedSecond =
          food.prices[1].variations?.[selectedVariant[1]]
      }
      if (variationSelectedFist) {
        new StockControllerApi(config(SERVICE.FOOD))
          .findStockByVariationIds(
            variationSelectedFist?.id,
            variationSelectedSecond?.id || undefined
          )
          .then((res) => {
            if (res?.data?.data) {
              if (res?.data?.data?.quantity > 0) {
                updateStockData(res.data.data)
                setStockData(res.data.data)
                updateSelectedVariant(res.data.data.id)
                setIsOutOfStock(false)
                setIsNoLimit(false)
              } else if (!res.data.data.is_limited) {
                // case limit
                setIsOutOfStock(false)
                setStockData(res.data.data)
                updateSelectedVariant(res.data.data.id)
                setIsNoLimit(true)
              } else {
                setIsNoLimit(false)
                setIsOutOfStock(true)
              }
            }
          })
          .catch(() => setIsOutOfStock(true))
      }
    } else {
      const stock = food?.stocks?.[0]
      updateStockData(stock)
      setStockData(stock)
      updateSelectedVariant(stock.id)
      setIsOutOfStock(false)
    }
  }, [selectedVariant])

  const handleBookmark = async () => {
    if (authStore?.value) {
      if (bloom_filter) {
        if (
          !handleCheckBloomFilter(bloom_filter as string, food?.id as string)
        ) {
          await bookmarkProduct(food?.id, FOOD_BOOKMARK_CATEGORY.FOOD)
            .then(() => {
              handleReload()
            })
            .catch((error) => error)
        } else {
          deleteBookmark(food?.id)
            .then(() => {
              handleReload()
            })
            .catch((error) => {
              error
            })
        }
      } else {
        await bookmarkProduct(food?.id, FOOD_BOOKMARK_CATEGORY.FOOD)
          .then(() => {
            handleReload()
          })
          .catch((error) => error)
      }
    } else {
      showToast({ detail: "Please login before bookmark", severity: "warn" })
    }
  }

  return (
    <div className="flex h-full w-full flex-col gap-1 px-3 py-3">
      <div className="flex w-full items-center justify-between">
        <div className="w-full">
          <span className="text-14 font-medium text-gray-500">
            {food.catalogs?.[0]?.reference_global}
          </span>
          <span className="mx-1 text-14 font-medium text-gray-300">{">"}</span>
          <span className="text-14 font-semibold text-gray-900">
            {food.catalogs?.[0]?.reference}
          </span>
        </div>
        <div className="flex flex-1 items-end">
          <div className="flex w-full items-end gap-1">
            <div className="flex gap-10px">
              <div className="flex h-[44px] w-[44px] items-center justify-center rounded-3 border">
                <div
                  onClick={handleBookmark}
                  className={`flex cursor-pointer items-center justify-center rounded-full bg-white fill-none stroke-gray-700 p-6px hover:stroke-blue-500 ${
                    bloom_filter &&
                    handleCheckBloomFilter(
                      bloom_filter as string,
                      food?.id as string
                    )
                      ? "fill-yellow-400 stroke-yellow-400"
                      : "fill-none stroke-gray-500"
                  }`}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 12 15"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.33331 4.5999C1.33331 3.4798 1.33331 2.91974 1.5513 2.49192C1.74305 2.1156 2.04901 1.80964 2.42533 1.61789C2.85316 1.3999 3.41321 1.3999 4.53331 1.3999H7.46665C8.58675 1.3999 9.1468 1.3999 9.57463 1.61789C9.95095 1.80964 10.2569 2.1156 10.4487 2.49192C10.6666 2.91974 10.6666 3.4798 10.6666 4.5999V13.3999L5.99998 10.7332L1.33331 13.3999V4.5999Z" />
                  </svg>
                </div>
              </div>
              <div
                onClick={handleShareToFeed}
                className="flex h-[44px] w-[44px] items-center justify-center rounded-3 border fill-gray-900 stroke-gray-300"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 18 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.5236 0.0837206C8.14218 0.0740736 7.77674 0.244945 7.53566 0.546963C7.31891 0.818501 7.28926 1.08202 7.27178 1.27961C7.25431 1.47719 7.2573 1.66122 7.2573 1.89398V4.92316C5.59696 5.36751 4.05937 6.2147 2.90321 7.53509C1.4644 9.1782 0.667758 11.3056 0.666687 13.5096V14.0238C0.66673 14.1967 0.719003 14.3655 0.816384 14.507C0.913766 14.6486 1.05152 14.756 1.21088 14.8148C1.37024 14.8735 1.54346 14.8806 1.70694 14.8352C1.87042 14.7898 2.01621 14.694 2.12445 14.5609C2.91311 13.591 3.89782 12.808 5.01106 12.2628C5.71626 11.9174 6.49114 11.7963 7.2573 11.6583V14.106C7.2573 14.3387 7.25431 14.5228 7.27179 14.7203C7.28926 14.9179 7.31911 15.1799 7.53567 15.4513C7.53567 15.4519 7.53567 15.4525 7.53567 15.453C7.77667 15.7547 8.14169 15.9261 8.5236 15.9163C8.86662 15.9076 9.08129 15.7586 9.23962 15.6436C9.39795 15.5285 9.53154 15.4074 9.70463 15.2559L16.683 9.14992C16.8841 8.9739 17.1122 8.84836 17.2607 8.42713C17.3576 8.15149 17.3576 7.84857 17.2607 7.57294C17.1122 7.15185 16.8843 7.02622 16.683 6.85015L9.70463 0.744176C9.53154 0.5927 9.39794 0.471553 9.23962 0.356495C9.0813 0.241437 8.86659 0.0924004 8.5236 0.0837206ZM8.90494 2.25863L15.465 7.99992L8.90494 13.7412V10.5822C8.90497 10.4669 8.88178 10.3528 8.83679 10.247C8.79181 10.1413 8.72601 10.046 8.64346 9.96733C8.56092 9.8886 8.4634 9.82805 8.35697 9.78943C8.25053 9.75081 8.13745 9.73494 8.02476 9.74282C6.733 9.83229 5.46843 10.1717 4.29826 10.7449C3.70274 11.0365 3.14977 11.4037 2.62326 11.8044C2.89972 10.6532 3.34552 9.55391 4.13094 8.657C5.2034 7.43216 6.64609 6.61582 8.22273 6.33423C8.41367 6.3002 8.58674 6.19853 8.71152 6.04711C8.8363 5.89568 8.90479 5.70419 8.90495 5.50631L8.90494 2.25863Z" />
                </svg>
              </div>
            </div>
            <ShareToFeed
              isShow={isOpenShare}
              handleClose={() => setIsOpenShare(false)}
              id={food?.id as string}
              type={SHARE_TYPE.FOOD}
              attachments={food?.attachments}
              description={food?.description}
              avatar={food?.avatar?.id}
              name={food?.name}
            />
          </div>
        </div>
      </div>
      <div className="line-clamp-2 text-24 font-bold text-gray-700">
        {food.name}
      </div>
      <div className="mt-1 min-h-fit text-14 text-gray-500">
        {food?.description}
      </div>

      {/* PRICE */}
      <div className="rounded-3px flex w-full items-center justify-between">
        <div className="flex w-full items-center justify-between">
          <div className="flex w-full items-center gap-1">
            <p className="text-24 font-semibold leading-[32px] text-orange-dark-500">
              {convertCurrency(stockData?.price)}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
