
function escapeRegExp(text: string) {
  return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

export function getKeywords(listOfStrings: string[], typingText: string): Array<string> {
  if (typingText.length === 0) {
    return []
  }
  const results: Set<string> = new Set<string>()
  for (const string of listOfStrings) {
    const match = new RegExp(
      "((\\w+)?\\s)?(\\w*" + escapeRegExp(typingText) + "\\w*)(\\s(\\w+\\b))?", "i")
    const matchResult: RegExpMatchArray | null = string.match(match)
    if (matchResult !== null) {
      results.add(matchResult[3].trim().toLowerCase())
      if (matchResult[1] !== undefined) {
        results.add(`${matchResult[1]}${matchResult[3]}`.trim().toLowerCase())
      }
      if (matchResult[4] !== undefined) {
        results.add(`${matchResult[3]}${matchResult[4]}`.trim().toLowerCase())
      }
      results.add(matchResult[0].trim().toLowerCase())
    }
  }
  return Array.from(results).slice(0, 16)
}

