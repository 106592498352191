import React, { useMemo, useState } from "react"
import { Dialog } from "primereact/dialog"
import { CardFormType } from "./types"
import "./index.scss"
import { loadStripe } from "@stripe/stripe-js"
import { useToast } from "src/hooks/use-toast"
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js"
import { LOGO } from "src/constants/common"

export const cardOptionMobile = {
  style: {
    base: {
      fontSize: "16px",
      color: "#667085",
      lineHeight: "26px",
      fontWeight: "400",
    },
    invalid: {
      color: "red",
    },
  },
}
export const cardNumberOptionMobile = {
  placeholder: "1234 5678 9101 1213",
  style: {
    base: {
      fontSize: "16px",
      lineHeight: "26px",
      fontWeight: "400",
      color: "#667085",
    },
    invalid: {
      color: "red",
    },
  },
}

const CardForm = (props: CardFormType) => {
  const { isShow, handleClose, updateCardToken } = props
  const stripe = useStripe()
  const elements = useElements()
  const [isCardNumberError, setIsCardNumberError] = useState(true)
  const [isCardExpiredError, setIsCardExpiredError] = useState(true)
  const [isCardCvcError, setIsCardCvcError] = useState(true)
  const showToast = useToast()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleCardNumberChange(e: any) {
    setIsCardNumberError(!e.complete)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleCardExpiryChange(e: any) {
    setIsCardExpiredError(!e.complete)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleCvcChange(e: any) {
    setIsCardCvcError(!e.complete)
  }

  const delay = (delayTime: number) => {
    return new Promise((resolve) => setTimeout(resolve, delayTime))
  }

  const getTokenCard = async () => {
    if (stripe && elements) {
      const card = elements.getElement(CardNumberElement)
      if (card) {
        setIsLoading(true)
        const result = await stripe
          .createToken(card)
          .catch((err) => console.log(err))
        if (result?.token?.id) {
          updateCardToken(result.token.id as string)
          await delay(5000)
          handleClose()
        } else {
          showToast({
            detail: "Card invalid. Please try again!",
            severity: "error",
          })
        }
      }
    }
  }

  const isErrorCard = useMemo(() => {
    return isCardCvcError || isCardExpiredError || isCardNumberError
  }, [isCardCvcError, isCardExpiredError, isCardNumberError])

  if (!stripe || !elements) {
    return null
  }
  return (
    <Dialog
      id="cardFormModal"
      visible={isShow}
      draggable={false}
      onHide={() => handleClose()}
    >
      <div className="flex w-[500px] flex-col p-4 pt-0">
        <div className="flex flex-col items-center justify-center">
          <span className="text-24 font-bold text-blue-700">
            {`${LOGO.NAME} Payment`}
          </span>
        </div>
        <div className={"p-8 mt-3 flex flex-col rounded-4 bg-gray-300"}>
          <div
            className={
              "flex flex-col rounded-3 border border-gray-200 bg-white"
            }
          >
            <div className="flex flex-col border-b-[1px] border-gray-200">
              <div id="cardNumber" className="rounded-t-3 p-3 text-[#000000]">
                <CardNumberElement
                  options={cardNumberOptionMobile}
                  onChange={handleCardNumberChange}
                />
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-1 flex-col gap-2 border-r-[1px] border-gray-200">
                <div id="expiry" className="rounded-bl-3 p-3">
                  <CardExpiryElement
                    options={cardOptionMobile}
                    onChange={handleCardExpiryChange}
                  />
                </div>
              </div>
              <div className="flex flex-1 flex-col gap-2">
                <div id="cvc" className="rounded-br-lg p-3">
                  <CardCvcElement
                    options={cardOptionMobile}
                    onChange={handleCvcChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isErrorCard ? (
          <div className="mt-2 flex items-center">
            <span className="text-[12px] font-semibold leading-[16px] text-[#e24c4c]">
              {"Please enter card info correctly"}
            </span>
          </div>
        ) : null}
        <button
          disabled={isErrorCard || isLoading}
          onClick={getTokenCard}
          className={`mt-3 flex items-center justify-center rounded-3  px-3 py-1 font-medium text-white transition  ${
            isErrorCard ? "bg-blue-500" : "bg-blue-600 hover:bg-blue-500"
          }`}
        >
          {isLoading ? <i className="pi pi-spin pi-spinner mr-3" /> : null}
          <span className={isLoading ? "animate-pulse" : ""}>
            {isLoading ? "Waiting verify card!" : "Verify"}
          </span>
        </button>
      </div>
    </Dialog>
  )
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)

export default function CardFormView(props: CardFormType) {
  return (
    <Elements stripe={stripePromise}>
      <CardForm {...props} />
    </Elements>
  )
}
