import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { IMAGE_TYPE, RECOMMEND_TIME } from "src/constants/common"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { BannerType } from "../header-home-page/type"
import { getSystemBanner } from "src/services/banner-service"
import { handleCheckTime } from "src/utils/common"
import { ImgCustom } from "../common"

export default function HeaderPopularNearYou() {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParam, setSearchParam] = useSearchParams()
  const [title, setTitle] = useState<string>("")
  const [banner, setBanner] = useState<string>("")

  const handleGetBanner = async () => {
    await getSystemBanner(
      0,
      10,
      encodeURIComponent("category_code==food_popular_banner"),
      encodeURIComponent("")
    )
      .then((response) => {
        const data: BannerType[] = response?.data?.data?.data
        data?.map((item) => {
          if (handleCheckTime(item?.start_time, item?.end_time)) {
            if (item?.medias?.length) {
              setBanner(item?.medias?.[0]?.id)
            }
          }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    handleGetBanner()
    const type = searchParam.get("type")
    if (type === RECOMMEND_TIME.MORNING) {
      setTitle("food-home-page.morning-repast")
    } else if (type === RECOMMEND_TIME.MIDDAY) {
      setTitle("food-home-page.midday-delights")
    } else if (type === RECOMMEND_TIME.DINNER) {
      setTitle("food-home-page.dinner-delights")
    } else {
      setTitle("food-home-page.popular-near-title")
    }
  }, [])
  return (
    <div className="relative h-[288px] w-full">
      <ImgCustom
        type={IMAGE_TYPE.WEB}
        url={banner}
        alt={"banner"}
        className="h-full w-full object-fill"
      />
      <div className="max-[1200px] absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center justify-center">
        <div className="font-[ArchivoBlack400] text-[30px] font-normal leading-[38px] text-white">
          {capitalizedFirst(t(title)).toUpperCase()}
        </div>
      </div>
    </div>
  )
}
