import React from "react"
import requestCompleteIcon from "src/assets/images/request-complete-icon.svg"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { CompleteFormType } from "../types"
import { TYPE_PAGE } from "src/constants/common"
import { useNavigate } from "react-router-dom"

const CompleteForm = (props: CompleteFormType) => {
  const { dataComplete, typePage } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleGoToHome = () => {
    if (typePage === TYPE_PAGE.FOOD) navigate("/food")
    else navigate("/shopping")
  }

  if (!dataComplete) return null
  return (
    <div className="bg-white p-4 border-gray-200 rounded-4">
      <div className="flex flex-col items-center max-w-[739px]">
        <img src={requestCompleteIcon} alt="RequestIcon" className="w-[80px] h-[80px]" />
        <span className="text-gray-900 text-24 leading-32 font-semibold">{capitalizedFirst(t("request-open-page.complete-title"))}</span>
        <span className="mt-4px text-gray-500 text-14 leading-20 text-center">{capitalizedFirst(t("request-open-page.complete-description"))}</span>

        <div className="w-full mt-[4px]">
          <div className="flex flex-col py-3">
            <span className="text-gray-400 text-12 leading-16 font-medium">{capitalizedFirst(t("request-open-page.shop-information"))}</span>
            <span className="mt-[4px] text-gray-700 text-16 leading-24 font-semibold">{dataComplete.shop.name}</span>
            <span className="mt-[2px] text-gray-500 text-14 leading-20">{dataComplete.shop.address}</span>
          </div>
          <div className="flex justify-between items-start py-3 mt-[4px]">
            <div className="flex-1 flex flex-col">
              <span className="text-gray-400 text-12 leading-16 font-medium">{capitalizedFirst(t("request-open-page.user-information"))}</span>
              <span className="mt-[4px] text-gray-700 text-16 leading-24 font-semibold">{dataComplete.user.name}</span>
              <span className="mt-[2px] text-gray-500 text-14 leading-20">{dataComplete.user.email}</span>
              <span className="mt-[2px] text-gray-500 text-14 leading-20">{dataComplete.user.phone}</span>
            </div>
            <div className="flex-1 flex flex-col">
              <span className="text-gray-400 text-12 leading-16 font-medium">{capitalizedFirst(t("request-open-page.status"))}</span>
              <div className="block">
                <div className="w-fit rounded-[100px] py-[4px] px-2 mt-[4px] text-green-600 bg-green-100 text-14 leading-20 font-medium flex items-center justify-start">{"Successfully"}</div>
              </div>
            </div>
          </div>

        </div>

        <div className="flex mt-[4px] w-full gap-2">
          {
            typePage === TYPE_PAGE.FOOD ? (
              <div className="flex flex-col flex-1">
                <span className="text-gray-400 text-12 leading-16 font-medium">{capitalizedFirst(t("request-open-page.category"))}</span>
                {
                  dataComplete?.category?.map(i => <span key={i} className="mt-[4px] text-gray-700 text-16 leading-24 font-medium">{i}</span>)
                }
              </div>
            ) : null
          }

        </div>

        <div className="mt-4">
          <button
            onClick={handleGoToHome}
            className="text-white text-16 leading-24 font-semibold bg-blue-600 hover:bg-blue-500 rounded-3 border-blue-600 px-20px py-2">{capitalizedFirst(t("request-open-page.back-to-home"))}</button>
        </div>
      </div>
    </div>
  )
}

export default CompleteForm