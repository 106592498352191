import React, { useEffect, useState } from "react"
import { FoodViewProps } from "./types"
import { RecommendViewType } from "../food-home-view/types"
import FoodCardView from "../food-home-view/food-card-view"
import { FOOD_CARD_TYPE } from "src/constants/common"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function FoodView(props: FoodViewProps) {
  const [viewMoreLoading, setViewMoreLoading] = useState<boolean>(false)
  const [newData, setNewData] = useState<RecommendViewType[]>()
  const [limit, setLimit] = useState<number>(10)
  const { t } = useTranslation()

  useEffect(() => {
    const result: RecommendViewType[] = []
    if (props?.data?.foods?.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      props?.data?.foods?.forEach((i: any) => {
        result.push({
          id: i?.id,
          title: i?.name,
          imgUrl: i?.avatar?.id,
          point: i?.point,
          countReview: i?.sum_review,
          address: i?.restaurant_food?.name,
          price: i?.price,
          originPrice: i?.original_price,
          restaurant_food: i?.restaurant_food,
        })
      })
    }
    setNewData(result)
  }, [props?.data])

  const handleViewMore = () => {
    setViewMoreLoading(true)
    setLimit(limit + 10)
    setViewMoreLoading(false)
  }

  const handleViewLess = () => {
    setViewMoreLoading(true)
    setLimit(10)
    setViewMoreLoading(false)
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {newData?.slice(0, limit)?.map((item: RecommendViewType) => (
          <div key={item?.id}>
            <FoodCardView
              isShowPrice={true}
              isAddressView={true}
              isFoodType={true}
              type={FOOD_CARD_TYPE.FOOD}
              bloom_filter={props?.bloom_filter}
              handleReload={props?.handleReload}
              {...item}
            />
          </div>
        ))}
      </div>
      {viewMoreLoading ? (
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "1.5rem" }}></i>
      ) : (
        <>
          {newData?.length && newData?.length > limit ? (
            <div className="cursor-pointer text-14 font-semibold text-blue-700">
              <span onClick={handleViewMore}>
                {capitalizedFirst(t("global.view-more"))}
              </span>
            </div>
          ) : null}
          {newData?.length &&
          limit > 10 &&
          newData?.length &&
          limit > newData?.length ? (
              <div
                onClick={handleViewLess}
                className="cursor-pointer text-14 font-semibold text-blue-700"
              >
                {capitalizedFirst(t("global.view-less"))}
              </div>
            ) : null}
        </>
      )}
    </div>
  )
}
