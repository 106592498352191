import React from "react"
import emptyReviewsImage from "../../assets/images/empty-reviews.png"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"

export default function EmptyComment() {
  const { t } = useTranslation()

  return (
    <div className="flex h-full w-full items-center">
      <div className="flex h-full w-full flex-col">
        <div className="flex w-full items-center justify-center">
          <div className="h-[172px] w-[172px]">
            <img src={emptyReviewsImage} alt="" className="h-full w-full" />
          </div>
        </div>
        <div className="flex w-full items-center justify-center">
          <div className="flex w-full flex-col gap-1">
            <div className="w-full text-center text-18 font-semibold leading-7 text-gray-900">
              {capitalizedFirst(t("global.no-reviews-found"))}
            </div>
            <div className="w-full text-center text-14 font-normal leading-5 text-gray-700">
              {capitalizedFirst(t("global.no-reviews-message"))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
