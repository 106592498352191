import { CartType } from "src/components/order-cart-view/type"

export interface CartDetail {
  id: string
  count: number
  entity_id: string
  stock_id: string
}
export interface MyCartType {
  cartLocal: CartDetail[]
  cartData: CartType[]
  isSyncServer: boolean
  checkoutItems: string[]
  totalCart: number
}

export const initialState: MyCartType = {
  cartLocal: [],
  cartData: [],
  isSyncServer: false,
  checkoutItems: [],
  totalCart: 0,
}
