import { Skeleton } from "primereact/skeleton"
import React from "react"

export default function LoadingReport() {
  return (
    <div className="card">
      <div className="border-1 py-4">
        <ul className="m-0 list-none p-0">
          <li className="mb-3">
            <div className="flex items-center">
              <div className="flex items-center">
                <Skeleton
                  shape="circle"
                  size="2rem"
                  className="mr-2"
                ></Skeleton>
              </div>
              <div className="flex w-full items-center">
                <Skeleton width="100%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex items-center">
              <div className="flex items-center">
                <Skeleton
                  shape="circle"
                  size="2rem"
                  className="mr-2"
                ></Skeleton>
              </div>
              <div className="flex w-full items-center">
                <Skeleton width="100%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex items-center">
              <div className="flex items-center">
                <Skeleton
                  shape="circle"
                  size="2rem"
                  className="mr-2"
                ></Skeleton>
              </div>
              <div className="flex w-full items-center">
                <Skeleton width="100%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex items-center">
              <div className="flex items-center">
                <Skeleton
                  shape="circle"
                  size="2rem"
                  className="mr-2"
                ></Skeleton>
              </div>
              <div className="flex w-full items-center">
                <Skeleton width="100%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex items-center">
              <div className="flex items-center">
                <Skeleton
                  shape="circle"
                  size="2rem"
                  className="mr-2"
                ></Skeleton>
              </div>
              <div className="flex w-full items-center">
                <Skeleton width="100%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex items-center">
              <div className="flex items-center">
                <Skeleton
                  shape="circle"
                  size="2rem"
                  className="mr-2"
                ></Skeleton>
              </div>
              <div className="flex w-full items-center">
                <Skeleton width="100%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex items-center">
              <div className="flex items-center">
                <Skeleton
                  shape="circle"
                  size="2rem"
                  className="mr-2"
                ></Skeleton>
              </div>
              <div className="flex w-full items-center">
                <Skeleton width="100%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex items-center">
              <div className="flex items-center">
                <Skeleton
                  shape="circle"
                  size="2rem"
                  className="mr-2"
                ></Skeleton>
              </div>
              <div className="flex w-full items-center">
                <Skeleton width="100%"></Skeleton>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}
