import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { ReservationDetailsInfoProps } from "../reservation-information/type"
import CancelReservationModal from "src/components/cancel-reservation-modal"
import { RESERVATION_CODE } from "src/constants/common"
import {
  ReservationStatusControllerApi,
  ReservationStatusDetailDTO,
} from "@soctrip/axios-food-order-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { RestaurantDataType } from "src/components/common/modal/rating-modal/types"
import RatingRestaurantModal from "src/components/common/modal/rating-modal"

export default function ReservationStatus({
  reservations,
  handleReload,
}: ReservationDetailsInfoProps) {
  const { t } = useTranslation()
  const [isShowModalCancel, setIsShowModalCancel] = useState<boolean>(false)
  const [orderStatusTabDetails, setOrderStatusTabDetails] = useState<
    ReservationStatusDetailDTO[] | undefined
  >([])
  const [isShowRatingModal, setIsShowRatingModal] = useState<boolean>(false)

  const handleGetTabs = async () => {
    await new ReservationStatusControllerApi(config(SERVICE.FOOD_ORDER))
      .getReservationStatusHistory(reservations?.id as string)
      .then((response) => {
        setOrderStatusTabDetails(response?.data?.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (reservations?.id) {
      handleGetTabs()
    }
  }, [reservations])

  const activeIndex = orderStatusTabDetails?.findIndex(
    (tab) => tab.code === reservations?.status?.code
  )
  const isActive = (index: number) => index <= Number(activeIndex) || 0
  const restaurantDataRating = useMemo(() => {
    if (reservations) {
      return {
        name: reservations?.restaurant?.name || "",
        url: (reservations?.restaurant?.avatar?.id as string) || "",
        address: reservations?.restaurant?.address || "",
        guests: reservations?.number_guests || 0,
        time: reservations?.reservation_time + "" || "",
      } as RestaurantDataType
    }
    return undefined
  }, [reservations])

  const handleConfirm = () => {
    setIsShowRatingModal(false)
    if (handleReload) {
      setTimeout(() => {
        handleReload()
      }, 300)
    }
  }

  const handleRating = () => {
    setIsShowRatingModal(true)
  }

  return (
    <div className="flex w-full flex-col rounded-3 bg-white">
      <div className="flex h-[52px] w-full items-center border-b border-gray-100 py-3 md:px-3 text-18 font-semibold text-gray-800">
        {capitalizedFirst(t("reservation.reservation-status"))}
      </div>
      <div className="flex w-full flex-col gap-1">
        <div className=" flex w-full flex-row items-center py-3 md:px-3">
          {orderStatusTabDetails?.map((status, index) => (
            <div
              key={index}
              className="flex h-[32px] w-full flex-1 justify-center md:h-[44px]"
            >
              <div
                className={`relative flex w-full items-center pl-1 md:pl-[0px]  md:justify-center ${
                  index == orderStatusTabDetails.length - 1 &&
                  "rounded-r-[100px]"
                } ${index == 0 && "rounded-l-[100px]"}`}
                style={{
                  backgroundColor: status?.bg_color,
                  color: status?.color,
                }}
              >
                <span
                  className="z-[1] line-clamp-1 text-10 font-semibold md:text-16"
                  style={{ color: status?.color }}
                >
                  {capitalizedFirst(
                    t(`purchase-history.order-status-tabs.${status.name}`)
                  )}
                </span>
                {index !== orderStatusTabDetails.length - 1 && (
                  <>
                    <div
                      className={`${
                        isActive(index) ? "bg-blue-500" : ""
                      } absolute right-[3px] top-[4px] z-10 h-[24px] w-[24px] rotate-45 border-2 border-b-0 border-l-0 border-r-white border-t-white md:right-[0] md:top-[6px] md:h-[32px] md:w-[32px]`}
                      style={{
                        backgroundColor: status?.bg_color,
                      }}
                    ></div>
                    <div
                      className={
                        "wd:w-[20px] absolute right-0 h-[32px] w-[16px] md:h-[44px]"
                      }
                      style={{
                        backgroundColor:
                          orderStatusTabDetails?.[index + 1]?.bg_color,
                      }}
                    ></div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        {reservations?.status?.code === RESERVATION_CODE.PENDING ? (
          <div className="flex w-full items-center justify-end pb-1">
            <div
              onClick={() => setIsShowModalCancel(true)}
              className="cursor-pointer rounded-3 px-3 py-1"
            >
              <div className="text-14 font-semibold text-red-600">
                {capitalizedFirst(t("reservation.cancel-reservation"))}
              </div>
            </div>
          </div>
        ) : null}
        {reservations?.status?.code === RESERVATION_CODE.CHECK_IN && (
          <div className="flex w-full items-center justify-end px-3 pb-1">
            <button
              type="button"
              onClick={handleRating}
              className="truncate rounded-3 border border-gray-300 px-3 py-1 text-14 font-semibold leading-5"
            >
              {!reservations.total_rating
                ? capitalizedFirst(t("reservation.review-restaurant"))
                : capitalizedFirst(t("reservation.view"))}
            </button>
          </div>
        )}
      </div>
      <CancelReservationModal
        isShowModalCancel={isShowModalCancel}
        id={reservations?.id as string}
        closeModal={() => setIsShowModalCancel(false)}
        refetch={handleReload}
      />
      {isShowRatingModal &&
      reservations?.id &&
      reservations?.restaurant_id &&
      restaurantDataRating ? (
          <RatingRestaurantModal
            orderId={reservations.id}
            restaurantData={restaurantDataRating}
            restaurantId={reservations.restaurant_id}
            isShow={isShowRatingModal}
            // isRated={false}
            isRated={!!reservations?.total_rating}
            handleClose={() => setIsShowRatingModal(false)}
            handleConfirm={handleConfirm}
            code={reservations?.code || ""}
          />
        ) : null}
    </div>
  )
}
