import React from "react"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { OrderStatusProps } from "./props"
import { useQuery } from "@tanstack/react-query"
import { TabControllerApi } from "@soctrip/axios-food-order-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"

export default function OrderStatus({ order }: OrderStatusProps) {
  const { t } = useTranslation()
  const { data: tabsDetailRes } = useQuery({
    queryKey: ["order", "tabs", "detail"],
    queryFn: async () =>
      await new TabControllerApi(config(SERVICE.FOOD_ORDER)).getTabDetails(),
  })
  const activeIndex = tabsDetailRes?.data.findIndex(
    (tab) => tab.code === order?.status?.status_property?.tab
  )
  const isActive = (index: number) => index <= Number(activeIndex) || 0
  return (
    <div className="flex w-full flex-col rounded-3 bg-white">
      <div className="flex h-[52px] w-full items-center border-b border-gray-100 p-3 text-18 font-semibold text-gray-800">
        {capitalizedFirst(t("purchase-history.order-status"))}
      </div>
      <div className=" flex w-full flex-col flex-wrap items-center p-3 md:flex-row">
        {tabsDetailRes?.data?.map((tab, index) => (
          <div
            key={tab.code}
            className="m-4px flex h-[44px] w-full flex-1 justify-center md:m-0 "
          >
            <div
              className={`flex w-[90%] items-center justify-center md:w-full ${
                isActive(index) ? "bg-blue-600" : "bg-gray-100"
              } relative ${
                index == tabsDetailRes.data.length - 1 && "rounded-r-[100px]"
              } ${index == 0 && "rounded-l-[100px] md:rounded-l-[100px]"}`}
            >
              <span
                className={`font-semibold ${
                  isActive(index) ? "text-white" : "text-gray-600"
                }`}
              >
                {capitalizedFirst(
                  t(`purchase-history.order-status-tabs.${tab.name}`)
                )}
              </span>
              {index !== tabsDetailRes.data.length - 1 && (
                <>
                  <div
                    className={`${
                      isActive(index) ? "bg-blue-600" : ""
                    } absolute -right-1 z-10 h-20px w-20px  rotate-45 border-2 border-b-0 border-l-0 border-r-white border-t-white md:right-[3px] md:top-[6px] md:h-[32px] md:w-[32px]`}
                  ></div>
                  <div
                    className={`absolute right-0 h-[44px] w-0  md:w-[20px] ${
                      isActive(index + 1) ? "bg-blue-600" : "bg-gray-100"
                    }`}
                  ></div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
