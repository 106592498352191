import React, { useEffect, useState } from "react"
import { InputText } from "primereact/inputtext"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useDebounce } from "use-debounce"
import EmptyPage from "src/components/common/empty"
import {
  ReservationControllerApi,
  ReservationDetailDTO,
  ReservationStatusControllerApi,
} from "@soctrip/axios-food-order-service"
import { useQuery } from "@tanstack/react-query"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import RestaurantReservationCard from "../restaurant-reservation-card"
import CommentLoading from "../comment-view/loading"

export default function MyReservation() {
  const [searchValue, setSearchValue] = useState<string>("")
  const [tabSelected, setTabSelected] = useState<string>("")
  const [searchDebounce] = useDebounce(searchValue, 800)
  const [totalOrder, setTotalOrder] = useState(0)
  const [page, setPage] = useState(0)
  const { t } = useTranslation()
  const [orderData, setOrderData] = useState<ReservationDetailDTO[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [isRenderView, setIsRenderView] = useState<boolean>(false)

  const { data: orderTabs } = useQuery({
    queryKey: ["order", "tabs"],
    queryFn: async () =>
      await new ReservationStatusControllerApi(
        config(SERVICE.FOOD_ORDER)
      ).getAllTabMyReservation(),
  })

  const fetchOrderData = async (
    tabSelected: string,
    searchDebounce: string,
    page: number
  ) => {
    setIsLoading(true)
    try {
      const res = await new ReservationControllerApi(
        config(SERVICE.FOOD_ORDER)
      ).myReservation(tabSelected, searchDebounce, page, 5)
      const data = res.data.data?.data
      const total = res?.data.data?.totalElement
      if (data) {
        setOrderData(data)
        setIsLoading(false)
      }
      if (total) {
        setTotalOrder(total)
      }
    } catch (error) {
      setOrderData([])
      setTotalOrder(0)
      setIsLoading(false)
    }
  }

  const fetchNextPage = async () => {
    const nextPage = page + 1
    setPage(nextPage)
    setIsLoadMore(true)
    try {
      const res = await new ReservationControllerApi(
        config(SERVICE.FOOD_ORDER)
      ).myReservation(tabSelected, searchDebounce, nextPage, 5)
      const data = res.data.data?.data
      const total = res?.data.data?.totalElement
      if (data) {
        const newOrderData = [...orderData, ...data]
        setOrderData(newOrderData)
      }
      if (total) {
        setTotalOrder(total)
      }
      setIsLoadMore(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const historyTab = localStorage.getItem("ORDER_TAB")
    const currentTab = historyTab ? historyTab : "ALL"
    setTabSelected(currentTab)
    setIsRenderView(true)
  }, [])

  const handleChangeTab = (code?: string) => {
    if (code) {
      setTabSelected(code)
      fetchOrderData(code, searchValue, 0)
      localStorage.setItem("ORDER_TAB", code)
    }
  }

  const refetchData = () => {
    setPage(0)
    fetchOrderData(tabSelected, searchValue, 0)
  }

  useEffect(() => {
    if (!isRenderView) return
    setPage(0)
    fetchOrderData(tabSelected, searchValue, 0)
  }, [searchDebounce, isRenderView])

  if (!isRenderView) return null
  return (
    <div className="h-full w-full">
      <div className="flex flex-col rounded-3 bg-white">
        <div className="flex w-full flex-col md:flex-row items-center justify-between border-b py-2 gap-1">
          <div className="flex items-center px-3 text-18 font-semibold text-gray-800">
            {capitalizedFirst(t("global.my-reservation"))}
          </div>
          <div className="flex items-center justify-end px-3">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                className="flex h-[36px] min-w-[50px] max-w-[320px] items-center text-16"
                placeholder={capitalizedFirst(t("global.search"))}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </span>
          </div>
        </div>
        <div className=" item-center flex h-fit flex-row flex-wrap justify-center gap-1 px-2 py-2  md:justify-start md:gap-2 lg:px-3 ">
          {orderTabs?.data?.data?.map((tab, index) => (
            <div
              key={index}
              onClick={() => handleChangeTab(tab.code)}
              className={`md:px-[4px] px-4px flex flex-row cursor-pointer items-center justify-center gap-4px truncate rounded-2 border border-gray-200 py-1 text-14 font-semibold md:border-none md:text-12  lg:w-max lg:px-3 lg:text-14 ${
                tab?.code === tabSelected
                  ? "bg-blue-light-100 text-blue-700"
                  : "text-gray-500"
              }`}
            >
              <p className="truncate flex flex-row">
                {capitalizedFirst(
                  t(`purchase-history.order-status-tabs.${tab?.name}`)
                )}
              </p>
              <div className={`flex items-start text-start rounded-3 border ${
                tab?.code === tabSelected
                  ? "border-blue-400 bg-blue-600 text-white"
                  : "border-gray-100"
              }`}>
                <span className="h-14px min-w-[20px] font-normal rounded-circle p-2px flex items-center justify-center text-10 ">
                  {tab?.total_items}
                </span> 
              </div>
              {/* <div className={`${tab?.code === tabSelected ? "border-blue-600" : ""} w-[24px] h-[24px] border rounded-circle flex items-center justify-center text-12`}>{tab?.total_items}</div> */}
            </div>
          ))}
        </div>
      </div>
      {/* content */}
      <div className="mt-3 w-full">
        {/* data order */}
        {isLoading ? (
          <div className="flex h-[600px] w-full bg-white">
            <CommentLoading />
          </div>
        ) : (
          <div className="h-full w-full overflow-y-visible">
            {!orderData?.length ? (
              <div className="flex h-[600px] w-full items-center justify-center">
                <EmptyPage message={capitalizedFirst(t("global.order-not-found"))} />
              </div>
            ) : (
              <div className="flex h-full w-full flex-col gap-3 overflow-auto">
                {orderData?.map((order, index) => (
                  <div className="w-full" key={index}>
                    <RestaurantReservationCard
                      data={order}
                      refetch={refetchData}
                    />
                  </div>
                ))}
              </div>
            )}
            {orderData.length < totalOrder && orderData.length > 0 && (
              <button
                type="button"
                onClick={fetchNextPage}
                className="mb-2 mt-[10px] text-12 font-semibold text-blue-600"
              >
                <div>
                  {isLoadMore ? (
                    <i
                      className="pi pi-spin pi-spinner"
                      style={{ fontSize: "1rem" }}
                    ></i>
                  ) : (
                    capitalizedFirst(t("global.view-more"))
                  )}
                </div>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
