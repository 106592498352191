import React from "react"
import { ReservationDetailsInfoProps } from "../reservation-information/type"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"

export default function ReservationRestaurantInformation({
  reservations,
}: ReservationDetailsInfoProps) {
  const { t } = useTranslation()

  return (
    <div className="min-h-[196px] w-full rounded-3 bg-white md:h-full">
      <div className="flex w-full flex-col">
        <div className="flex h-[60px] w-full items-center border-b border-gray-100 md:px-3">
          <div className="text-18 font-semibold leading-7 text-gray-900">
            {capitalizedFirst(t("reservation.restaurant-information"))}
          </div>
        </div>
        <div className="flex w-full flex-col gap-3 py-3 md:px-3">
          <div className="flex w-full items-center justify-between">
            {/* name */}
            <div className="flex w-1/2 flex-col gap-4px">
              <div className="text-14 font-medium leading-5 text-gray-500">
                {capitalizedFirst(t("reservation.restaurant-name"))}
              </div>
              <div className="flex items-center text-14 font-normal leading-5 text-gray-700">
                {reservations?.restaurant?.name}
              </div>
            </div>
            {/* phone */}
            <div className="flex w-1/2 flex-col gap-4px">
              <div className="text-14 font-medium leading-5 text-gray-500">
                {capitalizedFirst(t("purchase-history.phone"))}
              </div>
              <div className="flex items-center text-14 font-normal leading-5 text-gray-700">
                {reservations?.restaurant?.phone}
              </div>
            </div>
          </div>
          {/* Address */}
          <div className="flex w-full flex-col gap-4px">
            <div className="text-14 font-medium leading-5 text-gray-500">
              {capitalizedFirst(t("reservation.address"))}
            </div>
            <div className="flex items-center text-14 font-normal leading-5 text-gray-700">
              {reservations?.restaurant?.address}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
