import React, { useEffect, useMemo, useRef, useState } from "react"
import FoodDetailGallery from "src/components/food-detail-view/food-detail-gallery"
import FoodDetailActions from "src/components/food-detail-view/food-detail-actions"
import FoodDetailRelated from "src/components/food-detail-view/food-detail-related"
import * as restaurantService from "@soctrip/axios-restaurant-service"
import { useQuery } from "@tanstack/react-query"
import { getFoodDetails } from "src/services/food-details-service"
import { useParams } from "react-router-dom"
import { CartControllerApi } from "@soctrip/axios-cart-service"
import { config } from "src/config/interceptors"
import { useToast } from "src/hooks/use-toast"
import emptyImage from "src/assets/images/empty.png"
import SkeletonDetails from "src/components/common/skeleton-details"
import { useSelector } from "react-redux"
import type { RootState } from "src/app/store"
import { StockDetailType } from "src/components/order-cart-view/type"
import { RestaurantProperties } from "src/components/restaurant-view/types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { SERVICE } from "src/constants/common"
import RestaurantProfileDetailPage from "./restaurant-profile-detail-page"
import { useTranslation } from "react-i18next"
import FoodOfRestaurant from "src/components/food-detail-view/food-of-restaurant"
import { getBloomFilter } from "src/services/bookmark-service"
import { handlePostCountViewBoth } from "src/services/count-view-service"

export default function FoodDetailPage() {
  const { t } = useTranslation()
  const params = useParams()
  const showToast = useToast()
  const elRef = useRef<HTMLDivElement>(null)
  const [note, setNote] = useState<string>("")
  const [quantity, setQuantity] = useState(1)
  const [avatar, setAvatar] = useState<string>("")
  const [dataShop, setDataShop] = useState<RestaurantProperties>()
  const commentViewRef = useRef<HTMLDivElement>(null)
  const [, setStockData] = useState<StockDetailType | undefined>(undefined)
  const { data, isLoading, isError } = useQuery(
    ["food", "details", params?.id],
    async () => await getFoodDetails(params.id || "")
  )
  const [selectedVariant, setSelectedVariant] = useState<string>("")
  const authStore = useSelector((state: RootState) => state.auth)
  const [bloomData, setBloomData] = useState<string>("")
  const [catalogGlobalId, setCatalogGlobalId] = useState<string>("")

  if (isError)
    return (
      <div className="mb-5 mt-4 flex h-screen items-center justify-center bg-white">
        <img src={emptyImage} alt="" />
      </div>
    )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callApiAddToCart = (reqData: any) => {
    new CartControllerApi(config(SERVICE.CART))
      .cartsPost(reqData)
      .then(() => {
        showToast({
          detail: "Add food to cart successfully",
          severity: "success",
        })
      })
      .catch(() => {
        showToast({ detail: "Add food to cart failed", severity: "error" })
      })
  }

  const handleClickAddToCart = () => {
    const reqData = {
      object_type: "FOOD",
      entity_id: data?.data?.data?.restaurant_food?.id as string,
      object_id: data?.data?.data?.id as string,
      stock_id: selectedVariant,
      quantity: quantity,
      note: note,
    }
    if (authStore.value) callApiAddToCart(reqData)
    else {
      showToast({
        detail: "Please login before add to cart!",
        severity: "warn",
      })
    }
  }

  useEffect(() => {
    if (data?.data?.data?.restaurant_food?.id) {
      setCatalogGlobalId(
        data?.data?.data?.catalogs?.[0]?.reference_global_id as string
      )
      new restaurantService.RestaurantControllerApi(config(SERVICE.RESTAURANT))
        .restaurantsIdGet(data?.data?.data?.restaurant_food?.id)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          setDataShop(response?.data?.data)
        })
        .catch((error) => error)
    }
  }, [data])

  useEffect(() => {
    if (dataShop?.avatar?.id) {
      setAvatar(dataShop?.avatar?.id)
    }
  }, [dataShop])
  const clickIntoCommentView = () => {
    commentViewRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    handleGetBloomData()
  }, [])
  const restaurantId = useMemo(() => {
    if (data?.data?.data?.restaurant_food?.id)
      return data.data.data.restaurant_food.id
    return ""
  }, [data])

  // get bloom data
  const handleGetBloomData = async () => {
    await getBloomFilter()
      .then((response) => {
        setBloomData(response?.data?.data?.bloom_filter)
      })
      .catch((error) => error)
  }

  useEffect(() => {
    if (authStore?.value && restaurantId ) {
      handlePostCountViewBoth()
    }
  }, [restaurantId])

  return (
    <div className="mx-auto mb-5 mt-4 max-w-[1200px]">
      {isLoading ? (
        <div className="flex min-h-[100vh] items-center justify-center">
          <SkeletonDetails />
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="mb-3 flex flex-col gap-4 lg:flex-row">
            <div className="w-full flex-1 lg:w-[60%]">
              <FoodDetailGallery images={data?.data?.data?.attachments || []} />
            </div>
            <div className="flex w-full flex-col gap-2 lg:w-[40%]" ref={elRef}>
              <div className="flex-1 rounded-3 border border-gray-200 bg-white">
                <FoodDetailActions
                  restaurantId={restaurantId}
                  clickIntoCommentView={clickIntoCommentView}
                  point={data?.data?.data?.point}
                  note={note}
                  quantity={quantity}
                  updateSelectedVariant={(newValue: string) =>
                    setSelectedVariant(newValue)
                  }
                  updateQuantity={(newQuantity: number) =>
                    setQuantity(newQuantity)
                  }
                  updateNote={(newValue: string) => setNote(newValue)}
                  addToCart={handleClickAddToCart}
                  updateStockData={(newStock: StockDetailType) =>
                    setStockData(newStock)
                  }
                  food={data?.data?.data}
                  bloom_filter={bloomData}
                  handleReload={handleGetBloomData}
                />
              </div>
              <RestaurantProfileDetailPage
                id={dataShop?.id}
                avatar={avatar}
                background={dataShop?.background?.id}
                rating={dataShop?.rating}
                name={dataShop?.name}
                province={`${dataShop?.district?.name}, ${dataShop?.province?.name}`}
                isNavigate={true}
                sum_reviews={dataShop?.sum_reviews}
                bloom_filter={bloomData}
                handleReload={handleGetBloomData}
              />
              <div className="w-full rounded-3 border border-gray-200 bg-white">
                <FoodOfRestaurant data={dataShop} />
              </div>
            </div>
          </div>
          <div>
            <FoodDetailRelated
              isAddress
              title={capitalizedFirst(t("product-details.recommend-meals"))}
              bloom_filter={bloomData}
              handleReload={handleGetBloomData}
              catalogGlobalId={catalogGlobalId}
            />
          </div>
        </div>
      )}
    </div>
  )
}
