import React from "react"
import { ShopDataProps } from "../approve-info-view/type"

export default function ApproveShopUserInfo(props: ShopDataProps) {
  const { user_email, user_name, user_phone } = props

  return (
    <div className="w-full p-3">
      <div className="flex w-full flex-col lg:flex-row">
        {/* Title */}
        <div className="flex-1">
          <div className="flex flex-col">
            <span className="leading-28px text-[18px] font-semibold text-gray-900">
              {"User information"}
            </span>
          </div>
        </div>
        {/* Content */}
        <div className="flex w-full flex-col gap-2 lg:w-2/3">
          {/* Full name */}
          <div className="flex w-full flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {"Full name"}
            </span>
            <input
              className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
              placeholder="Empty"
              name="shop_name"
              readOnly
              value={user_name ? user_name : ""}
            />
          </div>
          {/* Email and phone */}
          <div className="flex w-full flex-col items-center gap-3 md:flex-row">
            {/* Email */}
            <div className="flex w-full flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {"Email"}
              </span>
              <input
                className="h-[44px] rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
                placeholder="Empty"
                name="shop_name"
                readOnly
                value={user_email ? user_email : ""}
              />
            </div>
            {/* Phone */}
            <div className="flex w-full flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {"Phone"}
              </span>
              <input
                className="h-[44px] rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
                placeholder="Empty"
                name="shop_name"
                readOnly
                value={user_phone ? user_phone : ""}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
