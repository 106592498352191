/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { OrderStatusDetailsProps } from "./props"
import { ImgCustom } from "src/components/common"

export default function OrderStatusDetails({ order }: OrderStatusDetailsProps) {
  const { t } = useTranslation()
  const elRef = useRef<HTMLDivElement>(null)
  const formatDate = (date?: Date) => {
    if (date) {
      const newTime = new Date(date)?.toLocaleString("en-GB")
      return newTime
    }
  }
  return (
    <div className="flex h-full w-full flex-col rounded-3 bg-white">
      <div className="flex h-[60px] w-full items-center border-b border-gray-100">
        <p className="flex items-center p-3 text-18 font-semibold text-gray-800">
          {capitalizedFirst(t("purchase-history.order-status-details"))}
        </p>
      </div>
      {/* order status details content */}
      {order?.status_details?.length ? (
        <div className="h-full w-full px-3 pb-3 pt-1">
          {order?.status_details?.map((order_status, index) => (
            <div key={order_status.id}>
              {order_status?.is_public ? (
                <div
                  key={order_status.id}
                  ref={elRef}
                  className={"mt-3 flex h-full w-full gap-2 first:mt-2"}
                >
                  <div
                    className="flex flex-col gap-1 pt-10px"
                    style={{
                      height: `${elRef?.current?.clientHeight}`,
                    }}
                  >
                    <span
                      className={`pi pi-circle-fill text-10 text-gray-500 ${
                        index + 1 === order?.status_details?.length
                          ? "text-orange-dark-400"
                          : ""
                      }`}
                    ></span>
                    {index + 1 !== order?.status_details?.length && (
                      <div className="ml-4px mt-4px h-full w-full border-l-2 border-gray-200"></div>
                    )}
                  </div>
                  <div className="flex h-fit w-full flex-col gap-6px">
                    <div className="flex items-center gap-1">
                      <div
                        className={
                          "rounded-5 bg-gray-200 px-2 py-4px text-14 font-medium text-gray-700 "
                        }
                        style={{
                          backgroundColor: order_status?.status_property?.color,
                          color: order_status?.status_property?.font_color,
                        }}
                      >
                        {capitalizedFirst(
                          t(
                            `purchase-history.order-status-tabs.${order_status.name}`
                          )
                        )}
                      </div>
                    </div>
                    <div className="text-14 font-medium text-gray-700">
                      {order_status.note}
                    </div>
                    <div className="flex gap-1">
                      {order_status?.attachments?.map((attachment) => {
                        return (
                          <div key={attachment.id}>
                            <ImgCustom
                              url={attachment?.id as string}
                              alt="image"
                              className="h-[70px] w-[72px] rounded-2 object-cover object-center"
                            />
                          </div>
                        )
                      })}
                    </div>
                    <div className="text-14 font-normal leading-5 text-gray-500">
                      {order_status.message}
                    </div>
                    <div className="text-12 font-medium text-gray-500">
                      {formatDate((order_status as any)?.created_at)}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ) : (
        <div className="p-3 text-16 font-semibold text-gray-500">
          {capitalizedFirst(t("global.empty-data"))}
        </div>
      )}
    </div>
  )
}
