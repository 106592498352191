import React from "react"
import { ReservationDetailsInfoProps } from "../reservation-information/type"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"

export default function ReservationUserInformation({
  reservations,
}: ReservationDetailsInfoProps) {
  const { t } = useTranslation()
  return (
    <div className="min-h-[196px] w-full rounded-3 bg-white md:h-full">
      <div className="flex w-full flex-col">
        <div className="flex h-[60px] w-full items-center border-b border-gray-100 md:px-3">
          <div className="text-18 font-semibold leading-7 text-gray-900">
            {capitalizedFirst(t("reservation.user-information"))}
          </div>
        </div>
        <div className="flex w-full flex-col">
          <div className="flex w-full items-center justify-between py-3 md:px-3">
            {/* full name */}
            <div className="flex w-1/2 flex-col gap-4px">
              <div className="text-14 font-medium leading-5 text-gray-500">
                {capitalizedFirst(t("reservation.full-name"))}
              </div>
              <div className="flex items-center text-14 font-normal leading-5 text-gray-700">
                {reservations?.customer?.full_name}
              </div>
            </div>
            {/* phone */}
            <div className="flex w-1/2 flex-col gap-4px">
              <div className="text-14 font-medium leading-5 text-gray-500">
                {capitalizedFirst(t("purchase-history.phone"))}
              </div>
              <div className="flex items-center text-14 font-normal leading-5 text-gray-700">
                {reservations?.customer?.phone}
              </div>
            </div>
          </div>
          {/* email */}
          <div className="flex w-full flex-col gap-4px md:px-3 pb-3">
            <div className="text-14 font-medium leading-5 text-gray-500">
              {capitalizedFirst(t("global.email"))}
            </div>
            <div className="flex items-center text-14 font-normal leading-5 text-gray-700">
              {reservations?.customer?.email}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
