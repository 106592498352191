import React, { useEffect, useMemo, useState } from "react"
import { BreadCrumb } from "primereact/breadcrumb"
import { MenuItem } from "primereact/menuitem"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { PURCHASE_HISTORY_NAVIGATE_CODE } from "src/constants/order"
import EmptyPage from "src/components/common/empty"
import { useQuery } from "@tanstack/react-query"
import { OrderControllerApi } from "@soctrip/axios-food-order-service"
import { config } from "src/config/interceptors"
import AddressListView from "src/components/food-address-profile-view/address-list-view"
import { SERVICE } from "src/constants/common"
import MyReservation from "src/components/my-reservation"
import MyVoucher from "src/components/my-voucher"

export default function Orders() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [currentLabel, setCurrentLabel] = useState<string>(
    "global.my-reservation"
  )

  const [searchParams] = useSearchParams()
  const [selected, setSelected] = useState<string>(
    PURCHASE_HISTORY_NAVIGATE_CODE.PURCHASE
  )

  const { data, isLoading, isError } = useQuery(
    ["order", "setting"],
    async () =>
      await new OrderControllerApi(config(SERVICE.FOOD_ORDER)).getSettings()
  )

  const orderSetting = useMemo(() => data?.data.data, [data])
  const handleSelectedBar = (code?: string, label?: string) => {
    if (code) {
      setSelected(code)
    }
    if (label) setCurrentLabel(label)
  }

  const items: MenuItem[] = [
    {
      label: capitalizedFirst(t("purchase-history.food")),
      command: () => {
        navigate("/food")
      },
    },
    {
      label: capitalizedFirst(t(currentLabel)),
      className: "font-semibold",
      command: () => {
        navigate("/food/orders")
      },
    },
  ]
  const home: MenuItem = {
    icon: "pi pi-home",
    command: () => {
      navigate("/")
    },
  }

  if (isError) {
    return (
      <div className="mx-auto max-w-[1200px] pb-3">
        <div className="my-3 flex h-screen w-full items-center justify-center gap-2 rounded-3 bg-white">
          <div className="h-full w-full">
            <EmptyPage message="Network Error. Please refresh page try again." />
          </div>
        </div>
      </div>
    )
  }
  const detectTabActiveInit = () => {
    const tabValue = searchParams.get("tabState")
    if(tabValue === PURCHASE_HISTORY_NAVIGATE_CODE.VOUCHER) setSelected(PURCHASE_HISTORY_NAVIGATE_CODE.VOUCHER)
  }
  useEffect(() => {
    detectTabActiveInit()
  }, [])
  return (
    <div className="mx-auto max-w-[1200px] pb-3">
      {isLoading ? (
        <div className="my-3 flex h-screen w-full items-center justify-center gap-2 rounded-3 bg-white">
          <p className="animate-pulse duration-75">Loading...</p>
          <i className="pi pi-spin pi-spinner text-24 font-bold text-blue-light-400"></i>
        </div>
      ) : (
        <div className="flex h-full w-full flex-col">
          <div className="flex w-full sm:flex-col">
            {/* breadcrumb */}
            <div className="item-center flex w-full">
              <BreadCrumb
                model={items}
                home={home}
                className="flex h-6 items-center border-none bg-transparent text-16"
              />
            </div>
          </div>
          {/* content */}
          <div className="flex h-full w-full flex-col gap-3 md:flex-row">
            <div
              className={`z-10 flex h-fit w-full  flex-row flex-wrap justify-center gap-2 rounded-3 bg-white md:w-1/4 md:flex-col sticky top-[72px] ${
                orderSetting?.length && "py-2"
              }`}
            >
              {orderSetting?.slice(0, 3)?.map((item) => (
                <div
                  onClick={() => handleSelectedBar(item?.code, item.label)}
                  key={item.code}
                  className="flex w-[47.5%] cursor-pointer items-center justify-between rounded-3 border border-gray-200 p-1 md:w-full md:border-none"
                >
                  <div
                    className={`flex items-center gap-4px md:gap-3 hover:text-blue-600 ${
                      selected === item.code ? "text-blue-600" : "text-gray-500"
                    }`}
                  >
                    <span className={`pi ${item.icon} text-20`}></span>
                    <div className="truncate text-14 font-semibold lg:text-16">
                      {capitalizedFirst(t(item.label ?? ""))}
                    </div>
                  </div>
                  {![
                    PURCHASE_HISTORY_NAVIGATE_CODE.SETTING,
                    PURCHASE_HISTORY_NAVIGATE_CODE.SECURITY,
                    PURCHASE_HISTORY_NAVIGATE_CODE.HELP,                    
                  ].includes(item.code as string) && (
                    <div
                      className={
                        "flex items-center justify-end rounded-[100%] bg-gray-200 px-4px md:px-1 md:py-[3px]"
                      }
                    >
                      <p className="text-12 font-medium text-gray-700">
                        {item.total_item}
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex w-full flex-col rounded-3 md:w-3/4">
              {/* my order */}
              {selected === PURCHASE_HISTORY_NAVIGATE_CODE.PURCHASE && (
                // <MyOrders />
                <MyReservation />
              )}
              {selected === PURCHASE_HISTORY_NAVIGATE_CODE.ADDRESS && (
                <AddressListView />
              )}
              {selected === PURCHASE_HISTORY_NAVIGATE_CODE.VOUCHER && (
                <MyVoucher />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
