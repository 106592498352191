import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface SearchLocationNearYou {
  value:
    | {
        latitude: number
        longitude: number
        radius: number
      }
    | undefined
}

export interface DataSearchNearYou {
  latitude: number
  longitude: number
  radius: number
}

export const defaultSearchLocationNearYou = {
  longitude: 106.62965,
  latitude: 10.82302,
  radius: 100,
}

const initialState: SearchLocationNearYou = {
  value: undefined,
}

export const searchLocationNearYouSlice = createSlice({
  name: "searchLocationNearYou",
  initialState,
  reducers: {
    updateSearchLocationNearYou: (
      state,
      action: PayloadAction<DataSearchNearYou>
    ) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateSearchLocationNearYou } =
  searchLocationNearYouSlice.actions

export default searchLocationNearYouSlice.reducer
