import React from "react"
import { Location } from "./types"
import locationIcon from "../../assets/images/location-icon.svg"

export default function LocationOptionTemplate(option: Location) {
  return (
    <div
      className="
		  flex items-center gap-2"
    >
      <div className="flex h-40px w-40px items-center justify-center rounded-full bg-blue-light-100">
        <img src={locationIcon} alt="location-icon" />
      </div>
      <div className="flex flex-col">
        <div className="text-14 font-medium leading-5 text-gray-900">
          {option.province_name}
        </div>
        <div className="text-14 font-normal leading-5 text-gray-600">
          {` ${option?.country_name}`}
        </div>
      </div>
    </div>
  )
}
