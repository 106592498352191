import React, { useEffect, useState } from "react"
import MerchantBackground from "../restaurant-view/header"
import { useLocation } from "react-router"
import { RestaurantProperties } from "../restaurant-view/types"
import * as restaurantService from "@soctrip/axios-restaurant-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import MerchantProfile from "../merchant-profile"

export default function RestaurantReviewPageHeader() {
  const location = useLocation()
  const [background, setBackground] = useState<string>("")
  const [dataShop, setDataShop] = useState<RestaurantProperties>()
  const [avatar, setAvatar] = useState<string>("")

  useEffect(() => {
    const pathParams = location.pathname.split("/")
    const shopId = pathParams[pathParams?.length - 1] || ""
    if (shopId) {
      new restaurantService.RestaurantControllerApi(config(SERVICE.RESTAURANT))
        .restaurantsIdGet(shopId)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          setDataShop(response?.data?.data)
        })
        .catch((error) => error)
    }
  }, [])

  useEffect(() => {
    if (dataShop?.background?.id) {
      setBackground(dataShop?.background?.id)
    }
    if (dataShop?.avatar?.id) {
      setAvatar(dataShop?.avatar?.id)
    }
  }, [dataShop, location])

  return (
    <div className="w-full">
      <div className="relative">
        <MerchantBackground imgUrl={background} />
        <div className="absolute left-1/2 top-[80%] flex w-[1200px] -translate-x-1/2 transform flex-col rounded-t-3 bg-white">
          <MerchantProfile
            id={dataShop?.id}
            avatar={avatar}
            rating={dataShop?.rating}
            name={dataShop?.name}
            reviews={dataShop?.sum_reviews}
            food_types={dataShop?.food_types}
            background={background}
            province={dataShop?.province?.name}
            street={dataShop?.street}
            ward={dataShop?.ward?.name}
            district={dataShop?.district?.name}
          />
        </div>
      </div>
    </div>
  )
}
