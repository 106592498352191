import React, { useState } from "react"
import VoucherView from "../merchant-voucher/voucher"
import { RestaurantVoucherType } from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { t } from "i18next"
import { ImgCustom } from "../common"

export default function RestaurantVouchersDetails(
  props: RestaurantVoucherType
) {
  const { restaurant, vouchers, total_element, selectTab } = props
  const itemPerPage = 4
  const [switchVoucher, setSwitchVoucher] = useState<boolean>(false)
  const [visibleItems, setVisibleItems] = useState(itemPerPage)
  
  const onViewMore = () => {
    const newVisibleItems = Math.min(visibleItems + itemPerPage, total_element)
    if (visibleItems < total_element) {
      setVisibleItems(newVisibleItems)
    }
  }
  const onViewLess = () => {
    setVisibleItems(itemPerPage)
  }
  const onShowVoucher = () => {
    setSwitchVoucher(!switchVoucher)
  }

  return (
    <section className="flex flex-col rounded-3 border border-gray-200 bg-white">
      <div className="flex flex-row justify-between p-3">
        <div className="flex flex-row gap-1 ">
          <div className="md:h-5 md:w-5 w-[28px] h-[28px] ">
            <ImgCustom
              url={restaurant?.avatar?.id}
              alt="voucherImg"
              className="h-full w-full rounded-circle object-cover object-center"
            />
          </div>

          <div className="flex flex-col gap-4px flex-1">
            <div className="flex flex-row items-center gap-1">
              <span className="line-clamp-1 text-18 font-semibold leading-[28px] text-gray-900">
                {restaurant?.name}
              </span>
              <div className="flex flex-row items-center gap-4px">
                <a
                  href={`/food/restaurant/${restaurant.id}`}
                  className="cursor-pointer text-14 font-semibold leading-20 text-blue-600 line-clamp-1"
                >
                  {capitalizedFirst(t("my-voucher.visit-restaurant"))}
                </a>
                <i className="pi pi-angle-right cursor-pointer text-16 text-blue-600 hover:font-semibold"></i>
              </div>
            </div>
            <span className="line-clamp-1 text-14 leading-20 text-gray-600">{`${[
              restaurant?.street,
              restaurant?.ward?.name,
              restaurant?.district?.name,
              restaurant?.province?.name,
              restaurant?.country?.name,
            ]
              .filter((i) => i)
              .join(", ")}`}</span>
          </div>
        </div>
        <div onClick={() => onShowVoucher()}>
          {switchVoucher ? (
            <i className="pi pi-angle-up cursor-pointer text-16 text-gray-600 hover:text-blue-500"></i>
          ) : (
            <i className="pi pi-angle-down cursor-pointer text-16 hover:text-blue-500"></i>
          )}
        </div>
      </div>
      {switchVoucher && (
        <div className="flex flex-col">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 border-t border-gray-200 p-3">
            {vouchers.slice(0, visibleItems).map((i) => (
              <div key={i.id} className="flex w-auto flex-col gap-[4px]">
                <VoucherView {...i} isHiddenBuy={true} selectTab = {selectTab} type="BOUGHT"/>
              </div>
            ))}
          </div>
          {vouchers.length > visibleItems ? (
            <div className="w-full px-3 py-2 text-center">
              <span
                className="cursor-pointer text-14 font-medium text-blue-600"
                onClick={() => onViewMore()}
              >
                {capitalizedFirst(t("my-voucher.view-more"))}
              </span>
            </div>
          ) : (
            total_element > 4 ? <div className="w-full px-3 py-2 text-center">
              <span
                className="cursor-pointer text-14 font-medium text-blue-600"
                onClick={() => onViewLess()}
              >
                {capitalizedFirst(t("my-voucher.view-less"))}
              </span>
            </div> : null
          )}
        </div>
      )}
    </section>
  )
}
