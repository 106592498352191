import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "./index.scss"
import { Pagination, Navigation } from "swiper/modules"
import { ImgCustom } from "../common"
import { cataLogService } from "src/services"
import { useNavigate } from "react-router-dom"

interface ItemViewProps {
  title: string
  imgUrl: string
  id: string
}

const colorObject = [
  "#FEE4E2",
  "#FEF7C3",
  "#E6F4D7",
  "#FDEAD7",
  "#FFDDEB",
  "#EFF8FF",
  "#ECE9FE",
]

const randomColor = () => {
  const colorRandom =
    colorObject[Math.floor(Math.random() * colorObject.length)]
  return colorRandom
}

const ItemView = (props: ItemViewProps) => {
  const { title, imgUrl, id } = props
  const navigate = useNavigate()
  const handleClickRestaurant = () => {
    navigate("/food/search", { state: { restaurant_type: id } })
  }

  return (
    <div
      onClick={handleClickRestaurant}
      className="flex h-[82px] max-w-[164px] cursor-pointer flex-col rounded-3 px-1 pt-1"
      style={{
        backgroundColor: randomColor(),
      }}
    >
      <div className="flex h-[28px] w-full justify-start px-1">
        <div className="line-clamp-1 text-14 font-medium leading-24 text-gray-700">
          {title}
        </div>
      </div>
      <div className="flex w-full justify-end">
        <div className="flex items-center justify-end p-[2.5px] text-center">
          <ImgCustom
            url={imgUrl}
            alt="imgFoodItemView"
            className="h-[75px] w-[75px] rounded-full bg-cover"
          />
        </div>
      </div>
    </div>
  )
}

const ListFoodView = () => {
  const [foodType, setFoodType] = React.useState<ItemViewProps[]>([])

  React.useEffect(() => {
    function initView() {
      cataLogService
        .getRestaurantHomePage()
        .then((res) => {
          if (res?.data?.data?.data?.length) {
            const result: ItemViewProps[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data.data.forEach((i: any) => {
              result.push({
                title: i.name,
                id: i.id,
                imgUrl: i?.image?.id,
              })
            })
            setFoodType(result)
          }
        })
        .catch((err) => err)
    }
    initView()
  }, [])

  return (
    <div className="flex flex-col rounded-3 bg-white p-3" id="listFoodView">
      {foodType.length ? (
        <div>
          <Swiper
            slidesPerView={5}
            spaceBetween="auto"
            navigation={true}
            pagination={true}
            modules={[Navigation, Pagination]}
            breakpoints={{
              0: { slidesPerView: 2, spaceBetween: 16 },
              480: {
                slidesPerView: 3,
                spaceBetween: 16,
              },
              768: {
                slidesPerView: 5,
                spaceBetween: 16,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 16,
              },
              1200: {
                slidesPerView: 7,
                spaceBetween: 16,
              },
            }}
          >
            {foodType.map((i, index) => (
              <SwiperSlide key={index}>
                <ItemView {...i} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(ListFoodView)
