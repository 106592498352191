import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox"
import React, { useEffect, useState } from "react"
import RenderStar from "../common/render-star"
import { FacilityProps, FilterProps, RatingProps } from "./types"
import Maps from "../../assets/images/maps-bg.png"
import LocationIcon from "../../assets/images/location-icon.png"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { FACILITY_TYPE } from "src/constants/common"
import MapModal from "../common/modal/map-modal"

const ratingList: RatingProps[] = [
  { name: "Five star", key: "5" },
  { name: "Four star", key: "4" },
  { name: "Three star", key: "3" },
  { name: "Two star", key: "2" },
  { name: "One star", key: "1" },
]

const facilityList: FacilityProps[] = [
  {
    name: "wifi-free",
    value: FACILITY_TYPE.WIFI_FREE,
  },
  { name: "parking-lot", value: FACILITY_TYPE.PARKING_LOT },
  { name: "operating-hour", value: FACILITY_TYPE.OPERATING_HOUR },
  { name: "vehicle-charging", value: FACILITY_TYPE.CHARGING },
]

export default function FilterView({
  filterFunction,
  restaurantTypeList,
  foodTypeList,
  districtList,
  originalFilter,
  selectLocation,
}: FilterProps) {
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedCategories, setSelectedCategories] = useState<any[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedFoodCategories, setSelectedFoodCategories] = useState<any[]>(
    []
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedFacility, setSelectedFacility] = useState<any[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedDistrict, setSelectedDistrict] = useState<any[]>([])
  const [isShowCategory, setIsShowCategory] = useState<boolean>(false)
  const [isShowFoodCategory, setIsShowFoodCategory] = useState<boolean>(false)
  const [selectedRating, setSelectedRating] = useState<string>("")
  const [isShowRating, setIsShowRating] = useState<boolean>(false)
  const [isShowDistrict, setIsShowDistrict] = useState<boolean>(false)
  const [isShowFacilities, setIsShowFacilities] = useState<boolean>(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [isShowMapModal, setIsShowMapModal] = useState<boolean>(false)
  const handleResetFilter = () => {
    setSelectedCategories([])
    setSelectedDistrict([])
    setSelectedFoodCategories([])
    setSelectedFacility([])
    setSelectedRating("")
    filterFunction({
      rating: "",
      districts: [],
      categories: [],
      food_categories: [],
      facilities: [],
    })
  }

  const handleDisplayRatingFilter = () => {
    setIsShowRating(!isShowRating)
  }

  const handleDisplayFacility = () => {
    setIsShowFacilities(!isShowFacilities)
  }

  const handleDisplayCategoryFilter = () => {
    setIsShowCategory(!isShowCategory)
  }

  const handleDisplayFoodCategoryFilter = () => {
    setIsShowFoodCategory(!isShowFoodCategory)
  }

  const handleSelectRating = (event: CheckboxChangeEvent) => {
    setIsFirstLoad(false)
    if (event.checked) setSelectedRating(event?.target?.value?.key)
    else {
      setSelectedRating("0")
    }
  }

  const handleDisplayDistrictFilter = () => {
    setIsShowDistrict(!isShowDistrict)
  }

  // Category selected handle
  const onCategoryChange = (e: CheckboxChangeEvent) => {
    setIsFirstLoad(false)
    let _selectedCategories = [...selectedCategories]
    if (e.checked) _selectedCategories.push(e.value)
    else
      _selectedCategories = _selectedCategories.filter(
        (category: { id: string }) => category.id !== e.value.id
      )
    setSelectedCategories(_selectedCategories)
  }

  //Food Category selected handle
  const onFoodCategoryChange = (e: CheckboxChangeEvent) => {
    setIsFirstLoad(false)
    let _selectedCategories = [...selectedFoodCategories]

    if (e.checked) _selectedCategories.push(e.value)
    else
      _selectedCategories = _selectedCategories.filter(
        (category: { id: string }) => category.id !== e.value.id
      )

    setSelectedFoodCategories(_selectedCategories)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRegionChange = (e: CheckboxChangeEvent) => {
    setIsFirstLoad(false)
    let _selectedDistrict = [...selectedDistrict]
    if (e.checked) _selectedDistrict.push(e.value)
    else
      _selectedDistrict = _selectedDistrict.filter(
        (region) => region.district_id !== e.value.district_id
      )
    setSelectedDistrict(_selectedDistrict)
  }

  const onFacilityChange = (e: CheckboxChangeEvent) => {
    setIsFirstLoad(false)
    let _selectedFacility = [...selectedFacility]
    if (e.checked) _selectedFacility.push(e.value)
    else
      _selectedFacility = _selectedFacility.filter(
        (facility) => facility.value !== e.value.value
      )
    setSelectedFacility(_selectedFacility)
  }

  useEffect(() => {
    if (isFirstLoad) return
    filterFunction({
      rating: selectedRating,
      districts: selectedDistrict,
      categories: selectedCategories,
      food_categories: selectedFoodCategories,
      facilities: selectedFacility,
    })
  }, [
    selectedCategories,
    selectedDistrict,
    selectedRating,
    selectedFoodCategories,
    selectedFacility,
  ])

  const handleMapResults = () => {
    setIsShowMapModal(true)
  }

  const { t } = useTranslation()
  const handleTranslate = (text: string) => {
    return t(text)
  }

  useEffect(() => {
    if (foodTypeList?.length && isFirstLoad) {
      if (location?.state) {
        if (location.state?.food_type) {
          setIsShowFoodCategory(true)
          const data = foodTypeList.find(
            (type) => type?.id === location.state?.food_type
          )
          selectedFoodCategories.push(data)
          setIsFirstLoad(false)
        }
      }
    }
  }, [foodTypeList])

  useEffect(() => {
    if (restaurantTypeList?.length && isFirstLoad) {
      if (location?.state) {
        if (location.state?.restaurant_type) {
          setIsShowCategory(true)
          const data = restaurantTypeList?.find(
            (type) => type?.id === location.state?.restaurant_type
          )
          selectedCategories.push(data)
          setIsFirstLoad(false)
        }
      }
    }
  }, [restaurantTypeList])

  useEffect(() => {
    if (!districtList?.length && selectedDistrict?.length) {
      setSelectedDistrict([])
    }
  }, [districtList])

  useEffect(() => {
    if (isFirstLoad) {
      if (originalFilter?.categories?.length) {
        setIsShowCategory(true)
        setSelectedCategories(originalFilter?.categories)
      }
      if (originalFilter?.food_categories?.length) {
        setIsShowFoodCategory(true)
        setSelectedFoodCategories(originalFilter?.food_categories)
      }
      if (originalFilter?.districts?.length && districtList?.length) {
        setIsShowDistrict(true)
        setSelectedDistrict(originalFilter?.districts)
      }
      if (originalFilter?.rating) {
        setIsShowRating(true)
        setSelectedRating(originalFilter?.rating)
      }
      if (originalFilter?.facilities) {
        setIsShowFacilities(true)
        setSelectedFacility(originalFilter?.facilities)
      }
    }
  }, [originalFilter])

  return (
    <div className="flex w-full flex-col gap-4 md:flex-row-reverse lg:flex-col">
      {/* map */}
      {isShowMapModal ? (
        <MapModal
          location={selectLocation}
          isShow={isShowMapModal}
          handleClose={() => setIsShowMapModal(false)}
          category="Restaurant"
        />
      ) : null}
      <div className="relative min-h-[200px] w-full rounded-3 border-2 border-white pt-2px">
        <img
          src={Maps}
          alt="map"
          className="h-[200px] w-full rounded-3 md:h-[273px] xl:max-h-[240px]"
        />
        <div
          onClick={handleMapResults}
          className="absolute bottom-3 right-[33%] flex cursor-pointer items-center rounded-2 bg-blue-600 fill-white stroke-white px-2 py-4px text-14 font-semibold leading-5 text-white"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Bold / Map &#38; Location / Map">
              <g id="Vector">
                <path
                  d="M2.03055 4.28856C1.66699 4.70239 1.66699 5.41191 1.66699 6.83095V14.6587C1.66699 15.6637 1.66699 16.1662 1.92916 16.5445C2.19132 16.9228 2.64967 17.0817 3.56636 17.3995L4.64485 17.7734C5.22896 17.9759 5.67841 18.1317 6.0556 18.232C6.30166 18.2975 6.5281 18.1044 6.5281 17.8497V5.22459C6.5281 5.01716 6.37503 4.8421 6.17396 4.7911C5.84961 4.70883 5.45971 4.57366 4.92534 4.3884C3.63096 3.93965 2.98378 3.71528 2.49134 3.94313C2.3165 4.02403 2.15963 4.14163 2.03055 4.28856Z"
                  fill="#1570EF"
                />
                <path
                  d="M10.5173 2.90031L9.23733 3.78781C8.77428 4.10888 8.43492 4.34418 8.14512 4.51194C8.00778 4.59144 7.91699 4.73527 7.91699 4.89396V17.4331C7.91699 17.7419 8.23698 17.9347 8.49713 17.7683C8.77635 17.5897 9.09607 17.368 9.48334 17.0995L10.7633 16.212C11.2264 15.8909 11.5657 15.6556 11.8555 15.4878C11.9929 15.4083 12.0837 15.2645 12.0837 15.1058V2.56671C12.0837 2.25788 11.7637 2.06506 11.5035 2.23148C11.2243 2.4101 10.9046 2.63179 10.5173 2.90031Z"
                  fill="#1570EF"
                />
                <path
                  d="M16.4343 2.60029L15.3558 2.22639C14.7716 2.02387 14.3223 1.86808 13.9451 1.76774C13.699 1.70229 13.4725 1.89542 13.4725 2.15004V14.7752C13.4725 14.9826 13.6256 15.1577 13.8267 15.2087C14.151 15.2909 14.541 15.4261 15.0753 15.6114C16.3697 16.0601 17.0169 16.2845 17.5093 16.0566C17.6842 15.9757 17.841 15.8581 17.9701 15.7112C18.3337 15.2974 18.3337 14.5879 18.3337 13.1688V5.34108C18.3337 4.3361 18.3337 3.8336 18.0715 3.4553C17.8093 3.077 17.351 2.91809 16.4343 2.60029Z"
                  fill="#1570EF"
                />
              </g>
            </g>
          </svg>
          <span className="ml-1">
            {capitalizedFirst(
              handleTranslate("search-filter-page.view-on-map")
            )}
          </span>
        </div>
        <div className="absolute left-1/2 top-1/2 flex h-[46px] w-[46px] -translate-x-1/2 -translate-y-1/2 transform">
          <img src={LocationIcon} alt="location-icon" />
        </div>
      </div>

      {/* filter options */}
      <div className="flex w-full flex-col rounded-3 bg-white p-2 shadow-sm">
        <div className="flex w-full items-center justify-between border-b border-gray-200 px-1 pb-2">
          <div className="text-18 font-semibold leading-7 text-gray-900">
            {capitalizedFirst(handleTranslate("global.filter"))}
          </div>
          <div
            onClick={handleResetFilter}
            className={`cursor-pointer text-14 font-semibold leading-5 ${
              selectedCategories?.length ||
              selectedDistrict?.length ||
              selectedFoodCategories?.length ||
              selectedRating !== "0"
                ? "text-blue-600"
                : "text-gray-300"
            }`}
          >
            {capitalizedFirst(handleTranslate("global.reset-filter"))}
          </div>
        </div>
        {/* rating */}
        <div className="flex w-full flex-col gap-3 pt-2">
          <div
            onClick={handleDisplayRatingFilter}
            className="flex w-full cursor-pointer items-center justify-between rounded-3 px-1 py-4px hover:bg-gray-50"
          >
            <div className="text-16 font-semibold leading-6 text-gray-900">
              {capitalizedFirst(
                handleTranslate("purchase-history.button.rating")
              )}
            </div>
            {!isShowRating ? (
              <span className="pi pi-angle-up cursor-pointer text-gray-500"></span>
            ) : (
              <span className="pi pi-angle-down cursor-pointer text-gray-500"></span>
            )}
          </div>
          {isShowRating && (
            <div className="w-full">
              <div className="flex flex-col gap-4">
                {ratingList.map((rating) => {
                  return (
                    <div key={rating.key} className="align-items-center flex">
                      <Checkbox
                        inputId={rating.key}
                        name="rating"
                        value={rating}
                        onChange={(e) => handleSelectRating(e)}
                        checked={rating.key === selectedRating}
                      />
                      <label
                        htmlFor={rating.key}
                        className="ml-2 flex items-center"
                      >
                        {/* {category.key} */}
                        <RenderStar total={rating.key as unknown as number} />
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        {/* restaurant categories*/}
        {restaurantTypeList?.length ? (
          <div className="mt-4 flex w-full flex-col gap-3">
            <div
              onClick={handleDisplayCategoryFilter}
              className="flex w-full cursor-pointer items-center justify-between rounded-3 px-1 py-4px hover:bg-gray-50"
            >
              <div className="text-16 font-semibold leading-6 text-gray-900">
                {capitalizedFirst(
                  handleTranslate("search-filter-page.restaurant-category")
                )}
              </div>
              {!isShowCategory ? (
                <span className="pi pi-angle-up cursor-pointer text-gray-500"></span>
              ) : (
                <span className="pi pi-angle-down cursor-pointer text-gray-500"></span>
              )}
            </div>
            {/* category filter content */}
            {isShowCategory && (
              <div className="flex w-full flex-col gap-2">
                {restaurantTypeList?.map((category) => {
                  return (
                    <div
                      key={category.id}
                      className="align-items-center flex text-gray-500"
                    >
                      <Checkbox
                        inputId={category.id}
                        name="category"
                        value={category}
                        onChange={onCategoryChange}
                        checked={selectedCategories.some(
                          (item) => item.id === category.id
                        )}
                      />
                      <label
                        htmlFor={category.id}
                        className="ml-2 flex items-center truncate text-14 font-medium"
                      >
                        {`${category.name}`}
                      </label>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        ) : null}

        {/* food categories*/}
        {foodTypeList?.length ? (
          <div className="mt-4 flex w-full flex-col gap-3">
            <div
              onClick={handleDisplayFoodCategoryFilter}
              className="flex w-full cursor-pointer items-center justify-between rounded-3 px-1 py-4px hover:bg-gray-50"
            >
              <div className="text-16 font-semibold leading-6 text-gray-900">
                {capitalizedFirst(
                  handleTranslate("search-filter-page.food-category")
                )}
              </div>
              {!isShowFoodCategory ? (
                <span className="pi pi-angle-up cursor-pointer text-gray-500"></span>
              ) : (
                <span className="pi pi-angle-down cursor-pointer text-gray-500"></span>
              )}
            </div>

            {/* category filter content */}
            {isShowFoodCategory && (
              <div className="flex w-full flex-col gap-2">
                {foodTypeList?.map((category) => {
                  return (
                    <div
                      key={category.id}
                      className="align-items-center flex text-gray-500"
                    >
                      <Checkbox
                        inputId={category.id}
                        name="category"
                        value={category}
                        onChange={onFoodCategoryChange}
                        checked={selectedFoodCategories.some(
                          (item) => item.id === category.id
                        )}
                      />
                      <label
                        htmlFor={category.id}
                        className="ml-2 flex items-center truncate text-14 font-medium"
                      >
                        {`${category.name}`}
                      </label>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        ) : null}

        {/* district */}
        {districtList?.length ? (
          <div className="mt-4 flex w-full flex-col">
            <div
              onClick={handleDisplayDistrictFilter}
              className="flex w-full cursor-pointer items-center justify-between rounded-3 px-1 py-4px hover:bg-gray-50"
            >
              <div className="text-16 font-semibold leading-6 text-gray-900">
                {capitalizedFirst(handleTranslate("global.district"))}
              </div>
              {!isShowDistrict ? (
                <span className="pi pi-angle-up cursor-pointer text-gray-500"></span>
              ) : (
                <span className="pi pi-angle-down cursor-pointer text-gray-500"></span>
              )}
            </div>

            {/* district content */}
            <div className="w-full">
              {districtList?.length && isShowDistrict && (
                <div className="flex w-full flex-row gap-2">
                  <div className="w-full">
                    {districtList
                      ?.slice(0, districtList?.length / 2 + 1)
                      ?.map((region) => (
                        <div
                          key={region?.district_id}
                          className="my-2 flex items-center gap-2"
                        >
                          <Checkbox
                            inputId={region?.district_id}
                            name="region"
                            value={region}
                            onChange={onRegionChange}
                            checked={selectedDistrict?.some(
                              (item) =>
                                item?.district_id === region?.district_id
                            )}
                          />
                          <p className="truncate text-14 font-medium text-gray-500">
                            {region?.district_name}
                          </p>
                        </div>
                      ))}
                  </div>
                  <div className="w-full">
                    <div className="w-full">
                      {districtList
                        ?.slice(
                          districtList?.length / 2 + 1,
                          districtList?.length
                        )
                        ?.map((region) => (
                          <div
                            key={region?.district_id}
                            className="my-2 flex items-center gap-2"
                          >
                            <Checkbox
                              inputId={region?.district_id}
                              name="region"
                              value={region}
                              onChange={onRegionChange}
                              checked={selectedDistrict?.some(
                                (item) =>
                                  item?.district_id === region?.district_id
                              )}
                            />
                            <p className="truncate text-14 font-medium text-gray-500">
                              {region?.district_name}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {/* facilities */}
        <div className="flex w-full flex-col gap-3 pt-4">
          <div
            onClick={handleDisplayFacility}
            className="flex w-full cursor-pointer items-center justify-between rounded-3 px-1 py-4px hover:bg-gray-50"
          >
            <div className="text-16 font-semibold leading-6 text-gray-900">
              {capitalizedFirst(handleTranslate("global.facilities"))}
            </div>
            {!isShowFacilities ? (
              <span className="pi pi-angle-up cursor-pointer text-gray-500"></span>
            ) : (
              <span className="pi pi-angle-down cursor-pointer text-gray-500"></span>
            )}
          </div>
          {isShowFacilities && (
            <div className="w-full">
              <div className="flex flex-col gap-2">
                {facilityList.map((facility) => {
                  return (
                    <div
                      key={facility.value}
                      className="align-items-center flex"
                    >
                      <Checkbox
                        inputId={facility.value}
                        name="rating"
                        value={facility}
                        onChange={(e) => onFacilityChange(e)}
                        checked={selectedFacility?.some(
                          (item) => item?.value === facility?.value
                        )}
                      />
                      <div className="truncate pl-2 text-14 font-medium text-gray-500">
                        {capitalizedFirst(
                          handleTranslate(`search-filter-page.${facility.name}`)
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
