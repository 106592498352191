import React, { useEffect, useState } from "react"
import { DataBookingType } from "src/components/bookingModal/types"
import BookingStatus from "src/components/common/modal/book-table-complete"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ReservationControllerApi } from "@soctrip/axios-food-order-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { Loading } from "src/components/common"

const ORDER_STATE = {
  COMPARE: "COMPARE",
  FAIL: "FAIL",
  SUCCESS: "SUCCESS",
  RENDER: "RENDER",
}
export default function BookingOrder() {
  const [dataBooking, setDataBooking] = useState<DataBookingType | undefined>(
    undefined
  )
  const [token, setToken] = useState<string>("")
  const [isInitView, setIsInitView] = useState<boolean>(false)
  const [orderState, setOrderState] = useState<string>(ORDER_STATE.COMPARE)
  const [orderId, setOrderId] = useState<string>("")
  const [requestOrderId, setRequestOrderId] = useState<string>("")
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  useEffect(() => {
    setTimeout(() => {
      const paymentToken = searchParams.get("token")
      if (paymentToken) {
        setToken(paymentToken)
        setIsInitView(true)
        return 
      }
      const orderIdParam = searchParams.get("orderId")
      if (orderIdParam) {
        setRequestOrderId(orderIdParam)
        setIsInitView(true)
        return 
      }
    }, 2000)
  }, [])

  useEffect(() => {
    if (isInitView && !token && !requestOrderId) navigate("/")
  }, [isInitView, token])

  useEffect(() => {
    if (token) {
      new ReservationControllerApi(config(SERVICE.FOOD_ORDER))
        .getPaymentHistoryByToken(token)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res:any) => {
          let result = false
          if (
            res?.data?.data?.status === "succeeded" ||
            res?.data?.data?.status === "waiting_for_account"
          ) {

            result = true
          }
          setIsComplete(result)
          setOrderId(res?.data?.data?.orders[0]?.id)
        })
        .catch((err) => console.log(err))
    }
  }, [token])
  
  useEffect(() => {
    if(requestOrderId) {
      setIsComplete(true)
      setOrderId(requestOrderId)
    }
  }, [requestOrderId])

  useEffect(() => {
    if(orderState === ORDER_STATE.COMPARE && orderId) {
      new ReservationControllerApi(config(SERVICE.FOOD_ORDER)).getPaymentInformation(orderId).then((res) => {
        if(res?.data?.data) {
          const result:DataBookingType = {
            restaurantId: res.data.data?.restaurant_id || "",
            bookingData: {
              userInformation: {
                fullName: res.data.data?.full_name || "",
                phone: res.data.data?.phone || "",
                email: res.data.data?.email || "",
              },
              bookingInformation: {
                time:  new Date(res.data.data?.reservation_time).toString() || "",
                guest:  res.data.data?.number_guests || 0,
              },
            },
          }
          setDataBooking(result)
          setOrderState(ORDER_STATE.RENDER)
        }
      }).catch(() => navigate("/"))
    }
  }, [orderState, token, orderId])

  if (orderState === ORDER_STATE.COMPARE)
    return (
      <div className="h-[600px]">
        <Loading />
      </div>
    )

  return (
    <div className="flex w-full mx-auto md:w-[600px] min-h-[600px] items-center justify-center bg-white rounded-3 p-2 border border-gray-200 my-2">
      <BookingStatus dataBooking={dataBooking} isComplete={isComplete} />
    </div>
  )
}
