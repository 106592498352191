import React, { useEffect, useState } from "react"
import { t } from "i18next"
import { Dropdown } from "primereact/dropdown"
import PhoneInput from "react-phone-number-input"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { Dialog } from "primereact/dialog"
import { AddressAddressTypeEnum } from "@soctrip/order-service"
import { InputText } from "primereact/inputtext"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useToast } from "src/hooks/use-toast"
import {
  DropdownProvinceProps,
  DropdownDistrictProps,
  DropdownWardProps,
  DropdownCountryProps,
  AddressViewProps,
  typeDropdown,
} from "./props"
import { config } from "src/config/interceptors"
import * as orderService from "@soctrip/order-service"
import * as shipmentService from "@soctrip/shipment-service"
import { Checkbox } from "primereact/checkbox"
import { SERVICE } from "src/constants/common"

export default function AddressCreateView(props: AddressViewProps) {
  const { isShow, handleClose, handleConfirm } = props

  const headerCustom = (
    <div className="flex flex-col">
      <div className="flex items-center justify-between text-18 font-semibold leading-28 text-gray-700">
        <span>{capitalizedFirst(t("modal.createNewAddress"))}</span>
      </div>
      <span className="mt-4px text-14 font-normal leading-20 text-gray-500">
        {capitalizedFirst(t("modal.addressDescription"))}
      </span>
    </div>
  )
  const dataForm = useFormik({
    initialValues: {
      user_name: "",
      user_phone: "",
      user_address: "",
      user_country: null,
      user_city: null,
      user_district: null,
      user_ward: null,
    },
    validationSchema: Yup.object({
      user_name: Yup.string()
        .required("This field is required!")
        .max(50, "Must be at most 30 characters!")
        .matches(
          /^[a-zA-Z0-9À-Ỹà-ỹẠ-Ỹạ-ỹĂ-Ỷă-ỷÂ-Ếâ-ếẤ-Ứấ-ứÊ-Ểê-ểẾ-Ứế-ứÔ-Ộô-ộỐ-Ựố-ựƠ-Ởơ-ởĐđ\s]*$/,
          "Invalid data!"
        )
        .min(3, "Please enter full name!"),
      user_phone: Yup.string()
        .required("This field is required!")
        .matches(/^[^\s].*$/, "Cannot start with a space")
        .min(10, "Please enter the correct phone number")
        .max(15, "Please enter the correct phone number"),

      user_address: Yup.string()
        .required("This field is required!")
        .max(200, "You entered more than the allowed number of characters!"),
      user_country: Yup.string().required("This field is required!"),
      user_city: Yup.string().required("This field is required!"),
      user_district: Yup.string().required("This field is required!"),

    }),
    onSubmit: () => {
      if(dataForm.isValid){
        handleSumitForm()
        dataForm.resetForm()
      }
    },
    validateOnChange: true,
    validateOnMount: true,
  })
  const isInValidField = (name: string) => {
    return !!(
      dataForm?.touched[`${name}` as keyof typeof dataForm.touched] &&
      dataForm?.errors[`${name}` as keyof typeof dataForm.errors]
    )
  }
  const [dropdownCountryData, setDropdownCountryData] = useState<typeDropdown[]>([])
  const [dropdownCityData, setDropdownCityData] = useState<typeDropdown[]>([])
  const [dropdownDistrictsData, setDropdownDistrictsData] = useState<typeDropdown[]>([])
  const [dropdownWardData, setDropdownWardData] = useState<typeDropdown[]>([])

  const toast = useToast()

  const [addressType, setAddressType] = useState<AddressAddressTypeEnum>(
    AddressAddressTypeEnum.HOME
  )
  const [isDefault, setIsDefault] = useState<boolean>(true)
  const addressService = new orderService.AddressControllerApi(
    config(SERVICE.ORDER)
  )
  const addressShipmentService =
    new shipmentService.AddressDirectoryControllerApi(config(SERVICE.SHIPMENT))
  useEffect(() => {
    async function addressCountriesGet() {
      await addressShipmentService.addressCountriesGet().then((resCountry) => {
        if (resCountry.data?.data && resCountry.data.data.length) {
          const dataCountry = resCountry.data?.data.map(
            (item: DropdownCountryProps) => {
              return {
                value: item.country_id,
                label: item.country_name,
                code: item?.code_name,
              }
            }
          )
          setDropdownCountryData(dataCountry)
        }
      })
    }
    if (isShow) addressCountriesGet()
  }, [isShow])

  const getCityList = (countryId: string) => {
    const result = [] as { label: string; value: string ; code:string}[]
    addressShipmentService
      .addressProvincesGet(countryId)
      .then((resCity) => {
        if (resCity.data?.data && resCity.data?.data.length) {
          resCity.data?.data.forEach((el: DropdownProvinceProps) => {
            result.push({
              value: el.province_id,
              label: el.province_name,
              code: el?.code_name,
            })
          })
        }
        setDropdownCityData(result)
      })
      .catch((err) => console.log(err))
  }
  const getDistrictList = (cityId: string) => {
    const result = [] as { label: string; value: string ; code:string}[]
    addressShipmentService
      .addressDistrictsGet(cityId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((resDistrict: any) => {
        if (resDistrict?.data?.data && resDistrict?.data?.data?.length) {
          resDistrict?.data?.data.forEach((el: DropdownDistrictProps) => {
            result.push({
              value: el?.district_id,
              label: el?.district_name,
              code: el?.code_name,
            })
          })
        }
        setDropdownDistrictsData(result)
      })
      .catch((err) => console.log(err))
  }
  const getWardList = (districtId: string) => {
    const result = [] as { label: string; value: string ; code:string }[]
    addressShipmentService.addressWardsGet(districtId).then((resWarn) => {
      if (resWarn.data.data && resWarn.data.data.length) {
        resWarn.data?.data.forEach((el: DropdownWardProps) => {
          result.push({
            value: el.ward_id,
            label: el.ward_name,
            code: el?.code_name,
          })
        })
      }
      setDropdownWardData(result)
    })
  }
  useEffect(() => {
    if (dataForm?.values?.user_country) {
      setDropdownCityData([])
      setDropdownDistrictsData([])
      setDropdownWardData([])
      dataForm.setFieldValue("user_city", null)
      dataForm.setFieldValue("user_district", null)
      dataForm.setFieldValue("user_ward", null)
      getCityList(dataForm?.values?.user_country)
    }
  }, [dataForm?.values?.user_country])

  useEffect(() => {
    if (dataForm?.values?.user_city) {
      setDropdownDistrictsData([])
      setDropdownWardData([])
      dataForm.setFieldValue("user_district", null)
      dataForm.setFieldValue("user_ward", null)
      getDistrictList(dataForm?.values?.user_city)
    }
  }, [dataForm?.values?.user_city])
  useEffect(() => {
    if (dataForm?.values?.user_district) {
      setDropdownWardData([])
      dataForm.setFieldValue("user_ward", null)
      getWardList(dataForm?.values?.user_district)
    }
  }, [dataForm?.values?.user_district])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getProperty = (dropdownsData:any , property:string, value:string|null) => {
    const countryItem = dropdownsData.find((i:typeDropdown) => i.value === value)
    return countryItem ? countryItem[property]  : "" 
  }
  const handleSumitForm = () => {
    const data = {
      user_full_name: dataForm?.values?.user_name,
      user_phone_number: dataForm?.values?.user_phone,
      address: dataForm?.values?.user_address,
      country: getProperty(dropdownCountryData,"label",dataForm?.values?.user_country),
      city: getProperty(dropdownCityData,"label",dataForm?.values?.user_city),
      district: getProperty(dropdownDistrictsData,"label",dataForm?.values?.user_district),
      ward: getProperty(dropdownWardData,"label",dataForm?.values?.user_ward),
      countryId:getProperty(dropdownCountryData,"value",dataForm?.values?.user_country),
      cityId:getProperty(dropdownCityData,"value",dataForm?.values?.user_city),
      districtId:getProperty(dropdownDistrictsData,"value",dataForm?.values?.user_district),
      wardId:getProperty(dropdownWardData,"value",dataForm?.values?.user_ward),
      countryCode:getProperty(dropdownCountryData,"code",dataForm?.values?.user_country),
      cityCode:getProperty(dropdownCityData,"code",dataForm?.values?.user_city),
      districtCode:getProperty(dropdownDistrictsData,"code",dataForm?.values?.user_district),
      wardCode:getProperty(dropdownWardData,"code",dataForm?.values?.user_ward),
      address_type: addressType,
      is_default: isDefault, 
    }
    if( dataForm.isValid && !(!data?.countryId|| !data.cityId|| !data.districtId)){
      addressService.createAddress(data).then(() => {
        handleConfirm()
        toast({ detail: capitalizedFirst(t("modal.add-address-successfully")), severity: "success" })
      })
        .catch(() => {
          toast({ detail: capitalizedFirst(t("modal.add-address-failed")), severity: "error" })
        })
        .finally(()=>{
          dataForm.resetForm()
        })
    }
  }
  
  const footerContent = (
    <div className="mt-5 flex justify-end gap-2">
      <button
        className="flex items-center justify-center gap-1 rounded-3 border border-gray-300 bg-white px-18px py-10px transition hover:border-gray-600"
        onClick={() => handleClose()}
      >
        <span className="text-16 font-semibold leading-6 text-gray-700">
          {capitalizedFirst(t("global.cancel"))}
        </span>
      </button>
      <button
        className={`flex items-center justify-center gap-1 rounded-3 border ${
          dataForm.isValid && !(!getProperty(
            dropdownCityData,
            "value",
            dataForm?.values?.user_city
          ) || !getProperty(
            dropdownCityData,
            "value",
            dataForm?.values?.user_city
          )|| !getProperty(
            dropdownDistrictsData,
            "value",
            dataForm?.values?.user_district
          ))
            ? "border-blue-600 bg-blue-600"
            : "border-blue-200 bg-blue-200"
        } px-18px py-10px`}
        disabled={!dataForm.isValid && !(!getProperty(
          dropdownCityData,
          "value",
          dataForm?.values?.user_city
        ) || !getProperty(
          dropdownCityData,
          "value",
          dataForm?.values?.user_city
        )|| !getProperty(
          dropdownDistrictsData,
          "value",
          dataForm?.values?.user_district
        )) }
        type="submit"
        onClick={handleSumitForm}
      >
        <span className="text-16 font-semibold leading-6 text-white">
          {capitalizedFirst(t("global.create"))}
        </span>
      </button>
    </div>
  )
  return (
    <Dialog header={headerCustom} visible={isShow} onHide={() => handleClose()} className="w-full sm:w-[640px]" footer={footerContent}>
      <form onSubmit={dataForm.handleSubmit}>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col md:flex-row gap-3">
            <div className="flex w-full flex-col gap-1">
              <span className="text-14 font-medium leading-5 text-gray-700">
                {capitalizedFirst(t("global.fullName"))}
                <span className="text-red-600">{"*"}</span>
              </span>
              <InputText
                className={`flex items-center gap-1 rounded-3 border border-gray-300 bg-white px-14px py-10px ${
                  isInValidField("user_name") ? "p-invalid" : ""
                } `}
                placeholder="Full name"
                value={dataForm?.values.user_name}
                onChange={(e) => {
                  if (!dataForm?.touched?.user_name)
                    dataForm.setTouched({
                      ...dataForm.touched,
                      ...{ user_name: true },
                    })
                  dataForm.setFieldValue("user_name", e?.target?.value)
                }}
              />
              {dataForm.errors.user_name && dataForm.touched.user_name && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.user_name}
                </span>
              )}
            </div>
            <div className="flex w-full flex-col gap-1">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {capitalizedFirst(t("global.phoneNumber"))}
                <span className="text-red-600">{"*"}</span>
              </span>
              <div
                className={`flex items-center gap-1 rounded-3 border bg-white px-14px py-10px ${
                  isInValidField("user_phone")
                    ? "p-invalid border border-[#e24c4c]"
                    : "border border-gray-200"
                }`}
              >
                <PhoneInput
                  className="text-16 font-normal leading-24 text-gray-700"
                  placeholder="Enter Phone Number"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="VN"
                  value={dataForm.values.user_phone}
                  onChange={(e) => {
                    if (!dataForm?.touched?.user_phone)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ user_phone: true },
                      })

                    dataForm.setFieldValue("user_phone", e||null)
                  }}
                />
              </div>
              {dataForm.errors.user_phone && dataForm.touched.user_phone && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.user_phone}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-6px">
            <span className="text-14 font-medium leading-5 text-gray-700">
              {capitalizedFirst(t("global.address"))}
              <span className="text-red-600">{"*"}</span>
            </span>
            <InputText
              className={`flex items-center gap-1 rounded-3 border border-gray-300 bg-white px-14px py-10px ${
                isInValidField("user_address") ? "p-invalid" : ""
              }`}
              placeholder={capitalizedFirst(t("food-address-page.street-name"))}
              value={dataForm.values.user_address}
              onChange={(e) => {
                if (!dataForm?.touched?.user_address)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ user_address: true },
                  })
                dataForm.setFieldValue("user_address", e?.target?.value)
              }}
            />
            {dataForm.errors.user_address && dataForm.touched.user_address && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm.errors.user_address}
              </span>
            )}
          </div>
          <div className="flex w-full flex-col gap-3">
            <div className="flex flex-col md:flex-row items-start gap-3">
              <div className="flex w-full flex-col gap-6px">
                <span className="text-14 font-medium leading-20 text-gray-700">
                  {capitalizedFirst(t("global.country"))}
                  <span className="text-red-600">{"*"}</span>
                </span>
                <Dropdown
                  placeholder={capitalizedFirst(t("global.country"))}
                  optionLabel="label"
                  value={dataForm?.values.user_country}
                  options={dropdownCountryData}
                  className={`flex h-[44px] w-full items-center gap-1 rounded-3 border border-gray-200 bg-white px-14px py-10px ${
                    isInValidField("user_country") ? "p-invalid" : ""
                  }`}
                  onChange={(e) => {
                    if (!dataForm?.touched?.user_country)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ user_country: true },
                      })
                    dataForm.setFieldValue("user_country", e.value)
                  }}
                />
                {dataForm.errors.user_country &&
                  dataForm.touched.user_country && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.user_country}
                  </span>
                )}
              </div>
              <div className="flex w-full flex-col items-start gap-6px">
                <span className="text-14 font-medium leading-5 text-gray-700">
                  {capitalizedFirst(t("global.city"))}
                  <span className="text-red-600">{"*"}</span>
                </span>
                <Dropdown
                  placeholder={capitalizedFirst(t("global.city"))}
                  value={dataForm?.values?.user_city}
                  optionLabel="label"
                  options={dropdownCityData}
                  className={`flex h-[44px] w-full items-center gap-1 rounded-3 border border-gray-200 bg-white px-14px py-10px ${
                    isInValidField("user_city") ? "p-invalid" : ""
                  }`}
                  onChange={(e) => {
                    if (!dataForm?.touched?.user_city)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ user_city: true },
                      })
                    dataForm.setFieldValue("user_city", e.value)
                  }}
                />
                {dataForm.errors.user_city && dataForm.touched.user_city && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.user_city}
                  </span>
                )}
              </div>
            </div>
            <div className="flex w-full flex-col md:flex-row items-start gap-3">
              <div className="flex w-full flex-col items-start gap-6px">
                <span className="text-14 font-medium leading-5 text-gray-700">
                  {capitalizedFirst(t("global.district"))}
                  <span className="text-red-600">{"*"}</span>
                </span>
                <Dropdown
                  placeholder={capitalizedFirst(t("global.district"))}
                  value={dataForm?.values?.user_district}
                  optionLabel="label"
                  options={dropdownDistrictsData}
                  className={`flex h-[44px] w-full items-center gap-1 rounded-3 border border-gray-200 bg-white px-14px py-10px ${
                    isInValidField("user_district") ? "p-invalid" : ""
                  }`}
                  onChange={(e) => {
                    if (!dataForm?.touched?.user_district)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ user_district: true },
                      })
                    dataForm.setFieldValue("user_district", e.value)
                  }}
                />
                {dataForm.errors.user_district &&
                  dataForm.touched.user_district && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.user_district}
                  </span>
                )}
              </div>
              <div className="flex w-full flex-col items-start gap-6px">
                <span className="text-14 font-medium leading-5 text-gray-700">
                  {capitalizedFirst(t("global.ward"))}
                </span>
                <Dropdown
                  placeholder={capitalizedFirst(t("global.ward"))}
                  value={dataForm?.values?.user_ward}
                  optionLabel="label"
                  options={dropdownWardData}
                  className={
                    "flex h-[44px] w-full items-center gap-1 rounded-3 border border-gray-200 bg-white px-14px py-10px"
                  }
                  onChange={(e) => {
                    if (!dataForm?.touched?.user_ward)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ user_ward: true },
                      })
                    dataForm.setFieldValue("user_ward", e.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6px">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {capitalizedFirst(t("global.addressType"))}
            </span>
            <div className="flex flex-row gap-1">
              <div
                className={`flex flex-row items-center gap-4px rounded-5 py-4px pl-10px pr-2  ${
                  addressType === AddressAddressTypeEnum.HOME
                    ? "bg-blue-50"
                    : "bg-gray-200"
                }`}
                onClick={() => setAddressType(AddressAddressTypeEnum.HOME)}
              >
                <i className={`sctr-icon-home-smile text-14 font-medium ${addressType === AddressAddressTypeEnum.HOME
                  ? "text-blue-700"
                  : "text-gray-700"} `}></i>
                <span
                  className={`cursor-pointer text-14 font-medium leading-5 ${
                    addressType === AddressAddressTypeEnum.HOME
                      ? "text-blue-700"
                      : "text-gray-700"
                  }`}
                >
                  {capitalizedFirst(t("global.HOME"))}
                </span>
              </div>
              <div
                className={`flex flex-row items-center gap-4px rounded-5 py-4px pl-10px pr-2 ${
                  addressType === AddressAddressTypeEnum.OFFICE
                    ? "bg-blue-50"
                    : "bg-gray-200"
                }`}
                onClick={() => setAddressType(AddressAddressTypeEnum.OFFICE)}
              >
                <i className={`sctr-icon-building-07 text-14 font-medium ${addressType === AddressAddressTypeEnum.OFFICE
                  ? "text-blue-700"
                  : "text-gray-700"}`}></i>
                <span
                  className={`cursor-pointer text-14 font-medium leading-5 ${
                    addressType === AddressAddressTypeEnum.OFFICE
                      ? "text-blue-700"
                      : "text-gray-700"
                  }`}
                >
                  {capitalizedFirst(t("global.office"))}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-1 ">
            <Checkbox
              checked={isDefault}
              onChange={(e) => setIsDefault(e.target.checked as boolean)}
            />
            <span className="text-14 font-medium leading-5 text-gray-700">
              {capitalizedFirst(t("modal.setDefault"))}
            </span>
          </div>
        </div>
        
      </form>
    </Dialog>
  )
}
