import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import type { RootState } from "src/app/store"
import { signIn, signOut } from "src/features/auth"
export const receiveChannel = "AUTH"
export const SHARE_MESSAGE_TYPE = {
  INIT: "INIT",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  RECEIVE_SUCCESS: "RECEIVE_SUCCESS",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
}

export default function ReceiveToken() {
  const dispatch = useDispatch()
  const authStore = useSelector((state: RootState) => state.auth)

  const sendMessageToParent = (messageType: string) => {
    const message = {
      channel: "AUTH",
      message: {
        type: messageType,
        data: "",
      },
      date: Date.now(),
    }
    window?.parent?.postMessage(message, "*")
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleReceiveMessage = (e:any) => {
    if(e?.data?.channel === receiveChannel && e?.data?.message?.type) {
      switch (e?.data?.message?.type) {
      case SHARE_MESSAGE_TYPE.INIT:
        break
      case SHARE_MESSAGE_TYPE.LOGIN:
        {
          const messageData = e?.data?.message?.data
          if(!authStore.value){
            dispatch(signIn(messageData))
            sendMessageToParent(SHARE_MESSAGE_TYPE.LOGIN_SUCCESS)
          }
        }
        break
      case SHARE_MESSAGE_TYPE.LOGOUT:
        if(authStore.value) {
          dispatch(signOut())
          sendMessageToParent(SHARE_MESSAGE_TYPE.LOGIN_SUCCESS)
        } 
        break
      default:
        break
      }
    }
  }
  useEffect(() => {
    window.addEventListener("message", handleReceiveMessage)
    return () => window.removeEventListener("message", handleReceiveMessage)
  }, [])
  return (
    <div id="receiveToken"></div>
  )
}
