import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination, Autoplay } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "./index.scss"
import { BannerViewType } from "./types"
import { ImgCustom } from "../common"
import { IMAGE_TYPE } from "src/constants/common"
import { getSystemBanner } from "src/services/banner-service"
import { BannerType } from "../header-home-page/type"
import { handleCheckTime } from "src/utils/common"

const BannerView = () => {
  const [banners, setBanners] = useState<BannerViewType[]>([])

  const handleGetBanner = async () => {
    await getSystemBanner(
      0,
      10,
      encodeURIComponent("category_code==food_promotion_slide_banner"),
      encodeURIComponent("")
    )
      .then((response) => {
        const data: BannerType[] = response?.data?.data?.data
        if (data?.length) {
          const results: BannerViewType[] = []
          data?.map((item) => {
            if (handleCheckTime(item?.start_time, item?.end_time)) {
              if (item?.medias?.length) {
                const id = item?.medias?.[0]?.id
                const src = item?.medias?.[0]?.original_name
                const media: BannerViewType = { id, src }
                results.push(media)
              }
            }
          })
          setBanners(results)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    setBanners([])
    handleGetBanner()
  }, [])

  return (
    <>
      {banners?.length ? (
        <div className="h-[360px]" id="bannerView">
          {banners.length ? (
            <>
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                autoplay
                navigation={true}
                modules={[Navigation, Pagination, Autoplay]}
                className="h-full w-full"
              >
                {banners.map((i, index) => (
                  <SwiperSlide key={index}>
                    <div className="h-full w-full cursor-pointer rounded-4">
                      <ImgCustom
                        url={i.id}
                        alt="bannerSlide"
                        className="w-full rounded-4"
                        type={IMAGE_TYPE.WEB}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

export default React.memo(BannerView)
