import React, { useEffect, useMemo, useState } from "react"

import { ImgCustom } from "../common"
// import { useNavigate } from "react-router-dom"
// import { MenuItem } from "primereact/menuitem"
// import { Menu } from "primereact/menu"

import { FOOD_BOOKMARK_CATEGORY, SHARE_TYPE } from "src/constants/common"
import {
  bookmarkProduct,
  deleteBookmark,
  getBloomFilter,
} from "src/services/bookmark-service"
import { useToast } from "src/hooks/use-toast"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { handleCheckBloomFilter } from "src/utils/common"
import { useNavigate } from "react-router-dom"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { ProfileProps } from "../restaurant-view/types"
import ShareToFeed from "../restaurant-view/share-to-feed"
import BookingModal from "../bookingModal"

export default function MerchantProfile({
  id,
  avatar,
  name,
  rating,
  reviews,
  background,
  province,
  isNavigate,
  street,
  ward,
  district,
  handleShowReview,
}: ProfileProps) {
  const { t } = useTranslation()
  const showToast = useToast()
  const navigate = useNavigate()
  const authStore = useSelector((state: RootState) => state.auth)
  const [isOpenShare, setIsOpenShare] = useState<boolean>(false)
  const [bloomData, setBloomData] = useState<string>("")
  const [isBookTable, setIsBookTable] = useState(false)

  const handleBookmark = async (id?: string) => {
    if (authStore?.value) {
      if (bloomData) {
        if (!handleCheckBloomFilter(bloomData as string, id as string)) {
          await bookmarkProduct(id, FOOD_BOOKMARK_CATEGORY.RESTAURANT)
            .then(() => {
              handleGetBloomData()
            })
            .catch((error) => error)
        } else {
          deleteBookmark(id)
            .then(() => {
              handleGetBloomData()
            })
            .catch((error) => {
              error
            })
        }
      } else {
        await bookmarkProduct(id, FOOD_BOOKMARK_CATEGORY.RESTAURANT)
          .then(() => {
            handleGetBloomData()
          })
          .catch((error) => error)
      }
    } else {
      showToast({ detail: "Please login before bookmark", severity: "warn" })
    }
  }

  const handleShareToFeed = () => {
    if (authStore?.value) {
      setIsOpenShare(true)
    } else {
      showToast({ detail: "Please login before bookmark", severity: "warn" })
    }
  }

  useEffect(() => {
    handleGetBloomData()
  }, [])

  // get bloom data
  const handleGetBloomData = async () => {
    await getBloomFilter()
      .then((response) => {
        setBloomData(response?.data?.data?.bloom_filter)
      })
      .catch((error) => error)
  }

  const handleNavigate = () => {
    if (isNavigate) {
      navigate(`/food/restaurant/${id}`)
    }
  }

  const getFullAddress = useMemo(() => {
    return [street, ward, district, province].filter((i) => i).join(", ")
  }, [street, province, district, ward])

  return (
    <div className="box-border flex  min-h-[132px] w-full items-center justify-between rounded-4 bg-white ">
      <div className="flex w-full flex-col  justify-between md:flex-row">
        {/* shop information */}
        <div className="flex w-full items-start">
          <div className="h-[100px] w-[125px] px-2 py-2 md:py-1 md:h-[105px]">
            <ImgCustom
              url={avatar}
              alt="header"
              className="h-full w-full rounded-full object-cover object-center"
            />
          </div>
          <div className=" flex w-full flex-col gap-1 md:gap-2 py-1">
            <div className="gap-12 flex items-center justify-between">
              <div
                className={`flex cursor-pointer items-center justify-start gap-1 text-24  font-semibold ${
                  isNavigate
                    ? "hover:text-blue-700 hover:underline"
                    : "text-gray-900"
                }`}
              >
                <div onClick={handleNavigate}>{name}</div>
              </div>
            </div>
            <div className="flex items-center pr-2 md:pr-0">
              <p className="text-14 md:text-16 font-normal leading-6 text-gray-500 line-clamp-3">
                {getFullAddress}
              </p>
            </div>
            <div className="flex items-center justify-start ">
              <div className="flex items-center justify-start gap-1  pr-2 text-14 font-normal leading-5 text-gray-700 sm:pl-0">
                <i className="pi pi-star-fill text-20 text-amber-400"></i>
                <p className="text-14 font-medium text-amber-400">{rating}</p>
              </div>
              <div className="flex items-center gap-1 pt-2px text-14 font-medium leading-5 text-gray-500">
                <span className="pi pi-circle-fill text-[4px] text-gray-300"></span>
                <div
                  onClick={handleShowReview}
                  className="flex cursor-pointer items-center gap-4px hover:text-blue-700"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.99986 0.666775C4.40741 0.666775 0.666626 4.40756 0.666626 9C0.666626 10.1061 0.883823 11.1666 1.27697 12.1347C1.34341 12.2987 1.3627 12.3687 1.35184 12.3203C1.35183 12.2883 1.35086 12.3277 1.3258 12.4781L0.831011 15.4419C0.779261 15.7524 0.658154 16.0177 0.858679 16.4853C0.984961 16.7797 1.21959 17.0148 1.5146 17.1412C1.98191 17.3417 2.24733 17.2206 2.55792 17.1688L5.52171 16.674C5.52225 16.674 5.5228 16.674 5.52334 16.674C5.67356 16.649 5.71117 16.648 5.67959 16.648C5.63099 16.6371 5.7012 16.6564 5.86513 16.7229C6.8332 17.1161 7.89386 17.3332 8.99984 17.3332C13.5923 17.3332 17.3331 13.5924 17.3331 9C17.3331 4.40755 13.5923 0.666775 8.99986 0.666775ZM8.99986 2.33342C12.6916 2.33342 15.6664 5.3083 15.6664 9C15.6664 12.6918 12.6916 15.6666 8.99986 15.6666C8.1107 15.6666 7.26663 15.494 6.49339 15.1799C6.32676 15.1123 6.22783 15.0632 6.04418 15.0221C5.9352 14.9977 5.79168 14.9814 5.6796 14.9814C5.50997 14.9814 5.39879 15.0051 5.24829 15.0302L2.51399 15.4859L2.96971 12.7516C2.99477 12.6013 3.01856 12.4903 3.01854 12.3203C3.01853 12.2084 3.00235 12.065 2.97785 11.9557C2.9367 11.7723 2.88764 11.6732 2.81998 11.5065C2.50594 10.7333 2.33333 9.88914 2.33333 9.00006C2.33333 5.30836 5.30815 2.33342 8.99986 2.33342ZM5.2499 7.75002C4.56934 7.75002 3.99992 8.31949 3.99992 9C3.99992 9.68052 4.56934 10.25 5.2499 10.25C5.93047 10.25 6.49989 9.68052 6.49989 9C6.49989 8.31949 5.93047 7.75002 5.2499 7.75002ZM8.99986 7.75002C8.31926 7.75002 7.74987 8.31941 7.74987 9C7.74987 9.6806 8.31926 10.25 8.99986 10.25C9.68045 10.25 10.2498 9.6806 10.2498 9C10.2498 8.31941 9.68045 7.75002 8.99986 7.75002ZM12.7498 7.75002C12.0692 7.75002 11.4998 8.31941 11.4998 9C11.4998 9.6806 12.0692 10.25 12.7498 10.25C13.4304 10.25 13.9998 9.6806 13.9998 9C13.9998 8.31941 13.4304 7.75002 12.7498 7.75002Z"
                      fill="#667085"
                    />
                  </svg>
                  <div>
                    {reviews} {capitalizedFirst(t("global.reviews"))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-full px-1 pt-2">
          <div className="flex items-center gap-2 px-2">
            {/* book table */}
            {
              authStore?.value ? (
                <div
                  onClick={() => setIsBookTable(true)}
                  className="cursor-pointer truncate rounded-3 bg-blue-600 px-20px py-2 text-14 font-semibold leading-5 text-white hover:bg-blue-700"
                >
                  {capitalizedFirst(t("merchant-page.reserve"))}
                </div>
              ) : null
            }


            {/* bookmark */}
            <div
              onClick={() => handleBookmark(id)}
              className="flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-3 border border-gray-300 hover:bg-gray-50"
            >
              <div
                className={`flex items-center justify-center rounded-3 fill-none stroke-gray-500 ${
                  id &&
                  bloomData &&
                  handleCheckBloomFilter(bloomData as string, id as string)
                    ? "fill-yellow-400 stroke-yellow-400"
                    : "fill-none stroke-gray-600"
                }`}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 12 15"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1.33331 4.5999C1.33331 3.4798 1.33331 2.91974 1.5513 2.49192C1.74305 2.1156 2.04901 1.80964 2.42533 1.61789C2.85316 1.3999 3.41321 1.3999 4.53331 1.3999H7.46665C8.58675 1.3999 9.1468 1.3999 9.57463 1.61789C9.95095 1.80964 10.2569 2.1156 10.4487 2.49192C10.6666 2.91974 10.6666 3.4798 10.6666 4.5999V13.3999L5.99998 10.7332L1.33331 13.3999V4.5999Z" />
                </svg>
              </div>
            </div>

            {/* share to feed */}
            <div
              onClick={handleShareToFeed}
              className="flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-3 border border-gray-300 fill-gray-800 stroke-gray-300 hover:bg-gray-50"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 18 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.5236 0.0837206C8.14218 0.0740736 7.77674 0.244945 7.53566 0.546963C7.31891 0.818501 7.28926 1.08202 7.27178 1.27961C7.25431 1.47719 7.2573 1.66122 7.2573 1.89398V4.92316C5.59696 5.36751 4.05937 6.2147 2.90321 7.53509C1.4644 9.1782 0.667758 11.3056 0.666687 13.5096V14.0238C0.66673 14.1967 0.719003 14.3655 0.816384 14.507C0.913766 14.6486 1.05152 14.756 1.21088 14.8148C1.37024 14.8735 1.54346 14.8806 1.70694 14.8352C1.87042 14.7898 2.01621 14.694 2.12445 14.5609C2.91311 13.591 3.89782 12.808 5.01106 12.2628C5.71626 11.9174 6.49114 11.7963 7.2573 11.6583V14.106C7.2573 14.3387 7.25431 14.5228 7.27179 14.7203C7.28926 14.9179 7.31911 15.1799 7.53567 15.4513C7.53567 15.4519 7.53567 15.4525 7.53567 15.453C7.77667 15.7547 8.14169 15.9261 8.5236 15.9163C8.86662 15.9076 9.08129 15.7586 9.23962 15.6436C9.39795 15.5285 9.53154 15.4074 9.70463 15.2559L16.683 9.14992C16.8841 8.9739 17.1122 8.84836 17.2607 8.42713C17.3576 8.15149 17.3576 7.84857 17.2607 7.57294C17.1122 7.15185 16.8843 7.02622 16.683 6.85015L9.70463 0.744176C9.53154 0.5927 9.39794 0.471553 9.23962 0.356495C9.0813 0.241437 8.86659 0.0924004 8.5236 0.0837206ZM8.90494 2.25863L15.465 7.99992L8.90494 13.7412V10.5822C8.90497 10.4669 8.88178 10.3528 8.83679 10.247C8.79181 10.1413 8.72601 10.046 8.64346 9.96733C8.56092 9.8886 8.4634 9.82805 8.35697 9.78943C8.25053 9.75081 8.13745 9.73494 8.02476 9.74282C6.733 9.83229 5.46843 10.1717 4.29826 10.7449C3.70274 11.0365 3.14977 11.4037 2.62326 11.8044C2.89972 10.6532 3.34552 9.55391 4.13094 8.657C5.2034 7.43216 6.64609 6.61582 8.22273 6.33423C8.41367 6.3002 8.58674 6.19853 8.71152 6.04711C8.8363 5.89568 8.90479 5.70419 8.90495 5.50631L8.90494 2.25863Z" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <ShareToFeed
        isShow={isOpenShare}
        handleClose={() => setIsOpenShare(false)}
        background={background}
        name={name}
        id={id as string}
        type={SHARE_TYPE.RESTAURANT}
        avatar={avatar}
        province={province}
      />
      {id && authStore?.value ? (
        <BookingModal
          isShowModalBooking={isBookTable}
          restaurantId={id}
          closeModal={() => setIsBookTable(false)}
        />
      ) : null}
    </div>
  )
}
