import React from "react"
import emptyIcon from "src/assets/images/empty-reviews.png"
import { EmptyMessageProps } from "./type"

export default function EmptyDataFound(props: EmptyMessageProps) {
  const { title, message } = props
  return (
    <div className="flex h-full w-full items-center">
      <div className="flex h-full w-full flex-col items-center justify-center">
        <div className="flex w-full items-center justify-center">
          <div className="h-[172px] w-[172px]">
            <img src={emptyIcon} alt="" className="h-full w-full" />
          </div>
        </div>
        <div className="flex w-full items-center justify-center">
          <div className="flex w-full flex-col gap-1">
            <div className="w-full text-center text-18 font-semibold leading-7 text-gray-900">
              {title}
            </div>
            <div className="w-full text-center text-14 font-normal leading-5 text-gray-700">
              {message}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
