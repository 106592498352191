import React, { useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import backIcon from "src/assets/images/map-back-icon.svg"
export default function Map() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const handleOnLoad = () => {
    setIsLoading(true)
  }
  const getUrlMap = useMemo(() => {
    const category = searchParams.get("category")
    const oid = searchParams.get("oid")
    let mapUrl = "https://map.meetingtma.com/map-detail"

    if (category) mapUrl = mapUrl + `?category=${category}`
    if (oid) mapUrl = mapUrl + `&oid=${oid}`

    return mapUrl
  }, [searchParams])

  const back = () => {
    navigate("/food/search")
  }
  return (
    <div className="relative h-screen w-screen">
      {!isLoading ? (
        <div className="absolute left-0 top-0 z-[2] flex h-full w-full items-center justify-center gap-2 rounded-3 bg-white">
          <p className="animate-pulse duration-75">Loading...</p>
          <i className="pi pi-spin pi-spinner text-24 font-bold text-blue-light-400"></i>
        </div>
      ) : null}
      {isLoading ? (
        <div
          className="absolute right-1 top-1 z-[2] cursor-pointer"
          onClick={back}
        >
          <img
            src={backIcon}
            alt="backIcon"
            className="h-[44px] w-[44px] rounded-full"
          />
        </div>
      ) : null}
      <iframe
        id="mapResult"
        title="mapResult"
        src={getUrlMap}
        // src={
        //   "https://map.meetingtma.com/map-detail?oid=86e19425-d52f-433e-ae87-1ff0649f49a3&category=Restaurant"
        // }
        className="h-full w-full"
        onLoad={handleOnLoad}
      />
    </div>
  )
}
