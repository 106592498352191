import React from "react"
import { ImgCustom } from "src/components/common"
import { HeaderImageCustomProps } from "./types"
import bgRestaurant from "src/assets/images/bg-restaurant.png"
export default function MerchantBackground(props: HeaderImageCustomProps) {
  const { imgUrl } = props
  return (
    <div className="flex h-[450px] w-full items-center rounded-t-3 bg-blue-light-100 object-cover object-center">
      {imgUrl ? (
        <ImgCustom
          url={imgUrl}
          alt="header"
          className="h-full w-full object-fill object-center"
        />
      ) :
        <img
          src={bgRestaurant}
          alt="header"
          className="h-full w-full object-fill object-center"
        />}
    </div>
  )
}
