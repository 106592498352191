import React from "react"
import { convertCurrency } from "src/utils/convert-currency"
import { OrderFoodInfoProps } from "./props"
import { translateCapitalize } from "src/utils/translate-with-capitalize"
import { useNavigate } from "react-router-dom"
import { ImgCustom } from "src/components/common"

export default function OrderFoodInfo({ food }: OrderFoodInfoProps) {
  const navigate = useNavigate()
  const handleGoToDetails = (id?: string) => {
    if (id) navigate(`/food/${id}`)
  }

  return (
    <div className="flex w-full items-center gap-3 px-3 py-3 sm:flex-row">
      {/* image */}
      <div className="flex h-[76px] w-[80px] items-center">
        <ImgCustom
          url={food?.avatar?.id}
          alt="Food Avatar"
          className="h-[76px] w-[80px] rounded-2 border-gray-200 sm:border"
        />
      </div>

      <div className="flex w-full flex-col">
        {/* title */}
        <div
          onClick={() => handleGoToDetails(food.food_id)}
          className="w-full cursor-pointer items-center break-words text-14 font-semibold text-gray-700 hover:text-blue-700"
        >
          {food?.name}
        </div>
        {/* info */}
        <div className="flex w-full items-center">
          <div className="flex w-3/4 flex-col">
            {food?.variation1 && food.variation2 && (
              <div className="flex items-center gap-1">
                <p className="text-12 font-medium text-gray-500">
                  {translateCapitalize("shoppingCartPage.variation")}:
                </p>

                <p className="text-12 font-semibold text-gray-700">
                  {`${food?.variation1}/${food?.variation2}`}
                </p>
              </div>
            )}
            <div className="flex items-center gap-1">
              <p className="text-12 font-medium leading-3 text-gray-500">
                {translateCapitalize("global.quantity")}:
              </p>
              <p className="text-12 font-semibold text-gray-700">
                {food.quantity}
              </p>
            </div>
          </div>
          {/* price */}
          <div className="flex h-full w-1/4 items-end justify-end gap-1 pt-5">
            {food?.original_price && (
              <div className="flex items-center text-12 font-normal text-gray-500 line-through">
                {convertCurrency(food?.original_price)}
              </div>
            )}
            <div className="flex items-center text-14 font-semibold text-gray-700">
              {convertCurrency(food?.current_price)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
