import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import RantingsSummaryCustom from "../ratings-summary/ratings-summary-custom"
import { NoteType } from "../ratings-summary/ratings-summary-custom-props"
import CommentView from "../comment-view"
import { SERVICE } from "src/constants/common"
import { config } from "src/config/interceptors"
import { NoteControllerApi } from "@soctrip/axios-rating-service"
import { handlePromiseAll } from "src/utils/common"
import { useNavigate, useParams } from "react-router"
import * as restaurantService from "@soctrip/axios-restaurant-service"

export default function RestaurantReviewPage() {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const [restaurantDetails, setRestaurantDetails] = useState<any>()
  const commentViewRef = useRef<HTMLDivElement>(null)
  const [starFilterSelected, setStarFilterSelected] = useState<number>(0)
  const [notesFilter, setNotesFilter] = useState<NoteType[]>([])
  const [starFilter, setStarFilter] = useState<string>("")
  const [noteSelected, setNoteSelected] = useState<NoteType | undefined>(
    undefined
  )

  const getTotalReviews = useMemo(() => {
    const result = {
      numberReviews: 0,
      numberOfReviewsFor5Stars: 0,
      numberOfReviewsFor4Stars: 0,
      numberOfReviewsFor3Stars: 0,
      numberOfReviewsFor2Stars: 0,
      numberOfReviewsFor1Stars: 0,
    }
    if (
      restaurantDetails &&
      restaurantDetails?.reviews &&
      restaurantDetails?.reviews.length
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      restaurantDetails?.reviews.forEach((i: any) => {
        result.numberReviews += i?.count_review
        if (i.value === 5) {
          result.numberOfReviewsFor5Stars = i?.count_review
        }
        if (i.value === 4) {
          result.numberOfReviewsFor4Stars = i?.count_review
        }
        if (i.value === 3) {
          result.numberOfReviewsFor3Stars = i?.count_review
        }
        if (i.value === 2) {
          result.numberOfReviewsFor2Stars = i?.count_review
        }
        if (i.value === 1) {
          result.numberOfReviewsFor1Stars = i?.count_review
        }
      })
    }
    return result
  }, [restaurantDetails])

  const handleGetRestaurantInformation = async (restaurantId: string) => {
    await new restaurantService.RestaurantControllerApi(
      config(SERVICE.RESTAURANT)
    )
      .restaurantsIdGet(restaurantId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setRestaurantDetails(response?.data?.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateFilterComment = (value: number) => {
    const result = value === starFilterSelected ? 0 : value
    setStarFilterSelected(result)
    clickIntoCommentView()
  }
  const updateNoteFilter = (value: NoteType) => {
    clickIntoCommentView()
    const result =
      value.id === noteSelected?.id ? undefined : (value as NoteType)
    setNoteSelected(result)
  }
  const clickIntoCommentView = () => {
    commentViewRef.current?.scrollIntoView({
      behavior: "smooth",
    })
  }

  const getNotes = async () => {
    new NoteControllerApi(config(SERVICE.RATING))
      .notesTypeFirstPageGet("RESTAURANT", 10)
      .then((res) => {
        const result: NoteType[] = []
        if (res?.data?.data?.length) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push(i as NoteType)
          })
        }
        setNotesFilter(result)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    async function initView() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const apis: any = []
      apis.push(getNotes())
      apis.push(handleGetRestaurantInformation(id as string))
      handlePromiseAll(apis)
    }
    initView()
  }, [])

  useEffect(() => {
    if (starFilterSelected) {
      setStarFilter(`${starFilterSelected}`)
    } else {
      setStarFilter("")
    }
  }, [starFilterSelected])

  const handleBackToRestaurant = () => {
    navigate(`/food/restaurant/${id}`)
  }

  return (
    <div className="mt-[40px] flex w-full max-w-[1200px] flex-col pb-4">
      <div className="relative rounded-3 bg-white pt-4">
        <div
          className="absolute left-[0px] top-[-70px]"
          ref={commentViewRef}
        ></div>
        <div className="flex w-full items-center gap-1 px-3 ">
          <div
            onClick={handleBackToRestaurant}
            className="flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-3 border border-gray-300 bg-white hover:bg-gray-25 hover:shadow-lg"
          >
            <i className="pi pi-angle-left text-16 font-thin text-gray-700"></i>
          </div>
          <p className="py-2 text-20 font-semibold text-gray-800">
            {capitalizedFirst(t("product-details.customer-reviews"))}
          </p>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="w-[unset] border-r  border-solid border-gray-100 md:w-[25%]">
            <RantingsSummaryCustom
              totalStarRating={restaurantDetails?.rating || 0}
              totalNumberOfReviews={restaurantDetails?.sum_reviews || 0}
              numberOfReviewsFor5Stars={
                getTotalReviews.numberOfReviewsFor5Stars
              }
              numberOfReviewsFor4Stars={
                getTotalReviews.numberOfReviewsFor4Stars
              }
              numberOfReviewsFor3Stars={
                getTotalReviews.numberOfReviewsFor3Stars
              }
              numberOfReviewsFor2Stars={
                getTotalReviews.numberOfReviewsFor2Stars
              }
              numberOfReviewsFor1Stars={
                getTotalReviews.numberOfReviewsFor1Stars
              }
              updateFilterComment={updateFilterComment}
            ></RantingsSummaryCustom>

            <div className="mt-1 flex flex-wrap px-3">
              {notesFilter.map((note) => (
                <div
                  onClick={() => updateNoteFilter(note)}
                  key={note.id}
                  className={`mb-2 mr-2 cursor-pointer rounded-5 border px-2 py-[7px] text-14 font-medium transition ${
                    noteSelected?.id == note.id && "bg-yellow-500 text-white"
                  }`}
                >
                  {note?.content}
                </div>
              ))}
            </div>
          </div>
          <div className="min-h-[unset] w-full py-2 md:min-h-[400px] md:w-[75%] md:py-0">
            <CommentView
              starFilter={starFilter}
              noteFilter={noteSelected?.id || ""}
              clickIntoCommentView={clickIntoCommentView}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
