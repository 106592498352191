import React, { useMemo, useState } from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { VoucherInfoType } from "../types"
import { convertCurrency } from "src/utils/convert-currency"
import paymentLogo from "src/assets/images/payment-logo.svg"
import { generateQrCodeWithString } from "src/utils/qr-code-utils"

const VoucherInfo = (props: VoucherInfoType) => {
  const { t } = useTranslation()
  const { name, reward, description, qrUrl } = props
  const [qrDataImage, setQrDataImage] = useState<string>("")

  const getQrData = async (qrData: string) => {
    const dataRes = await generateQrCodeWithString(qrData)
    if (dataRes) setQrDataImage(dataRes)
  }

  useMemo(() => {
    if (qrUrl) getQrData(qrUrl)
  }, [qrUrl])

  return (
    <div className="flex flex-col px-4 py-3">
      <span className="text-[16px] font-semibold leading-[24px] text-gray-900">
        {capitalizedFirst(t("buy-voucher.voucher-info-title"))}
      </span>
      <div className="mt-3 flex text-[14px] font-medium leading-[20px]">
        <div className="flex flex-1 flex-col">
          <span className="text-gray-400">
            {capitalizedFirst(t("buy-voucher.voucher-info-name"))}
          </span>
          <span className="mt-4px text-gray-700">{name}</span>
        </div>
        <div className="flex flex-1 flex-col">
          <span className="text-gray-400">
            {capitalizedFirst(t("buy-voucher.voucher-info-reward"))}
          </span>
          <span className="mt-4px text-gray-700">
            {capitalizedFirst(
              t("buy-voucher.voucher-reward", {
                value: convertCurrency(reward),
              })
            )}
          </span>
        </div>
      </div>
      {description ? (
        <div className="mt-3 flex flex-col text-[14px] font-medium leading-[20px]">
          <span className="text-gray-400">
            {capitalizedFirst(t("buy-voucher.voucher-info-description"))}
          </span>
          <span
            dangerouslySetInnerHTML={{ __html: description }}
            className="mt-4px text-gray-700"
          ></span>
        </div>
      ) : null}
      {qrUrl && qrDataImage ? (
        <div className="mt-3 flex items-center justify-center">
          <div className="relative h-[300px] w-[300px]">
            <img
              alt="voucherQrCode"
              className="h-full w-full"
              src={qrDataImage}
            />
            <div className="absolute left-[120px] top-[120px] z-[2] h-[60px] w-[60px] rounded-full bg-white">
              <img
                src={paymentLogo}
                alt={"paymentLogo"}
                className="h-[60px] w-[60px]"
              />
            </div>
          </div>
          <span></span>
        </div>
      ) : null}
    </div>
  )
}

export default VoucherInfo
