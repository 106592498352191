/* eslint-disable @typescript-eslint/no-explicit-any */
import { VouchersType } from "../merchant-voucher/types"

export interface RestaurantVouchersDetailType {
  id: string
  name: string
  street: string
  ward: string
  district: string
  province: string
  country: string
  vouchers: VouchersType[]
  total_element?: number
  setItemPerPage?: (value: number) => void
}
export interface RestaurantType {
  id: string
  name: string
  avatar: {
    id: string
  }

  street: string
  ward: {
    name: string
  }

  district: {
    name: string
  }

  province: {
    name: string
  }

  country: {
    name: string
  }
}
export interface RestaurantVoucherType {
  restaurant: RestaurantType
  total_element: number
  vouchers:any[]
  totalItems?:number,
  selectTab?:string
}

export const TYPE_VOUCHER = {
  REDEEMED:"REDEEMED",
  UNREDEEMED: "UNREDEEMED",
  ALL:"ALL",
}

export const VoucherTab = [
  { name: "unredeemed", code: TYPE_VOUCHER.UNREDEEMED,quantity: 0 },
  { name: "redeemed", code: TYPE_VOUCHER.REDEEMED, quantity: 0 },
  
]