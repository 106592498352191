import React from "react"
import voucherSuccessIcon from "src/assets/images/buy-voucher-success.svg"
import voucherFailIcon from "src/assets/images/buy-voucher-fail.svg"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { HeaderViewType } from "../types"

const HeaderView = (props: HeaderViewType) => {
  const { t } = useTranslation()
  const { isSuccess } = props
  return (
    <div className="flex w-full flex-col items-center px-4">
      <img
        className="h-[48px] w-[48px]"
        src={isSuccess ? voucherSuccessIcon : voucherFailIcon}
        alt="paymentStatusIcon"
      />
      <span className="mt-3 text-[20px] font-semibold leading-[30px] text-gray-900">
        {capitalizedFirst(
          t(
            isSuccess ? "buy-voucher.header-success" : "buy-voucher.header-fail"
          )
        )}
      </span>
      <span className=" mt-1 text-center text-[14px] leading-[20px] text-gray-500">
        {capitalizedFirst(
          t(
            isSuccess
              ? "buy-voucher.header-success-info"
              : "buy-voucher.header-fail-info"
          )
        )}
      </span>
    </div>
  )
}

export default HeaderView
