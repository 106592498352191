import React, { useState } from "react"
import FoodCardView from "./food-card-view"
import { PopularFoodType } from "./types"
import EmptyPage from "../common/empty"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { FOOD_CARD_TYPE, RECOMMEND_TIME, SERVICE } from "src/constants/common"
import { getBloomFilter } from "src/services/bookmark-service"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { RestaurantControllerApi } from "@soctrip/axios-restaurant-service"
import { config } from "src/config/interceptors"
import { useNavigate } from "react-router-dom"
import { SkeletonLoading } from "../common"

const PopularFoodView = () => {
  const navigate = useNavigate()
  const [popularFood, setPopularFood] = React.useState<PopularFoodType[]>([])
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [bloomData, setBloomData] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { t } = useTranslation()
  const locationNearYou = useSelector(
    (state: RootState) => state.searchLocationNearYou
  )
  const loadData = async (viewMore = false) => {
    const currentPage = viewMore ? pageIndex + 1 : 0
    setPopularFood([])
    setIsLoading(true)
    await new RestaurantControllerApi(config(SERVICE.RESTAURANT))
      .restaurantsNearYouGet(
        locationNearYou?.value?.latitude as number,
        locationNearYou?.value?.longitude as number,
        locationNearYou?.value?.radius,
        currentPage,
        5
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          const result: PopularFoodType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.data.forEach((i: any) => {
            result.push({
              id: i?.id,
              title: i?.name,
              imgUrl: i?.avatar?.id,
              point: i?.rating,
              countReview: i?.sum_reviews,
              is_free_wifi: i?.is_free_wifi,
              is_parking_lot: i?.is_parking_lot,
              is_operating_hours: i?.is_operating_hours,
              is_vehicle_charging: i?.is_vehicle_charging,
              address: {
                country: i?.country,
                province: i?.province,
                district: i?.district,
                ward: i?.ward,
                street: i?.street,
              },
            })
          })
          if (viewMore) {
            setPageIndex(currentPage)
            setPopularFood([...popularFood, ...result])
          } else {
            setPopularFood(result)
          }
        }
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  React.useEffect(() => {
    handleGetBloomData()
  }, [])

  React.useEffect(() => {
    setPageIndex(0)
    if (locationNearYou?.value) loadData()
  }, [locationNearYou])

  // get bloom data
  const handleGetBloomData = async () => {
    await getBloomFilter()
      .then((response) => {
        setBloomData(response?.data?.data?.bloom_filter)
      })
      .catch((error) => error)
  }

  const handleClickViewAll = () => {
    navigate(`/food/delights?type=${RECOMMEND_TIME.POPULAR_NEAR_YOU}`)
  }
  if(!locationNearYou?.value) return null
  return (
    <>
      {isLoading ? (
        <div>
          <SkeletonLoading limit={5} rows={1} />
        </div>
      ) : (
        <>
          {popularFood?.length ? (
            <div className="mt-3 flex flex-col">
              <div className="flex w-full items-center justify-between">
                <div className="text-20 font-semibold leading-30 text-gray-900">
                  {capitalizedFirst(t("food-home-page.popular-near-title"))}
                </div>
                <div
                  onClick={handleClickViewAll}
                  className="cursor-pointer text-14 font-semibold leading-5 text-blue-600 hover:text-blue-700"
                >
                  {capitalizedFirst(t("global.view-all"))}
                </div>
              </div>
              <div className="mt-4 flex items-center ">
                {popularFood?.length ? (
                  <div className="grid w-full grid-cols-2 gap-4px md:gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                    {popularFood?.map((item) => (
                      <div key={item?.id}>
                        <FoodCardView
                          isFoodType={false}
                          isRatingView={true}
                          isAddressView={true}
                          type={FOOD_CARD_TYPE.RESTAURANT}
                          bloom_filter={bloomData}
                          handleReload={handleGetBloomData}
                          {...item}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="mt-4 h-[300px] w-full">
                    <EmptyPage message="There are currently no restaurant near you." />
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  )
}

export default React.memo(PopularFoodView)
