/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { VouchersType } from "src/components/merchant-voucher/types"
import VoucherView from "src/components/merchant-voucher/voucher"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { convertCurrency } from "src/utils/convert-currency"
import { getExpiredDateVoucher } from "src/utils/get-expired-date-voucher"

export default function VoucherInfo(props: VouchersType) {
  const {
    name,
    voucher_code,
    voucher_price,
    description,
    discount_price,
    max_distribution_per_buyer,
    start_date,
    end_date,
  } = props
  const { t } = useTranslation()
  return (
    <div className="flex w-full flex-col rounded-3 border border-gray-200 p-4 relative">
      <div className="absolute bg-orange-dark-100 top-0 left-0 right-0 h-[112px]"></div>
      <div className="mt-4">
        <VoucherView {...props} isHiddenBuy={true} isHiddenDetail={true} type="VIEW"/>
        <div className="flex items-center justify-center py-3 text-[18px] font-semibold leading-[28px] text-gray-900">
          {name}
        </div>
        <div className="flex flex-col text-[14px] font-medium leading-[20px]">
          <div className="flex w-full gap-3">
            <div className="flex flex-1 flex-col">
              <span className="text-gray-400">
                {capitalizedFirst(t("buy-voucher.voucher-code"))}
              </span>
              <span className="mt-4px text-gray-700">{voucher_code}</span>
            </div>
            <div className="flex flex-1 flex-col">
              <span className="text-gray-400">
                {capitalizedFirst(t("buy-voucher.voucher-price"))}
              </span>
              <span className="mt-4px text-gray-700 line-clamp-1">
                {convertCurrency(voucher_price)}
              </span>
            </div>
          </div>
          <div className="flex w-full gap-3">
            <div className="flex-1 mt-3 flex flex-col">
              <span className="text-gray-400 line-clamp-1">
                {capitalizedFirst(t("buy-voucher.voucher-info-reward"))}
              </span>
              <span className="mt-4px text-gray-700 line-clamp-1 truncate">
                {capitalizedFirst(
                  t("buy-voucher.voucher-reward", {
                    value: convertCurrency(discount_price),
                  })
                )}
              </span>
            </div>
            <div className="flex-1 mt-3 flex flex-col">
              <span className="text-gray-400 line-clamp-1">
                {capitalizedFirst(t("buy-voucher.expiration"))}
              </span>
              <span className="mt-4px text-gray-700">
                {capitalizedFirst(t(getExpiredDateVoucher(start_date)))} - 
                {capitalizedFirst(t(getExpiredDateVoucher(end_date)))}
              </span>
            </div>

          </div>
          {description ? (
            <div className="mt-3 flex flex-col">
              <span className="text-gray-400 ">
                {capitalizedFirst(t("global.description"))}
              </span>
              <span dangerouslySetInnerHTML={{ __html: description }} className="mt-4px text-gray-700"></span>
            </div>
          ) : null}
          <div className="mt-3 flex flex-col">
            <span className="text-gray-400 ">
              {capitalizedFirst(t("buy-voucher.max-buy"))}
            </span>
            <span className="mt-4px text-gray-700">
              {max_distribution_per_buyer}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
