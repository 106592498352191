import React, { useEffect, useState } from "react"
import { ShopDataProps } from "../approve-info-view/type"
import { CurrencyType } from "../request-open-view/types"
import { getCurrencies } from "src/services/billing-service"
import { InputSwitch } from "primereact/inputswitch"

export default function ApproveShopInformation(props: ShopDataProps) {
  const {
    name,
    country,
    district,
    province,
    street,
    sub_street,
    ward,
    restaurant_types,
    deposit,
    currency,
    is_deposit,
    is_campaign,
  } = props
  const isForeign = ("vn" ?? "") != "vn"

  const [currencyData, setCurrencyData] = useState<CurrencyType[]>([])
  const [currentCurrencyData, setCurrentCurrencyData] = useState<string>("")

  useEffect(() => {
    async function fetchCurrencies() {
      try {
        const res = await getCurrencies()
        setCurrencyData(res?.data?.data ?? [])
      } catch (error) {
        console.log(error)
      }
    }
    fetchCurrencies()
  }, [])

  useEffect(() => {
    if (currencyData && currency) {
      const currencyExist = currencyData.find(
        (currencyItem) => currencyItem.code.toUpperCase() === currency.toUpperCase()
      )
      if(currencyExist) {
        setCurrentCurrencyData(`${currencyExist?.code} - ${currencyExist?.name}`)
      } else {
        setCurrentCurrencyData(currency)
      }
    } else {
      setCurrentCurrencyData("Empty")
    }
  }, [currencyData, currency])

  return (
    <div className="w-full p-3">
      <div className="flex w-full flex-col lg:flex-row">
        <div className="flex-1">
          <div className="flex flex-col">
            <span className="leading-28px text-[18px] font-semibold text-gray-900">
              {"Restaurant information"}
            </span>
          </div>
        </div>
        <div className="flex w-full flex-col gap-2 lg:w-2/3">
          {/* Shop name */}
          <div className="flex w-full flex-col">
            <span className="w-full text-14 font-medium leading-20 text-gray-700">
              {"Merchant name"}
            </span>
            <input
              className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
              placeholder="Empty"
              name="shop_name"
              readOnly
              value={name ? name : ""}
            />
          </div>
          {/* Address 1 */}
          <div className="flex w-full flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">{"Address line 1"}</span>
            <input
              className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
              placeholder="Empty"
              name="shop_name"
              readOnly
              value={street ? street : ""}
            />
          </div>
          {/* Address 2 */}
          <div className="flex w-full flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">{"Address line 2"}</span>
            <input
              className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
              placeholder="Empty"
              name="shop_name"
              readOnly
              value={sub_street ? sub_street : ""}
            />
          </div>
          {/* Country */}
          <div id="shopInformation" className="flex flex-col items-center gap-3">
            <div className="flex w-full items-center justify-between gap-3">
              {/* Country */}
              <div className="flex w-full flex-1 flex-col">
                <span className="text-14 font-medium leading-20 text-gray-700">{"Country"}</span>
                <input
                  value={country?.name ? country?.name : ""}
                  name="shop_country"
                  className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
                  placeholder="Empty"
                  readOnly
                />
              </div>
              {/* State */}
              <div className="flex w-full flex-1 flex-col">
                <span className="text-14 font-medium leading-20 text-gray-700">
                  {isForeign ? "State/Province" : "City/Province"}
                </span>
                <input
                  value={province?.name ? province?.name : ""}
                  name="shop_country"
                  className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
                  placeholder="Empty"
                  readOnly
                />
              </div>
            </div>

            <div className="flex w-full items-center justify-between gap-3">
              {/* City */}
              <div className="flex w-full flex-1 flex-col">
                <span className="text-14 font-medium leading-20 text-gray-700">
                  {isForeign ? "City" : "District"}
                </span>
                <input
                  value={district?.name ? district.name : ""}
                  name="shop_country"
                  className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
                  placeholder="Empty"
                  readOnly={true}
                />
              </div>
              {/* Ward */}
              <div className="flex w-full flex-1 flex-col">
                <span className="text-14 font-medium leading-20 text-gray-700">{"Ward "}</span>
                <input
                  value={ward?.name ? ward?.name : ""}
                  name="shop_ward"
                  className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
                  placeholder="Empty"
                  readOnly={true}
                />
              </div>
            </div>
          </div>
          {/* Shop types */}
          <div className="flex w-full flex-col">
            <span className="w-full text-14 font-medium leading-20 text-gray-700">
              {"Category type"}
            </span>
            <input
              className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
              placeholder="Empty"
              name="shop_type"
              readOnly
              value={restaurant_types?.length ? restaurant_types[0] : ""}
            />
          </div>
          {/* Deposit */}
          <div id="customSwitch" className="flex items-center gap-1 py-1">
            <InputSwitch checked={is_deposit} />
            <span className="flex-1 text-[14px] font-medium leading-[20px] text-gray-700">
              Require customers to make a deposit to secure a table reservation
            </span>
          </div>
          <div className="flex w-full flex-col">
            <span className="w-full text-14 font-medium leading-20 text-gray-700">
              {"Deposit amount"}
            </span>
            <input
              className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
              placeholder="Empty"
              name="deposit_amount"
              readOnly
              value={deposit ? `$${deposit}` : "$0"}
            />
          </div>
          {/* submit bill */}
          <div id="customSwitch" className="flex items-center gap-1 py-1">
            <InputSwitch checked={is_campaign || false} disabled />
            <span className="flex-1 text-[14px] font-medium leading-[20px] text-gray-700">
              Allow customers to submit bills to receive discount offers.
            </span>
          </div>
          <div id="shopInformation" className="flex flex-col items-center gap-3 md:flex-row">
            {/* Currency */}
            <div className="flex w-full flex-1 flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">Currency</span>
              <input
                value={currentCurrencyData}
                name="shop_country"
                className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
                placeholder="Empty"
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
