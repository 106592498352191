import React, { useEffect, useRef, useState } from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Navigate, useLocation, useRoutes } from "react-router-dom"
import { Toast } from "primereact/toast"

import { ToastContext } from "./app/toast-context"
import FoodDetailPage from "./pages/food-detail"
import FoodPage from "./pages/food"
import MainLayout from "./layout"
import { ROUTERS } from "./constants/router"
import SearchPage from "./pages/search"
import MapResults from "./pages/map-results"
import OrdersPage from "./pages/orders"
import OrderDetailsPage from "./pages/order-details"
import Restaurant from "./pages/restaurant"
import "./App.scss"
import ReceiveToken from "./pages/receive-token"
import RequestOpen from "./pages/request-open"
import ShareToken from "src/components/share-token"
import { STATE_RENDER_APP } from "src/constants/common"
import Map from "./pages/map"
import DelightView from "./pages/delights-view"
import RestaurantReviewPage from "./components/restaurant-review-content"
import ReservationDetails from "./pages/reservation-details"
import OrderVoucher from "./pages/order-voucher"
import ReservationConfirmed from "./pages/reservation-confirmed"
import RestaurantReportRatingDetails from "./pages/restaurant-report-rating-details"
import IframeApproveShop from "./pages/iframe-approve-shop"
import BookingOrder from "./pages/booking-order"
import WelComePage from "./pages/welcome"
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function App() {
  const routes = useRoutes([
    {
      path: ROUTERS.FOOD,
      element: <MainLayout />,
      children: [
        {
          path: "",
          element: <FoodPage />,
          index: true,
        },
        {
          path: ROUTERS.SEARCH,
          element: <SearchPage />,
        },
        {
          path: ROUTERS.FOOD_DETAIL,
          element: <FoodDetailPage />,
        },
        {
          path: ROUTERS.MAP_RESULTS,
          element: <MapResults />,
        },
        {
          path: ROUTERS.ORDERS,
          element: <OrdersPage />,
        },
        {
          path: ROUTERS.ORDER_DETAILS,
          element: <OrderDetailsPage />,
        },
        {
          path: ROUTERS.RESTAURANT,
          element: <Restaurant />,
        },
        {
          path: ROUTERS.MAP,
          element: <Map />,
        },
        {
          path: ROUTERS.DELIGHT,
          element: <DelightView />,
        },
        {
          path: ROUTERS.RESTAURANT_REVIEW,
          element: <RestaurantReviewPage />,
        },
        {
          path: ROUTERS.RESERVATION_DETAILS,
          element: <ReservationDetails />,
        },
        {
          path: ROUTERS.RESERVATION_CONFIRMED,
          element: <ReservationConfirmed />,
        },
        {
          path: ROUTERS.ORDER_VOUCHER,
          element: <OrderVoucher />,
        },
        {
          path: ROUTERS.BOOKING_ORDER,
          element: <BookingOrder />,
        },
        {
          path: ROUTERS.IFRAME_RESTAURANT_RATING,
          element: <RestaurantReportRatingDetails />,
        },
        {
          path: ROUTERS.IFRAME_APPROVE_RESTAURANT_REQUEST,
          element: <IframeApproveShop />,
        },
      ],
    },
    {
      path: `${ROUTERS.FOOD}/${ROUTERS.REQUEST_OPEN}`,
      element: <RequestOpen />,
    },
    {
      path: ROUTERS.SHARE_TOKEN,
      element: <ReceiveToken />,
    },
    {
      path: "/delights",
      element: <DelightView />,
    },
    {
      path: "/order-voucher",
      element: <OrderVoucher />,
    },
    {
      path: "/restaurant/report-rating/details/:id",
      element: <RestaurantReportRatingDetails />,
    },
    {
      path: "/approval-request/restaurant/:id",
      element: <IframeApproveShop />,
    },
    {
      path: ROUTERS.WELCOME,
      element: <WelComePage />,
    },
    { path: "/", element: <Navigate to="/food" /> },
  ])
  const [stateRenderView, setStateRenderView] = useState<string>(
    STATE_RENDER_APP.INIT
  )
  const location = useLocation()

  const detectLogin = () => {
    setStateRenderView(STATE_RENDER_APP.IFRAME)
  }

  useEffect(() => {
    if (
      !location.pathname.includes("/orders") &&
      !location.pathname.includes("/reservation/")
    ) {
      localStorage.removeItem("ORDER_TAB")
    }
  }, [location.pathname])

  useEffect(() => {
    function initView() {
      detectLogin()
    }
    initView()
  }, [])
  const toast = useRef<Toast>(null)
  if (stateRenderView === STATE_RENDER_APP.INIT) return null
  return (
    <ToastContext.Provider value={toast}>
      {stateRenderView === STATE_RENDER_APP.APP ? (
        <QueryClientProvider client={queryClient}>
          {routes}
        </QueryClientProvider>
      ) : null}
      <ShareToken
        isLoading={true}
        stateRenderView={stateRenderView}
        setStateRenderView={(newValue: string) =>
          setStateRenderView(newValue)
        }
      />
      <Toast ref={toast} />
    </ToastContext.Provider>
  )
}

export default App
