export const ROUTERS = {
  HOME: "/home",
  SEARCH: "search",
  FOOD: "/food",
  FOOD_DETAIL: ":id",
  MAP_RESULTS: "search/map-results",
  ORDERS: "orders",
  ORDER_DETAILS: "orders/:id",
  RESTAURANT: "restaurant/:id",
  RESTAURANT_REVIEW: "restaurant/review-page/:id",
  SHOPPING: "/shopping",
  PURCHASE_HISTORY: "/orders",
  SHOPPING_CART: "/shopping-cart",
  ORDER_CART: "/order-cart",
  SHARE_TOKEN: "/share-token",
  REQUEST_OPEN: "request-open-restaurant",
  MAP: "map",
  DELIGHT: "delights",
  RESERVATION_DETAILS: "reservation/:id",
  ORDER_VOUCHER: "order-voucher",
  RESERVATION_CONFIRMED: "reservation/confirmed/:id",
  IFRAME_RESTAURANT_RATING: "restaurant/report-rating/details/:id",
  IFRAME_APPROVE_RESTAURANT_REQUEST: "approval-request/restaurant/:id",
  BOOKING_ORDER: "booking/order",
  WELCOME: "/welcome",
}

export const ROUTERS_NOT_LAYOUT = [
  ROUTERS.SHARE_TOKEN,
  ROUTERS.REQUEST_OPEN,
  ROUTERS.MAP,
  ROUTERS.IFRAME_RESTAURANT_RATING,
  ROUTERS.WELCOME,
]
