import React, { useMemo, useEffect, useState } from "react"
import "./index.scss"
import type { MainLayoutProps } from "./types"
export type { MainLayoutProps }
import HeaderView from "src/components/header-view"
import { Outlet, useLocation } from "react-router-dom"
import { ROUTERS, ROUTERS_NOT_LAYOUT } from "src/constants/router"
import HeaderHomePage from "src/components/header-home-page"
import HeaderPopularNearYou from "src/components/header-popular-near-you"
import MerchantHeader from "src/components/merchant-header"
import RestaurantReviewPageHeader from "src/components/restaurant-review-page-header"
import SearchHeader from "src/components/search-home-view/header"
import scrollToTopIcon from "src/assets/images/scroll-top-icon.svg"
// import soctripLogo from "src/assets/images/logo.svg"
// import { isDevMode } from "src/utils/app-logo"
// import { t } from "i18next"
import FooterView from "src/components/footer-view"
import { validateAndFetchCurrency } from "src/utils/convert-currency"

export default function MainLayout() {
  const location = useLocation()
  const [isShowScroll, setIsShowScroll] = useState<boolean>(false)

  useEffect(() => {
    handleScrollToTop()
  }, [location?.pathname])

  // validate currency when change page
  useEffect(() => {
    validateAndFetchCurrency()
  },[location.pathname])

  const isNotLayout = useMemo(() => {
    const path = location.pathname?.split("/")?.splice(1, 3)?.join("/")
    const pathApproval = location.pathname?.split("/")?.splice(1, 2)?.join("/")
    return (
      ROUTERS_NOT_LAYOUT.includes(location.pathname) ||
      ROUTERS.IFRAME_RESTAURANT_RATING?.includes(path) ||
      ROUTERS.IFRAME_APPROVE_RESTAURANT_REQUEST?.includes(pathApproval)
    )
  }, [location])

  const getPath = (routers: string) => {
    const pathData = location.pathname.replace("/food/","").split("/")
    const routerData = routers.split("/")
    const path = pathData.splice(0, pathData.length - 1).join("/")
    const router = routerData.splice(0, routerData.length - 1).join("/")
    if (path === router) {
      return true
    } else {
      return false
    }
  }
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
  const detectScrollButton = () => {
    const isScroll = window.scrollY > 100
    setIsShowScroll(isScroll)
  }

  useEffect(() => {
    window.addEventListener("scroll", detectScrollButton)
    return () => {
      window.removeEventListener("scroll", detectScrollButton)
    }
  }, [])

  if (isNotLayout) return <Outlet/>

  return (
    <div
      id="content-body"
      className="no-scrollbar mx-auto flex h-fit min-h-screen w-screen flex-col overflow-auto z-1"
    >
      <HeaderView />
      <main className="mt-[65px] flex w-full flex-col justify-center bg-gray-50">
        {/* <div className="mx-auto my-2 flex flex h-[52px] min-h-[52px] w-full max-w-[1200px] items-center justify-center gap-2 rounded-2 border border-amber-600 bg-amber-50 px-3 text-amber-600">
          <i className="pi pi-exclamation-triangle text-20"></i>
          <span className="text-14 font-semibold">{t("global.beta")}</span>
        </div> */}
        {location.pathname === ROUTERS.FOOD && <HeaderHomePage />}
        {location.pathname === `/food/${ROUTERS.DELIGHT}` && <HeaderPopularNearYou />}
        {location.pathname === `/food/${ROUTERS.SEARCH}` && <SearchHeader />}
        {getPath(ROUTERS.RESTAURANT) && <MerchantHeader />}
        {getPath(ROUTERS.RESTAURANT_REVIEW) && <RestaurantReviewPageHeader />}
        <div className="mx-auto w-full max-w-[1200px] px-2 md:px-4 xl:px-0 main">
          <Outlet/>
        </div>
      </main>
      {/* FOOTER */}
      <FooterView/>
      <div className="fixed bottom-40px right-1 z-50 flex items-center gap-1">
        {/* {!isDevMode() && (
          <a
            href="https://forms.gle/eX9Jb2eDMPuw6Hky9"
            target="_blank"
            rel="noopener noreferrer"
            className="group flex cursor-pointer items-center gap-2 rounded-[100px] border border-gray-100 bg-white px-[4px] py-[4px] shadow-lg hover:bg-gray-25"
          >
            <span className="hidden text-12 font-semibold group-hover:ml-2 group-hover:block">
              Give feedback
            </span>
            <img className="h-40px w-40px" src={soctripLogo} alt="Logo Soctrip" />
          </a>
        )} */}
        {isShowScroll && (
          <div
            onClick={handleScrollToTop}
            className="flex h-40px w-40px cursor-pointer items-center justify-center md:bottom-[40px] md:right-[40px]"
          >
            <img className="h-full w-full" src={scrollToTopIcon} alt="scrollToTopIcon" />
          </div>
        )}
      </div>
    </div>
  )
}
