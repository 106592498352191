import { Dialog } from "primereact/dialog"
import React, { useEffect, useState } from "react"
import {
  ReasonReportComment,
  ReportDto,
  ReportReviewsProperties,
} from "./ratings-and-reviews-custom-props"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { InputTextarea } from "primereact/inputtextarea"
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton"
import { useToast } from "src/hooks/use-toast"
import LoadingReport from "./loading"
import { REPORT_TYPE } from "src/constants/common"
import {
  getCategoryReportRating,
  reportRating,
} from "src/services/rating-service"

export default function ModalReport({
  isShowModal,
  handleClose,
  object_id,
  object_parent_type,
  object_parent_id,
  owner_id,
  key_search,
  city_code,
}: ReportReviewsProperties) {
  const { t } = useTranslation()
  const showToast = useToast()
  const [valueDescription, setValueDescription] = useState<string>("")
  const [selectedReason, setSelectedReason] = useState<ReasonReportComment>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isDisable, setIsDisable] = useState<boolean>(true)
  const [reasonCategory, setReasonCategory] = useState<ReasonReportComment[]>(
    []
  )

  useEffect(() => {
    if (selectedReason) {
      if (
        selectedReason?.name?.toUpperCase()?.includes(REPORT_TYPE.REASON_TYPE)
      ) {
        if (valueDescription) {
          setIsDisable(false)
        } else {
          setIsDisable(true)
        }
      } else {
        setIsDisable(false)
      }
    } else {
      setIsDisable(true)
    }
  }, [selectedReason, valueDescription])

  const handleConfirm = async () => {
    const dto: ReportDto = {
      object_id: object_id,
      object_type: REPORT_TYPE.RESTAURANTRATING,
      object_parent_id: object_parent_id,
      object_parent_type: object_parent_type,
      report_reason: valueDescription,
      category_type: [`${selectedReason?.name}`],
      owner_id: owner_id,
      key_search: key_search,
      city_code: city_code,
      url_detail: `${process.env.REACT_APP_FOOD_DOMAIN_URL}/restaurant/report-rating/details/${object_id}`,
    }
    console.log("Confirmed", dto)
    await reportRating(dto)
      .then(() => {
        showToast({
          detail: "Report comment successfully",
          severity: "success",
        })
      })
      .catch(() => {
        showToast({
          detail: "Failed to report this comment",
          severity: "error",
        })
      })

    handleClose()
  }

  const handleGetCategoryReportRating = async () => {
    await getCategoryReportRating()
      .then((response) => {
        setReasonCategory(response?.data?.data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    handleGetCategoryReportRating()
  }, [])

  return (
    <Dialog
      id="report-id"
      draggable={false}
      visible={isShowModal}
      onHide={() => handleClose()}
      header={capitalizedFirst(t("global.report-this-review"))}
    >
      <div className="flex w-[640px] flex-col gap-2 px-4 pt-4px">
        {isLoading ? (
          <div className="w-full">
            <LoadingReport />
          </div>
        ) : (
          <div className="flex w-full flex-col gap-3">
            <div className="flex w-full flex-col gap-3">
              {reasonCategory?.map((item) => (
                <div key={item.id} className="flex items-center gap-1">
                  <RadioButton
                    inputId={item.id}
                    name="category"
                    value={item}
                    onChange={(e: RadioButtonChangeEvent) =>
                      setSelectedReason(e.value)
                    }
                    checked={selectedReason?.id === item.id}
                  />
                  <label
                    htmlFor={item.id}
                    className="text-14 font-medium leading-5 text-gray-700"
                  >
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
            {/* other */}
            {selectedReason?.name
              ?.toUpperCase()
              ?.includes(REPORT_TYPE.REASON_TYPE) ? (
                <div className="mt-2 flex w-full flex-col py-1">
                  <InputTextarea
                    autoResize
                    value={valueDescription}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setValueDescription(e.target.value)
                    }
                    rows={3}
                    className="w-full text-14 font-normal"
                    placeholder={capitalizedFirst(t("global.report-description"))}
                  />
                </div>
              ) : null}
          </div>
        )}

        {/* footer */}
        <div className="flex w-full items-center justify-end gap-2 border-t border-gray-100 py-4">
          <button
            onClick={handleClose}
            type="button"
            className="rounded-3 border border-gray-300 px-20px py-2 text-14 font-semibold text-gray-900"
          >
            {capitalizedFirst(t("global.cancel"))}
          </button>
          <button
            disabled={isDisable}
            type="button"
            onClick={handleConfirm}
            className={`rounded-3 border border-blue-700 bg-blue-700 px-20px py-2 text-14 font-semibold text-white ${
              isDisable ? "opacity-60" : ""
            }`}
          >
            {capitalizedFirst(t("global.submit"))}
          </button>
        </div>
      </div>
    </Dialog>
  )
}
