import React, { useEffect, useState } from "react"
import { InputText } from "primereact/inputtext"
import RestaurantVouchersDetails from "./restaurant-vouchers-detail"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { t } from "i18next"
import { VoucherControllerApi } from "@soctrip/axios-restaurant-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { RestaurantVoucherType, VoucherTab, TYPE_VOUCHER } from "./types"
import EmptyPage from "../common/empty"

export default function MyVoucher() {
  const [keywords, setKeywords] = useState<string>("")
  const [restaurantVouchers, setRestaurantVouchers] = useState<
    RestaurantVoucherType[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingQuantityVoucher, setIsLoadingQuantityVoucher] =
    useState<boolean>(false)
  const [totalRestaurant, setTotalRestaurant] = useState<number>(0)
  const [selectTab, setSelectTab] = useState<string>(TYPE_VOUCHER.UNREDEEMED)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [orderData, setOrderData] = useState<any[]>([])
  const [page, setPage] = useState(0)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const itemPerPage = 999

  const getAllVoucher = async () => {
    setIsLoadingQuantityVoucher(true)
    await new VoucherControllerApi(config(SERVICE.RESTAURANT)).vouchersCountTypeGet()
      .then((res) => {
        const totalQuantityUnredeemed = res?.data?.data?.find(el => el.type_code == TYPE_VOUCHER.UNREDEEMED)?.amount as number
        const totalQuantityRedeemed = res?.data?.data?.find(el => el.type_code == TYPE_VOUCHER.REDEEMED )?.amount as number
        const unredeemedVoucher = VoucherTab?.find(el => el.code === TYPE_VOUCHER.UNREDEEMED)
        const redeemedVoucher = VoucherTab?.find(el => el.code === TYPE_VOUCHER.REDEEMED)
        
        if (unredeemedVoucher) {
          unredeemedVoucher.quantity = totalQuantityUnredeemed 
        }
        if(redeemedVoucher){
          redeemedVoucher.quantity = totalQuantityRedeemed
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoadingQuantityVoucher(false)
      })
  }
  useEffect(() => {
    getAllVoucher()
  }, [])

  useEffect(() => {
    async function _fetch() {
      setIsLoading(true)
      setRestaurantVouchers([])
      await new VoucherControllerApi(config(SERVICE.RESTAURANT))
        .vouchersGet(selectTab, keywords, page, 5, itemPerPage)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          setTotalRestaurant(res?.data?.totalElement)
          setRestaurantVouchers(res.data?.data?.data)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    _fetch()
  }, [keywords, itemPerPage,selectTab])
  const onChangeKeyword = (value: string) => {
    setIsLoading(true)
    setTimeout(() => {
      setKeywords(value)
      setIsLoading(false)
    }, 400)
  }

  const fetchNextPage = async () => {
    const nextPage = page + 1
    setPage(nextPage)
    setIsLoadMore(true)
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await new VoucherControllerApi(
        config(SERVICE.RESTAURANT)
      ).vouchersGet("ALL", keywords, nextPage, 5, itemPerPage)
      const data = res?.data?.data?.data
      const total = res?.data?.data?.totalElement
      if (data) {
        const newOrderData = [...orderData, ...data]
        setOrderData(newOrderData)
      }
      if (total) {
        setTotalRestaurant(total)
      }
      setIsLoadMore(false)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="flex flex-col gap-2">
      <section className="flex w-full flex-col gap-4 rounded-3 border bg-white p-3">
        <div className="flex flex-col md:flex-row items-center justify-between gap-1">
          <span className="md:text-18 text-14 font-semibold leading-[18px] md:leading-[20px] text-gray-900">
            {capitalizedFirst(t("my-voucher.my-vouchers"))}
          </span>
          <div >
            <div className="p-input-icon-left w-full">
              <i className="pi pi-search !text-gray-600" />
              <InputText
                placeholder={capitalizedFirst(t("global.search"))}
                className="h-[44px] md:w-[322px] w-fit"
                onChange={(e) => onChangeKeyword(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-1">
          {!isLoadingQuantityVoucher &&
            VoucherTab.map((item, index) => (
              <div
                className={` flex cursor-pointer items-center justify-center gap-1 rounded-1 px-2 py-1 text-14 font-semibold leading-20 ${
                  item.code === selectTab
                    ? "bg-blue-50 text-blue-600 "
                    : "bg-white text-gray-500"
                }`}
                key={`${new Date().getTime()}-${index}`}
                onClick={() => setSelectTab(item.code)}
              >
                <span>{capitalizedFirst(t(`buy-voucher.${item.name}`))}</span>
                <div className={`rounded-[12px] border text-12  ${
                  item.code === selectTab
                    ? "border-blue-600 text-blue-600 "
                    : "border-gray-300 text-gray-500"
                }`}>
                  <span className="p-1">{item?.quantity}</span>
                </div>
              </div>
            ))}
        </div>
      </section>

      {isLoading ? (
        <>
          <div className="my-3 flex h-auto max-h-screen min-h-[300px] w-full items-center justify-center gap-2 rounded-3 bg-white">
            <p className="animate-pulse duration-75">
              {capitalizedFirst(t("global.loading"))}
            </p>
            <i className="pi pi-spin pi-spinner text-24 font-bold text-blue-light-400"></i>
          </div>
        </>
      ) : (
        <>
          {restaurantVouchers.length ? (
            restaurantVouchers.map((value, index) => (
              <div key={`${index}-${new Date().getTime()}`}>
                <RestaurantVouchersDetails
                  selectTab = {selectTab}
                  {...value}
                ></RestaurantVouchersDetails>
              </div>
            ))
          ) : (
            <div className="h-[300px] w-full">
              <EmptyPage title={capitalizedFirst(t("global.purchased-voucher-not-found"))} />
            </div>
          )}
        </>
      )}
      {restaurantVouchers?.length < totalRestaurant &&
        restaurantVouchers?.length > 0 && (
        <button
          type="button"
          onClick={fetchNextPage}
          className="mb-2 mt-[10px] text-12 font-semibold text-blue-600"
        >
          <div>
            {isLoadMore ? (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "1rem" }}
              ></i>
            ) : (
              capitalizedFirst(t("global.view-more"))
            )}
          </div>
        </button>
      )}
    </div>
  )
}
