import React from "react"
import headerImage from "src/assets/images/popular_near_you_banner.png"
import SearchViewCustom from "./search-view-custom"

export default function SearchHeader() {
  return (
    <div className="w-full">
      <div className="relative h-[224px] w-full">
        <img src={headerImage} alt="banner" className="h-full w-full" />
        <div className="absolute left-1/2 top-[60%] md:top-[80%] z-10 flex w-full max-w-[1200px] -translate-x-1/2 -translate-y-1/2 transform items-center justify-center">
          <SearchViewCustom />
        </div>
      </div>
    </div>
  )
}
