/* eslint-disable indent */
import React, { useEffect, useState } from "react"
import { Galleria } from "primereact/galleria"
import {
  DataProperties,
  ImageSlideshowProperties,
  SlideShowDataProperties,
} from "./types"
import { ImgCustom, SkeletonLoading } from "../common"
import { FOOD_CARD_TYPE, LAYOUT_TYPE, SERVICE } from "src/constants/common"
import * as foodService from "@soctrip/axios-food-service"
import { config } from "src/config/interceptors"
import { Swiper, SwiperSlide } from "swiper/react"
import { useNavigate } from "react-router-dom"
import { Autoplay, Pagination } from "swiper/modules"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import FoodCardView from "../food-home-view/food-card-view"
import { RecommendViewType } from "../food-home-view/types"

interface ItemViewProps {
  title: string
  imgUrl: string
  id: string
}

const ItemView = (props: ItemViewProps) => {
  const { id } = props
  const navigate = useNavigate()
  const handleClickView = () => {
    navigate("/food/search", { state: { food_type: id } })
  }
  return (
    <div
      onClick={handleClickView}
      className="flex flex-1 cursor-pointer flex-col items-center p-1"
    >
      <div className="h-[370px] w-full">
        <ImgCustom
          url={id}
          alt="imgFoodItemView"
          className="w-full rounded-3"
        />
      </div>
    </div>
  )
}

export default function CategoryView(data: DataProperties) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [bloomData, setBloomData] = useState<string>("")
  const [slideshowData, setSlideshowData] =
    useState<SlideShowDataProperties[]>()
  const [foodListData, setFoodListData] = useState<RecommendViewType[]>()
  const [limit, setLimit] = useState<number>(20)
  const [totalProducts, setTotalProducts] = useState<number>(0)
  // const showToast = useToast()
  const handleViewMore = () => {
    setLimit(limit + 20)
  }

  useEffect(() => {
    if (data?.code === LAYOUT_TYPE.SLIDESHOW) {
      const slideshowData: { itemImageSrc: string; alt: string }[] = []
      data?.dataContent?.forEach((element: ImageSlideshowProperties) => {
        slideshowData.push({
          itemImageSrc: `${process.env.REACT_APP_API_URL}/storage/files/web/${element?.id}`,
          alt: "slideshow",
        })
      })
      setSlideshowData(slideshowData)
    }

    // food list
    if (data?.code === LAYOUT_TYPE.FOOD_LIST) {
      if (data?.fullData?.is_food_list) {
        setLoading(true)
        new foodService.FoodControllerApi(config(SERVICE.FOOD))
          .foodsListGet(data?.fullData?.food_ids as string[])
          .then((response) => {
            setTotalProducts(response?.data?.data?.length)
            if (response?.data?.data?.length) {
              const result: RecommendViewType[] = []
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              response.data.data?.forEach((i: any) => {
                result.push({
                  id: i?.id,
                  title: i?.name,
                  imgUrl: i?.avatar?.id,
                  point: i?.point,
                  countReview: i?.sum_reviews,
                  price: i?.price,
                  originPrice: i?.original_price,
                  restaurant_food: i?.restaurant_food,
                  address: i?.restaurant_food?.name,
                })
              })
              setFoodListData(result)
            }
          })
          .catch((error) => error)
        setLoading(false)
      }
    }
  }, [data?.fullData, data?.dataContent])

  useEffect(() => {
    if (data?.bloom_filter) {
      setBloomData(data?.bloom_filter)
    }
  }, [data])

  return (
    <div className="h-full w-full">
      <div className="flex items-center">
        {data?.code === LAYOUT_TYPE.IMAGE_WITH_TEXT && data?.dataContent && (
          <div className=" mb-3 flex min-h-[200px] w-full flex-col items-center justify-center pb-1">
            <ImgCustom
              url={data?.dataContent[0]?.id}
              alt={`${data?.dataContent[0]?.content} image`}
              className="h-[200px] w-full rounded-4 object-cover object-center md:h-[350px]"
            />
            {data?.dataContent[0]?.content && (
              <div className="flex w-full items-center justify-center bg-opacity-25 pt-1 text-16 font-medium italic text-gray-600">
                {data?.dataContent[0]?.content}
              </div>
            )}
          </div>
        )}
        {data?.code === LAYOUT_TYPE.BANNER && data?.dataContent && (
          <div className="mb-4 flex min-h-[200px] w-full items-center justify-center rounded-4 bg-blue-light-50">
            <ImgCustom
              url={data?.dataContent[0]?.id}
              alt="banner image"
              className="h-[200px] w-full rounded-4 object-cover object-center md:h-[350px]"
            />
          </div>
        )}
        {/* single slideshow */}
        {data?.code === LAYOUT_TYPE.SLIDESHOW && (
          <div className="mb-4 max-h-[400px] min-h-[200px] w-full rounded-4 bg-blue-light-50">
            <Galleria
              value={slideshowData}
              showIndicators
              showIndicatorsOnItem
              showThumbnails={false}
              autoPlay
              circular
              transitionInterval={2500}
              item={(item) => {
                return (
                  <img
                    className="h-[200px] w-full rounded-4 object-cover object-center md:h-[350px]"
                    src={item?.itemImageSrc}
                    alt={item?.alt}
                  />
                )
              }}
            />
          </div>
        )}

        {/* multiple slideshow */}
        {data?.code === LAYOUT_TYPE.MULTIPLE_SLIDESHOW && (
          <div className="mb-4 flex max-h-[400px] min-h-[350px] w-full items-center rounded-4 bg-blue-light-25 px-1 py-3 shadow-sm">
            <div className="h-full w-full">
              {/* <CarouselCustom images={data?.dataContent} /> */}
              <div className="w-full">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={2}
                  autoplay
                  // navigation={true}
                  modules={[Autoplay, Pagination]}
                  pagination={true}
                >
                  {data?.dataContent?.map((i: ItemViewProps, index: number) => (
                    <SwiperSlide key={index} style={{ width: "unset" }}>
                      <ItemView {...i} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        )}

        {data?.code === LAYOUT_TYPE.TITLE && data?.dataContent && (
          <div
            className="mb-2 h-full w-full text-center text-20"
            dangerouslySetInnerHTML={{ __html: data?.dataContent[0]?.content }}
          ></div>
        )}

        {data?.code === LAYOUT_TYPE.PARAGRAPH && data?.dataContent && (
          <div
            className="mb-3 h-full w-full text-14 font-medium"
            dangerouslySetInnerHTML={{ __html: data?.dataContent[0]?.content }}
          ></div>
        )}

        {data?.code === LAYOUT_TYPE.FOOD_LIST &&
          data?.fullData?.is_food_list && (
            <div
              className="mb-4 flex h-full w-full flex-col gap-2 rounded-3 bg-white p-2"
              style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
            >
              <div className="flex w-full items-center">
                {data?.fullData?.restaurant_decoration_collection?.name !==
                  "" && (
                  <div className="w-full text-18 font-semibold text-gray-700">
                    {data?.fullData?.restaurant_decoration_collection?.name}
                  </div>
                )}
              </div>
              {loading ? (
                <div className="h-full w-full">
                  <SkeletonLoading limit={4} rows={3} />
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
                  {foodListData
                    ?.slice(0, limit)
                    ?.map((pro: RecommendViewType) => (
                      <div key={pro?.id}>
                        <FoodCardView
                          isShowPrice={true}
                          isAddressView={true}
                          isFoodType={true}
                          bloom_filter={bloomData}
                          type={FOOD_CARD_TYPE.FOOD}
                          handleReload={data?.handleReload}
                          {...pro}
                        />
                      </div>
                    ))}
                </div>
              )}

              {totalProducts > limit && (
                <div>
                  <p
                    onClick={handleViewMore}
                    className="cursor-pointer text-14 font-medium text-blue-700"
                  >
                    {capitalizedFirst(t("global.view-more"))}
                  </p>
                </div>
              )}
            </div>
          )}
      </div>
    </div>
  )
}
