import React, { useEffect, useRef, useState } from "react"
import { SkeletonLoading } from "../common"
import { useParams } from "react-router-dom"
import { RestaurantControllerApi } from "@soctrip/axios-food-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { AllFoodViewProps, CatalogFoodProps } from "./types"
import FoodView from "./foods-view"
import useOnScreen from "src/hooks/use-on-screen"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function AllFoodView(props: AllFoodViewProps) {
  const { id } = useParams()
  const loadingRef = useRef(null)
  const isOnScreen = useOnScreen(loadingRef)
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [catalogData, setCatalogData] = useState<CatalogFoodProps[]>([])
  const [totalElement, setTotalElement] = useState<number>(0)
  const [handleShowMoreLoading, setHandleShowMoreLoading] =
    useState<boolean>(false)

  const handleGetAllFoods = async () => {
    await new RestaurantControllerApi(config(SERVICE.FOOD))
      .restaurantsCatalogsGet(id, pageIndex, 10)
      .then((response) => {
        setTotalElement(response?.data?.data?.totalElement)
        
        // load init
        if(pageIndex === 0 && !catalogData.length) {
          setCatalogData(response?.data?.data?.data)
        } else {
          // load more
          if(catalogData.length) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            setCatalogData([...catalogData, ...response?.data?.data?.data])
          }
        }
        setLoading(false)
        setHandleShowMoreLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (id) {
      handleGetAllFoods()
      setIsFirstLoad(false)
    }
  }, [id])

  const handleLoadMore = () => {
    setHandleShowMoreLoading(true)
    setPageIndex(pageIndex + 1)
  }

  useEffect(() => {
    if (isOnScreen && !isFirstLoad && catalogData?.length < totalElement) {
      handleLoadMore()
    }
  }, [isOnScreen])

  useEffect(() => {
    if (pageIndex && !isFirstLoad) handleGetAllFoods()
  }, [pageIndex])

  return (
    <div className="flex w-full flex-col gap-3">
      {loading ? (
        <div>
          <SkeletonLoading limit={5} rows={3} />
        </div>
      ) : (
        <div className="w-full">
          {catalogData?.length ? (
            <div className="flex w-full flex-col">
              {catalogData?.map((data, index) => (
                <div key={index} className="flex w-full flex-col gap-1">
                  {data?.foods?.length ? (
                    <div className="mt-4 flex flex-col gap-1">
                      <div className="text-18 font-semibold text-gray-700">
                        {capitalizedFirst(t("global.main-dish"))}
                      </div>
                      <div className="w-full">
                        <FoodView
                          data={data}
                          bloom_filter={props?.bloom_filter}
                          handleReload={props?.handleReload}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
      {pageIndex < 2 ? (
        <div>
          <div ref={loadingRef}></div>
        </div>
      ) : (
        <>
          {!handleShowMoreLoading && (
            <div className="flex items-center justify-center">
              {catalogData?.length < totalElement ? (
                <div
                  onClick={handleLoadMore}
                  className="cursor-pointer text-14 font-medium text-blue-700"
                >
                  {capitalizedFirst(t("global.view-more"))}
                </div>
              ) : null}
            </div>
          )}
        </>
      )}
      {handleShowMoreLoading && (
        <div className="flex justify-center py-4">
          <i
            className="pi pi-spin pi-spinner text-blue-500"
            style={{ fontSize: "1.5rem" }}
          ></i>
        </div>
      )}
    </div>
  )
}
