/* eslint-disable @typescript-eslint/no-explicit-any */
import { BloomFilter } from "soctrip-algorithm/dist/api.js"

export const handleConvertPrice = (price: number | bigint | undefined) => {
  const config = {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 3,
  }
  if (price) {
    const formatted = new Intl.NumberFormat("en-US", config).format(price)
    return formatted
  } else {
    const formatted = new Intl.NumberFormat("en-US", config).format(0)
    return formatted
  }
}

export const handlePromiseAll = (promises: any[]) => {
  const wrappedPromises = promises.map((p) =>
    Promise.resolve(p)
      .then(
        (val) => ({ status: "fulfilled", data: val }),
        (err) => ({ status: "rejected", reason: err })
      )
      .catch((err) => err)
  )
  return Promise.all(wrappedPromises)
}

export const handlePromiseAllNoRejected = (promises: any[]) => {
  const wrappedPromises = promises.map((p) =>
    Promise.resolve(p)
      .then((val) => ({ status: "fulfilled", data: val }))
      .catch((err) => err)
  )
  return Promise.all(wrappedPromises)
}

export const handleCheckBloomFilter = (
  bloomData: string,
  itemCheck: string
) => {
  const bloomFilter = BloomFilter.load(bloomData)
  const exist = bloomFilter.has(itemCheck)
  if (exist) {
    return true
  } else {
    return false
  }
}

export const handleCheckTime = (startTime: string, endTime: string) => {
  const currentTime = new Date()
  const currentTimeStamp = Math.floor(currentTime.getTime())
  const start = new Date(startTime)
  const startTimeStamp = Math.floor(start.getTime())
  const end = new Date(endTime)
  const endTimeStamp = Math.floor(end.getTime())
  if (currentTimeStamp >= startTimeStamp && currentTimeStamp <= endTimeStamp) {
    return true
  } else {
    return false
  }
}
