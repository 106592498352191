export const ORDER_STATE = {
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  WAIT_FOR_SHIP: "WAIT_FOR_SHIP",
  DELIVER_HANDEL: "DELIVER_HANDEL",
  DELIVER_ON_WAY: "DELIVER_ON_WAY",
  DELIVERED: "DELIVERED",
  COMPLETED: "COMPLETED",
  CANCELLED_BY_YOU: "CANCELLED_BY_YOU",
  CANCELLED_BY_SHOP: "CANCELLED_BY_SHOP",
  CANCELLED_BY_SYSTEM: "CANCELLED_BY_SYSTEM",
  REFUND: "REFUND",
  CONFIRMED: "CONFIRMED",
  UNPAID: "UNPAID",
}

export const PURCHASE_HISTORY_NAVIGATE_CODE = {
  PURCHASE: "PURCHASE",
  ADDRESS: "ADDRESS",
  VOUCHER: "VOUCHER",
  SETTING: "SETTING",
  SECURITY: "SECURITY",
  HELP: "HELP",
  BOOKMARK: "BOOKMARK",
}

export const OrderStatusData = [
  {
    label: "To ship",
    code: [
      "WAIT_FOR_SHIP",
      "SUCCESS",
      "CONFIRMED",
      "CANCELLED_BY_YOU",
      "CANCELLED_BY_SHOP",
      "CANCELLED_BY_SYSTEM",
    ],
  },
  {
    label: "To deliver",
    code: ["DELIVER_HANDEL", "DELIVER_ON_WAY"],
  },
  {
    label: "Delivered",
    code: ["DELIVERED", "REFUND"],
  },
  {
    label: "Completed",
    code: ["COMPLETED"],
  },
]
