import http from "src/config/interceptors"

export const getAddressNearYou = (provinceIds?: string) => {
  return http.get(`/restaurant/restaurants/${provinceIds}/locations`)
}

export const getRestaurantType = (page: number, limit: number, language: string) => {
  return http.get(
    `/restaurant/restaurant-types?pageNum=${page}&pageSize=${limit}&language=${language}`
  )
}

export const getFoodType = (page: number, limit: number, language:string) => {
  return http.get(
    `/catalog/global-food-category?pageNum=${page}&pageSize=${limit}&language=${language}`
  )
}

export const getCountryDetails = () => {
  return http.get("/shipment/address/countries/detail")
}

export const getRestaurantPage = (
  page: number,
  limit: number,
  filterString?: string
) => {
  return http.get(
    `/restaurant/restaurants/search?pageNum=${page}&pageSize=${limit}&filters=${filterString}`
  )
}
