import React, { useEffect, useMemo, useState } from "react"
import { ReservationDto } from "./types"
import { ImgCustom } from "src/components/common"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { convertTimeCommon } from "src/utils/time-utils"
import { RESERVATION_CODE } from "src/constants/common"
import CancelReservationModal from "../cancel-reservation-modal"
import { useNavigate } from "react-router-dom"
import RatingRestaurantModal from "../common/modal/rating-modal"
import { RestaurantDataType } from "../common/modal/rating-modal/types"

export default function RestaurantReservationCard({
  data,
  refetch,
}: ReservationDto) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isShowModalCancel, setIsShowModalCancel] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [time, setTime] = useState<any>()
  const [isShowRatingModal, setIsShowRatingModal] = useState<boolean>(false)

  const handleCancel = () => {
    setIsShowModalCancel(true)
  }

  const handleRating = () => {
    setIsShowRatingModal(true)
  }

  const handleGoToDetails = () => {
    navigate(`/food/reservation/${data?.id}`)
  }

  const handleGoToRestaurant = () => {
    navigate(`/food/restaurant/${data?.restaurant_id}`)
  }

  useEffect(() => {
    if (data) {
      setTime(new Date(data?.reservation_time))
    }
  }, [data])
  const restaurantDataRating = useMemo(() => {
    if (data) {
      return {
        name: data?.restaurant?.name || "",
        url: (data?.restaurant?.avatar?.id as string) || "",
        address: data?.restaurant?.address || "",
        guests: data?.number_guests || 0,
        time: data?.reservation_time + "" || "",
      } as RestaurantDataType
    }
    return undefined
  }, [data])

  const handleConfirm = () => {
    setIsShowRatingModal(false)
    setTimeout(() => {
      refetch()
    }, 300)
  }

  return (
    <div className="flex h-fit w-full flex-col rounded-3 bg-white">
      <div className="flex items-start gap-3 border-b border-gray-200 p-3">
        {/* avatar */}
        <div className="md:h-[60px] md:w-[60px] h-[32px] w-[32px]">
          <ImgCustom
            url={data?.restaurant?.avatar?.id as string}
            alt={""}
            className="rounded-full object-cover object-center"
          />
        </div>

        <div className="flex flex-col gap-4px flex-1">
          {/* name */}
          <div
            onClick={handleGoToRestaurant}
            className="cursor-pointer text-16 font-semibold leading-6 text-gray-700 hover:text-blue-700"
          >
            {data?.restaurant?.name}
          </div>
          {/* address */}
          {data?.restaurant?.address ? (
            <div className="flex items-start md:items-center gap-4px">
              <div className="text-14 font-medium leading-5 text-gray-500">
                {`${capitalizedFirst(t("reservation.address"))}:`}
              </div>
              <div className="text-14 font-medium text-gray-700 line-clamp-1">
                {data?.restaurant?.address}
              </div>
            </div>
          ) : null}
          {/* reservation time */}
          <div className="flex items-center gap-4px">
            <div className="text-14 font-medium leading-5 text-gray-500">
              {`${capitalizedFirst(t("reservation.reservation-time"))}:`}
            </div>
            <div className="text-14 font-medium text-gray-700">
              {convertTimeCommon(time, true)}
            </div>
          </div>
          {/* number of guest */}
          <div className="flex items-center gap-4px">
            <div className="text-14 font-medium leading-5 text-gray-500">
              {`${capitalizedFirst(t("reservation.number-of-guest"))}:`}
            </div>
            <div className="text-14 font-medium text-gray-700">
              {data?.number_guests}
            </div>
          </div>
        </div>
      </div>
      {/* footers */}
      <div className="flex w-full flex-col justify-between gap-3 p-3 sm:flex-row">
        <div className="flex items-center gap-4">
          <div
            className="truncate rounded-3 px-4px py-2px text-12 font-medium"
            style={{
              backgroundColor: data?.status?.bg_color,
              color: data?.status?.color,
            }}
          >
            {capitalizedFirst(
              t(`purchase-history.order-status-tabs.${data?.status?.name}`)
            )}
          </div>
          {data?.cancel ? (
            <div className="text-14 font-normal leading-5 text-gray-500">{`${capitalizedFirst(
              t("reservation.cancelled-reason")
            )}: ${data?.cancel?.reason_type?.name}`}</div>
          ) : null}
        </div>
        <div className="flex items-center gap-3 justify-end">
          {/*Go to details */}
          <div>
            <button
              type="button"
              onClick={handleGoToDetails}
              className="rounded-3 border border-gray-300 px-3 py-1 text-14 font-semibold leading-5"
            >
              {capitalizedFirst(t("reservation.detail"))}
            </button>
          </div>
          {/* Rating */}
          {data?.status?.code === RESERVATION_CODE.CHECK_IN && (
            <button
              type="button"
              onClick={handleRating}
              className="truncate rounded-3 border border-gray-300 px-3 py-1 text-14 font-semibold leading-5"
            >
              {!data.total_rating
                ? capitalizedFirst(t("reservation.review-restaurant"))
                : capitalizedFirst(t("reservation.view-rating"))}
            </button>
          )}
          {/* Cancel */}
          {data?.status?.code === RESERVATION_CODE.PENDING && (
            <button
              type="button"
              onClick={handleCancel}
              className="truncate rounded-3 border border-gray-300 px-3 py-1 text-14 font-semibold leading-5"
            >
              {capitalizedFirst(t("reservation.cancel-reservation"))}
            </button>
          )}
        </div>
      </div>

      <CancelReservationModal
        isShowModalCancel={isShowModalCancel}
        id={data?.id as string}
        closeModal={() => setIsShowModalCancel(false)}
        refetch={refetch}
      />
      {isShowRatingModal &&
      data?.id &&
      data?.restaurant_id &&
      restaurantDataRating ? (
          <RatingRestaurantModal
            orderId={data.id}
            restaurantData={restaurantDataRating}
            restaurantId={data.restaurant_id}
            isShow={isShowRatingModal}
            // isRated={false}
            isRated={!!data?.total_rating}
            handleClose={() => setIsShowRatingModal(false)}
            handleConfirm={handleConfirm}
            code={data?.code || ""}
          />
        ) : null}
    </div>
  )
}
