import { useEffect, useRef, KeyboardEvent } from "react"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import "./index.scss"
import { restaurantService } from "src/services"
import districtLocationIcon from "../../assets/images/district-location-icon.svg"
import { DistrictDropdownType, SearchViewProps } from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { updateSearchLocationNearYou } from "src/features/near-you-location"
import { useDebounce } from "use-debounce"
import { useClickOutside } from "src/hooks/use-click-outside"
import { getRestaurantPreflightSearch } from "src/services/restaurant-service"
import { getKeywords } from "src/utils/get-keyword-suggestion"
import { RadiusType } from "../search-home-view/types"
import { radius } from "src/constants/common"
import { updateRadius } from "src/features/search-location"
import i18n from "src/locales"

const SearchView = (props: SearchViewProps) => {
  const dispatch = useDispatch()
  const { districtSelected, changeSelected } = props
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState<string>("")
  const [isGetNearYou, setIsGetNearYou] = useState<boolean>(true)
  // const [districtSelected, setDistrictSelected] = useState<DistrictDropdownType | undefined>(undefined)
  const [districtData, setDistrictData] = useState<DistrictDropdownType[]>([])
  // Suggestions
  const searchRef = useRef(null)
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [isSuggestionModal, setIsSuggestionModal] = useState(false)
  const [searchDebounce] = useDebounce(searchValue, 700)
  const [selectedSugIdx, setSelectedSugIdx] = useState(-1)
  const [isSearching, setIsSearching] = useState(false)
  const [selectedRadius, setSelectedRadius] = useState<RadiusType | null>({
    name: "7km +",
    code: 20,
  })
  useClickOutside(searchRef, () => setIsSuggestionModal(false))
  useEffect(() => {
    async function fetchSuggestions() {
      setIsSearching(true)
      try {
        const res = await getRestaurantPreflightSearch(searchDebounce)
        const data: string[] = res?.data?.data?.data
        if (data) {
          const keywords = getKeywords(data, searchDebounce)
          setSuggestions(keywords)
        }
        setIsSearching(false)
      } catch (error) {
        setIsSearching(false)
      }
    }
    if (searchDebounce) {
      fetchSuggestions()
    } else {
      setSuggestions([])
    }
  }, [searchDebounce])

  useEffect(() => {
    function fetchInitView() {
      restaurantService
        .getRestaurantAddressDropdown(i18n.language)
        .then((res) => {
          if (res?.data?.data?.provinces?.length) {
            const result: DistrictDropdownType[] = []
            res?.data?.data?.provinces.forEach((i: DistrictDropdownType, index: number) => {
              result.push({
                province_id: i?.province_id,
                country_id: i?.country_id,
                country_name: i?.country_name,
                province_name: i?.province_name,
                code: i?.code,
                longitude: i?.longitude,
                latitude: i?.latitude,
                index: index,
              })
            })
            setDistrictData(result)
          }
        })
        .catch((err) => err)
    }
    fetchInitView()
  }, [i18n.language])

  const handleSearch = (suggestion?: string) => {
    const path =
      searchValue || suggestion ? `/food/search?keyword=${suggestion || searchValue}` : "/food/search"
    if (districtSelected) {
      navigate(path, {
        state: { provinceSelected: districtSelected },
      })
    } else {
      navigate(path)
    }
  }

  const handleChangeDistrict = (newDistrict: DistrictDropdownType) => {
    changeSelected(newDistrict)    
    if (isGetNearYou && selectedRadius?.code) {
      dispatch(updateRadius(selectedRadius.code))
    }
    setIsGetNearYou(false)
  }

  const handleEnterKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isSearching) return
    if (e.key === "ArrowDown") {
      const index = selectedSugIdx == suggestions.length - 1 ? 0 : selectedSugIdx + 1
      setSelectedSugIdx(index)
    } else if (e.key === "ArrowUp") {
      const index = selectedSugIdx == 0 ? suggestions.length - 1 : selectedSugIdx - 1
      setSelectedSugIdx(index)
    } else if (e.key === "Enter") {
      if (selectedSugIdx !== -1) {
        handleSearch(suggestions[selectedSugIdx])
      } else {
        handleSearch()
      }
    }
  }

  const countryOptionTemplate = (option: DistrictDropdownType) => {
    return (
      <div className="flex w-full items-center">
        <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-blue-100">
          <img alt="districtLocationIcon" src={districtLocationIcon} />
        </div>
        <div className="ml-2 flex flex-col text-14 leading-20">
          <span className="font-medium text-gray-900">{option.province_name}</span>
          <span className="text-gray-600">{`${option.province_name}, ${option.country_name}`}</span>
        </div>
      </div>
    )
  }

  const { t } = useTranslation()
  const handleTranslate = (text: string) => {
    return t(text)
  }

  const SelectedLocationTemplate = (option: DistrictDropdownType) => {
    // eslint-disable-next-line react/prop-types

    if (option && !isGetNearYou) {
      return (
        <div className="flex items-center gap-2">
          <img src={districtLocationIcon} alt="location-icon" />
          <div className="text-14 font-medium leading-5 text-gray-900">{option?.province_name}</div>
        </div>
      )
    }

    if (isGetNearYou) {
      return (
        <div className="flex items-center gap-2">
          <img src={districtLocationIcon} alt="location-icon" />
          <div className="text-14 font-medium leading-5 text-gray-900">
            {capitalizedFirst(handleTranslate("search-filter-page.restaurants-near-you"))}
          </div>
        </div>
      )
    }

    return <span>{capitalizedFirst(t("food-home-page.select-city"))}</span>
  }

  // handle get restaurant near you
  const handleGetNearYou = () => {
    const getRestaurant = () => {
      const geolocationAPI = navigator.geolocation
      if (geolocationAPI) {
        geolocationAPI.getCurrentPosition(async (response) => {
          const { coords } = response
          const clientLocation = {
            latitude: coords?.latitude || 0,
            longitude: coords?.longitude || 0,
            radius: Number(selectedRadius?.code),
          }
          dispatch(updateSearchLocationNearYou(clientLocation))
        })
      }
    }
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((response) => {
          if (response.state === "granted") {
            getRestaurant()
          } else if (response.state === "prompt") {
            getRestaurant()
          } else {
            console.log("Access denied")
          }
        })
        .catch((err) => err)
    }
  }

  useEffect(() => {
    if (isGetNearYou) {
      handleGetNearYou()
    }
  }, [selectedRadius, isGetNearYou])

  const FilterTemplate = () => {
    const { t } = useTranslation()
    const handleTranslate = (text: string) => {
      return t(text)
    }

    return (
      <div className="flex flex-col">
        <div className="border-b border-gray-200 p-2 text-18 font-semibold leading-7 text-gray-700">
          {capitalizedFirst(handleTranslate("search-filter-page.some-famous-locations"))}
        </div>
        <div className="flex items-center">
          <div
            onClick={() => setIsGetNearYou(true)}
            className="flex w-full cursor-pointer items-center gap-3 stroke-gray-700 px-3 py-2 text-gray-900 hover:stroke-blue-700 hover:text-blue-700"
          >
            {/* <img src={nearIcon} alt="near-icon hover:bg-blue-700" /> */}
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M16.6667 10.0001C16.6667 13.682 13.6819 16.6667 10 16.6667M16.6667 10.0001C16.6667 6.31818 13.6819 3.33341 10 3.33341M16.6667 10.0001H18.3334M10 16.6667C6.31812 16.6667 3.33335 13.682 3.33335 10.0001M10 16.6667V18.3334M3.33335 10.0001C3.33335 6.31818 6.31812 3.33341 10 3.33341M3.33335 10.0001H1.66669M10 3.33341V1.66675M12.5 10.0001C12.5 11.3808 11.3807 12.5001 10 12.5001C8.61931 12.5001 7.50002 11.3808 7.50002 10.0001C7.50002 8.61937 8.61931 7.50008 10 7.50008C11.3807 7.50008 12.5 8.61937 12.5 10.0001Z" />
              </g>
              <defs>
                <clipPath id="clip0_4824_97590">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <p className="text-14 font-medium leading-5">
              {capitalizedFirst(handleTranslate("search-filter-page.restaurants-near-you"))}
            </p>
          </div>
        </div>
      </div>
    )
  }

  const highlightMatchedText = (text: string, keyword: string) => {
    const startIndex = text.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase())
    if (startIndex === -1) {
      return text
    }

    const endIndex = startIndex + keyword.length
    const prefix = text.slice(0, startIndex)
    const match = text.slice(startIndex, endIndex)
    const suffix = text.slice(endIndex)

    return (
      <>
        {prefix}
        <strong className="font-semibold text-black">{match}</strong>
        {suffix}
      </>
    )
  }

  const handleChangeRadius = (option: RadiusType) => {
    setSelectedRadius(option)
    if (!isGetNearYou) {
      dispatch(updateRadius(option.code))
    }
  }

  const RadiusOptionTemplate = (option: RadiusType) => {
    return (
      <div
        onClick={() => handleChangeRadius(option)}
        className="flex cursor-pointer items-center justify-between gap-1"
      >
        <p className="text-14 font-medium text-gray-700">{capitalizedFirst(t(option.name))}</p>
        {option?.code === selectedRadius?.code && (
          <span className="pi pi-check text-blue-600"></span>
        )}
      </div>
    )
  }

  return (
    <div
      ref={searchRef}
      className="relative flex w-full flex-col items-center gap-1 rounded-3 border border-gray-300 p-4px md:h-[48px] md:flex-row md:bg-white"
      id="searchVie"
    >
      <div className="flex w-full flex-col items-center justify-center gap-4px md:flex-row">
        <div className="p-input-icon-left w-full rounded-3 border border-gray-300 bg-white md:border-none">
          <i className="pi pi-search text-blue-600" />
          <InputText
            onClick={() => setIsSuggestionModal(true)}
            value={searchValue}
            placeholder={capitalizedFirst(t("food-home-page.search-restaurant"))}
            className="h-[40px] w-full max-w-[400px] border-0 px-20px py-14px shadow-none"
            onChange={(e) => {
              setSearchValue(e?.target?.value)
              setIsSuggestionModal(true)
            }}
            onKeyDown={handleEnterKeyDown}
          />
        </div>
        <div className="hidden h-[28px] w-[1px] bg-gray-300 md:flex"></div>
        <div className="select-city relative flex h-full w-full items-center justify-between gap-1 rounded-3 pr-0 md:pr-0">
          <div className="relative w-full">
            <Dropdown
              itemTemplate={countryOptionTemplate}
              valueTemplate={SelectedLocationTemplate}
              filter
              filterTemplate={FilterTemplate}
              onChange={(e) => handleChangeDistrict(e.value)}
              optionLabel="label"
              value={districtSelected}
              options={districtData}
              className="h-[40px] w-full border-0 px-10px shadow-none md:w-[300px]"
              placeholder={capitalizedFirst(t("food-home-page.select-city"))}
            />
            <div id="radius-dropdown" className="absolute right-[2%] top-6px flex items-center">
              <Dropdown
                className="flex h-30px items-center rounded-5 text-center text-12 placeholder:text-12"
                placeholder="Select radius"
                optionLabel="name"
                itemTemplate={RadiusOptionTemplate}
                options={radius}
                value={selectedRadius}
              />
            </div>
          </div>
          <button
            onClick={() => handleSearch()}
            className="min-w-[80px] shrink-0 rounded-3 bg-blue-600 px-1 py-10px text-center text-14 font-semibold leading-20 text-white hover:bg-blue-500"
          >
            {capitalizedFirst(t("global.search"))}
          </button>
        </div>
      </div>
      <div className="absolute left-0 right-0 top-[52px] z-[1] rounded-3 bg-white shadow">
        {suggestions.length > 0 && isSuggestionModal && (
          <ul className="py-3">
            {suggestions.map((suggestion, index) => {
              return (
                <li
                  key={index}
                  onClick={() => handleSearch(suggestion)}
                  className={`max-w-full cursor-pointer truncate px-3 py-[4px] text-14 text-gray-700 hover:bg-gray-200 ${
                    selectedSugIdx === index ? "bg-gray-100" : ""
                  }`}
                >
                  {highlightMatchedText(suggestion, searchValue)}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </div>
  )
}

export default React.memo(SearchView)
