import { AxiosResponse } from "axios"
import http from "src/config/interceptors"
import { PaginationResponseType, ResponseType } from "src/types/common"
import { FoodDetailType, FoodImpactType } from "src/types/food-detail"

interface GetFoodSearchParams {
  pageNum?: number;
  pageSize?: number;
  filters?: string;
  sort?: string
}

export const getFoodDetails = (id: string): Promise<AxiosResponse<ResponseType<FoodDetailType>>> => {
  return http.get(`/food/foods/${id}`)
}

export const getFoodsSearch = (params: GetFoodSearchParams): Promise<AxiosResponse<PaginationResponseType<FoodImpactType[]>>> => {
  const { pageNum = 0, pageSize = 10, sort, filters } = params
  return http.get("/food/foods/search", {
    params: {
      pageNum, pageSize, sort,
      filters,
    },
  })
}

export const getFoodsRecommend = (params: GetFoodSearchParams): Promise<AxiosResponse<PaginationResponseType<FoodImpactType[]>>> => {
  const { pageNum = 0, pageSize = 10, sort, filters } = params
  return http.get("/food-recommend/food_recommend", {
    params: {
      pageNum, pageSize, sort,
      filters,
      recommendSession: "BREAKFAST",
    },
  })
}