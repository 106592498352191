import React, { useEffect, useRef, useState } from "react"
import MerchantBackground from "../restaurant-view/header"
import { config } from "src/config/interceptors"
import { ALL_FOOD_CODE, SERVICE } from "src/constants/common"
import * as restaurantService from "@soctrip/axios-restaurant-service"
import { CategoryTabProperties, RestaurantProperties } from "../restaurant-view/types"
import { useNavigate } from "react-router"
import MerchantProfile from "../merchant-profile"
import RestaurantReviews from "../restaurant-reviews"
import { useLocation } from "react-router-dom"
import { RatingControllerApi } from "@soctrip/axios-rating-service"
import { ReviewType } from "../restaurant-reviews/type"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function MerchantHeader() {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const reviewRef = useRef<HTMLDivElement>(null)
  const refHeader = useRef<HTMLDivElement>(null)
  const [background, setBackground] = useState<string>("")
  const [dataShop, setDataShop] = useState<RestaurantProperties>()
  const [avatar, setAvatar] = useState<string>("")
  const [categorySelected, setCategorySelected] = useState<string>("")
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [reviewData, setReviewData] = useState<ReviewType[]>([])
  const [id, setShopId] = useState<string>()
  const [isShowReviews, setIsShowReviews] = useState<boolean>(true)
  const [shopCategoryTab, setShopCategoryTab] = useState<CategoryTabProperties[]>()
  const [isFirstChange, setIsFirstChange] = useState<boolean>(false)

  useEffect(() => {
    refHeader.current?.scrollIntoView({ behavior: "smooth" })
    const shopId = location.pathname.split("/")[3]
    setShopId(shopId)
    setIsFirstLoad(false)
    if (shopId) {
      new restaurantService.RestaurantControllerApi(config(SERVICE.RESTAURANT))
        .restaurantsIdGet(shopId)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          setDataShop(response?.data?.data)
          setShopCategoryTab(response?.data?.data?.restaurant_categories)
        })
        .catch((error) => error)
    }
  }, [])

  useEffect(() => {
    if (dataShop) {
      if (dataShop?.background?.id) {
        setBackground(dataShop?.background?.id)
      }
      if (dataShop?.avatar?.id) {
        setAvatar(dataShop?.avatar?.id)
      }
      if (location?.hash) {
        setCategorySelected(location?.hash.slice(1))
      } else {
        if(isFirstChange) {
          navigate(-1)
          return
        }
        setIsFirstChange(true)
        if (dataShop?.restaurant_categories.length) {
          setCategorySelected(dataShop?.restaurant_categories[0]?.id)
        } else {
          setCategorySelected(ALL_FOOD_CODE.CODE)
        }
      }
    }
  }, [dataShop, location])

  useEffect(() => {
    if (!isFirstLoad) {
      navigate(`#${categorySelected}`)
    }
  }, [categorySelected])

  const fetchRatingOfRestaurant = async (shopId: string) => {
    try {
      const res = await new RatingControllerApi(config(SERVICE.RATING)).ratingsObjectIdGet(
        shopId as string,
        0,
        3
      )
      const data = res?.data?.data
      if (data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any[] = []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data?.data?.forEach((rating: any) => {
          result.push({
            userName: rating?.created_by?.full_name,
            imgUrl: rating?.created_by?.avatar_thumbnail_url,
            comment: rating?.content,
            commentDate: rating?.updated_at,
            valueRating: rating?.rating as number,
            notes: rating?.notes,
          })
        })
        setReviewData(result)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (id) {
      fetchRatingOfRestaurant(id)
    }
  }, [id])

  const handleChangeTab = (id: string) => {
    setCategorySelected(id)
  }

  const handleSelectAllFood = () => {
    setCategorySelected(ALL_FOOD_CODE.CODE)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const divAnimate: any = ref.current?.getBoundingClientRect().top
    const onScroll = () => {
      let scrollHeight = 0
      if (reviewRef?.current) {
        scrollHeight = window.scrollY - 100
      } else {
        scrollHeight = window.scrollY + 50
      }
      if (divAnimate < scrollHeight) {
        if (ref.current) {
          ref.current.style.position = "fixed"
          ref.current.style.top = "6"
          ref.current.style.left = "0"
          ref.current.style.width = "100%"
        }
      } else {
        if (ref.current) {
          ref.current.style.position = "relative"
          ref.current.style.width = "1200px"
        }
      }
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  const handleShowReview = () => {
    if (!isShowReviews)
      setIsShowReviews(!isShowReviews)
  }

  return (
    <div className="relative flex max-h-[680px] w-full flex-col items-center ">
      <div
        ref={refHeader}
        className={`w-full ${reviewData?.length && isShowReviews ? "h-[670px]" : "h-[450px]"} ${
          !reviewData?.length ? "mb-6" : ""
        }`}
      >
        <div className="rounded-3 border border-gray-100">
          {/* background and info */}
          <div className="relative">
            {/* background image */}
            <MerchantBackground imgUrl={background} />
            <div
              className={`absolute left-1/2 mx-auto my-0 flex w-full -translate-x-1/2 transform flex-col rounded-3 bg-white pb-0 md:max-w-[1200px] ${
                reviewData?.length ? "top-[70%]" : "top-[80%]"
              }`}
            >
              <div className="box-border w-full">
                {/* shop info */}
                <MerchantProfile
                  id={dataShop?.id}
                  avatar={avatar}
                  rating={dataShop?.rating}
                  name={dataShop?.name}
                  reviews={dataShop?.sum_reviews}
                  food_types={dataShop?.food_types}
                  background={background}
                  province={dataShop?.province?.name}
                  street={dataShop?.street}
                  ward={dataShop?.ward?.name}
                  district={dataShop?.district?.name}
                  handleShowReview={handleShowReview}
                />
                {isShowReviews ? (
                  <div ref={reviewRef} className="w-full">
                    <RestaurantReviews id={dataShop?.id as string} data={reviewData} />
                  </div>
                ) : null}

                {/* category navigation bar */}
                <div className="mx-auto flex w-full grid-cols-10 rounded-b-3 xl:grid">
                  <div className="col-span-12 flex w-full justify-center lg:items-end lg:px-4 xl:items-end xl:px-4">
                    <div className="flex w-full items-center justify-evenly sm:gap-2 md:gap-5 lg:justify-center lg:gap-5 xl:gap-5">
                      {shopCategoryTab?.map((cate: CategoryTabProperties) => (
                        <p
                          key={cate?.id}
                          className={`cursor-pointer truncate py-1 text-12 font-semibold leading-6 md:text-16 ${
                            categorySelected === cate.id
                              ? "border-b-2 border-blue-600 text-blue-600"
                              : "text-gray-500"
                          }`}
                          onClick={() => handleChangeTab(cate.id)}
                        >
                          {cate.name}
                        </p>
                      ))}
                      <div className="">
                        <p
                          className={`cursor-pointer truncate  py-1 text-12 font-semibold md:text-16 ${
                            categorySelected === ALL_FOOD_CODE.CODE
                              ? "border-b-2 border-blue-700 text-blue-700"
                              : "text-gray-500"
                          }`}
                          onClick={handleSelectAllFood}
                        >
                          {capitalizedFirst(t("global.all-foods"))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
