import React, { useState } from "react"
import HeaderView from "src/components/header-view"
import { InputForm, CompleteForm } from "src/components/request-open-view"
import { DataCompleteType } from "src/components/request-open-view/types"
import { TYPE_PAGE } from "src/constants/common"

const STATE_FORM = {
  INPUT: "INPUT",
  COMPLETE: "COMPLETE",
}

export default function RequestOpen() {
  const [stateForm, setStateForm] = useState<string>(STATE_FORM.INPUT)
  const [dataComplete, setDataComplete] = useState<
    DataCompleteType | undefined
  >(undefined)
  const handleUpdateDataComplete = (data: DataCompleteType) => {
    setDataComplete(data)
    setStateForm(STATE_FORM.COMPLETE)
  }
  return (
    <div id="requestOpenPage" className="flex min-h-full w-full flex-col">
      <div className="h-[200px] bg-[linear-gradient(98.52deg,#FEAD34_4.54%,#FF7041_87.05%)]">
        <HeaderView />
      </div>
      <div className="flex flex-1 justify-center bg-gray-50">
        <div className="mt-[-120px] max-w-[1200px]">
          {stateForm === STATE_FORM.INPUT ? (
            <InputForm
              updateDateComplete={(data: DataCompleteType) =>
                handleUpdateDataComplete(data)
              }
              typePage={TYPE_PAGE.FOOD}
            />
          ) : (
            <CompleteForm
              dataComplete={dataComplete}
              typePage={TYPE_PAGE.FOOD}
            />
          )}
        </div>
      </div>
    </div>
  )
}
