/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react"
import { BuyVoucherModalType, VoucherUserInfo } from "./types"
import { Dialog } from "primereact/dialog"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import "./index.scss"
import BuyerInfoForm from "./buyer-form"
import VoucherInfo from "./voucher-info"
import InputNumberCustom from "../../input-number"
import { convertCurrency } from "src/utils/convert-currency"
import { VoucherRestaurantControllerApi } from "@soctrip/axios-promotion-service"
import { VouchersType } from "src/components/merchant-voucher/types"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { VoucherControllerApi } from "@soctrip/axios-restaurant-service"
import { useToast } from "src/hooks/use-toast"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"

export default function BuyVoucherModal(props: BuyVoucherModalType) {
  const { isShow, handleClose, voucherId } = props
  const showToast = useToast()
  const { t } = useTranslation()
  const authStore = useSelector((state: RootState) => state.auth)
  const [voucherDetail, setVoucherDetail] = useState<VouchersType | undefined>(
    undefined
  )
  const [isValidUserInfo, setIsValidUserInfo] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState<VoucherUserInfo | undefined>(
    undefined
  )
  const [quantity, setQuantity] = useState<number>(1)

  const handleBuyVoucher = () => {
    if (authStore?.value) {
      const callBackUrl = `${process.env.REACT_APP_FOOD_DOMAIN_URL}/order-voucher`
      const reqData = {
        success_callback: callBackUrl,
        fail_callback: callBackUrl,
        full_name: userInfo?.full_name || "",
        email: userInfo?.email || "",
        phone: userInfo?.phone || "",
        voucher_id: voucherDetail?.id || "",
        quantity: quantity,
        amount: (voucherDetail?.voucher_price as number) * quantity || 0,
      }
      new VoucherControllerApi(config(SERVICE.RESTAURANT))
        .vouchersPost(reqData)
        .then((res: any) => {
          if (res?.data?.data) {
            const paymentToken = res.data.data?.token || ""
            const redirectUrl = res.data.data?.payment_url || ""
            if (paymentToken && redirectUrl)
              window.open(
                `${redirectUrl}?paymentToken=${paymentToken}`,
                "_self"
              )
          } else {
            showToast({
              detail: "Order failed. Please try again!",
              severity: "error",
            })
          }
        })
        .catch(() => {
          showToast({
            detail: "Order failed. Please try again!",
            severity: "error",
          })
        })
    } else
      showToast({ detail: "Please login before continue", severity: "warn" })
  }

  const isUserCanBuy = useMemo(() => {
    if (voucherDetail) {
      return (
        voucherDetail.max_distribution_per_buyer -
          voucherDetail.my_bought_count >
        0
      )
    }
    return false
  }, [voucherDetail])

  const updateVoucherQuantity = (newValue: number) => {
    if (voucherDetail) {
      const newResult =
        newValue >
        voucherDetail.max_distribution_per_buyer - voucherDetail.my_bought_count
          ? voucherDetail.max_distribution_per_buyer -
            voucherDetail.my_bought_count
          : newValue
      setQuantity(newResult)
    }
  }

  const getMaxCountVoucher = useMemo(() => {
    if (voucherDetail) {
      const currentQuantity =
        voucherDetail.quantity - voucherDetail.bought_count
      const limitByUser =
        voucherDetail.max_distribution_per_buyer - voucherDetail.my_bought_count
      if (voucherDetail?.is_unlimited_quantity) {
        return limitByUser >= 0 ? limitByUser : 0
      } else {
        const result = currentQuantity > limitByUser ? (limitByUser) : currentQuantity
        return result >= 0 ? result : 0
      }
    }
    return 1
  }, [voucherDetail])

  const footerTemplate = (
    <div className="flex flex-col md:flex-row items-start justify-start px-4 md:px-0 md:items-center md:justify-between pt-2 gap-1">
      <div className="flex items-center justify-between md:justify-start w-full">
        {isUserCanBuy && voucherDetail ? (
          <>
            <div className="w-[120px]">
              <InputNumberCustom
                min={1}
                value={quantity}
                max={getMaxCountVoucher}
                updateValue={updateVoucherQuantity}
              />
            </div>
            <div className="ml-3 flex items-center">
              <span className="text-[16px] font-semibold leading-[20px] text-gray-700">
                {capitalizedFirst(t("buy-voucher.total"))}
              </span>
              <span className="ml-1 text-[18px] font-semibold leading-[28px] text-orange-dark-400">
                {convertCurrency(
                  voucherDetail?.voucher_price * quantity || 0
                )}
              </span>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center py-3 text-[18px] font-semibold leading-[28px] text-orange-dark-600">
            <span>{capitalizedFirst(t("buy-voucher.user-max-buy"))}</span>
          </div>
        )}
      </div>
      <div className="flex items-center gap-2 justify-end w-full">
        <button
          className="rounded-3 border border-gray-200 px-[20px] py-[12px] text-[14px] font-semibold leading-[20px] text-gray-700"
          onClick={() => handleClose()}
        >
          {capitalizedFirst(t("buy-voucher.btn-cancel"))}
        </button>

        <button
          disabled={!isUserCanBuy || !isValidUserInfo}
          className={`rounded-3 bg-blue-600 px-[20px] py-[12px] text-[14px] font-semibold leading-[20px] text-white  ${
            isUserCanBuy && isValidUserInfo ? "hover:bg-blue-500" : "opacity-50"
          }`}
          onClick={handleBuyVoucher}
        >
          {capitalizedFirst(t("buy-voucher.btn-order"))}
        </button>
      </div>
    </div>
  )

  const updateUser = (
    userInfoData: VoucherUserInfo | undefined,
    isValid: boolean
  ) => {
    setUserInfo(userInfoData)
    setIsValidUserInfo(isValid)
  }

  useEffect(() => {
    if (isShow && voucherId) {
      new VoucherRestaurantControllerApi(config(SERVICE.PROMOTION))
        .findById2(voucherId)
        .then((res: any) => {
          if (res?.data?.data) {
            setVoucherDetail(res?.data.data)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [voucherId, isShow])

  if (!voucherDetail) return null
  return (
    <Dialog
      id="buyVoucherModal"
      footer={footerTemplate}
      draggable={false}
      header={capitalizedFirst(t("buy-voucher.header"))}
      visible={isShow}
      onHide={handleClose}
    >
      <div className="flex flex-col xl:flex-row max-w-[996px] xl:w-[996px] gap-4 rounded-t-3 px-4 md:p-0">
        <div className="flex-1 flex-col">
          <BuyerInfoForm updateUser={updateUser} />
          <div className="mt-4 flex flex-col rounded-3 border border-gray-300 py-3 text-[14px] font-medium leading-[20px]">
            <span className="px-3 text-gray-400">
              {capitalizedFirst(t("buy-voucher.voucher-remain"))}
            </span>
            <span className="mt-4px px-3 text-gray-700">
              {getMaxCountVoucher}
            </span>
          </div>
        </div>
        <div className="flex w-full flex-1">
          <VoucherInfo {...voucherDetail} />
        </div>
      </div>
    </Dialog>
  )
}
