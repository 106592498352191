import React, { useEffect, useRef, useState } from "react"
import { FoodDetailRelatedProps } from "./props"
import "swiper/scss"
import "swiper/scss/navigation"
import "./food-detail-related.scss"
import useOnScreen from "src/hooks/use-on-screen"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import FoodCardView from "src/components/food-home-view/food-card-view"
import { FOOD_CARD_TYPE } from "src/constants/common"
import { RecommendViewType } from "src/components/food-home-view/types"
import { FoodControllerApi } from "@soctrip/axios-food-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"

export default function FoodDetailRelated({
  title,
  handleReload,
  bloom_filter,
  catalogGlobalId,
}: FoodDetailRelatedProps) {
  const { t } = useTranslation()
  const loadingRef = useRef(null)
  const isOnScreen = useOnScreen(loadingRef)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [recommendFoods, setRecommendFoods] = useState<RecommendViewType[]>([])
  const [totalElement, setTotalElement] = useState<number>(0)
  const [pageIndex, setPageIndex] = React.useState<number>(0)
  const [handleShowMoreLoading, setHandleShowMoreLoading] =
    useState<boolean>(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

  useEffect(() => {
    if (catalogGlobalId) {
      fetchRecommendFoods(pageIndex)
      setIsFirstLoad(false)
    }
  }, [catalogGlobalId])

  const fetchRecommendFoods = async (page: number) => {
    try {
      const res = await new FoodControllerApi(
        config(SERVICE.FOOD)
      ).foodsSearchGet(page, 20, `str_catalog@=${catalogGlobalId}`)
      setTotalElement(res?.data?.data?.totalElement)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (res?.data?.data?.data?.length) {
        const result: RecommendViewType[] = []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        res.data.data.data.forEach((i: any) => {
          result.push({
            id: i?.id,
            title: i?.name,
            imgUrl: i?.avatar?.id,
            point: i?.point,
            countReview: i?.sum_reviews,
            price: i?.price,
            originPrice: i?.original_price,
            restaurant_food: i?.restaurant_food,
            address: i?.restaurant_food?.name,
          })
        })
        setRecommendFoods([...recommendFoods, ...result])
      }

      setHandleShowMoreLoading(false)
    } catch (error) {
      console.log(error)
      setHandleShowMoreLoading(false)
    }
  }

  const handleLoadMore = () => {
    setHandleShowMoreLoading(true)
    const currentPage = pageIndex + 1
    setPageIndex(currentPage)
    fetchRecommendFoods(currentPage)
  }

  useEffect(() => {
    if (isOnScreen && !isFirstLoad && recommendFoods?.length < totalElement) {
      setHandleShowMoreLoading(true)
      const currentPage = pageIndex + 1
      setPageIndex(currentPage)
      fetchRecommendFoods(currentPage)
    }
  }, [isOnScreen])

  return (
    <div className="flex flex-col">
      <span className="justify-center text-20 font-semibold text-gray-800">
        {title}
      </span>
      <div className="swiper-custom relative mt-3 grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
        {recommendFoods.map((food, index) => (
          <div key={index}>
            <FoodCardView
              isShowPrice={true}
              isAddressView={true}
              isFoodType={true}
              bloom_filter={bloom_filter}
              type={FOOD_CARD_TYPE.FOOD}
              handleReload={handleReload}
              {...food}
            />
          </div>
        ))}
      </div>
      {pageIndex < 2 ? (
        <div>
          <div ref={loadingRef}></div>
        </div>
      ) : (
        <>
          {!handleShowMoreLoading && (
            <div className="flex items-center justify-center">
              {recommendFoods?.length < totalElement ? (
                <div
                  onClick={handleLoadMore}
                  className="cursor-pointer text-14 font-medium text-blue-700"
                >
                  {capitalizedFirst(t("shoppingPage.view-more"))}
                </div>
              ) : null}
            </div>
          )}
        </>
      )}
      {handleShowMoreLoading && (
        <div className="flex justify-center py-4">
          <i
            className="pi pi-spin pi-spinner text-blue-500"
            style={{ fontSize: "1.5rem" }}
          ></i>
        </div>
      )}
    </div>
  )
}
