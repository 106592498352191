import React, { useState } from "react"
import { Button } from "primereact/button"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { t } from "i18next"
import { VouchersType } from "../types"
import BuyVoucherModal from "src/components/common/modal/buy-voucher-modal"
import { ImgCustom } from "src/components/common"
import { convertCurrency } from "src/utils/convert-currency"
import { useToast } from "src/hooks/use-toast"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import VoucherDetailModal from "src/components/common/modal/voucher-detail-modal"
import QRCodeModal from "src/components/common/modal/voucher-detail-modal/qr-code-modal"
import { getExpiredDateVoucher } from "src/utils/get-expired-date-voucher"
import { TYPE_VOUCHER } from "src/components/my-voucher/types"

export default function VoucherView(props: VouchersType) {
  const {
    id,
    isHiddenBuy,
    isHiddenDetail,
    voucher_price,
    discount_price,
    avatar_id,
    name,
    quantity,
    type,
    start_date,
    end_date,
    selectTab,
  } = props
  const showToast = useToast()
  const authStore = useSelector((state: RootState) => state.auth)
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [isVisibleModalQRCode, setIsVisibleModalQRCode] =
    useState<boolean>(false)
  const [isVisibleQRCode, setIsVisibleQRCode] = useState<boolean>(false)

  const handleBuy = () => {
    if (authStore?.value) setIsShowModal(true)
    else showToast({ detail: "Please login before continue", severity: "warn" })
  }
  const onShowQRCode = () => {
    setIsVisibleQRCode(true)
  }
  return (
    <div className="coupon rounded-md item-center  relative flex min-w-[300px] overflow-hidden">
      <div className="coupon-left flex justify-center rounded-bl-3 rounded-tl-3 border-b border-l border-t  border-orange-dark-600 bg-white p-10px">
        <div className="h-[100px] w-[100px] rounded-2">
          <ImgCustom
            url={avatar_id}
            alt="voucherImg"
            className="rounded-2 object-cover"
          />
        </div>
      </div>
      <div className="my-3 w-[1px] border-r-[1px] border-dashed border-orange-dark-600"></div>
      <div
        className={`relative flex flex-1 flex-col rounded-3 border-b border-r border-t border-orange-dark-600 bg-white px-3 pb-3 pt-4 ${
          !isHiddenBuy && !isHiddenDetail
            ? "!justify-between"
            : "justify-around"
        }`}
      >
        <div className="flex flex-col mt-4px">
          <span
            className={`text-16 font-semibold leading-6 text-gray-700 ${
              !isHiddenBuy && !isHiddenDetail ? "" : ""
            }`}
          >
            {type === "BOUGHT"
              ? name
              : capitalizedFirst(
                t("merchant-page.get-money-off", {
                  price: convertCurrency(discount_price),
                })
              )}
          </span>
          {type === "BOUGHT" && (
            <span className="line-clamp-1 text-14 font-medium leading-20 text-gray-500">
              {capitalizedFirst(
                t("merchant-page.get-money-off", {
                  price: convertCurrency(discount_price),
                })
              )}
            </span>
          )}
          { type !== "BOUGHT" && <span className="text-14 font-medium">{name}</span>}
          <span className="line-clamp-1 text-12 font-medium leading-20 text-gray-500">
            {capitalizedFirst(t(getExpiredDateVoucher(start_date)))} -
            {capitalizedFirst(t(getExpiredDateVoucher(end_date)))}
          </span>
        </div>
        {!isHiddenBuy || !isHiddenDetail ? (
          <div
            id="custom-button"
            className="flex flex-row items-center justify-end gap-3"
          >
            {!isHiddenDetail ? (
              <span
                className={`cursor-pointer text-14 font-medium leading-5  hover:text-blue-500 ${
                  isHiddenDetail ? "text-blue-600 " : "text-gray-600"
                } text-blue-600`}
                onClick={() => setIsVisibleModalQRCode(true)}
              >
                {capitalizedFirst(t("reservation.detail"))}
              </span>
            ) : null}
            {!isHiddenBuy ? (
              <Button
                onClick={handleBuy}
                className="flex h-[28px] w-auto items-center justify-center rounded-3 border !border-gray-300 text-14 font-semibold"
                label={capitalizedFirst(t("global.buy-now"))}
                severity="info"
              />
            ) : selectTab === TYPE_VOUCHER.UNREDEEMED ? (
              <span
                className="cursor-pointer text-14 font-medium text-blue-600 hover:text-blue-500"
                onClick={onShowQRCode}
              >
                {capitalizedFirst(t("my-voucher.qr-code"))}
              </span>
            ) : null}
          </div>
        ) : null}
        <div className="absolute right-0 top-0 h-4 w-fit rounded-bl-2 rounded-tr-2 bg-orange-dark-600 text-center px-4px">
          <span className="text-14 font-medium leading-5 text-white">
            {type === "BOUGHT"
              ? `X${quantity || 0}`
              : `${convertCurrency(voucher_price)}`}
          </span>
        </div>
      </div>
      <div className="custom-style absolute left-[114px] top-[-8px] z-[2] h-[16px] w-[16px] rounded-full border border-orange-dark-600 bg-white"></div>
      <div className="custom-style absolute left-[0px] top-[-8px] z-[3] h-[8px] w-full bg-white "></div>
      <div className="absolute bottom-[-8px] left-[114px] z-[2] h-[16px] w-[16px] rounded-full border border-orange-dark-600 bg-white "></div>
      <div className="absolute bottom-[-8px] left-[0px] z-[3] h-[8px] w-full bg-white "></div>

      {isShowModal ? (
        <BuyVoucherModal
          voucherId={id}
          isShow={isShowModal}
          handleClose={() => setIsShowModal(false)}
        />
      ) : null}
      {isVisibleModalQRCode ? (
        <VoucherDetailModal
          voucherId={id}
          isShow={isVisibleModalQRCode}
          handleClose={() => setIsVisibleModalQRCode(false)}
          type={type}
        />
      ) : null}
      {isVisibleQRCode ? (
        <QRCodeModal
          voucherId={id}
          isShow={isVisibleQRCode}
          handleClose={() => setIsVisibleQRCode(false)}
          type={type}
        />
      ) : null}
    </div>
  )
}
