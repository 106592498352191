/* eslint-disable indent */
import React, { useMemo, useRef } from "react"
import { ItemRatingViewProps, NoteReviewType } from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import ImgCustom from "src/components/common/img-custom"
import uploadFileIcon from "src/assets/images/upload-file-icon.svg"
import uploadMoreIcon from "src/assets/images/upload-more-file-icon.svg"
import deleteMediaIcon from "src/assets/images/delete-media-icon.svg"
import * as fileServices from "src/services/file-service"
import TextLineView from "./text-line-view"
import StarView from "./star-view"
import VideoCustom from "./video-custom"
import { convertTimeCommon } from "src/utils/time-utils"

const StarRating = [1, 2, 3, 4, 5]

const ItemRatingView = (props: ItemRatingViewProps) => {
  const { t } = useTranslation()
  const {
    notesReview,
    updateRatingItem,
    isRated,
    restaurantData,
    ratingProductData,
  } = props

  const getRateLabel = () => {
    switch (ratingProductData.rating) {
      case 1:
        return "purchase-history.rating.terrible"
      case 2:
        return "purchase-history.rating.poor"
      case 3:
        return "purchase-history.rating.fair"
      case 4:
        return "purchase-history.rating.good"
      default:
        return "purchase-history.rating.amazing"
    }
  }
  const labelRateDisplay = useMemo(() => {
    return getRateLabel()
  }, [ratingProductData.rating])

  const updateLikeText = (newLikeText: NoteReviewType) => {
    if (!isRated) {
      let result: NoteReviewType[] =
        ratingProductData.notes?.map((tValue) => {
          return tValue
        }) || []
      if (!ratingProductData.notes?.find((i) => i.id === newLikeText.id)) {
        result.push(newLikeText)
      } else {
        result = ratingProductData.notes.filter((i) => i.id !== newLikeText.id)
      }

      updateRatingItem({
        rating: ratingProductData.rating,
        notes: result,
        content: ratingProductData.content,
        medias: ratingProductData.medias,
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeFile = (file: any) => {
    if (file?.files && file?.files?.length) {
      fileServices
        .uploadFile(file?.files[0])
        .then((res) => {
          if (res?.data?.data) {
            const newMediaResult = ratingProductData.medias?.map((i) => i) || []
            newMediaResult.push(res.data.data)
            updateRatingItem({
              rating: ratingProductData.rating,
              notes: ratingProductData.notes,
              content: ratingProductData.content,
              medias: newMediaResult,
            })
          }
        })
        .catch((err) => console.log(err))
    }
  }
  const fileRef = useRef<HTMLInputElement>(null)

  const handleSelectFile = () => {
    if (isRated) return
    if (fileRef) {
      fileRef?.current?.click()
    }
  }
  const deleteMediaFile = (mediaId: string) => {
    fileServices.deleteFile(mediaId).then(() => {
      const newResult =
        ratingProductData.medias?.filter((i) => i.id !== mediaId) || []
      updateRatingItem({
        rating: ratingProductData.rating,
        notes: ratingProductData.notes,
        content: ratingProductData.content,
        medias: newResult,
      })
    })
  }
  const updateRate = (newRate: number) => {
    if (!isRated) {
      updateRatingItem({
        rating: newRate,
        notes: ratingProductData.notes,
        content: ratingProductData.content,
        medias: ratingProductData.medias,
      })
    }
  }
  const updateContentChange = (newContent: string) => {
    updateRatingItem({
      rating: ratingProductData.rating,
      notes: ratingProductData.notes,
      content: newContent,
      medias: ratingProductData.medias,
    })
  }
  const getAppTitle = useMemo(() => {
    return process.env.REACT_APP_TITLE
  }, [])

  return (
    <div className={"flex flex-col"}>
      <div className="flex border-b-[1px] border-gray-25 pb-3">
        <div className="h-[56px] w-[56px]">
          <ImgCustom
            url={restaurantData.url}
            alt="productImg"
            className="object-cover"
          />
        </div>
        <div className="ml-2 flex flex-1 flex-col">
          <span className="text-14 font-semibold leading-20 text-gray-700">
            {restaurantData?.name}
          </span>
          <span className="mt-2px text-12 font-medium leading-18 text-gray-700">
            {`${capitalizedFirst(t("reservation.address"))}: `}
            {restaurantData.address}
          </span>
          <span className="mt-2px text-12 font-medium leading-18 text-gray-700">
            {`${capitalizedFirst(t("reservation.number-of-guest"))}: `}
            {restaurantData.guests}
          </span>
          <span className="mt-2px text-12 font-medium leading-18 text-gray-700">
            {`${capitalizedFirst(t("reservation.reservation-time"))}: `}
            {convertTimeCommon(restaurantData.time, true)}
          </span>
        </div>
      </div>
      <div className="mt-3">
        <span>{capitalizedFirst(t("purchase-history.ratingExperienceRestaurant"))}</span>

        <div className="mt-3 flex flex-col items-center rounded-3 bg-blue-25 py-3">
          <div className="flex items-center justify-center">
            {StarRating.map((i, index) => (
              <StarView
                updateRate={updateRate}
                key={index}
                index={index}
                isCheck={i <= ratingProductData.rating}
              />
            ))}
          </div>
          {ratingProductData.rating > 0 && (
            <span className="mt-1 text-14 font-medium leading-20 text-gray-500">
              {capitalizedFirst(t(labelRateDisplay))}
            </span>
          )}
        </div>
      </div>
      <div className="mt-3 flex flex-col">
        <span>
          {capitalizedFirst(
            t("purchase-history.ratingLike", { getAppTitle: getAppTitle })
          )}
        </span>
        {notesReview && (
          <div className="mt-3 grid grid-cols-2 gap-2 md:grid-cols-4 ">
            {notesReview?.map((n, index) => (
              <TextLineView
                label={n.name}
                id={n.id}
                key={index}
                updateLikeText={updateLikeText}
                isChecked={
                  !!ratingProductData.notes?.find((i) => i.id === n.id)
                }
              />
            ))}
          </div>
        )}
      </div>
      <div className="mt-3 flex flex-col">
        <span className="text-14 font-medium leading-20 text-gray-700">
          {capitalizedFirst(t("purchase-history.rateFeedback"))}
          <span className={`ml-[2px] text-red-500 ${isRated ? "hidden":""}`}>*</span>
        </span>
        <textarea
          onChange={(e) => updateContentChange(e.target.value)}
          readOnly={isRated}
          value={ratingProductData.content}
          className="mt-6px resize-none rounded-3 border border-gray-300 px-14px py-2 text-16 leading-24 text-gray-500 outline-none"
        />
      </div>
      {!isRated ? (
        <div className="mt-3 flex">
          {!ratingProductData.medias.length ? (
            <>
              <input
                ref={fileRef}
                className="hidden"
                type="file"
                name="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeFile(e.target)
                }
              />
              <button
                onClick={handleSelectFile}
                className="flex items-center justify-center rounded-3 bg-blue-50 px-2 py-1 hover:bg-blue-25"
              >
                <img
                  className="h-[20px] w-[20px]"
                  src={uploadFileIcon}
                  alt="uploadFileIcon"
                />
                <span className="ml-1 text-14 font-semibold leading-20 text-blue-700">
                  {"Photo/video"}
                </span>
              </button>
            </>
          ) : (
            <div className="grid grid-cols-4 gap-2">
              {ratingProductData.medias?.map((i) => (
                <div
                  key={i.id}
                  className="relative h-[128px] w-[128px] rounded-3"
                >
                  {i.type.includes("image") ? (
                    <ImgCustom
                      className={"h-full w-full rounded-3 object-cover"}
                      alt="fileItem"
                      url={i.id}
                    />
                  ) : (
                    <VideoCustom type={i.type} idSource={i.id} />
                  )}
                  <div
                    onClick={() => deleteMediaFile(i.id)}
                    className={`absolute right-1 top-1 flex h-[16px] w-[16px] cursor-pointer items-center justify-center rounded-[50%] border ${
                      i.type.includes("image")
                        ? "border-white"
                        : "border-transparent bg-gray-50"
                    }`}
                  >
                    <img
                      src={deleteMediaIcon}
                      className="z-[2]"
                      alt="deleteIcon"
                    />
                  </div>
                </div>
              ))}
              {ratingProductData.medias?.length < 5 && (
                <div className="h-[128px] w-[128px] rounded-3">
                  <input
                    ref={fileRef}
                    className="hidden"
                    type="file"
                    name="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeFile(e.target)
                    }
                  />
                  <button
                    onClick={handleSelectFile}
                    className="flex h-full w-full flex-col items-center justify-center rounded-3 border border-dashed border-gray-300"
                  >
                    <img
                      className="h-[24px] w-[24px]"
                      src={uploadMoreIcon}
                      alt="uploadFileIcon"
                    />
                    <span className="mt-3 text-10 leading-16 text-gray-500">{`${ratingProductData.medias.length}/5`}</span>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}
      {isRated ? (
        <div className="mt-3 flex">         
            <div className="grid grid-cols-4 gap-2">
              {ratingProductData.medias?.map((i) => (
                <div
                  key={i.id}
                  className="relative h-[128px] w-[128px] rounded-3"
                >
                  {i.type.includes("image") ? (
                    <ImgCustom
                      className={"h-full w-full rounded-3 object-cover"}
                      alt="fileItem"
                      url={i.id}
                    />
                  ) : (
                    <VideoCustom type={i.type} idSource={i.id} />
                  )}
                  {!isRated ? (
                    <div
                      onClick={() => deleteMediaFile(i.id)}
                      className={`absolute right-1 top-1 flex h-[16px] w-[16px] cursor-pointer items-center justify-center rounded-[50%] border ${
                        i.type.includes("image")
                          ? "border-white"
                          : "border-transparent bg-gray-50"
                      }`}
                    >
                      <img
                        src={deleteMediaIcon}
                        className="z-[2]"
                        alt="deleteIcon"
                      />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
        </div>
      ) : null}
      <div className="h-[1px] w-full bg-gray-25" />
    </div>
  )
}

export default ItemRatingView
