/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper/modules"
import "./index.scss"
import VoucherView from "./voucher"
import { t } from "i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { VouchersType, MerchantVoucherViewType } from "./types"
import { VoucherRestaurantControllerApi } from "@soctrip/axios-promotion-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"

export default function MerchantVoucherView(props: MerchantVoucherViewType) {
  const { restaurantId } = props
  const [vouchers, setVouchers] = useState<VouchersType[]>([])

  const getVoucherRestaurant = () => {
    const searchFilter = `object_id==${restaurantId}`
    new VoucherRestaurantControllerApi(config(SERVICE.PROMOTION))
      .search(searchFilter, 0, 200)
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          setVouchers(res.data.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    if (restaurantId) getVoucherRestaurant()
  }, [])

  if (!vouchers.length) return null
  return (
    <div className="mt-[65px] md:mt-6 mb-3 w-full rounded-4 border border-gray-200 bg-white p-1 md:p-3 shadow-sm">
      <div className="flex flex-row justify-between pb-1">
        <span className="text-18 font-semibold leading-28 text-gray-900">
          {capitalizedFirst(t("purchase-history.voucher"))}
        </span>
      </div>
      <div id="custom-swiper">
        <Swiper
          navigation={true}
          pagination={false}
          className=""
          slidesPerView={"auto"}
          modules={[Navigation, Pagination]}
          breakpoints={{
            480: {
              slidesPerView: 1,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 8,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 8,
            },
          }}
        >
          {vouchers.map((i) => (
            <SwiperSlide key={i?.id}>
              <div className="flex w-auto flex-col gap-[4px]">
                <VoucherView {...i} type="VIEW" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
