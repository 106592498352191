import React, { useEffect, useState } from "react"
import { MapModalProps } from "./types"
import { Dialog } from "primereact/dialog"
import "./index.scss"

export interface MyLocationType {
  lat: number
  lng: number
}

const STATE_VIEW = {
  INIT: "INIT",
  LOADING: "LOADING",
  RENDER: "RENDER",
  COMPLETE: "COMPLETE",
}
export default function MapModal(props: MapModalProps) {
  const { isShow, handleClose, location, isHighlight } = props
  const [myLocation, setMyLocation] = useState<MyLocationType | undefined>(
    undefined
  )
  const [stateView, setStateView] = useState<string>(STATE_VIEW.INIT)
  const [mapUrl, setMapUrl] = useState<string>("")
  const handleOnLoad = () => {
    setStateView(STATE_VIEW.RENDER)
  }

  const getMyLocation = () => {
    const geolocationAPI = navigator.geolocation
    if (geolocationAPI) {
      return geolocationAPI.getCurrentPosition(
        async (response) => {
          const { coords } = response
          const clientLocation = {
            lat: coords?.latitude || 0,
            lng: coords?.longitude || 0,
          }
          setMyLocation(clientLocation)
          setStateView(STATE_VIEW.LOADING)
        },
        function () {
          setStateView(STATE_VIEW.LOADING)
        }
      )
    }
  }
  useEffect(() => {
    getMyLocation()
  }, [])

  useEffect(() => {
    if (stateView === STATE_VIEW.LOADING) {
      let result = ""
      let paramData = ""
      if (location?.latitude && location?.longitude) {
        paramData = `?highlight=${!!isHighlight}&lat=${
          location?.latitude
        }&lng=${location?.longitude}`
        result = `${process.env.REACT_APP_ECOMMERCE_MANAGEMENT_DOMAIN_URL}/map${paramData}`
      } else {
        // LatLng(38.810821, -121.220398) dummy data
        if (myLocation?.lat && myLocation?.lng) {
          paramData = `?highlight=${!!isHighlight}&lat=${myLocation?.lat}&lng=${
            myLocation?.lng
          }`
          result = `${process.env.REACT_APP_ECOMMERCE_MANAGEMENT_DOMAIN_URL}/map${paramData}`
        }
      }
      if (result) {
        setMapUrl(result)
      } else
        setMapUrl(
          `${process.env.REACT_APP_ECOMMERCE_MANAGEMENT_DOMAIN_URL}/map`
        )

      setStateView(STATE_VIEW.RENDER)
    }
  }, [location, myLocation, stateView])

  return (
    <Dialog
      id="mapModal"
      header=""
      visible={isShow}
      onHide={() => handleClose()}
      className="w-full max-w-[1200px]"
    >
      <div className="no-scrollbar flex h-[80vh] w-full flex-col">
        <div className="relative flex h-full w-full items-center">
          {stateView !== STATE_VIEW.COMPLETE ? null : (
            <div className="absolute left-0 top-0 z-[2] flex h-full w-full items-center justify-center gap-2 rounded-3 bg-white">
              <p className="animate-pulse duration-75">Loading...</p>
              <i className="pi pi-spin pi-spinner text-24 font-bold text-blue-light-400"></i>
            </div>
          )}
          {stateView === STATE_VIEW.RENDER && mapUrl ? (
            <iframe
              id="mapResult"
              title="mapResult"
              src={mapUrl}
              className="h-full w-full"
              onLoad={handleOnLoad}
            />
          ) : null}
        </div>
      </div>
    </Dialog>
  )
}
