import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { OrderDetailsInfoProps } from "./props"

export default function OrderDetailsInformation({
  order,
}: OrderDetailsInfoProps) {
  const { t } = useTranslation()
  const formatDate = (date?: Date) => {
    if (date) {
      const newTime = new Date(date)?.toLocaleString("en-GB")
      return newTime
    }
  }

  const getFullAddress = useMemo(() => {
    const address = order?.address
    return [
      address?.address,
      address?.ward,
      address?.district,
      address?.city,
      address?.country,
    ]
      .filter((i) => i)
      .join(", ")
  }, [order?.address])

  return (
    <div className="flex h-full w-full flex-col">
      <div className="item-center flex h-full w-full flex-col gap-3 md:flex-row">
        {/* Order information */}
        <div className="flex h-[initial] w-full flex-1 flex-col rounded-3 bg-white ">
          <div className="h-[60px] w-full border-b border-gray-100">
            <div className="flex items-center p-3 text-18 font-semibold text-gray-800">
              {capitalizedFirst(t("purchase-history.order-information"))}
            </div>
          </div>
          <div className="flex h-full w-full flex-1 flex-col gap-1 p-3">
            <div className="flex h-full w-full flex-col gap-3 lg:flex-row">
              {/* status and order date*/}
              <div className="flex h-full w-full flex-col gap-2 lg:flex-row">
                {/* status */}
                <div className="flex w-full flex-col">
                  <div className="text-14 font-medium text-gray-500">
                    {capitalizedFirst(t("purchase-history.status"))}
                  </div>
                  <div className="flex w-full">
                    <p className="line-clamp-1 rounded-5 bg-gray-200 px-10px py-2px text-14 font-medium text-gray-700">
                      {order?.status?.name}
                    </p>
                  </div>
                </div>
                {/* order date */}
                <div className="flex w-full flex-col">
                  <div className="text-14 font-medium text-gray-500">
                    {capitalizedFirst(t("purchase-history.order-date"))}
                  </div>
                  <div className="flex w-full">
                    <p className="line-clamp-1 text-16 font-normal text-gray-900">
                      {formatDate(order?.created_at)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* note */}
            <div className="flex w-full flex-col">
              <div className="text-14 font-medium text-gray-500">
                {order?.note && capitalizedFirst(t("purchase-history.note"))}
              </div>
              <div className="text-16 font-normal text-gray-900">
                {order?.note ? order.note : ""}
              </div>
            </div>
          </div>
        </div>
        {/* delivery information */}
        <div className="h-fit w-full rounded-3 bg-white md:w-1/2">
          <div className="flex h-full flex-col">
            <div className="h-[60px] w-full border-b border-gray-100">
              <div className="line-clamp-1 flex items-center p-3 text-18 font-semibold text-gray-800">
                {capitalizedFirst(t("purchase-history.delivery-information"))}
              </div>
            </div>
            <div className="flex h-full flex-col p-3 sm:gap-2 lg:gap-1">
              <div className="flex h-full w-full flex-col gap-2 lg:flex-row">
                {/* name */}
                <div className="flex w-full flex-col">
                  <div className="text-14 font-medium text-gray-500">
                    {capitalizedFirst(t("purchase-history.name"))}
                  </div>
                  <div className="flex max-w-[180px] items-center gap-1">
                    <p className="line-clamp-1 text-16 font-normal text-gray-900">
                      {order?.address?.user_full_name}
                    </p>
                  </div>
                </div>
                {/* status and order date*/}
                <div className="flex w-full gap-1">
                  {/* order date */}
                  <div className="flex flex-col">
                    <div className="text-14 font-medium text-gray-500">
                      {capitalizedFirst(t("purchase-history.phone"))}
                    </div>
                    <div className="flex w-full">
                      <p className="line-clamp-1 text-16 font-normal text-gray-900">
                        {order?.address?.user_phone_number}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* address */}
              <div className="flex w-full flex-col">
                <div className="text-14 font-medium text-gray-500">
                  {capitalizedFirst(t("global.address"))}
                </div>
                {order?.address ? (
                  <div className="line-clamp-1 text-16 font-normal text-gray-900">
                    {getFullAddress}
                  </div>
                ) : (
                  <div className="line-clamp-1 text-16 font-normal text-gray-900">
                    {capitalizedFirst(t("purchase-history.address-empty"))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
