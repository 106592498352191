import React, { useState } from "react"
import FoodCardView from "./food-card-view"
import { PopularFoodType } from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { FOOD_CARD_TYPE, SERVICE } from "src/constants/common"
import { getBloomFilter } from "src/services/bookmark-service"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper/modules"
import { config } from "src/config/interceptors"
import { HistoryBookingControllerApi } from "@soctrip/axios-food-order-service"

const BookingAgainView = () => {
  const [popularFood, setPopularFood] = React.useState<PopularFoodType[]>([])
  const [bloomData, setBloomData] = useState<string>("")
  const { t } = useTranslation()

  const loadData = async () => {
    await new HistoryBookingControllerApi(config(SERVICE.FOOD_ORDER))
      .getMyHistoryBooking(0, 10)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          const result: PopularFoodType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.data.forEach((i: any) => {
            result.push({
              id: i?.id,
              title: i?.name,
              imgUrl: i?.avatar?.id,
              point: i?.rating,
              countReview: i?.sum_reviews,
              is_free_wifi: i?.is_free_wifi,
              is_parking_lot: i?.is_parking_lot,
              is_operating_hours: i?.is_operating_hours,
              is_vehicle_charging: i?.is_vehicle_charging,
              address: {
                country: i?.country,
                province: i?.province,
                district: i?.district,
                ward: i?.ward,
                street: i?.street,
              },
            })
          })
          setPopularFood(result)
        }
      })
      .catch((error) => {
        error
      })
  }

  React.useEffect(() => {
    loadData()
    handleGetBloomData()
  }, [])

  // get bloom data
  const handleGetBloomData = async () => {
    await getBloomFilter()
      .then((response) => {
        setBloomData(response?.data?.data?.bloom_filter)
      })
      .catch((error) => error)
  }

  return (
    <>
      {popularFood.length ? (
        <div className="flex w-full flex-col gap-2">
          <div className="flex w-full items-center justify-between">
            <div className="text-20 font-semibold leading-30 text-gray-900">
              {capitalizedFirst(t("food-home-page.visit-again"))}
            </div>
          </div>
          <div className="flex items-center" id="book-again-restaurant">
            <div className="w-full">
              <Swiper
                slidesPerView={1}
                spaceBetween="auto"
                navigation={true}
                pagination={true}
                modules={[Navigation, Pagination]}
                breakpoints={{
                  0: { slidesPerView: 1, spaceBetween: 16 },
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                  },
                  1200: {
                    slidesPerView: 5,
                    spaceBetween: 16,
                  },
                }}
              >
                {popularFood?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="w-full">
                      <FoodCardView
                        isFoodType={false}
                        isRatingView={true}
                        isAddressView={true}
                        type={FOOD_CARD_TYPE.RESTAURANT}
                        bloom_filter={bloomData}
                        handleReload={handleGetBloomData}
                        {...item}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default React.memo(BookingAgainView)
