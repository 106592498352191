/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"
import { t } from "i18next"
import { Dialog } from "primereact/dialog"
import VoucherView from "src/components/merchant-voucher/voucher"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import "./index.scss"
import paymentLogo from "src/assets/images/payment-logo.svg"
import { VoucherDetailModalType } from "./types"
import { VoucherRestaurantControllerApi } from "@soctrip/axios-promotion-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { VouchersType } from "src/components/merchant-voucher/types"
import { generateQrCodeWithString } from "src/utils/qr-code-utils"
import { VoucherControllerApi } from "@soctrip/axios-restaurant-service"
import VoucherInfo from "../buy-voucher-modal/voucher-info"
import { getExpiredDateVoucher } from "src/utils/get-expired-date-voucher"
import RedeemedIcon from "src/assets/images/redeemed-icon.svg"
import { convertCurrency } from "src/utils/convert-currency"
import moment from "moment"

export default function VoucherDetailModal(props: VoucherDetailModalType) {
  const { voucherId, isShow, handleClose, type } = props
  const [voucherInfo, setVoucherInfo] = useState<any>()
  const [qrDataImage, setQrDataImage] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    async function _fetchData() {
      setIsLoading(true)
      if (type === "BOUGHT") {
        await new VoucherControllerApi(config(SERVICE.RESTAURANT))
          .vouchersIdGet(voucherId)
          .then((res: any) => {
            setVoucherInfo(res?.data?.data)
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        await new VoucherRestaurantControllerApi(config(SERVICE.PROMOTION))
          .findById2(voucherId)
          .then((res: any) => {
            setVoucherInfo(res?.data?.data)
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }
    if(voucherId) _fetchData()
  }, [])

  const footerTemplate = (
    <div className="mt-3">
      <button
        className=" rounded-3 border border-gray-200 px-3 py-2 text-16 font-semibold text-gray-900 shadow-sm hover:bg-gray-50"
        onClick={() => handleClose()}
      >
        {capitalizedFirst(t("my-voucher.btn-close"))}
      </button>
    </div>
  )

  const getQrData = async (qrData: string) => {
    const dataRes = await generateQrCodeWithString(qrData)
    if (dataRes) setQrDataImage(dataRes)
  }

  useEffect(() => {
    if (voucherInfo?.qr_code) getQrData(voucherInfo?.qr_code)
  }, [voucherInfo?.qr_code])

  const formattedDate = (date: Date) => {
    return moment(date).format("HH:mm, MMMM DD, YYYY")
  }

  if(!voucherInfo && qrDataImage) return null
  return (
    <Dialog
      id="buyVoucherModal"
      footer={footerTemplate}
      draggable={false}
      header={capitalizedFirst(t("my-voucher.voucher-detail"))}
      visible={isShow}
      onHide={handleClose}
      className="w-[600px]"
    >
      <div className="h-full w-full">
        {isLoading ? (
          <>
            <div className="my-3 flex h-[800px] w-full items-center justify-center gap-2 rounded-3 bg-white">
              <p className="animate-pulse duration-75">
                {capitalizedFirst(t("global.loading"))}
              </p>
              <i className="pi pi-spin pi-spinner text-24 font-bold text-blue-light-400"></i>
            </div>
          </>
        ) : type == "BOUGHT" ? (
          <>
            <div
              id="custom-top-body"
              className="relative m-auto h-[120px] w-full rounded-tl-3 rounded-tr-3 border-x border-t bg-orange-dark-50 shadow-sm "
            >
              <div className="absolute top-1/2 mx-[28px] w-[90%] bg-white">
                <VoucherView
                  {...(voucherInfo || ({} as VouchersType))}
                  isHiddenBuy={true}
                  isHiddenDetail={true}
                  type="BOUGHT"
                />
              </div>
            </div>
            <div className="h-auto rounded-bl-3 rounded-br-3 border-x border-b bg-white pb-2 shadow-sm">
              <div className="mx-4 flex flex-row gap-4">
                <div className="mt-[76px] flex flex-1 flex-col gap-4px">
                  <span className="text-14 font-medium leading-20 text-gray-400">
                    {capitalizedFirst(t("my-voucher.reward"))}
                  </span>
                  <span className="text-14 font-medium leading-20 text-gray-600">
                    {capitalizedFirst(
                      t("merchant-page.get-money-off", {
                        price: convertCurrency(voucherInfo?.discount_price),
                      })
                    )}
                  </span>
                </div>
                <div className="mt-[76px] flex flex-1 flex-col gap-4px">
                  <span className="text-14 font-medium leading-20 text-gray-400">
                    {capitalizedFirst(t("my-voucher.used-total"))}
                  </span>
                  <span className="text-14 font-medium leading-20 text-gray-600">
                    {voucherInfo?.used}/{voucherInfo?.quantity}
                  </span>
                </div>
              </div>
              <div className="mx-4 flex items-center gap-4 border-b border-dashed border-gray-200 py-4">
                <div className="flex flex-1 flex-col gap-4px">
                  <span className="text-14 font-medium leading-20 text-gray-400">
                    {capitalizedFirst(t("my-voucher.description"))}
                  </span>
                  <span
                    className="text-justify text-14 font-medium leading-20 text-gray-600"
                    dangerouslySetInnerHTML={{
                      __html: voucherInfo?.description as string,
                    }}
                  ></span>
                </div>
                <div className="flex flex-1 flex-col">
                  <span className="text-14 font-medium leading-20 text-gray-400">
                    {capitalizedFirst(t("buy-voucher.expiration"))}
                  </span>
                  <span className="text-gray-60 text-14 font-medium leading-20">
                    {capitalizedFirst(t(getExpiredDateVoucher(voucherInfo?.start_date)))} -
                    {capitalizedFirst(t(getExpiredDateVoucher(voucherInfo?.end_date)))}
                  </span>
                </div>
              </div>
              <div className="flex w-full flex-col gap-4px pt-4 text-center">
                {voucherInfo?.qr_code && qrDataImage ? (
                  voucherInfo?.used === voucherInfo?.quantity ? (
                    <div>
                      <div className="flex items-center justify-center border-b border-dashed border-gray-200 pb-4">
                        <div className="relative h-[300px] w-[300px]">
                          <img
                            loading="lazy"
                            alt="voucherQrCode"
                            className="h-full w-full rounded-[60px] opacity-10"
                            src={qrDataImage}
                          />
                          <div className="absolute left-[120px] top-[120px] z-[2] h-[60px] w-[60px] rounded-full bg-white opacity-10">
                            <img
                              loading="lazy"
                              src={paymentLogo}
                              alt={"paymentLogo"}
                              className="h-[60px] w-[60px]"
                            />
                          </div>
                          <div className="absolute top-[92px] z-[2]">
                            <img
                              src={RedeemedIcon}
                              alt="Redeemed_Voucher_Icon"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full flex-col gap-2 p-4 text-left">
                        <span className="text-16 font-semibold leading-6 text-gray-700">
                          {capitalizedFirst(t("buy-voucher.usage-history"))}
                        </span>
                        {voucherInfo?.usage_history.map(
                          (item: Date, index: number) => (
                            <span
                              key={`${index}-${new Date().getTime()}`}
                              className="text-14 font-medium leading-5 text-gray-500"
                            >
                              {capitalizedFirst(t("buy-voucher.redeemed-time"))}
                              :{" "}
                              <span className="text-gray-700">
                                {formattedDate(item)}
                              </span>
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="flex items-center justify-center">
                        <div className="relative  h-[300px] w-[300px]">
                          <img
                            loading="lazy"
                            alt="voucherQrCode"
                            className="h-full w-full rounded-[60px]"
                            src={qrDataImage}
                          />
                          <div className="absolute left-[120px] top-[120px] z-[2] h-[60px] w-[60px] rounded-full bg-white">
                            <img
                              loading="lazy"
                              src={paymentLogo}
                              alt={"paymentLogo"}
                              className="h-[60px] w-[60px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <VoucherInfo {...voucherInfo} />
        )}
      </div>
    </Dialog>
  )
}
