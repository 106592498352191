import React from "react"

export default function Loading() {
  return (
    <div className="flex h-full w-full items-center justify-center gap-2 rounded-3 bg-white">
      <p className="animate-pulse duration-75">Loading...</p>
      <i className="pi pi-spin pi-spinner text-24 font-bold text-blue-light-400"></i>
    </div>
  )
}
