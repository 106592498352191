import http from "src/config/interceptors"
export const getBanner = (bannerType: string, bannerSubType: string) => {
  return http.get("/banner/banners", { params: { bannerType, bannerSubType } })
}

export const getSystemBanner = (
  pageNum: number,
  pageSize: number,
  filterString: string,
  sortString?: string
) => {
  return http.get(
    `/banner/s-banners?pageNum=${pageNum}&pageSize=${pageSize}&filters=${filterString}&sorts=${sortString}`
  )
}
