/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react"
import { BookTableProps } from "./type"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { InputTextarea } from "primereact/inputtextarea"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import * as userService from "src/services/user-service"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { ReservationControllerApi } from "@soctrip/axios-food-order-service"
import { useToast } from "src/hooks/use-toast"
import { useFormik } from "formik"
import * as Yup from "yup"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { convertCurrency } from "src/utils/convert-currency"
import {RestaurantControllerApi} from "@soctrip/axios-restaurant-service"
import { InputSwitch } from "primereact/inputswitch"
import "./index.scss"
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export default function BookTable(props: BookTableProps) {
  const { restaurantId, visible, setVisible } = props
  const { t } = useTranslation()
  const showToast = useToast()
  const authStore = useSelector((state: RootState) => state.auth)
  const [deposit, setDeposit] = useState<number>(0)
  const [isDeposit, setIsDeposit] = useState<boolean>(true)
  const [isHaveDeposit, setIsHaveDeposit] = useState<boolean>(false)
  const dataForm = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone: "",
      time: "",
      guests: 1,
      note: "",
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required(
        capitalizedFirst(t("book-table.required-field"))
      ),
      email: Yup.string()
        .required(capitalizedFirst(t("book-table.required-field")))
        .email(capitalizedFirst(t("book-table.email-required"))),
      phone: Yup.string()
        .required(capitalizedFirst(t("book-table.required-field")))
        .min(7, capitalizedFirst(t("book-table.phone-invalid")))
        .matches(phoneRegExp, capitalizedFirst(t("book-table.phone-invalid"))),
      time: Yup.string()
        .required(capitalizedFirst(t("book-table.required-field")))
        .test(
          "isValidTime",
          capitalizedFirst(t("book-table.range-time-invalid")),
          (value) => {
            return !(
              new Date(value).getTime() - new Date().getTime() <=
              15 * 1000 * 60
            )
          }
        ),
      guests: Yup.number()
        .required(capitalizedFirst(t("book-table.required-field")))
        .min(1, capitalizedFirst(t("book-table.guests-invalid"))),
    }),
    onSubmit: () => {
      if (dataForm.isValid) {
        dataForm.resetForm()
      }
    },
    validateOnChange: true,
    validateOnMount: true,
  })
  const isInValidField = (name: string) => {
    return !!(
      dataForm?.touched[`${name}` as keyof typeof dataForm.touched] &&
      dataForm?.errors[`${name}` as keyof typeof dataForm.errors]
    )
  }

  useEffect(() => {
    if (authStore?.value?.user?.id) {
      userService
        .getUserInfo(authStore.value.user.id)
        .then((res: any) => {
          if (res?.data?.data) {
            const { full_name, email, phone } = res.data.data
            dataForm.setFieldValue("full_name", full_name)
            dataForm.setFieldValue("email", email)
            dataForm.setFieldValue("phone", phone)
          }
        })
        .catch((err) => err)
    }
  }, [authStore?.value?.user?.id])
  const handleBooking = () => {
    const resData = {
      full_name: dataForm.values.full_name,
      phone: dataForm.values.phone,
      email: dataForm.values.email,
      restaurant_id: restaurantId,
      number_guests: dataForm.values.guests,
      reservation_time: new Date(dataForm.values.time + ""),
      note: dataForm.values.note,
      success_callback: `${process.env.REACT_APP_FOOD_DOMAIN_URL}/food/booking/order`,
      fail_callback: `${process.env.REACT_APP_FOOD_DOMAIN_URL}/food/booking/order`,
      avatar_id: authStore?.value?.user?.avatar_thumbnail_url || "",
      // success_callback: "http://localhost:4200/food/booking/order",
      // fail_callback: "http://localhost:4200/food/booking/order",
      amount: deposit,
    }
    new ReservationControllerApi(config(SERVICE.FOOD_ORDER))
      .createNewOrder(resData, isDeposit)
      .then((res) => {
        if(isDeposit) {
          if (res?.data?.data?.token && res?.data?.data?.payment_url) {
            const url = `${res?.data?.data?.payment_url}?paymentToken=${res?.data?.data?.token}`
            window.open(url, "_self")
          } else {
            showToast({
              detail: capitalizedFirst(t("book-table.booking-fail")),
              severity: "error",
            })
          }
        } else {
          window.open(`${resData.success_callback}?orderId=${res.data.data?.id}`, "_self")
        }

      })
      .catch(() => {
        showToast({
          detail: capitalizedFirst(t("book-table.booking-fail")),
          severity: "error",
        })
      })
  }

  const getAppTitle = useMemo(() => {
    return process.env.REACT_APP_TITLE
  }, [])

  const getResInfo = () => {
    if (restaurantId) {
      new RestaurantControllerApi(config(SERVICE.RESTAURANT))
        .restaurantsInfoIdGet(restaurantId)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          console.log(res.data.data)
          if (res?.data?.data) {
            setIsHaveDeposit(res.data.data?.is_deposit)
            setDeposit(res.data.data?.deposit)
          } 
        })
        .catch((error) => error)
    }
  }
  useEffect(() => {
    if (restaurantId) getResInfo()
  }, [])
  const footerContent = (
    <div className="mt-2">
      <Button
        onClick={() => setVisible()}
        className="h-[44px] rounded-3 border !border-gray-300 text-14 font-semibold"
        label={capitalizedFirst(t("global.cancel"))}
        severity="secondary"
        text
      />
      <Button
        disabled={!dataForm.isValid}
        onClick={handleBooking}
        className="h-[44px] rounded-3 text-14 font-semibold"
        label={capitalizedFirst(t("global.confirm"))}
        severity="info"
      />
    </div>
  )
  return (
    <Dialog
      footer={footerContent}
      draggable={false}
      header={capitalizedFirst(t("book-table.reservation-form"))}
      visible={visible}
      onHide={() => setVisible()}
    >
      <div className="w-[85vw] md:w-[608px]">
        <div>
          <h2 className="font-semibold text-gray-900">
            {capitalizedFirst(t("book-table.user"))}
          </h2>
          <p className="mb-3 mt-[4px] text-14 text-gray-900">
            {capitalizedFirst(
              t("book-table.user-des", { getAppTitle: getAppTitle })
            )}
          </p>

          <div className="mb-3 flex flex-col gap-3">
            <div className="flex flex-col gap-[4px]">
              <label className="text-14 font-medium">
                {capitalizedFirst(t("global.fullName"))}
                <span className=" text-red-500">{" *"}</span>
              </label>
              <InputText
                className={`h-[44px] ${
                  isInValidField("full_name")
                    ? " p-invalid border border-[#e24c4c]"
                    : ""
                }`}
                name="full_name"
                value={dataForm.values.full_name}
                onChange={(e) => {
                  if (!dataForm?.touched?.full_name)
                    dataForm.setTouched({
                      ...dataForm.touched,
                      ...{ full_name: true },
                    })
                  dataForm.setFieldValue("full_name", e?.target?.value)
                }}
              />
              {dataForm.errors.full_name && dataForm.touched.full_name && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.full_name}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-3 md:flex-row">
              <div className="flex flex-1 flex-col gap-[4px]">
                <label className="text-14 font-medium">
                  {capitalizedFirst(t("global.email"))}
                  <span className=" text-red-500">{" *"}</span>
                </label>
                <InputText
                  className={`h-[44px] ${
                    isInValidField("email")
                      ? " p-invalid border border-[#e24c4c]"
                      : ""
                  }`}
                  name="email"
                  value={dataForm.values.email}
                  onChange={(e) => {
                    if (!dataForm?.touched?.email)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ email: true },
                      })
                    dataForm.setFieldValue("email", e?.target?.value)
                  }}
                />
                {dataForm.errors.email && dataForm.touched.email && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.email}
                  </span>
                )}
              </div>
              <div className="flex flex-1 flex-col gap-[4px]">
                <label className="text-14 font-medium">
                  {capitalizedFirst(t("global.phoneNumber"))}
                  <span className=" text-red-500">{" *"}</span>
                </label>
                <InputText
                  className={`h-[44px] ${
                    isInValidField("phone")
                      ? "p-invalid border border-[#e24c4c]"
                      : ""
                  }`}
                  name="phone"
                  value={dataForm.values.phone}
                  onChange={(e) => {
                    if (!dataForm?.touched?.phone)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ phone: true },
                      })
                    dataForm.setFieldValue("phone", e?.target?.value)
                  }}
                />
                {dataForm.errors.phone && dataForm.touched.phone && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.phone}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="my-3">
          <h2 className="font-semibold text-gray-900">
            {capitalizedFirst(t("book-table.reservation-info"))}
          </h2>
          <p className="mb-3 mt-[4px] text-14 text-gray-900">
            {capitalizedFirst(t("book-table.reservation-des"))}
          </p>

          <div className="flex flex-col gap-3">
            <div className=" flex flex-col gap-3 md:flex-row">
              <div className="flex flex-1 flex-col gap-[4px]">
                <label className="text-14 font-medium">
                  {capitalizedFirst(t("book-table.time"))}{" "}
                  <span className=" text-red-500">*</span>{" "}
                </label>
                <div className="relative">
                  <Calendar
                    value={dataForm.values.time}
                    onChange={(e) => {
                      dataForm.setFieldValue("time", e?.target?.value)
                    }}
                    onShow={() => {
                      if (!dataForm?.touched?.time)
                        dataForm.setTouched({
                          ...dataForm.touched,
                          ...{ time: true },
                        })
                    }}
                    id="time"
                    name="time"
                    className={`h-[44px] w-full ${
                      isInValidField("time") ? " p-invalid " : ""
                    }`}
                    showTime
                    placeholder={capitalizedFirst(t("book-table.select-time"))}
                  />
                  <div className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer">
                    <i className="pi pi-clock text-gray-900"></i>
                  </div>
                </div>
                {dataForm.errors.time && dataForm.touched.time && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {capitalizedFirst(dataForm.errors.time)}
                  </span>
                )}
              </div>
              <div className="flex flex-1 flex-col gap-[4px]">
                <label className="text-14 font-medium">
                  {capitalizedFirst(t("book-table.guests"))}
                  <span className=" text-red-500">{" *"}</span>
                </label>
                <InputText
                  placeholder={capitalizedFirst(t("book-table.enter-guests"))}
                  type="number"
                  name="guests"
                  className={`h-[44px] ${
                    isInValidField("guests")
                      ? " p-invalid border border-[#e24c4c]"
                      : ""
                  }`}
                  value={dataForm.values.guests + ""}
                  onChange={(e) => {
                    if (!dataForm?.touched?.guests)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ guests: true },
                      })
                    dataForm.setFieldValue("guests", e?.target?.value)
                  }}
                />
                {dataForm.errors.guests && dataForm.touched.guests && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.guests}
                  </span>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-[4px]">
              <label className="text-14 font-medium">
                {capitalizedFirst(t("global.note"))}
              </label>
              <InputTextarea
                rows={3}
                name="note"
                placeholder={capitalizedFirst(t("book-table.enter-note"))}
                value={dataForm.values.note}
                onChange={(e) =>
                  dataForm.setFieldValue("note", e?.target?.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="mb-[16px] flex items-start">
          <div id="customSwitch" className="flex items-center gap-1">
            <InputSwitch 
              disabled={isHaveDeposit || !deposit }
              checked={deposit ? isDeposit : false} 
              onChange={(e) => setIsDeposit(e.value as boolean)} 
            />
            <span className="leading-[20px] text-[14px] font-medium text-gray-700">
              {capitalizedFirst(t("book-table.is-deposit"))}
            </span>
          </div>
        </div>

        <div className="mb-5 flex items-start gap-3 rounded-3 bg-gray-100 px-3 py-2">
          <div className="flex flex-col gap-4px">
            <span className="font-semibold text-gray-700">
              {capitalizedFirst(t("book-table.total-deposit"))}
            </span>
            <p className="leading-5 text-gray-500">
              {capitalizedFirst(t("book-table.deposit-description"))}
            </p>
          </div>
          {deposit ? (
            <div className="text-20 font-semibold text-red-600">
              {convertCurrency(deposit)}
            </div>
          ) : null}
        </div>
      </div>
    </Dialog>
  )
}
