import React, { useEffect, useState } from "react"

import BookTable from "src/components/common/modal/book-table"
import { DataBookingConfirmType } from "src/components/common/modal/book-table/type"
import { BookingModalType, DataBookingType } from "./types"
export default function BookingModal(props: BookingModalType) {
  const { restaurantId, closeModal, isShowModalBooking } = props
  const [isBookTableComplete, setIsBookTableComplete] = useState<boolean>(false)
  const [dataBooking, setDataBooking] = useState<DataBookingType | undefined>(
    undefined
  )
  const handleConfirmBooking = (bookingValue: DataBookingConfirmType) => {
    closeModal()
    setIsBookTableComplete(true)
    setDataBooking({
      restaurantId: restaurantId,
      bookingData: bookingValue,
    })
  }
  useEffect(() => {
    if (dataBooking && !isShowModalBooking && !isBookTableComplete)
      setDataBooking(undefined)
  }, [dataBooking, isShowModalBooking, isBookTableComplete])

  return (
    <>
      {isShowModalBooking ? (
        <BookTable
          visible={isShowModalBooking}
          setVisible={closeModal}
          restaurantId={restaurantId}
          handleConfirm={handleConfirmBooking}
        />
      ) : null}
    </>
  )
}
