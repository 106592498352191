import React, { useState } from "react"
import { getBloomFilter } from "src/services/bookmark-service"
// import SponsorView from "./sponsor-view"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { FOOD_CARD_TYPE, RECOMMEND_TIME } from "src/constants/common"
import { PopularFoodType, SponsorDataType } from "./types"
import FoodCardView from "./food-card-view"
import SkeletonLoading from "../common/skeleton-loading"
import { RestaurantControllerApi } from "@soctrip/axios-restaurant-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { useLocation, useNavigate } from "react-router-dom"
import emptyImage from "src/assets/images/empty-reviews.png"

const timeOption: SponsorDataType[] = [
  {
    label: "food-home-page.morning-repast",
    code: RECOMMEND_TIME.MORNING,
    sponsorType: RECOMMEND_TIME.MORNING_REPAST,
  },
  {
    label: "food-home-page.midday-delights",
    code: RECOMMEND_TIME.MIDDAY,
    sponsorType: RECOMMEND_TIME.MIDDAY_DELIGHTS,
  },
  {
    label: "food-home-page.dinner-delights",
    code: RECOMMEND_TIME.DINNER,
    sponsorType: RECOMMEND_TIME.DINNER_DELIGHTS,
  },
]

const RecommendTime = () => {
  const navigate = useNavigate()
  const { hash } = useLocation()
  const { t } = useTranslation()
  const [bloomData, setBloomData] = useState<string>("")
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selected, setSelected] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [recommendRestaurants, setRecommendRestaurants] = React.useState<PopularFoodType[]>([])
  const [originalData, setOriginalData] = useState<PopularFoodType[]>([])

  const loadData = async () => {
    setIsLoading(true)
    setRecommendRestaurants([])

    await new RestaurantControllerApi(config(SERVICE.RESTAURANT))
      .restaurantsSellingTimeSellingTimeGet(selected?.code, 0, 10)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setIsLoading(false)
        const result: PopularFoodType[] = []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.data.data.data.forEach((i: any) => {
          result.push({
            id: i?.id,
            title: i?.name,
            imgUrl: i?.avatar?.id,
            point: i?.rating,
            countReview: i?.sum_reviews,
            is_free_wifi: i?.is_free_wifi,
            is_parking_lot: i?.is_parking_lot,
            is_operating_hours: i?.is_operating_hours,
            is_vehicle_charging: i?.is_vehicle_charging,
            address: {
              country: i?.country,
              province: i?.province,
              district: i?.district,
              ward: i?.ward,
              street: i?.street,
            },
          })
        })
        setRecommendRestaurants(result)
        if (originalData?.length === 0) {
          setOriginalData(result)
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  React.useEffect(() => {
    handleGetBloomData()
  }, [])

  React.useEffect(() => {
    const hashData = hash?.split("&")[0]?.split("=")[1]
    if (hashData === "undefined" || hashData === undefined || !hashData) {
      if (!selected) {
        setSelected(timeOption[0])
      }
    } else {
      const index = timeOption.findIndex((item) => item?.code === hashData)
      setSelected(timeOption?.[index])
    }
  }, [hash])

  React.useEffect(() => {
    loadData()
    if (selected) {
      navigate(`#type=${selected?.code}&sponsorType=${selected?.sponsorType}`)
    }
  }, [selected])

  // get bloom data
  const handleGetBloomData = async () => {
    await getBloomFilter()
      .then((response) => {
        setBloomData(response?.data?.data?.bloom_filter)
      })
      .catch((error) => error)
  }

  const handleClickViewAll = () => {
    navigate(`/food/delights?type=${selected?.code}#sponsorType=${selected?.sponsorType}`)
  }

  if (originalData?.length === 0 && recommendRestaurants?.length === 0) return null

  return (
    <div className="flex w-full flex-col gap-3 rounded-3 bg-white px-0 py-2 md:p-3">
      {/* navigate tabs */}
      <div className="flex w-full items-center justify-between">
        <div className="flex justify-center gap-1 text-center md:gap-3">
          {timeOption?.map((option, index) => (
            <div
              key={index}
              onClick={() => setSelected(option)}
              className={`cursor-pointer rounded-1 px-2 py-1 ${
                selected?.code === option?.code ? "bg-blue-50 text-blue-600" : "text-gray-500"
              }`}
            >
              <div className="text-12 font-semibold md:text-14">
                {capitalizedFirst(t(option?.label))}
              </div>
            </div>
          ))}
        </div>
        {recommendRestaurants.length ? (
          <div
            onClick={handleClickViewAll}
            className="shrink-0 cursor-pointer truncate text-12 font-semibold leading-5 text-blue-600 hover:text-blue-700 md:text-14"
          >
            {capitalizedFirst(t("global.view-all"))}
          </div>
        ) : null}
      </div>

      {/* sponsor */}
      {/* <div className="w-full">
        <SponsorView
          bloom_filter={bloomData}
          handleReload={handleGetBloomData}
        />
      </div> */}
      {/* recommend view */}
      {isLoading ? (
        <div>
          <SkeletonLoading limit={5} rows={3} />
        </div>
      ) : (
        <>
          {recommendRestaurants?.length === 0 && originalData?.length !== 0 ? (
            <div className="flex h-[200px] w-full items-center">
              <div className="flex h-full w-full flex-col">
                <div className="flex w-full items-center justify-center">
                  <div className="h-[172px] w-[172px]">
                    <img src={emptyImage} alt="" className="h-full w-full" />
                  </div>
                </div>
                <div className="flex w-full items-center justify-center">
                  <div className="flex w-full flex-col gap-1">
                    <div className="w-full text-center text-18 font-semibold leading-7 text-gray-900">
                      {capitalizedFirst(t("global.no-data-found"))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid w-full grid-cols-2 gap-4px sm:grid-cols-2 md:grid-cols-3 md:gap-3 lg:grid-cols-4 xl:grid-cols-5">
              {recommendRestaurants?.map((item) => (
                <div className="w-full" key={item?.id}>
                  <FoodCardView
                    isFoodType={false}
                    isRatingView={true}
                    isAddressView={true}
                    type={FOOD_CARD_TYPE.RESTAURANT}
                    bloom_filter={bloomData}
                    handleReload={handleGetBloomData}
                    {...item}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(RecommendTime)
