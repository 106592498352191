import React, { useMemo, useRef, useState } from "react"
import { ImgCustomProps } from "./props"
import "./index.scss"
import imageNotFound from "../../../assets/images/image-not-found.png"
import { getImageUrl } from "src/services/file-service"
import avatarDefault from "src/assets/images/avatar-default.webp"
import { IMAGE_TYPE } from "src/constants/common"

const ImgCustom = (props: ImgCustomProps) => {
  const { alt, className, url, type, isAvatar, isContainImage } = props
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const imgRef = useRef<HTMLImageElement>(null)

  const getStyleImage = useMemo(() => {
    return className ? className : ""
  }, [className])

  const handleLoadError = () => {
    if (imgRef.current?.src) {
      imgRef.current.src = isAvatar ? avatarDefault : imageNotFound
      setIsLoaded(false)
    }
  }

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div
        className={`loader rounded-[50%] border-r-[2px] border-t-[2px] border-gray-700 ${
          isLoaded ? "opacity-1 h-[20px] w-[20px]" : "hidden"
        }`}
      />
      <img
        loading="lazy"
        className={
          !isLoaded
            ? `opacity-1 w-full ${getStyleImage} h-full ${
              isContainImage ? "object-contain" : "object-cover"
            } `
            : "h-[0px] w-[0px] opacity-0"
        }
        ref={imgRef}
        alt={alt}
        src={type === IMAGE_TYPE.LOCAL ? url : getImageUrl(url, type)}
        onLoad={() => setIsLoaded(false)}
        onError={handleLoadError}
      />
    </div>
  )
}

export default ImgCustom
