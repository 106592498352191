import React, { useEffect, useMemo, useState } from "react"
// import { InputText } from "primereact/inputtext"
import { ContactFooter, contact } from "src/constants/footer-data"
// import { Button } from "primereact/button"
import footerPhoneIcon from "../../assets/images/footer_phone.svg"
import footerMailIcon from "../../assets/images/footer_email.svg"
import footerLocationIcon from "../../assets/images/footer_location.svg"
// import footerGooglePlayIcon from "../../assets/images/footer_google_play.png"
// import submitIconFooter from "../../assets/images/footer_submit_icon.svg"
// import footerAppStoreIcon from "../../assets/images/footer_app_store.png"
import footerNotificationIcon from "../../assets/images/footer_notification.png"
import footerRegistryIcon from "../../assets/images/footer_registry.png"
import { getMyRegion } from "src/services/user-service"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function FooterView() {
  const { t } = useTranslation()
  const [region, setRegion] = useState<string>("")
  const getRegion = () => {    
    getMyRegion()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          setRegion(res.data.data[0].country_code)
        }
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    getRegion()
  }, [])
  const about = useMemo(() => {
    return [
      {
        label: capitalizedFirst(t("footer.about-data.about-us")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/about-us`,
      },
      {
        label: capitalizedFirst(t("footer.about-data.condition")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/term`,
      },
      {
        label: capitalizedFirst(t("footer.about-data.privacy")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/privacy`,
      },
      {
        label: capitalizedFirst(t("footer.about-data.security")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/privacy`,
      },
      {
        label: capitalizedFirst(t("footer.about-data.regulations")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/operation-rule`,
      },
      {
        label: capitalizedFirst(t("footer.about-data.faq")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/send-us-feedback`,
      },
    ]
  }, [])
  const clickItem = (link: string) => {
    if (link) {
      window.open(link, "_blank")
    }
  }

  const booking = useMemo(() => {
    return [
      {
        label: capitalizedFirst(t("footer.booking-data.flight")),
        link: `${process.env.REACT_APP_FLIGHT_BETA_DOMAIN_URL}/booking/flight`,
      },
      {
        label: capitalizedFirst(t("footer.booking-data.hotel")),
        link: process.env.REACT_APP_HOTEL_BETA_DOMAIN_URL,
      },
      {
        label: capitalizedFirst(t("footer.booking-data.car")),
        link: `${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}/booking/car`,
      },
      {
        label: capitalizedFirst(t("footer.booking-data.tour")),
        link: process.env.REACT_APP_TOUR_BETA_DOMAIN_URL,
      },
      {
        label: capitalizedFirst(t("footer.booking-data.restaurant")),
        link: process.env.REACT_APP_FOOD_DOMAIN_URL,
      },
      {
        label: capitalizedFirst(t("footer.booking-data.shop")),
        link: process.env.REACT_APP_ECOMMERCE_DOMAIN_URL,
      },
    ]
  }, [])
  const otherServices = useMemo(() => {
    return [
      {
        label: capitalizedFirst(t("footer.order-data.partner")),
        link: `${process.env.REACT_APP_FOOD_DOMAIN_URL}/food/request-open-restaurant`,
      },
      {
        label: capitalizedFirst(t("footer.order-data.program")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/about-us`,
      },
      {
        label: capitalizedFirst(t("footer.order-data.feedback")),
        link: `${process.env.REACT_APP_HOME_DOMAIN_URL}/help-center/send-us-feedback`,
      },
    ]
  }, [])

  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-[1200px] gap-4 py-[60px] px-3">
        <div className="mx-auto flex  flex-col justify-between gap-4 pb-4 lg:flex-row">
          <div className="flex w-full flex-wrap justify-between gap-3">
            {/* ABOUT */}
            <div className="flex-1">
              <h3 className="mb-1 font-semibold lg:mb-3">
                {capitalizedFirst(t("footer.about"))}
              </h3>
              <ul className="flex flex-col gap-1 lg:gap-3">
                {about.map((item) => (
                  <li
                    className="cursor-pointer hover:underline"
                    onClick={() => clickItem(item.link)}
                    key={item.label}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
            {/* BOOKING */}
            <div className="flex-1">
              <h3 className="mb-1 font-semibold lg:mb-3">
                {capitalizedFirst(t("footer.booking"))}
              </h3>
              <ul className="flex flex-col gap-1 lg:gap-3">
                {booking.map((item) => (
                  <li
                    className="cursor-pointer hover:underline"
                    onClick={() => clickItem(item.link as string)}
                    key={item.label}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
            {/* other */}
            <div className="flex-1 ">
              <h3 className="mb-1 font-semibold lg:mb-3">
                {capitalizedFirst(t("footer.order-service"))}
              </h3>
              <ul className="flex flex-col gap-1 lg:gap-3">
                {otherServices.map((item) => (
                  <li
                    className="cursor-pointer hover:underline"
                    onClick={() => clickItem(item.link as string)}
                    key={item.label}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>

            {/* SUBSCRIPTION */}
            {/* <div className="hidden flex-1 md:block"> */}
            {/* <div>
                <h3 className="font-semibold">{capitalizedFirst(t("footer.subscription"))}</h3>
                <p className="mb-3">{capitalizedFirst(t("footer.subscription-info"))}</p>
              </div>
              <div className="mb-3 flex w-full flex-col gap-2 sm:flex-row">
                <InputText placeholder="Your email" />
                <Button
                  className="rounded-3 bg-blue-600"
                  // label="Subscribe"
                  // title="Subscribe"
                  icon={<img src={submitIconFooter} />}
                />
              </div>
              <div className="mb-3 flex gap-2">
                <img src={footerGooglePlayIcon} alt="Google Play" />
                <img src={footerAppStoreIcon} alt="App Store" />
              </div> */}
            {/* {region === "VN" ? (
                <div className="flex gap-2">
                  <img src={footerNotificationIcon} alt="Footer Notification" />
                  <img src={footerRegistryIcon} alt="Footer Registry" />
                </div>
              ) : null}
            </div> */}
          </div>
        </div>
        {/* CONTACT */}
        <div className="flex flex-wrap items-start justify-between gap-1 border-t-[3px] border-dashed pt-4 pb-3 lg:gap-3">
          {contact?.map((item: ContactFooter) => {
            return (
              <div className="flex flex-1 flex-col gap-1" key={item.origin}>
                <h3 className="font-semibold">{item.origin}</h3>
                <div className="flex flex-col gap-1">
                  <div className="flex items-center gap-1">
                    <span className="min-w-[20px]">
                      <img src={footerPhoneIcon} alt="Footer Phone" />
                    </span>

                    <span
                      onClick={() => window.open(`tel:${item.phone}`, "_self")}
                      className="cursor-pointer text-[14px] hover:underline"
                    >
                      {item.phone}
                    </span>
                  </div>

                  <div className="flex items-center gap-1">
                    <span className="min-w-[20px]">
                      <img src={footerMailIcon} alt="Footer Phone" />
                    </span>

                    <span
                      onClick={() =>
                        window.open(`mailto:${item.mail}`, "_self")
                      }
                      className="cursor-pointer text-[14px] hover:underline"
                    >
                      {item.mail}
                    </span>
                  </div>
                  <div className="flex items-start gap-1 ">
                    <span className="min-w-[20px]">
                      <img
                        className="h-[20px] w-[20px]"
                        src={footerLocationIcon}
                        alt="Footer Phone"
                      />
                    </span>
                    <span className="text-[14px]">{item.address}</span>
                  </div>
                </div>
              </div>
            )
          })}
          <div className="hidden flex-1 gap-3 lg:flex">
            <span className="text-nowrap text-[14px]">
              {capitalizedFirst(
                t("footer.all-right", { name: process.env.REACT_APP_TITLE })
              )}
            </span>
          </div>
        </div>
        {/* SUBSCRIPTION */}
        <div className=" flex-1  border-t-[3px] border-dashed pt-2 md:hidden">
          {/* <div>
            <h3 className="font-semibold">{capitalizedFirst(t("footer.subscription"))}</h3>
            <p className="mb-3">{capitalizedFirst(t("footer.subscription-info"))}</p>
          </div>
          <div className="mb-3 flex w-full flex-col gap-2 sm:flex-row">
            <InputText placeholder="Your email" />
            <Button
              className="rounded-3 bg-blue-600"
              label="Subscribe"
              title="Subscribe"
            />
          </div>
          <div className="mb-3 flex w-full items-center justify-center gap-2">
            <img src={footerGooglePlayIcon} alt="Google Play" />
            <img src={footerAppStoreIcon} alt="App Store" />
          </div> */}
          {region === "VN" ? (
            <div className="flex w-full items-center justify-center gap-2">
              <img src={footerNotificationIcon} alt="Footer Notification" />
              <img src={footerRegistryIcon} alt="Footer Registry" />
            </div>
          ) : null}
        </div>
        <div className="flex w-full flex-1 justify-center gap-3 pt-4 lg:hidden">
          <span className="text-nowrap text-[14px]">
            {capitalizedFirst(
              t("footer.all-right", { name: process.env.REACT_APP_TITLE })
            )}
          </span>
        </div>
      </div>
    </footer>
  )
}
