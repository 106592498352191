import React, { useEffect, useState } from "react"
// import { addressService } from "src/services/address-service"
import { AddressListProps } from "./props"
import Edit from "../../../assets/images/edit-address-icon.svg"
import Trash from "../../../assets/images/trash-icon.svg"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { ConfirmModal } from "src/components/common/modal"
import { useToast } from "src/hooks/use-toast"
import AddressCreateView from "../address-create-view"
import AddressUpdateView from "../address-update-view"
import * as orderService from "@soctrip/order-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"

export default function AddressListView() {
  const [addressList, setAddressList] = useState([])
  const [visibleCreate, setVisibleCreate] = useState(false)

  const [editAddressModal, setEditAddressModal] = useState<{
    isShow: boolean
    addressId: string
  }>({ isShow: false, addressId: "" })
  const { t } = useTranslation()
  const showToast = useToast()

  const [deleteAddressModal, setDeleteAddressModal] = useState<{
    isShow: boolean
    addressId: string
  }>({ isShow: false, addressId: "" })

  const addressService = new orderService.AddressControllerApi(
    config(SERVICE.ORDER)
  )

  const getAddressList = async () => {
    await addressService
      .getAddressList()
      .then((res) => {
        if (res?.data?.data) {
          setAddressList(res?.data?.data)
        }
      })
      .catch((err) => err)
  }

  const onHandleCreate = async () => {
    await getAddressList()
    setVisibleCreate(false)
  }

  const handleConfirmUpdate = async () => {
    await getAddressList()
    setEditAddressModal({ isShow: false, addressId: "" })
  }
  const handleDeleteAddress = async () => {
    try {
      setDeleteAddressModal({ isShow: false, addressId: "" })
      await addressService.deleteAddressById(deleteAddressModal.addressId)
      await getAddressList()
      showToast({ detail: capitalizedFirst(t("modal.delete-address-successfully")), severity: "success" })
    } catch (error) {
      showToast({ detail: capitalizedFirst(t("modal.you-cannot-delete-default-address")), severity: "warn" })
    }
  }
  useEffect(() => {
    function initView() {
      getAddressList()
    }
    initView()
  }, [])
  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex flex-row items-center justify-between rounded-3 bg-white p-2">
        <div>
          <span className="text-18 font-semibold leading-7 text-gray-800">
            {capitalizedFirst(t("food-address-page.address-list"))}
          </span>
        </div>

        <button
          className="flex items-center justify-center gap-1 rounded-3 bg-blue-600 px-3 py-1 font-medium text-white transition hover:bg-blue-700"
          onClick={() => setVisibleCreate(true)}
        >
          <i className="pi pi-plus"></i>
          <span className="text-16 font-normal leading-5">
            {capitalizedFirst(t("food-address-page.add-address"))}
          </span>
        </button>
      </div>
      <div className="w-full rounded-3 bg-white p-2 ">
        <div className="flex w-full  flex-col gap-3 self-stretch">
          {addressList.map((item: AddressListProps, index) => (
            <div
              key={index}
              className="flex flex-row justify-between gap-1 rounded-3 border border-gray-300 bg-white p-3 shadow-sm shadow-gray-50"
            >
              <div className="flex flex-1 flex-col gap-1 w-full truncate">
                <div className="flex  flex-row gap-1">
                  <h1 className="truncate text-16 font-medium leading-6 text-gray-700">
                    {item.user_full_name}
                  </h1>
                  <div className=" flex cursor-pointer items-center rounded-5 bg-orange-dark-50 px-1 py-2px">
                    <span className="text-12 font-medium leading-18 text-orange-dark-700">
                      {capitalizedFirst(t(`global.${item.address_type}`))}
                    </span>
                  </div>
                  {item.is_default ? (
                    <div className="flex cursor-pointer items-center rounded-5 bg-blue-50 px-1 py-2px">
                      <span className="text-12 font-medium leading-18 text-blue-700 ">
                        {capitalizedFirst(t("global.default"))}
                      </span>
                    </div>
                  ) : null}
                </div>
                <span className="text-14 font-normal leading-5 text-gray-500">
                  {item.user_phone_number}
                </span>
                <span className="truncate text-14 font-normal leading-5 text-gray-500">
                  {`${[item.address, item.ward, item.district, item.city]
                    .filter((i) => i)
                    .join(", ")}`}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <div
                  className="flex cursor-pointer items-center justify-center gap-1 p-6px hover:rounded-2 hover:bg-gray-50"
                  onClick={() =>
                    setEditAddressModal({ addressId: item.id, isShow: true })
                  }
                >
                  <img src={Edit} className="h-[16px] w-[16px]" alt="edit" />
                </div>

                <div
                  className="flex cursor-pointer items-center justify-center gap-1 p-6px hover:rounded-2 hover:bg-gray-50"
                  onClick={() =>
                    setDeleteAddressModal({
                      isShow: true,
                      addressId: item.id,
                    })
                  }
                >
                  <img src={Trash} className="h-[16px] w-[16px]" alt="trash" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {editAddressModal.isShow && (
        <AddressUpdateView
          isShow={editAddressModal.isShow}
          addressId={editAddressModal.addressId}
          handleClose={() =>
            setEditAddressModal({ isShow: false, addressId: "" })
          }
          handleConfirm={handleConfirmUpdate}
        />
      )}
      <ConfirmModal
        title={t("checkoutPage.delete-address")}
        message={t("checkoutPage.are-you-sure-you-want-to-delete-this-address")}
        isShow={deleteAddressModal.isShow}
        handleClose={() =>
          setDeleteAddressModal({ isShow: false, addressId: "" })
        }
        handleConfirm={handleDeleteAddress}
      />
      {visibleCreate && (
        <AddressCreateView
          isShow={visibleCreate}
          handleClose={()=>setVisibleCreate(false)}
          handleConfirm={onHandleCreate}
        />
      )}
    </div>
  )
}
