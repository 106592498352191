import React from "react"
import Star from "../../../assets/images/star-icon.svg"

export default function RenderStar(props: { total: number }) {
  const objectStar = [
    {
      name: "star",
    },
    {
      name: "star",
    },
    {
      name: "star",
    },
    {
      name: "star",
    },
    {
      name: "star",
    },
    {
      name: "star",
    },
  ]
  return (
    <div className="flex w-full items-center gap-1">
      {objectStar.slice(0, props?.total).map((data, index) => (
        <div key={index} className="flex items-center gap-1">
          <img src={Star} alt="star" className="h-[20px] w-[20px]" />
        </div>
      ))}
    </div>
  )
}
