import React, { useEffect, useRef, useState } from "react"
import "./food-detail-gallery.scss"

import { Swiper, SwiperSlide } from "swiper/react"
import { Swiper as SwiperType } from "swiper"
import { Navigation } from "swiper/modules"
import { ImageType } from "src/types/common"
import { ImgCustom } from "src/components/common"
import { TYPELINK } from "src/constants/common"
import imageNotFound from "src/assets/images/image-not-found.png"

const configThumbnail = (idVideo: string) => {
  return `${process.env.REACT_APP_API_URL}/storage-streaming/files/thumbnail/${idVideo}.webp`
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ImageThumbnail = (props: any) => {
  const { image } = props
  const [thumbnailData, setThumbnailData] = useState<string>("")

  useEffect(() => {
    const fetchThumbnail = async () => {
      const parts = image?.id.split("/")
      const fileNameWithExtension = parts[parts.length - 1]
      const fileNameWithoutExtension = fileNameWithExtension.split(".")[0]

      const thumbnailURL = configThumbnail(fileNameWithoutExtension)
      setThumbnailData(thumbnailURL)
    }

    fetchThumbnail()
  }, [image])

  return (
    <img
      src={thumbnailData}
      alt="thumbnailData"
      className="h-full w-full object-cover"
    />
  )
}

export default function FoodDetailGallery({ images }: { images: ImageType[] }) {
  const swiperRef = useRef<SwiperType>()
  const [activeIndex, setActiveIndex] = useState(0)

  const handlePrevImage = () => {
    swiperRef.current?.slidePrev()
    if (activeIndex > 0) setActiveIndex((index) => index - 1)
  }

  const handleNextImage = () => {
    swiperRef.current?.slideNext()
    if (activeIndex < images?.length - 1) setActiveIndex((index) => index + 1)
  }

  const typeDataImageSmallType = (image: ImageType) => {
    if (image.type === TYPELINK.LINK) {
      const isLinkYoutube = image?.id?.includes(TYPELINK.DOMAIN_URL_YOUTUBE)
      if (isLinkYoutube) {
        return (
          <img
            src={`https://img.youtube.com/vi/${
              image?.id?.split("/").slice(-1)[0]
            }/maxresdefault.jpg`}
            alt=""
            className="h-full w-full"
          />
        )
      } else {
        return <ImageThumbnail image={image} />
      }
    } else return <ImgCustom url={image.id} alt="" />
  }
  const typeDataImage = () => {
    if (images[activeIndex].type === TYPELINK.LINK) {
      if (images[activeIndex]?.id?.includes(TYPELINK.DOMAIN_URL_YOUTUBE)) {
        return (
          <iframe
            src={`${images[activeIndex]?.id}?autoplay=${1}`}
            allow="autoplay"
            allowFullScreen
            className="!h-[660px] max-h-[660px] w-full "
          ></iframe>
        )
      } else
        return (
          <video
            src={images[activeIndex].id}
            autoPlay
            muted
            className="!h-[660px] max-h-[660px] w-full object-cover"
          ></video>
        )
    } else
      return (
        <ImgCustom
          isContainImage={true}
          className="h-auto"
          url={images[activeIndex]?.id}
          alt=""
          type="WEB"
        />
      )
  }
  if (!images.length)
    return (
      <div className="flex w-full flex-col items-center gap-2">
        <img
          src={imageNotFound}
          alt="imageNotFound"
          className="h-full w-full object-cover "
        />
      </div>
    )
  return (
    <div className="flex h-full w-full flex-col items-center gap-2">
      <div className="w-full flex-1 overflow-hidden rounded-3">
        {typeDataImage()}
      </div>
      <div className="relative h-[80px] w-full">
        <Swiper
          onBeforeInit={(swiper) => (swiperRef.current = swiper)}
          direction={"horizontal"}
          modules={[Navigation]}
          navigation={false}
          slidesPerView={5}
          spaceBetween={16}
          breakpoints={{
            480: {
              slidesPerView: 4,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 8,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 8,
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 8,
            },
          }}
          className="box-border h-full w-full"
        >
          {images?.map((image, index) => (
            <SwiperSlide key={index}>
              <div
                onClick={() => setActiveIndex(index)}
                className={`${
                  activeIndex === index
                    ? "border-2 border-red-400"
                    : "border-1 border-transparent brightness-[70%] transition-all"
                }  h-[80px] w-[80px] cursor-pointer overflow-hidden rounded-2 hover:border-red-400 hover:brightness-100`}
              >
                {typeDataImageSmallType(image)}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex flex-col">
          <button
            onClick={handlePrevImage}
            className={`${
              activeIndex === 0 && "cursor-default text-gray-300"
            } absolute left-0 top-1/2 z-10 flex h-5 w-5 -translate-y-1/2 translate-x-[-50%] rotate-[270deg] items-center justify-center rounded-full border bg-white shadow lg:-rotate-90`}
          >
            <i className="pi pi-angle-up"></i>
          </button>
          <button
            onClick={handleNextImage}
            className={`${
              activeIndex === images?.length - 1 &&
              "cursor-default text-gray-300"
            } absolute right-0 top-1/2 z-10 flex h-5 w-5 -translate-y-1/2 rotate-[270deg] items-center justify-center rounded-full border bg-white shadow lg:-rotate-90`}
          >
            <i className="pi pi-angle-down "></i>
          </button>
        </div>
      </div>
    </div>
  )
}
