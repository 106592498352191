import React from "react"
import { Dialog } from "primereact/dialog"
import { ConfirmModalProps } from "./props"
import { capitalizedFirst } from "../../../../utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import "./index.scss"
export default function ConfirmModal(props: ConfirmModalProps) {
  const { title, message, isShow, handleClose, handleConfirm } = props
  const { t } = useTranslation()
  const footerContent = (
    <div
      className="flex items-center justify-end gap-2 font-semibold"
      id="dialogFooter"
    >
      <button
        onClick={() => handleClose()}
        className="rounded-3 bg-gray-100 px-3 py-1 text-gray-800 hover:bg-gray-200"
      >
        {capitalizedFirst(t("global.no"))}
      </button>
      <button
        onClick={() => handleConfirm()}
        className="rounded-3 bg-blue-500 px-3 py-1 text-white hover:bg-blue-600"
        autoFocus
      >
        {capitalizedFirst(t("global.yes"))}
      </button>
    </div>
  )
  const headerCustom = (
    <div className="flex text-[20px] font-semibold text-gray-800">
      <span>{title}</span>
    </div>
  )
  return (
    <Dialog
      header={headerCustom}
      visible={isShow}
      style={{ width: "400px" }}
      onHide={() => handleClose()}
      footer={footerContent}
    >
      <div className="flex items-center justify-center">
        <span>{message}</span>
      </div>
    </Dialog>
  )
}
