import React from "react"
import RestaurantView from "src/components/restaurant-view"

export default function Restaurant() {
  return (
    <div className="mx-auto mt-80px h-full max-w-[1200px] py-3 md:mt-4">
      <RestaurantView />
    </div>
  )
}
