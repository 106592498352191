import React, { useEffect, useMemo, useState } from "react"
import confirmedIcon from "src/assets/images/confirmed-icon.svg"
import { ReservationControllerApi } from "@soctrip/axios-food-order-service"
import { useNavigate, useParams } from "react-router-dom"
import { convertTimeCommon } from "src/utils/time-utils"
import { generateQrCodeWithString } from "src/utils/qr-code-utils"
import paymentLogo from "src/assets/images/payment-logo.svg"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { ReservationType } from "./type"
import EmptyPage from "src/components/common/empty"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"

export default function ReservationConfirmed() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [reservationData, setReservationData] = useState<ReservationType>()
  const [qrDataImage, setQrDataImage] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(true)
  const authStore = useSelector((state: RootState) => state.auth)

  const handleGetReservationData = async () => {
    await new ReservationControllerApi(config(SERVICE.FOOD_ORDER))
      .findById(id as string)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setReservationData(response?.data?.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (id) {
      handleGetReservationData()
    }
  }, [id])

  const getQrData = async (qrData: string) => {
    const dataRes = await generateQrCodeWithString(qrData)
    if (dataRes) setQrDataImage(dataRes)
  }

  useMemo(() => {
    if (reservationData) getQrData(reservationData?.qr_code as string)
  }, [reservationData])

  const handleGoToMyReservation = () => {
    navigate("/food/orders")
  }

  const handleBackToHome = () => {
    navigate("/food")
  }

  return (
    <div className="mx-auto flex max-w-[1200px] justify-center px-2 py-3 xl:px-0">
      {loading ? (
        <div className="flex h-[500px] w-full items-center justify-center">
          <div className="flex gap-2">
            <div className="animate-pulse">Loading...</div>
            <i
              className="pi pi-spin pi-spinner text-blue-700"
              style={{ fontSize: "1.5rem" }}
            ></i>
          </div>
        </div>
      ) : (
        <>
          {reservationData ? (
            <div className="w-[792px] rounded-4 bg-white">
              <div className="flex h-full w-full flex-col">
                {/* message view */}
                <div className="flex w-full flex-col p-4">
                  {/* icon */}
                  <div className="flex w-full items-center justify-center">
                    <div className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-emerald-100">
                      <img src={confirmedIcon} alt="icon" className="h-4 w-4" />
                    </div>
                  </div>
                  {/* message */}
                  <div className="flex w-full flex-col gap-1">
                    <div className="flex w-full items-center justify-center text-center md:text-left">
                      <div className="text-[30px] font-semibold text-gray-900">
                        {capitalizedFirst(t("global.your-table-is-reserved"))}
                      </div>
                    </div>
                    <div className="flex w-full items-center justify-center">
                      <div className="text-14 font-normal text-gray-500">
                        {capitalizedFirst(t("global.reservation-message"))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Information */}
                <div className="flex h-full w-full flex-col gap-4 p-4">
                  {/* reservation id */}
                  <div className="flex w-full flex-col gap-4px rounded-3 bg-blue-50 px-2 py-1">
                    <div className="flex w-full items-center">
                      <div className="text-12 font-normal text-gray-700">
                        {capitalizedFirst(t("reservation.reservation-id"))}
                      </div>
                    </div>
                    <div className="flex w-full items-center">
                      <div className="text-14 font-semibold leading-5 text-blue-600">
                        {reservationData?.code}
                      </div>
                    </div>
                  </div>
                  {/* reservation information */}
                  <div className="flex w-full flex-col gap-3">
                    <div className="flex w-full items-center">
                      <div className="text-16 font-semibold leading-6 text-gray-900">
                        {capitalizedFirst(t("book-table.reservation-info"))}
                      </div>
                    </div>
                    <div className="flex w-full flex-col gap-3">
                      {/* restaurant information */}
                      <div className="flex w-full flex-col gap-4px">
                        <div className="flex w-full items-center">
                          <div className="text-14 font-semibold leading-5 text-gray-700">
                            {reservationData?.restaurant?.name}
                          </div>
                        </div>
                        <div className="flex w-full items-center">
                          <div className="text-14 font-medium text-gray-500">
                            {reservationData?.restaurant?.address}
                          </div>
                        </div>
                      </div>
                      {/* Reservation info */}
                      <div className="flex w-full items-center justify-between gap-3">
                        {/* time */}
                        <div className="flex w-full flex-col gap-4px">
                          <div className="flex items-center">
                            <div className="text-14 font-normal leading-5 text-gray-500">
                              {capitalizedFirst(t("global.time"))}
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-16 font-medium leading-6 text-gray-900">
                              {convertTimeCommon(
                                reservationData?.reservation_time as string, true
                              )}
                            </div>
                          </div>
                        </div>
                        {/* guest */}
                        <div className="flex w-full flex-col gap-4px">
                          <div className="flex items-center justify-start">
                            <div className="text-14 font-normal leading-5 text-gray-500">
                              {capitalizedFirst(t("global.guest"))}
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-16 font-medium leading-6 text-gray-900">
                              {reservationData?.number_guests}{" "}
                              {t("global.guests")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* QR code */}
                  <div className="flex w-full items-center justify-center">
                    {reservationData?.qr_code && qrDataImage ? (
                      <div className="mt-3 flex items-center justify-center">
                        <div className="relative h-[300px] w-[300px]">
                          <img
                            alt="voucherQrCode"
                            className="h-full w-full"
                            src={qrDataImage}
                          />
                          <div className="absolute left-[120px] top-[120px] z-[2] h-[60px] w-[60px] rounded-full bg-white">
                            <img
                              src={paymentLogo}
                              alt={"paymentLogo"}
                              className="h-[60px] w-[60px]"
                            />
                          </div>
                        </div>
                        <span></span>
                      </div>
                    ) : null}
                  </div>
                  {/* Guest information */}
                  <div className="flex w-full flex-col gap-3">
                    <div className="flex w-full items-center">
                      <div className="text-16 font-semibold leading-6 text-gray-900">
                        {capitalizedFirst(t("global.guest-information"))}
                      </div>
                    </div>
                    <div className="flex w-full flex-col items-center justify-between gap-2 rounded-2 border border-gray-200 p-2 md:flex-row">
                      {/* name */}
                      <div className="flex w-full flex-col gap-4px">
                        <div className="flex w-full items-center">
                          <div className="text-12 font-normal text-gray-500">
                            {capitalizedFirst(t("global.guest-name"))}
                          </div>
                        </div>
                        <div className="flex w-full items-center">
                          <div className="text-14 font-normal leading-5 text-gray-900">
                            {reservationData?.customer?.full_name}
                          </div>
                        </div>
                      </div>
                      {/* phone number */}
                      <div className="flex w-full flex-col gap-4px">
                        <div className="flex w-full items-center">
                          <div className="text-12 font-normal text-gray-500">
                            {capitalizedFirst(t("global.phoneNumber"))}
                          </div>
                        </div>
                        <div className="flex w-full items-center">
                          <div className="text-14 font-normal leading-5 text-gray-900">
                            {reservationData?.customer?.phone}
                          </div>
                        </div>
                      </div>
                      {/* Email */}
                      <div className="flex w-full flex-col gap-4px">
                        <div className="flex w-full items-center">
                          <div className="text-12 font-normal text-gray-500">
                            {capitalizedFirst(t("global.email"))}
                          </div>
                        </div>
                        <div className="flex w-full items-center">
                          <div className="text-14 font-normal leading-5 text-gray-900">
                            {reservationData?.customer?.email}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* footers */}
                <div className="flex w-full items-center justify-end gap-2 px-4 pb-4">
                  {authStore?.value ? (
                    <div
                      onClick={handleGoToMyReservation}
                      className="flex cursor-pointer items-center justify-center rounded-3 border border-gray-300 px-20px py-1"
                    >
                      <div className="text-14 font-semibold text-gray-900">
                        {capitalizedFirst(t("purchase-history.my-reservation"))}
                      </div>
                    </div>
                  ) : null}

                  <div
                    onClick={handleBackToHome}
                    className="flex cursor-pointer items-center justify-center rounded-3 border border-blue-600 bg-blue-600 px-20px py-1"
                  >
                    <div className="text-14 font-semibold text-white">
                      {capitalizedFirst(t("global.back-to-home"))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex h-[500px] w-full items-center justify-center">
              <EmptyPage />
            </div>
          )}
        </>
      )}
    </div>
  )
}
