import React from "react"
import {
  BannerView,
  PopularFoodView,
  ListFoodView,
  RecommendView,
  PopularLocationView,
  BookingAgainView,
  RecommendTime,
  HotDealView,
} from "../../components/food-home-view"
import { RecommendViewType } from "src/components/food-home-view/types"
import { handlePromiseAll } from "src/utils/common"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { defaultSearchLocationValue, updateSearchLocation } from "src/features/search-location"
import { FoodControllerApi } from "@soctrip/axios-food-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"

const Food = () => {
  const dispatch = useDispatch()
  const searchLocationStore = useSelector((state: RootState) => state.searchLocation)

  const [recommendData, setRecommendData] = React.useState<RecommendViewType[]>([])

  const getListRecommend = () => {
    return new FoodControllerApi(config(SERVICE.FOOD))
      .foodsSearchGet(
        0,
        10,
        "",
        "",
        searchLocationStore.value?.latitude,
        searchLocationStore.value?.longitude,
        searchLocationStore.radius
      )
      .then((res) => {
        const result: RecommendViewType[] = []
        if (res?.data?.data?.data?.length) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.data.forEach((i: any) => {
            result.push({
              id: i?.id,
              title: i?.name,
              imgUrl: i?.avatar?.id,
              point: i?.point,
              countReview: i?.sum_review,
              address: i?.restaurant_food?.name,
              price: i?.price,
              originPrice: i?.original_price,
              restaurant_food: i?.restaurant_food,
            })
          })
        }
        setRecommendData(result)
      })
      .catch((err) => err)
  }
  const loadDataView = () => {
    const apis = []
    apis.push(getListRecommend())
    handlePromiseAll(apis)
      .then(() => {
        console.log("complete")
      })
      .catch((err) => err)
  }

  React.useEffect(() => {
    loadDataView()
    return () => {
      if (searchLocationStore?.value) {
        dispatch(updateSearchLocation(defaultSearchLocationValue))
      }
    }
  }, [])

  React.useEffect(() => {
    if (searchLocationStore?.value) loadDataView()
  }, [searchLocationStore])

  return (
    <div className="my-2 flex flex-col gap-4">
      <PopularFoodView />
      <BookingAgainView />
      <ListFoodView />
      <BannerView />
      <RecommendTime />
      <RecommendView
        recommendData={recommendData}
        city={searchLocationStore?.value?.province_name || ""}
      />
      <PopularLocationView
        cityId={searchLocationStore?.value?.province_id || ""}
        radius={searchLocationStore?.radius}
      />
      <HotDealView />
    </div>
  )
}

export default Food
