/* eslint-disable indent */
import React, { useMemo } from "react"
import { ImgCustom } from "../common"
import locationIcon from "../../assets/images/location-icon.svg"
import { handleCheckBloomFilter } from "src/utils/common"
import {
  RatingViewType,
  AddressViewType,
  TypeFoodViewType,
  PriceFoodViewType,
  FoodCardViewProps,
} from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { bookmarkProduct, deleteBookmark } from "src/services/bookmark-service"
import { FOOD_BOOKMARK_CATEGORY, FOOD_CARD_TYPE } from "src/constants/common"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { useToast } from "src/hooks/use-toast"
import wifiFree from "../../assets/images/wifi.svg"
import parkingLot from "../../assets/images/parking-lot.svg"
import hour from "../../assets/images/hour.svg"
import charging from "../../assets/images/charging.svg"
import { Tooltip } from "primereact/tooltip"
import { handleCountViewBoth } from "src/services/count-view-service"
import { convertCurrency } from "src/utils/convert-currency"

const RatingView = (props: RatingViewType) => {
  const { point, countReview } = props
  const { t } = useTranslation()
  return (
    <div className="flex items-center gap-4px text-14 leading-20 text-gray-700">
      <div className="flex items-center justify-center">
        <span>{point}</span>
        <div className="flex h-3 w-3 items-center justify-center">
          <i className="pi pi-star-fill text-12 text-yellow-400" />
        </div>
      </div>
      <i className="pi pi-circle-fill mx-4px text-[4px]" />
      <span className="line-clamp-1 font-normal">
        {capitalizedFirst(t("food-home-page.count-reviews", { count: countReview }))}
      </span>
    </div>
  )
}
const AddressView = (props: AddressViewType) => {
  const { address, sortAddress, type } = props

  return (
    <div className="flex items-center gap-4px text-14 leading-20 text-gray-700">
      <div className="flex h-3 w-3 items-center justify-center">
        <img className="h-12px w-[12px]" src={locationIcon} alt="locationIcon" />
      </div>

      {type === FOOD_CARD_TYPE.RESTAURANT ? (
        <>
          <Tooltip target=".popular-location" />
          <span
            className="popular-location line-clamp-1"
            data-pr-tooltip={address}
            data-pr-position="top"
            data-pr-at="right-2 top"
          >
            {sortAddress}
          </span>
        </>
      ) : (
        <span className="line-clamp-1 text-12 font-normal text-gray-700">{sortAddress}</span>
      )}
    </div>
  )
}
const TypeFoodView = (props: TypeFoodViewType) => {
  const { typeFood } = props
  return (
    <div className="flex min-h-[24px] items-center gap-1 text-14 font-medium leading-20 ">
      {typeFood?.slice(0, 2)?.map((i, index) => (
        <div
          key={index}
          className={`flex w-max items-center justify-center rounded-5 bg-indigo-50 px-10px py-2px text-indigo-700  ${index}`}
        >
          {i}
        </div>
      ))}
    </div>
  )
}
const PriceFoodView = (props: PriceFoodViewType) => {
  const { price, originPrice } = props
  return (
    <div className="leading-14 flex items-center gap-6px text-12">
      <span className="text-14 font-semibold leading-20 text-orange-dark-500">
        {convertCurrency(price)}
      </span>
      {originPrice !== price ? (
        <span className=" font-normal leading-24 text-gray-600 line-through">
          {convertCurrency(originPrice)}
        </span>
      ) : null}
    </div>
  )
}

const FoodCardView = (props: FoodCardViewProps) => {
  const {
    id,
    title,
    imgUrl,
    isRatingView,
    point,
    countReview,
    isAddressView,
    address,
    isShowTypeFood,
    typeFood,
    isShowDiscount,
    discount,
    isShowPrice,
    price,
    originPrice,
    isFoodType,
    bloom_filter,
    type,
    handleReload,
    is_free_wifi,
    is_operating_hours,
    is_parking_lot,
    is_vehicle_charging,
    isClickShowMap,
    handleShowMap,
    restaurant_food,
  } = props

  const authStore = useSelector((state: RootState) => state.auth)
  const showToast = useToast()

  const handleGetPath = () => {
    if (isFoodType) {
      return `${process.env.REACT_APP_FOOD_DOMAIN_URL}/food/${id}`
    } else {
      return `${process.env.REACT_APP_FOOD_DOMAIN_URL}/food/restaurant/${id}#ALL_FOOD`
    }
  }

  const handleBookmark = async () => {
    if (authStore?.value) {
      if (bloom_filter) {
        if (!handleCheckBloomFilter(bloom_filter as string, id as string)) {
          if (type === FOOD_CARD_TYPE.RESTAURANT) {
            bookmark(FOOD_BOOKMARK_CATEGORY.RESTAURANT)
          } else {
            bookmark(FOOD_BOOKMARK_CATEGORY.FOOD)
          }
        } else {
          deleteBookmark(id)
            .then(() => {
              if (handleReload) {
                handleReload()
              }
            })
            .catch((error) => {
              error
            })
        }
      } else {
        if (type === FOOD_CARD_TYPE.RESTAURANT) {
          bookmark(FOOD_BOOKMARK_CATEGORY.RESTAURANT)
        } else {
          bookmark(FOOD_BOOKMARK_CATEGORY.FOOD)
        }
      }
    } else {
      showToast({ detail: "Please login before bookmark", severity: "warn" })
    }
  }

  const bookmark = async (object_category: string) => {
    await bookmarkProduct(id, object_category)
      .then(() => {
        if (handleReload) {
          handleReload()
        }
      })
      .catch((error) => error)
  }

  const handleGetFullAddress = useMemo(() => {
    const address = props?.address
    return [
      address?.street,
      address?.ward?.name,
      address?.district?.name,
      address?.province?.name,
      address?.country?.name,
    ]
      .filter((i) => i)
      .join(", ")
  }, [props?.address])

  const handleGetShortAddress = useMemo(() => {
    if (type === FOOD_CARD_TYPE.RESTAURANT) {
      const address = props?.address
      return [address?.ward?.name, address?.district?.name].filter((i) => i).join(", ")
    } else {
      return props?.address
    }
  }, [props?.address])

  const clickHandleShowMap = (e: React.MouseEvent<HTMLElement>) => {
    if (isClickShowMap) {
      if (handleShowMap) {
        handleShowMap()
      }
      e.stopPropagation()
    }
  }
  const handleSelectRestaurant = () => {
    if (authStore?.value) {
      if (isFoodType) {
        handleCountViewBoth(restaurant_food?.id as string)
      } else {
        handleCountViewBoth(id as string)
      }
    }
  }

  return (
    <div className="flex h-full w-full cursor-pointer flex-col rounded-3 border border-gray-100 bg-white shadow-[0px_1px_2px_0px_#1018280D]">
      <div
        className={
          isShowPrice
            ? "relative h-[200px] w-full rounded-t-3 px-2 pt-2"
            : "relative h-[200px] w-full rounded-t-3"
        }
        style={{
          background: "linear-gradient(180deg, #FFD8C2 0%, rgba(254.25, 227.65, 225.75, 0) 100%)",
        }}
      >
        <div className="h-full w-full" onClick={handleSelectRestaurant}>
          <a href={handleGetPath()} className="h-full w-full">
            <ImgCustom
              url={imgUrl}
              alt="foodImg"
              className={`bg-white ${
                isShowPrice
                  ? "flex flex-1 flex-col items-start rounded-3"
                  : "flex flex-1 flex-col items-start rounded-t-3"
              }
          `}
            />
          </a>
        </div>

        <div
          onClick={handleBookmark}
          className={`${
            isShowPrice ? "right-[20px] top-[20px]" : "right-[8px] top-[8px]"
          } absolute  z-[2] flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-3 bg-[#10182866]
          ${
            bloom_filter && handleCheckBloomFilter(bloom_filter as string, id as string)
              ? "fill-yellow-400 stroke-yellow-400"
              : "fill-none stroke-white"
          }
          `}
        >
          <svg width="18" height="18" viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33331 4.5999C1.33331 3.4798 1.33331 2.91974 1.5513 2.49192C1.74305 2.1156 2.04901 1.80964 2.42533 1.61789C2.85316 1.3999 3.41321 1.3999 4.53331 1.3999H7.46665C8.58675 1.3999 9.1468 1.3999 9.57463 1.61789C9.95095 1.80964 10.2569 2.1156 10.4487 2.49192C10.6666 2.91974 10.6666 3.4798 10.6666 4.5999V13.3999L5.99998 10.7332L1.33331 13.3999V4.5999Z" />
          </svg>
        </div>

        {isShowDiscount ? (
          <div className="absolute bottom-[0px] left-[0px] z-[2] flex h-[28px] w-[61px] items-center justify-center rounded-tr-3 bg-[linear-gradient(263.89deg,#FF692E_0%,#FF4405_100%)] text-14 font-semibold leading-20 text-white">
            {`-${discount}%`}
          </div>
        ) : null}
        {isClickShowMap ? (
          <div
            onClick={clickHandleShowMap}
            className="absolute left-1 top-1 flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-3 bg-[#10182866] fill-white stroke-white  text-14 font-semibold leading-5 text-white"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Bold / Map &#38; Location / Map">
                <g id="Vector">
                  <path
                    d="M2.03055 4.28856C1.66699 4.70239 1.66699 5.41191 1.66699 6.83095V14.6587C1.66699 15.6637 1.66699 16.1662 1.92916 16.5445C2.19132 16.9228 2.64967 17.0817 3.56636 17.3995L4.64485 17.7734C5.22896 17.9759 5.67841 18.1317 6.0556 18.232C6.30166 18.2975 6.5281 18.1044 6.5281 17.8497V5.22459C6.5281 5.01716 6.37503 4.8421 6.17396 4.7911C5.84961 4.70883 5.45971 4.57366 4.92534 4.3884C3.63096 3.93965 2.98378 3.71528 2.49134 3.94313C2.3165 4.02403 2.15963 4.14163 2.03055 4.28856Z"
                    fill="none"
                  />
                  <path
                    d="M10.5173 2.90031L9.23733 3.78781C8.77428 4.10888 8.43492 4.34418 8.14512 4.51194C8.00778 4.59144 7.91699 4.73527 7.91699 4.89396V17.4331C7.91699 17.7419 8.23698 17.9347 8.49713 17.7683C8.77635 17.5897 9.09607 17.368 9.48334 17.0995L10.7633 16.212C11.2264 15.8909 11.5657 15.6556 11.8555 15.4878C11.9929 15.4083 12.0837 15.2645 12.0837 15.1058V2.56671C12.0837 2.25788 11.7637 2.06506 11.5035 2.23148C11.2243 2.4101 10.9046 2.63179 10.5173 2.90031Z"
                    fill="none"
                  />
                  <path
                    d="M16.4343 2.60029L15.3558 2.22639C14.7716 2.02387 14.3223 1.86808 13.9451 1.76774C13.699 1.70229 13.4725 1.89542 13.4725 2.15004V14.7752C13.4725 14.9826 13.6256 15.1577 13.8267 15.2087C14.151 15.2909 14.541 15.4261 15.0753 15.6114C16.3697 16.0601 17.0169 16.2845 17.5093 16.0566C17.6842 15.9757 17.841 15.8581 17.9701 15.7112C18.3337 15.2974 18.3337 14.5879 18.3337 13.1688V5.34108C18.3337 4.3361 18.3337 3.8336 18.0715 3.4553C17.8093 3.077 17.351 2.91809 16.4343 2.60029Z"
                    fill="none"
                  />
                </g>
              </g>
            </svg>
          </div>
        ) : null}
      </div>

      <a
        href={handleGetPath()}
        onClick={handleSelectRestaurant}
        className="flex w-full flex-col items-start gap-4px self-stretch px-1 py-4px md:px-2"
      >
        <span className="line-clamp-1 w-full  text-16 font-semibold leading-24 text-gray-900">
          {title}
        </span>
        {isRatingView ? (
          <RatingView point={point as number} countReview={countReview as number} />
        ) : null}

        {/* facilities */}
        {type === FOOD_CARD_TYPE.RESTAURANT && (
          <div className="flex h-[20px] w-full items-center gap-1">
            {is_free_wifi ? (
              <div>
                <img src={wifiFree} alt="wifi-free" />
              </div>
            ) : null}
            {is_parking_lot ? (
              <div>
                <img src={parkingLot} alt="wifi-free" />
              </div>
            ) : null}
            {is_operating_hours ? (
              <div>
                <img src={hour} alt="wifi-free" />
              </div>
            ) : null}
            {is_vehicle_charging ? (
              <div>
                <img src={charging} alt="wifi-free" />
              </div>
            ) : null}
          </div>
        )}

        {address && isAddressView ? (
          <AddressView
            address={handleGetFullAddress as string}
            sortAddress={handleGetShortAddress as string}
            type={type}
          />
        ) : null}

        {isShowTypeFood ? <TypeFoodView typeFood={typeFood as string[]} /> : null}

        {isShowPrice ? (
          <PriceFoodView price={price as number} originPrice={originPrice as number} />
        ) : null}
      </a>
    </div>
  )
}

export default React.memo(FoodCardView)
