import React, { useState } from "react"
import { Rating } from "primereact/rating"
import { RatingsAndReviewsProperties } from "./ratings-and-reviews-custom-props"
import "./ratings-and-reviews-custom.scss"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { ImgCustom } from "../common"
import { convertDateString } from "src/utils/convert-date-to-string"
import VideoCustom from "../orders-view/order-food-rating/video-custom"
import MediaModal from "../media-modal"
import ModalReport from "./modal-report-rating"
import { useToast } from "src/hooks/use-toast"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"

export default function RantingsAndReviewsCustom(
  props: RatingsAndReviewsProperties
) {
  const {
    userName,
    imgUrl,
    comment,
    commentDate,
    listImage,
    valueRating,
    id,
    object_parent_id,
    object_parent_type,
    owner_id,
    city_code,
    key_search,
    isDisableReport,
  } = props

  const { t } = useTranslation()
  const showToast = useToast()
  const authStore = useSelector((state: RootState) => state.auth)
  const [isShowMedia, setIsShowMedia] = useState<boolean>(false)
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [mediaActivated, setMediaActivated] = useState({
    id: "",
    index: 0,
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickImage = (media: any, index: number) => {
    setIsShowMedia(true)
    setMediaActivated({
      id: media.id,
      index,
    })
  }

  const handleClose = () => {
    setIsShowMedia(false)
  }

  const handleOpenReportDialog = () => {
    if (authStore.value) {
      setIsShowModal(true)
    } else {
      showToast({
        detail: "Please login before report this comment",
        severity: "warn",
      })
    }
  }

  const handleCheckUserName = () => {
    if (userName && userName?.length > 1) {
      return userName
    } else {
      return "Inactive user"
    }
  }

  return (
    <div className="w-full gap-3 rounded-3 border-b border-solid border-gray-100 bg-white px-4 py-2">
      <div className="mb-4px">
        <div className="mb-6px flex justify-between gap-4px">
          <div className="relative flex">
            <div className="mr-1 h-[48px] w-[48px] rounded-full">
              <ImgCustom
                isAvatar={true}
                url={imgUrl as string}
                alt=""
                className="rounded-full object-cover"
              />
            </div>
            <div className="flex flex-col justify-center gap-2px">
              <label className="flex cursor-pointer items-center text-16 font-medium text-gray-700">
                {handleCheckUserName()}
              </label>
              <Rating
                readOnly
                onIcon={
                  <i className="pi pi-star-fill text-16 text-amber-400"></i>
                }
                offIcon={
                  <i className="pi pi-star-fill text-16 text-gray-200"></i>
                }
                className="relative mr-1"
                value={valueRating}
                cancel={false}
              />
            </div>
          </div>
          <label className="flex items-center text-14 font-normal text-gray-500">
            {convertDateString(commentDate)}
          </label>
        </div>
      </div>

      <div className="flex gap-1">
        <p className="mb-1 text-14 font-normal text-gray-700">{comment}</p>
      </div>
      <div className="flex flex-wrap gap-1">
        {listImage?.map((media, index) => {
          if (media?.isImage)
            return (
              <div
                key={index}
                className="h-80px w-80px cursor-pointer rounded-1"
                onClick={() => handleClickImage(media, index)}
              >
                <ImgCustom
                  url={media?.id}
                  alt="commentImage"
                  className="h-full w-full rounded-1 object-cover"
                />
              </div>
            )
          return (
            <div
              key={index}
              className="h-80px w-80px cursor-pointer rounded-3"
              onClick={() => handleClickImage(media, index)}
            >
              <VideoCustom
                isControls={true}
                type={media?.type}
                idSource={media?.id}
              />
            </div>
          )
        })}
      </div>
      {!isDisableReport ? (
        <div className="flex gap-2">
          <div className="flex items-center gap-1 py-1">
            <i
              className="pi pi-thumbs-up cursor-pointer text-gray-400"
              style={{ fontSize: "1rem" }}
            ></i>
            <label className="text-14 font-semibold text-gray-400">
              {capitalizedFirst(t("ecommerce.helpful"))}
            </label>
          </div>
          <div
            onClick={handleOpenReportDialog}
            className="flex cursor-pointer items-center gap-1 px-3 py-1"
          >
            <i
              className="pi pi-flag  cursor-pointer text-gray-400"
              style={{ fontSize: "1rem" }}
            ></i>
            <label className="cursor-pointer text-14 font-semibold text-gray-400">
              {capitalizedFirst(t("ecommerce.report"))}
            </label>
          </div>
        </div>
      ) : null}

      {isShowMedia && listImage ? (
        <MediaModal
          handleClose={handleClose}
          listImage={listImage}
          mediaActivated={mediaActivated}
        ></MediaModal>
      ) : null}
      {isShowModal ? (
        <ModalReport
          isShowModal={isShowModal}
          handleClose={() => setIsShowModal(false)}
          object_id={id}
          object_parent_type={object_parent_type}
          object_parent_id={object_parent_id}
          owner_id={owner_id}
          key_search={key_search}
          city_code={city_code}
        />
      ) : null}
    </div>
  )
}
