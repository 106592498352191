import React, { useEffect, useMemo, useState } from "react"
import { t } from "i18next"
import { Dialog } from "primereact/dialog"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import "./index.scss"
import paymentLogo from "src/assets/images/payment-logo.svg"
import { VoucherDetailModalType } from "./types"
import { VoucherControllerApi } from "@soctrip/axios-restaurant-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { VouchersType } from "src/components/merchant-voucher/types"
import { generateQrCodeWithString } from "src/utils/qr-code-utils"
export default function QRCodeModal(props: VoucherDetailModalType) {
  const { voucherId, isShow, handleClose } = props
  const [voucherInfo, setVoucherInfo] = useState<VouchersType>()
  const [qrDataImage, setQrDataImage] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    async function _fetchData() {
      setIsLoading(true)
      await new VoucherControllerApi(config(SERVICE.RESTAURANT))
        .vouchersIdGet(voucherId)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          setVoucherInfo(res?.data?.data)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    _fetchData()
  }, [voucherId])
  const footerTemplate = (
    <div className="">
      <button
        className="rounded-3 border border-gray-200 px-3 py-2 pt-2 text-16 font-semibold text-gray-900 shadow-sm hover:bg-gray-50"
        onClick={() => handleClose()}
      >
        {capitalizedFirst(t("global.close"))}
      </button>
    </div>
  )

  const getQrData = async (qrData: string) => {
    const dataRes = await generateQrCodeWithString(qrData)
    if (dataRes) setQrDataImage(dataRes)
  }

  useMemo(() => {
    if (voucherInfo?.qr_code) getQrData(voucherInfo?.qr_code)
  }, [voucherInfo?.qr_code])
  return (
    <Dialog
      id="custom-dialog"
      footer={footerTemplate}
      draggable={false}
      header={capitalizedFirst(t("my-voucher.qr-code"))}
      visible={isShow}
      onHide={handleClose}
      className="w-[384px]"
    >
      <div className="flex w-full flex-col gap-4px text-center">
        {isLoading ? (
          <>
            <div className="my-3 flex h-[500px] w-full items-center justify-center gap-2 rounded-3 bg-white">
              <p className="animate-pulse duration-75">
                {capitalizedFirst(t("global.loading"))}
              </p>
              <i className="pi pi-spin pi-spinner text-24 font-bold text-blue-light-400"></i>
            </div>
          </>
        ) : (
          <>
            {voucherInfo?.qr_code && qrDataImage ? (
              <div className="flex items-center justify-center">
                <div className="relative h-[300px] w-[300px]">
                  <img
                    loading="lazy"
                    alt="voucherQrCode"
                    className="h-full w-full rounded-[60px]"
                    src={qrDataImage}
                  />
                  <div className="absolute left-[120px] top-[120px] z-[2] h-[60px] w-[60px] rounded-full bg-white">
                    <img
                      loading="lazy"
                      src={paymentLogo}
                      alt={"paymentLogo"}
                      className=" h-[60px] w-[60px]"
                    />
                  </div>
                </div>
                <span></span>
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className="w-full text-center ">
        <span className="w-full text-justify text-14 leading-20 text-gray-400 ">
          {capitalizedFirst(
            t("my-voucher.share-qr-code-with-restaurant-payment")
          )}
        </span>
      </div>
    </Dialog>
  )
}
