import http from "src/config/interceptors"

export const getUserInfo = (userId: string) => {
  return http.get(`/user/users/${userId}`)
}
export const getMyRegion = () => {
  return http.get("/regional/me")
}

export const updateUserLanguage = (lng: string, currency: string) => {
  return http.put("/user/language-currency", {
    language: lng,
    currency: currency,
  })
}
