import React, { useEffect, useRef, useState } from "react"
import FoodCardView from "./food-card-view"
import { handlePromiseAll } from "src/utils/common"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { getBloomFilter } from "src/services/bookmark-service"
import { FOOD_CARD_TYPE } from "src/constants/common"
import { FoodControllerApi } from "@soctrip/axios-food-service"
import { SERVICE } from "src/constants/common"
import { config } from "src/config/interceptors"
import useOnScreen from "src/hooks/use-on-screen"
import { RecommendViewType } from "./types"
import { useInView } from "react-intersection-observer"

const HotDealView = () => {
  const { t } = useTranslation()
  const loadingRef = useRef(null)
  const isOnScreen = useOnScreen(loadingRef)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [popularFood, setPopularFood] = React.useState<RecommendViewType[]>([])
  const [pageIndex, setPageIndex] = React.useState<number>(0)
  const [totalElement, setTotalElement] = useState<number>(0)
  const [bloomData, setBloomData] = useState<string>("")
  const [handleShowMoreLoading, setHandleShowMoreLoading] = useState<boolean>(false)

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView && !isFirstLoad && pageIndex < 2) {
      handleLoadMore()
    }
  }, [inView])

  const handleGetHotDealData = async () => {
    await new FoodControllerApi(config(SERVICE.FOOD))
      .foodsHotDealGet(pageIndex, 40)
      .then((res) => {
        setTotalElement(res?.data?.data?.totalElement)
        setHandleShowMoreLoading(false)
        if (res?.data?.data?.data?.length) {
          const result: RecommendViewType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.data.forEach((i: any) => {
            result.push({
              id: i?.id,
              title: i?.name,
              imgUrl: i?.avatar?.id,
              point: i?.point,
              countReview: i?.sum_reviews,
              price: i?.price,
              originPrice: i?.original_price,
              restaurant_food: i?.restaurant_food,
              address: i?.restaurant_food?.name,
            })
          })

          if (res.data.data?.totalElement > (pageIndex + 1) * 10)
            setPopularFood([...popularFood, ...result])
        }
      })
      .catch((error) => error)
  }

  React.useEffect(() => {
    function initView() {
      const apis = [handleGetHotDealData()]
      handlePromiseAll(apis)
    }
    initView()
    handleGetBloomData()
    setIsFirstLoad(false)
  }, [])

  const handleLoadMore = () => {
    setHandleShowMoreLoading(true)
    setPageIndex(pageIndex + 1)
  }

  React.useEffect(() => {
    if (pageIndex && !isFirstLoad) handleGetHotDealData()
  }, [pageIndex])

  // get bloom data
  const handleGetBloomData = async () => {
    await getBloomFilter()
      .then((response) => {
        setBloomData(response?.data?.data?.bloom_filter)
      })
      .catch((error) => error)
  }

  useEffect(() => {
    if (isOnScreen && !isFirstLoad && popularFood?.length < totalElement) {
      handleLoadMore()
    }
  }, [isOnScreen])

  if (popularFood?.length === 0) return null

  return (
    <div className="mt-3 flex flex-col gap-4">
      <span className="text-20 font-semibold leading-30 text-gray-900">
        {capitalizedFirst(t("food-home-page.hot-deal-title"))}
      </span>
      <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {popularFood?.map((item, index) => (
          <div key={index}>
            <div ref={pageIndex * 40 + 20 === index ? ref : null}></div>
            <FoodCardView
              isShowPrice={true}
              isAddressView={true}
              isFoodType={true}
              bloom_filter={bloomData}
              type={FOOD_CARD_TYPE.FOOD}
              handleReload={handleGetBloomData}
              {...item}
            />
          </div>
        ))}
      </div>

      <>
        {!handleShowMoreLoading && (
          <div className="flex items-center justify-center">
            {popularFood?.length < totalElement ? (
              <div
                onClick={handleLoadMore}
                className="cursor-pointer text-14 font-medium text-blue-700"
              >
                {capitalizedFirst(t("shoppingPage.view-more"))}
              </div>
            ) : null}
          </div>
        )}
      </>

      {handleShowMoreLoading && (
        <div className="flex justify-center py-4">
          <i className="pi pi-spin pi-spinner text-blue-500" style={{ fontSize: "1.5rem" }}></i>
        </div>
      )}
    </div>
  )
}

export default React.memo(HotDealView)
