import React from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { OrderInfoType } from "../types"
import { convertTimeCommon } from "src/utils/time-utils"

const OrderInfo = (props: OrderInfoType) => {
  const { t } = useTranslation()
  const { orderId, orderTime, paymentType } = props

  return (
    <div className="mt-4 flex flex-col    ">
      <div className="flex border-b border-gray-200 px-4 pb-3 text-[14px] font-medium leading-[20px]  ">
        <span className="text-gray-500">
          {capitalizedFirst(t("buy-voucher.order-info-id"))}
        </span>
        <span className="ml-1 text-gray-700">{orderId}</span>
      </div>
      <div className="flex border-b border-gray-200 px-4 py-3 text-[14px] font-medium leading-[20px]">
        <div className="flex flex-1 flex-col">
          <span className="text-gray-400">
            {capitalizedFirst(t("buy-voucher.order-info-time"))}
          </span>
          <span className="mt-4px text-gray-700">
            {convertTimeCommon(orderTime, true)}
          </span>
        </div>
        <div className="flex flex-1 flex-col">
          <span className="text-gray-400">
            {capitalizedFirst(t("buy-voucher.order-info-payment"))}
          </span>
          <span className="mt-4px text-gray-700">{paymentType}</span>
        </div>
      </div>
    </div>
  )
}

export default OrderInfo
