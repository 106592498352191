import React, { useState } from "react"
import "./ratings-summary-custom.scss"
import { ProgressBar } from "primereact/progressbar"
import { Rating } from "primereact/rating"
import { RatingsSummaryProperties } from "./ratings-summary-custom-props"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"

export default function RantingsSummaryCustom(props: RatingsSummaryProperties) {
  const {
    totalStarRating,
    totalNumberOfReviews,
    numberOfReviewsFor5Stars,
    numberOfReviewsFor4Stars,
    numberOfReviewsFor3Stars,
    numberOfReviewsFor2Stars,
    numberOfReviewsFor1Stars,
    updateFilterComment,
  } = props

  const { t } = useTranslation()
  const [selectedRating, setSelectedRating] = useState<number>(0)

  const handleSelectedRating = (value: number) => {
    updateFilterComment(value)
    if (selectedRating !== value) {
      setSelectedRating(value)
    } else {
      setSelectedRating(0)
    }
  }

  return (
    <div className="mb-0 flex w-full gap-4 rounded-3 bg-white p-3 md:mb-20px md:block">
      <div className="item-center flex justify-center">
        <div className="mb-4px">
          <label className="total-rating item-center flex justify-center gap-1 text-24 font-bold">
            {totalStarRating}/5
          </label>
          <div className="item-center mb-1 flex gap-2">
            <Rating
              readOnly
              className="relative mr-1"
              onIcon={
                <i className="pi pi-star-fill text-20 text-amber-400"></i>
              }
              offIcon={
                <i className="pi pi-star-fill text-20 text-gray-200"></i>
              }
              value={totalStarRating}
              cancel={false}
            />
          </div>
          <label className="item-center flex justify-center gap-1 text-14 font-medium text-gray-600">
            {totalNumberOfReviews} {capitalizedFirst(t("global.reviews"))}
          </label>
        </div>
      </div>

      <div className="flex flex-col gap-3 md:flex-[unset]">
        <div
          className={`flex h-[20px] cursor-pointer items-center gap-1 px-[4px] hover:rounded-3  ${
            selectedRating === 5 ? "selectedShadow rounded-3" : ""
          }`}
          onClick={() => handleSelectedRating(5)}
        >
          <div className="flex h-full items-center justify-center gap-1">
            <label className="text-14 font-normal leading-5 text-gray-500">
              5
            </label>
            <i className="pi pi-star-fill text-amber-400 marker:text-14" />
          </div>
          <div className="w-full flex-1 items-center">
            <ProgressBar
              value={(numberOfReviewsFor5Stars / totalNumberOfReviews) * 100}
              showValue={false}
            ></ProgressBar>
          </div>
          <div className="flex h-full min-w-[30px] max-w-[30px] items-center justify-center">
            <span className="flex w-[30px] items-center justify-end truncate text-14 font-normal leading-5 text-gray-500">
              {numberOfReviewsFor5Stars}
            </span>
          </div>
        </div>

        <div
          className={`flex h-[20px] cursor-pointer items-center gap-1 px-[4px] hover:rounded-3  ${
            selectedRating === 4 ? "selectedShadow rounded-3" : ""
          }`}
          onClick={() => handleSelectedRating(4)}
        >
          <div className="flex h-full items-center justify-center gap-1">
            <label className="text-14 font-normal leading-5 text-gray-500">
              4
            </label>
            <i className="pi pi-star-fill text-amber-400 marker:text-14" />
          </div>
          <div className="w-full flex-1 items-center">
            <ProgressBar
              value={(numberOfReviewsFor4Stars / totalNumberOfReviews) * 100}
              showValue={false}
            ></ProgressBar>
          </div>
          <div className="flex min-w-[30px] max-w-[30px] items-center justify-center">
            <span className="flex w-[30px] items-center justify-end truncate text-14 font-normal leading-5 text-gray-500">
              {numberOfReviewsFor4Stars}
            </span>
          </div>
        </div>

        <div
          className={`flex h-[20px] cursor-pointer items-center gap-1 px-[4px] hover:rounded-3  ${
            selectedRating === 3 ? "selectedShadow rounded-3" : ""
          }`}
          onClick={() => handleSelectedRating(3)}
        >
          <div className="flex h-full items-center justify-center gap-1">
            <label className="text-14 font-normal leading-5 text-gray-500">
              3
            </label>
            <i className="pi pi-star-fill text-amber-400 marker:text-14" />
          </div>
          <div className="w-full flex-1 items-center">
            <ProgressBar
              value={(numberOfReviewsFor3Stars / totalNumberOfReviews) * 100}
              showValue={false}
            ></ProgressBar>
          </div>
          <div className="flex min-w-[30px] max-w-[30px] items-center justify-center">
            <span className="flex w-[30px] items-center justify-end truncate text-14 font-normal leading-5 text-gray-500">
              {numberOfReviewsFor3Stars}
            </span>
          </div>
        </div>

        <div
          className={`flex h-[20px] cursor-pointer items-center gap-1 px-[4px] hover:rounded-3  ${
            selectedRating === 2 ? "selectedShadow rounded-3" : ""
          }`}
          onClick={() => handleSelectedRating(2)}
        >
          <div className="flex h-full items-center justify-center gap-1">
            <label className="text-14 font-normal leading-5 text-gray-500">
              2
            </label>
            <i className="pi pi-star-fill text-amber-400 marker:text-14" />
          </div>
          <div className="w-full flex-1 items-center">
            <ProgressBar
              value={(numberOfReviewsFor2Stars / totalNumberOfReviews) * 100}
              showValue={false}
            ></ProgressBar>
          </div>
          <div className="flex min-w-[30px] max-w-[30px] items-center justify-center">
            <span className="flex w-[30px] items-center justify-end truncate text-14 font-normal leading-5 text-gray-500">
              {numberOfReviewsFor2Stars}
            </span>
          </div>
        </div>

        <div
          className={`flex h-[20px] cursor-pointer items-center gap-1 px-[4px] hover:rounded-3  ${
            selectedRating === 1 ? "selectedShadow rounded-3" : ""
          }`}
          onClick={() => handleSelectedRating(1)}
        >
          <div className="flex h-full items-center justify-center gap-1">
            <label className="text-14 font-normal leading-5 text-gray-500">
              1
            </label>
            <i className="pi pi-star-fill text-amber-400 marker:text-14" />
          </div>
          <div className="w-full flex-1 items-center">
            <ProgressBar
              value={(numberOfReviewsFor1Stars / totalNumberOfReviews) * 100}
              showValue={false}
            ></ProgressBar>
          </div>
          <div className="flex min-w-[30px] max-w-[30px] items-center justify-center ">
            <span className="flex w-[30px] items-center justify-end truncate text-14 font-normal leading-5 text-gray-500">
              {numberOfReviewsFor1Stars}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
