import React, { useEffect, useRef, useState } from "react"
import {
  RestaurantCategoriesProperties,
  RestaurantProperties,
  SectionProperties,
} from "./types"
import * as restaurantService from "@soctrip/axios-restaurant-service"
import { config } from "src/config/interceptors"
import "./index.scss"
import { useLocation, useParams } from "react-router-dom"
import CategoryView from "./category-view"
import { getBloomFilter } from "src/services/bookmark-service"
import { ALL_FOOD_CODE, SERVICE } from "src/constants/common"
import { Skeleton } from "primereact/skeleton"
import AllFoodView from "./all-food-view"
import MerchantVoucherView from "../merchant-voucher"
import { handlePostCountViewBoth } from "src/services/count-view-service"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"

export default function RestaurantView() {
  const { hash } = useLocation()
  const voucherRef = useRef<HTMLDivElement>(null)
  const param = useParams()
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [categorySelected, setCategorySelected] = useState<string>("")
  const [dataShop, setDataShop] = useState<RestaurantProperties>()
  const [bloomData, setBloomData] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [handleClick, setHandleClick] = useState<number>(0)
  const [categoryData, setCategoryData] =
    useState<RestaurantCategoriesProperties>()
  const authStore = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    handleGetBloomData()
    if (param?.id) {
      setIsLoading(true)
      new restaurantService.RestaurantControllerApi(config(SERVICE.RESTAURANT))
        .restaurantsIdGet(param?.id)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          setDataShop(response?.data?.data)
          setIsLoading(false)
        })
        .catch(() => setIsLoading(false))
    }
  }, [])

  useEffect(() => {
    if (authStore?.value && hash) {
      handlePostCountViewBoth()
    }
  }, [hash])

  useEffect(() => {
    if (dataShop?.restaurant_categories?.length) {
      const categoryTabData = dataShop?.restaurant_categories?.find(
        (data: RestaurantCategoriesProperties) => data?.id === categorySelected
      )
      setCategoryData(categoryTabData)
    }
  }, [dataShop, categorySelected])

  const handleGetBloomData = async () => {
    await getBloomFilter()
      .then((response) => {
        setBloomData(response?.data?.data?.bloom_filter)
      })
      .catch((error) => error)
  }

  useEffect(() => {
    setCategorySelected(hash.slice(1))
    setFirstLoad(false)
    setHandleClick(handleClick + 1)
  }, [hash])

  useEffect(() => {
    if (!firstLoad && handleClick >= 3) {
      const top = voucherRef.current?.getBoundingClientRect().top
      window.scrollTo({
        top: (top as number) + window.scrollY - 120,
        behavior: "smooth",
      })
    }
  }, [categorySelected])

  return (
    <div ref={voucherRef} className="flex h-full w-full flex-col">
      {/* content */}
      <MerchantVoucherView restaurantId={param?.id || ""} />
      {isLoading ? (
        <div className="card">
          <div className="border-round border-1 surface-border surface-card p-4">
            <div className="mb-3 flex">
              <div className="w-full">
                <Skeleton className="mb-2 w-full"></Skeleton>
                <Skeleton width="50%" className="mb-2"></Skeleton>
                <Skeleton height=".5rem"></Skeleton>
              </div>
            </div>
            <Skeleton width="100%" height="150px"></Skeleton>
            <div className="justify-content-between mt-3 flex gap-3">
              <Skeleton width="4rem" height="2rem"></Skeleton>
              <Skeleton width="4rem" height="2rem"></Skeleton>
            </div>
          </div>
        </div>
      ) : (
        <>
          {hash.slice(1) === ALL_FOOD_CODE.CODE ? (
            <div className="w-full">
              <AllFoodView
                bloom_filter={bloomData}
                handleReload={handleGetBloomData}
              />
            </div>
          ) : (
            <div className="h-full w-full pt-1">
              {categoryData?.restaurant_sections?.length !== 0 && (
                <div className="flex w-full flex-col ">
                  {categoryData?.restaurant_sections?.map(
                    (section: SectionProperties, index) => (
                      <div key={index} className="flex w-full">
                        <CategoryView
                          code={section?.restaurant_decoration_collection?.code}
                          dataContent={section?.contents}
                          fullData={section}
                          shopId={dataShop?.id}
                          bloom_filter={bloomData}
                          handleReload={handleGetBloomData}
                        />
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}
