import React from "react"
import ReviewCards from "./review-cards"
import { RestaurantInfoType } from "./type"
import { useNavigate } from "react-router"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function RestaurantReviews(props: RestaurantInfoType) {
  const { id, data } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleNavigateViewAll = () => {
    navigate(`/food/restaurant/review-page/${id}`)
  }

  return (
    <div className="flex w-full flex-col gap-1 px-2">
      {data?.length ? (
        <>
          <div className="flex w-full justify-between">
            <div className="text-16 font-semibold leading-6">
              {capitalizedFirst(t("global.last-review"))}
            </div>
            <div
              onClick={handleNavigateViewAll}
              className="cursor-pointer pr-1 text-14 font-medium text-blue-600 hover:text-blue-700"
            >
              {capitalizedFirst(t("global.view-all"))}
            </div>
          </div>

          <div className="flex w-full items-center gap-3">
            {data?.slice(0, 3)?.map((data, index) => (
              <div key={index} className="w-full max-w-[382px]">
                <ReviewCards {...data} />
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  )
}
