import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { CommentListType, MediaType } from "src/components/comment-view/types"
import LoadingApproval from "./loading-approval"
import { RatingControllerApi } from "@soctrip/axios-rating-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { RestaurantProperties } from "src/components/restaurant-view/types"
import RantingsAndReviewsCustom from "src/components/ratings-and-reviews-custom/ratings-and-reviews-custom"
import { RestaurantControllerApi } from "@soctrip/axios-restaurant-service"
import { ImgCustom } from "src/components/common"
import Empty from "./empty"

export default function RestaurantReportRatingDetails() {
  const { id } = useParams()
  const [ratingData, setRatingData] = useState<CommentListType>()
  const [restaurantData, setRestaurantData] = useState<RestaurantProperties>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getListMedia = (medias: any) => {
    const result: MediaType[] = []
    if (medias && medias?.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      medias.forEach((i: any) => {
        result.push({
          id: i?.id as string,
          isImage: i?.type.includes("image"),
          type: i?.type,
        })
      })
    }
    return result
  }

  const handleGetRatingData = async (id: string) => {
    await new RatingControllerApi(config(SERVICE.RATING))
      .ratingsIdObjectGet(id)
      .then((response) => {
        const rating = response?.data?.data
        if (rating) {
          setRatingData({
            userName: rating?.created_by?.full_name,
            imgUrl: rating?.created_by?.avatar_thumbnail_url,
            comment: rating?.content,
            commentDate: rating?.updated_at,
            listImage: getListMedia(rating?.medias),
            valueRating: rating?.rating as number,
            id: rating.id,
            object_id: rating.object_id,
            object_type: rating.object_type,
            key_search: rating?.created_by?.username,
            city_code: rating?.created_by?.city_code,
            owner_id: rating?.created_by?.id,
          })
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (id) {
      handleGetRatingData(id)
    }
  }, [id])

  const handleGetRestaurantData = async (objectId: string) => {
    await new RestaurantControllerApi(config(SERVICE.RESTAURANT))
      .restaurantsIdGet(objectId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setRestaurantData(response?.data?.data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (ratingData) {
      handleGetRestaurantData(ratingData?.object_id)
    }
  }, [ratingData])

  const getUrlProduct = () => {
    return `${process.env.REACT_APP_FOOD_DOMAIN_URL}/food/restaurant/${restaurantData?.id}`
  }

  const getFullAddress = useMemo(() => {
    return [
      restaurantData?.street,
      restaurantData?.ward?.name,
      restaurantData?.district?.name,
      restaurantData?.province?.name,
    ]
      .filter((i) => i)
      .join(", ")
  }, [restaurantData])

  return (
    <div className="mx-auto h-full w-full max-w-[1200px] bg-gray-25">
      {isLoading ? (
        <div className="w-full p-6">
          <LoadingApproval />
        </div>
      ) : (
        <div className="w-full">
          {restaurantData ? (
            <div className="flex w-full flex-col gap-6 p-4">
              {/* Content */}
              <div className="flex w-full flex-col gap-2">
                {/* Restaurant information */}
                <div className="flex min-h-[132px] w-full items-center gap-3">
                  <div className="flex h-full w-[80px] items-start justify-start">
                    <ImgCustom
                      url={restaurantData?.avatar?.id}
                      alt="header"
                      className="h-full w-full rounded-full object-cover object-center"
                    />
                  </div>
                  <div className="flex h-full flex-col items-center justify-center">
                    <div className="flex flex-col justify-center">
                      <a
                        href={getUrlProduct()}
                        target="_blank"
                        rel="noreferrer"
                        className="flex w-full cursor-pointer items-center text-20 font-semibold leading-8 text-gray-900 hover:text-blue-600 md:text-24"
                      >
                        {restaurantData?.name}
                      </a>
                      <div className="flex items-center">
                        <p className="text-16 font-normal leading-6 text-gray-500">
                          {getFullAddress}
                        </p>
                      </div>
                      <div className="mt-2px flex items-center justify-start gap-1 text-14 font-normal leading-5 text-gray-700 sm:pl-0">
                        <i className="pi pi-star-fill text-20 text-amber-400"></i>
                        <p className="text-14 font-medium text-amber-400">
                          {restaurantData?.rating}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Background */}
                <div className="h-[400px] w-full">
                  <ImgCustom
                    url={restaurantData?.background?.id}
                    alt="header"
                    className="h-full w-full rounded-3 object-cover object-center"
                  />
                </div>
              </div>
              {/* Comment */}
              <div className="flex w-full rounded-3 border border-orange-dark-400">
                <RantingsAndReviewsCustom
                  imgUrl={ratingData?.imgUrl}
                  userName={ratingData?.userName}
                  labelRating={ratingData?.labelRating}
                  comment={ratingData?.comment}
                  commentDate={ratingData?.commentDate}
                  listImage={ratingData?.listImage || []}
                  valueRating={ratingData?.valueRating}
                  id={ratingData?.id as string}
                  owner_id={ratingData?.owner_id}
                  key_search={ratingData?.key_search}
                  city_code={ratingData?.city_code}
                  object_parent_id={""}
                  object_parent_type={""}
                  isDisableReport={true}
                ></RantingsAndReviewsCustom>
              </div>
            </div>
          ) : (
            <div className="flex h-[700px] w-full items-center justify-center bg-gray-25">
              <Empty />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
