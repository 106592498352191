import React from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { BuyerInfoType } from "../types"

const BuyerInfo = (props: BuyerInfoType) => {
  const { t } = useTranslation()
  const { name, email, phone } = props
  return (
    <div className="mx-4 mt-3 flex flex-col rounded-3 border border-gray-200 p-3">
      <span className="text-[16px] font-semibold leading-[24px] text-gray-900 ">
        {capitalizedFirst(t("buy-voucher.buyer-info-title"))}
      </span>
      <div className="flex items-center text-[14px] font-medium leading-[20px]">
        <div className="flex flex-1 flex-col">
          <span className="text-gray-400">
            {capitalizedFirst(t("global.fullName"))}
          </span>
          <span className="mt-4px text-gray-700">{name}</span>
        </div>

        <div className="flex flex-1 flex-col">
          <span className="text-gray-400">
            {capitalizedFirst(t("global.email"))}
          </span>
          <span className="mt-4px text-gray-700">{email}</span>
        </div>

        <div className="flex flex-1 flex-col">
          <span className="text-gray-400">
            {capitalizedFirst(t("purchase-history.phone"))}
          </span>
          <span className="mt-4px text-gray-700">{phone}</span>
        </div>
      </div>
    </div>
  )
}

export default BuyerInfo
