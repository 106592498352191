import React from "react"
import { OrdersCardProps } from "./props"
import { useNavigate } from "react-router-dom"
import OrderFoodInfo from "../order-food-info"
import { copyToClipboard } from "src/utils/copyToClipboard"
import { ImgCustom } from "src/components/common"

export default function OrdersCard({
  data,
  rightActionElement,
}: OrdersCardProps) {
  const navigate = useNavigate()

  return (
    <div className="flex w-full flex-col">
      {data?.map((restaurant, index) => (
        <div key={restaurant.id} className="h-full w-full ">
          <div className="flex w-full flex-col border-b border-gray-200">
            <div className="flex items-center justify-between border-b p-3">
              <div className="flex items-center gap-2">
                {/* avatar */}
                <div className="h-[30px] w-[30px]">
                  <ImgCustom
                    url={restaurant.avatar?.id}
                    alt="product image"
                    className="h-[30px] w-[30px] rounded-full"
                  />
                </div>

                <div
                  onClick={() =>
                    navigate(`/food/restaurant/${restaurant.restaurant_id}`)
                  }
                  className="flex cursor-pointer items-center text-16 font-semibold text-gray-700 transition hover:text-blue-700 hover:underline"
                >
                  {restaurant.name}
                </div>
              </div>
              {index === 0 &&
                (rightActionElement || (
                  <div className="flex items-center gap-1">
                    <div className="flex max-w-[180px] items-center gap-1">
                      <p className="truncate text-16 font-normal text-gray-900">
                        {restaurant?.code}
                      </p>
                      <span
                        onClick={() => copyToClipboard(restaurant?.code)}
                        className="pi pi-clone cursor-pointer text-20 text-blue-700"
                      ></span>
                    </div>
                  </div>
                ))}
            </div>
            {restaurant.foods?.map((food) => (
              <div key={food.id} className="flex w-full items-center">
                <OrderFoodInfo food={food} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
