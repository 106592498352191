import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "./capitalized-first-character"

export const translateCapitalize = (text: string) => {
  const { t } = useTranslation()

  const handleTranslate = (text: string) => {
    return t(text)
  }

  return capitalizedFirst(handleTranslate(text))
}
