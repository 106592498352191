import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import restaurantIcon from "src/assets/images/restaurant-icon.svg"
import star from "../../assets/images/star-icon.svg"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { handleCheckBloomFilter } from "src/utils/common"
import { FOOD_BOOKMARK_CATEGORY, SHARE_TYPE } from "src/constants/common"
import { bookmarkProduct, deleteBookmark } from "src/services/bookmark-service"
import { useToast } from "src/hooks/use-toast"
import BookingModal from "src/components/bookingModal"
import ShareToFeed from "src/components/restaurant-view/share-to-feed"
import { handleCountViewBoth } from "src/services/count-view-service"

interface ProfileProps {
  avatar: string
  name?: string
  rating?: number
  is_bookmark?: boolean
  id?: string
  background?: string
  province?: string
  isNavigate?: boolean
  sum_reviews?: number
  bloom_filter?: string
  handleReload: () => void
}

export default function RestaurantProfileDetailPage({
  id,
  name,
  avatar,
  rating,
  province,
  background,
  isNavigate,
  sum_reviews,
  bloom_filter,
  handleReload,
}: ProfileProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const showToast = useToast()
  const authStore = useSelector((state: RootState) => state.auth)
  const [isFollow, setIsFollow] = useState<boolean>(false)
  const [isBookTable, setIsBookTable] = useState<boolean>(false)
  const [isOpenShare, setIsOpenShare] = useState<boolean>(false)

  const handleBookmark = async (id?: string) => {
    if (authStore?.value) {
      if (bloom_filter) {
        if (!handleCheckBloomFilter(bloom_filter as string, id as string)) {
          await bookmarkProduct(id, FOOD_BOOKMARK_CATEGORY.RESTAURANT)
            .then(() => {
              handleReload()
            })
            .catch((error) => error)
        } else {
          deleteBookmark(id)
            .then(() => {
              handleReload()
            })
            .catch((error) => {
              error
            })
        }
      } else {
        await bookmarkProduct(id, FOOD_BOOKMARK_CATEGORY.RESTAURANT)
          .then(() => {
            handleReload()
          })
          .catch((error) => error)
      }
    } else {
      showToast({ detail: "Please login before bookmark", severity: "warn" })
    }
  }

  const handleNavigate = () => {
    if (isNavigate) {
      navigate(`/food/restaurant/${id}`)
    }
    if (authStore?.value) {
      handleCountViewBoth(id as string)
    }
  }

  const handleShareToFeed = () => {
    if (authStore?.value) {
      setIsOpenShare(true)
    } else {
      showToast({ detail: "Please login before bookmark", severity: "warn" })
    }
  }

  useEffect(() => {
    if (bloom_filter?.length && (id as string)) {
      const isFollow = handleCheckBloomFilter(
        bloom_filter as string,
        id as string
      )
      if (isFollow) {
        setIsFollow(true)
      } else {
        setIsFollow(false)
      }
    }
  }, [bloom_filter, id])

  return (
    <div className="flex w-full flex-col gap-2 rounded-4 border-[1px] border-gray-200 bg-white p-3">
      <div className="flex w-full flex-col items-start justify-between gap-1 sm:flex-row">
        <div className="flex flex-1 items-center gap-2">
          <div className="h-[44px] w-[44px]">
            <img
              src={restaurantIcon}
              alt="header"
              className="rounded-full object-cover object-center"
            />
          </div>
          <div className="flex w-full flex-col gap-2">
            <div className="flex flex-col">
              <div
                className={`flex cursor-pointer font-medium ${
                  isNavigate ? "hover:text-blue-700 hover:underline" : ""
                }`}
              >
                <div onClick={handleNavigate}>{name}</div>
              </div>
              <div className="text-14 text-gray-500">{province}</div>
              <div className="flex items-center justify-start text-14 font-normal text-gray-700 sm:pl-0">
                <img
                  className="mr-[6px] h-[20px] w-[20px]"
                  src={star}
                  alt="star"
                />
                <p className="text-yellow-500">{rating}</p>
                <div className="mx-1 h-[4px] w-[4px] rounded-circle bg-gray-300"></div>
                <div className="flex items-center text-14 font-medium text-gray-500">
                  <i className="pi pi-comment mr-[6px]"></i>
                  {sum_reviews} {capitalizedFirst(t("global.reviews"))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-center flex gap-1">
          <div
            onClick={handleShareToFeed}
            className="flex h-[32px] w-[32px] items-center justify-center rounded-3 border fill-gray-600 stroke-gray-300"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 18 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.5236 0.0837206C8.14218 0.0740736 7.77674 0.244945 7.53566 0.546963C7.31891 0.818501 7.28926 1.08202 7.27178 1.27961C7.25431 1.47719 7.2573 1.66122 7.2573 1.89398V4.92316C5.59696 5.36751 4.05937 6.2147 2.90321 7.53509C1.4644 9.1782 0.667758 11.3056 0.666687 13.5096V14.0238C0.66673 14.1967 0.719003 14.3655 0.816384 14.507C0.913766 14.6486 1.05152 14.756 1.21088 14.8148C1.37024 14.8735 1.54346 14.8806 1.70694 14.8352C1.87042 14.7898 2.01621 14.694 2.12445 14.5609C2.91311 13.591 3.89782 12.808 5.01106 12.2628C5.71626 11.9174 6.49114 11.7963 7.2573 11.6583V14.106C7.2573 14.3387 7.25431 14.5228 7.27179 14.7203C7.28926 14.9179 7.31911 15.1799 7.53567 15.4513C7.53567 15.4519 7.53567 15.4525 7.53567 15.453C7.77667 15.7547 8.14169 15.9261 8.5236 15.9163C8.86662 15.9076 9.08129 15.7586 9.23962 15.6436C9.39795 15.5285 9.53154 15.4074 9.70463 15.2559L16.683 9.14992C16.8841 8.9739 17.1122 8.84836 17.2607 8.42713C17.3576 8.15149 17.3576 7.84857 17.2607 7.57294C17.1122 7.15185 16.8843 7.02622 16.683 6.85015L9.70463 0.744176C9.53154 0.5927 9.39794 0.471553 9.23962 0.356495C9.0813 0.241437 8.86659 0.0924004 8.5236 0.0837206ZM8.90494 2.25863L15.465 7.99992L8.90494 13.7412V10.5822C8.90497 10.4669 8.88178 10.3528 8.83679 10.247C8.79181 10.1413 8.72601 10.046 8.64346 9.96733C8.56092 9.8886 8.4634 9.82805 8.35697 9.78943C8.25053 9.75081 8.13745 9.73494 8.02476 9.74282C6.733 9.83229 5.46843 10.1717 4.29826 10.7449C3.70274 11.0365 3.14977 11.4037 2.62326 11.8044C2.89972 10.6532 3.34552 9.55391 4.13094 8.657C5.2034 7.43216 6.64609 6.61582 8.22273 6.33423C8.41367 6.3002 8.58674 6.19853 8.71152 6.04711C8.8363 5.89568 8.90479 5.70419 8.90495 5.50631L8.90494 2.25863Z" />
            </svg>
          </div>
          <div
            onClick={() => handleBookmark(id)}
            className="h-8 flex cursor-pointer items-center rounded-3 border border-blue-700 px-1 text-14 font-semibold capitalize text-blue-700 "
          >
            {!authStore?.value
              ? `${capitalizedFirst(t("global.follow"))}`
              : isFollow
                ? `${capitalizedFirst(t("global.follow"))}`
                : `${capitalizedFirst(t("global.followed"))}`}
          </div>
        </div>
      </div>
      {authStore?.value ? (
        <div className="flex w-full gap-2">
          <button
            onClick={() => setIsBookTable(true)}
            className="flex h-[44px] w-full items-center justify-center gap-2 rounded-3 border bg-blue-600 px-20px hover:bg-blue-700"
          >
            <p className="text-16 font-semibold text-white">
              {capitalizedFirst(t("merchant-page.reserve"))}
            </p>
          </button>
        </div>
      ) : null}
      {authStore?.value ? (
        <BookingModal
          restaurantId={id as string}
          isShowModalBooking={isBookTable}
          closeModal={() => setIsBookTable(false)}
        />
      ) : null}
      <ShareToFeed
        isShow={isOpenShare}
        handleClose={() => setIsOpenShare(false)}
        background={background}
        name={name}
        id={id as string}
        type={SHARE_TYPE.RESTAURANT}
        avatar={avatar}
        province={province}
      />
    </div>
  )
}
