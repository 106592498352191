import { AxiosRequestConfig, AxiosResponse } from "axios"
import { ReportDto } from "src/components/ratings-and-reviews-custom/ratings-and-reviews-custom-props"
import http from "src/config/interceptors"
import { PaginationResponseType, ResponseType } from "src/types/common"
import { RatingType } from "src/types/rating"

interface GetRatingParams {
  pageNum?: number
  pageSize?: number
  filters?: string
  referenceType?: "TIME_DESC" | "TIME_ASC" | "RELATE_TO_ME"
}

export interface FactorListType {
  id: string
  type: string
  value: string
  score: number
}

export interface MediaType {
  id: string
  type: string
  extension: string
  original_name: string
  signature: string
}

export interface CreateRatingProps {
  object_id: string
  object_type: string
  content: string
  rating: number
  notes: Array<{
    id: string
    name: string
  }>
  medias: MediaType[]
  factors: FactorListType[]
}

export interface RatingNote {
  id: string
  code: string
  content: string
}

export const getRatingNotes = (
  type?: string
): Promise<AxiosResponse<ResponseType<RatingNote[]>>> => {
  const url = `/rating/notes/${type || "PRODUCT"}/first-page?pageSize=100`
  return http.get(url)
}

export const getRating = (
  id: string,
  params: GetRatingParams
): Promise<AxiosResponse<PaginationResponseType<RatingType[]>>> => {
  const {
    pageNum = 0,
    pageSize = 10,
    referenceType = "TIME_DESC",
    filters,
  } = params
  return http.get(`/rating/ratings/${id}`, {
    params: {
      pageNum,
      pageSize,
      referenceType,
      filters,
    },
  })
}

export const getRatings = (ratingIds: string[]) => {
  let url = "rating/ratings"
  ratingIds.forEach((i, index) => {
    url = index
      ? (url = url + `&object_ids=${i}`)
      : (url = url + `?object_ids=${i}`)
  })

  return http.get(url, { isToken: true } as AxiosRequestConfig)
}

export const createRating = (resBody: CreateRatingProps) => {
  const url = "rating/ratings"
  return http.post(url, resBody, { isToken: true } as AxiosRequestConfig)
}

export const updateRating = (id: string, resBody: CreateRatingProps) => {
  const url = "rating/ratings/" + id
  return http.put(url, resBody, { isToken: true } as AxiosRequestConfig)
}

export const reportRating = (dto: ReportDto) => {
  return http.post("/report/report", dto, {
    isToken: true,
  } as AxiosRequestConfig)
}

export const getCategoryReportRating = () => {
  return http.get("/report/report-category/get-all")
}
