import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface SearchLocationState {
  value:
    | {
        province_id: string
        country_id: string
        country_name: string
        province_name: string
        latitude: number
        longitude: number
        code: number
        index: number
      }
    | undefined
  radius: number
}

export interface DataSearch {
  province_id: string
  country_id: string
  country_name: string
  province_name: string
  latitude: number
  longitude: number
  code: number
  index: number
}

export const defaultSearchLocationValue = {
  province_id: "75ef0a15-74c7-48c6-8eaf-01e6afa8d2c8",
  country_id: "1e5effa4-c844-48ee-a7b7-3f3c62ffc883",
  country_name: "Việt Nam",
  province_name: "Thành phố Hồ Chí Minh",
  code: 79,
  longitude: 106.62965,
  latitude: 10.82302,
  index: 4,
}

const initialState: SearchLocationState = {
  value: defaultSearchLocationValue,
  radius: 100,
}

export const searchLocationSlice = createSlice({
  name: "searchLocation",
  initialState,
  reducers: {
    updateSearchLocation: (state, action: PayloadAction<DataSearch>) => {
      state.value = action.payload
    },
    updateRadius(state, action: PayloadAction<number>) {
      state.radius = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateSearchLocation, updateRadius } = searchLocationSlice.actions

export default searchLocationSlice.reducer
