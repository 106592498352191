import http from "src/config/interceptors"

export const getRestaurantAddressDropdown = (language: string) => {
  const data = language === "vi" ? "vn" : "en"
  return http.get(`/shipment/address/countries/detail?language=${data}`)
}

export const getRestaurantPreflightSearch = (keyword: string) => {
  return http.get(
    `/restaurant/restaurants/preflight-search?pageNum=0&pageSize=12&keyword=${keyword}`
  )
}

export const getDepositSystemByRegion = (regionId: string) => {
  return http.get(
    `/billing/sample/${regionId}/RESTAURANT_DEPOSIT?regionType=COUNTRY&profileName=DEFAULT`
  )
}
