import React from "react"
import { ImgCustom } from "src/components/common"
import { ReviewType } from "./type"
import { Rating } from "primereact/rating"
import { convertDateString } from "src/utils/convert-date-to-string"

export default function ReviewCards(props: ReviewType) {
  const handleCheckUserName = () => {
    if (props?.userName && props?.userName?.length > 1) {
      return props?.userName
    } else {
      return "Inactive user"
    }
  }

  return (
    <div className="flex h-[136px] w-full flex-col gap-2 rounded-3 bg-gray-100 px-3 py-2">
      {/* info */}
      <div className="flex w-full items-center gap-2">
        <div className="w-9 flex items-center">
          <div className="flex h-6 w-6 items-center justify-center">
            <ImgCustom
              isAvatar={true}
              url={props?.imgUrl || ""}
              alt="Avatar"
              className="rounded-full"
            />
          </div>
        </div>
        {/* card review */}
        <div className="flex h-full w-full flex-col justify-center gap-2px">
          <div className="item-center flex w-full justify-between">
            <div className="truncate text-16 font-medium leading-5 text-gray-700">
              {handleCheckUserName()}
            </div>
            <div className="flex items-center justify-end"></div>
            <div className="truncate text-14 font-normal leading-5 text-gray-500">
              {convertDateString(props?.commentDate)}
            </div>
          </div>
          <div className="flex w-full items-center">
            <Rating
              readOnly
              onIcon={
                <i className="pi pi-star-fill text-16 text-amber-400"></i>
              }
              offIcon={
                <i className="pi pi-star-fill text-16 text-gray-200"></i>
              }
              className="relative mr-1"
              value={props?.valueRating}
              cancel={false}
            />
          </div>
        </div>
      </div>
      {/* attributes */}
      <div className="flex w-full items-center gap-1">
        {props?.notes?.slice(0, 3)?.map((note, index) => (
          <div
            className="rounded-5 border border-blue-600 p-2px px-1 text-12 font-medium leading-4 text-blue-600"
            key={index}
          >
            {note.content}
          </div>
        ))}
      </div>
      {/* Comment */}
      <div className="flex w-full items-center">
        <p className="line-clamp-1 w-full text-14 font-normal leading-5 text-gray-700">
          {props?.comment}
        </p>
      </div>
    </div>
  )
}
