import { RadiusType } from "src/components/search-home-view/types"

export const LAYOUT_TYPE = {
  IMAGE_WITH_TEXT: "IWT",
  BANNER: "BANNER",
  SLIDESHOW: "SINGLESLIDESHOW",
  MULTIPLE_SLIDESHOW: "MULTIPLESLIDESHOW",
  TITLE: "TITLE",
  PARAGRAPH: "PARAGRAPH",
  CATEGORY_LIST: "CATEGORYLIST",
  FOOD_LIST: "FOODLIST",
}

export const filterRating = [
  { id: "star5", starLabel: "5 Stars", startValue: 5 },
  { id: "star4", starLabel: "4 Stars & Up", startValue: 4 },
  { id: "star3", starLabel: "3 Stars & Up", startValue: 3 },
  { id: "star2", starLabel: "2 Stars & Up", startValue: 2 },
  { id: "star1", starLabel: "1 Star & Up", startValue: 1 },
]

export const SHARE_TYPE = {
  RESTAURANT: "RESTAURANT",
  FOOD: "FOOD",
}

export const ORDER_TYPE_VIEW = {
  SHOP: "shop",
  FOOD: "food",
  BOOKING: "booking",
}

export const CART_TYPE = {
  PRODUCT: "PRODUCT",
  FOOD: "FOOD",
}

export const ORDER_STEP_VIEW = {
  CART: "CART",
  CHECKOUT: "CHECKOUT",
  PAYMENT: "PAYMENT",
  COMPLETE: "COMPLETE",
}

export const PAYMENT_TYPE = {
  COD: "1",
  BANKING: "2",
  CREDIT: "3",
  PAYPAL: "4",
}
export const ADDRESS_TYPE = {
  HOME: "HOME",
  OFFICE: "OFFICE",
}

export const FOOD_CARD_TYPE = {
  RESTAURANT: "RESTAURANT",
  FOOD: "FOOD",
}

export const FOOD_BOOKMARK_CATEGORY = {
  RESTAURANT: "RESTAURANT_BOOKMARK",
  FOOD: "FOOD_BOOKMARK",
}
export const TYPE_PAGE = {
  PRODUCT: "PRODUCT",
  FOOD: "FOOD",
}

export const FACILITY_TYPE = {
  WIFI_FREE: "WIFI_FREE",
  PARKING_LOT: "PARKING_LOT",
  OPERATING_HOUR: "OPERATING_HOUR",
  CHARGING: "CHARGING",
}

export const IMAGE_TYPE = {
  WEB: "WEB",
  THUMBNAILS: "THUMBNAILS",
  LOCAL: "LOCAL",
}

export const STATE_RENDER_APP = {
  INIT: "INIT",
  IFRAME: "IFRAME",
  APP: "APP",
}

export const RECEIVE_TOKEN_URL = `${process.env.REACT_APP_HOME_DOMAIN_URL}/app-connect`

export const VOUCHER_TYPE = {
  VOUCHER: "VOUCHER",
  SHIPPING: "SHIPPING",
}
export const DISCOUNT_TYPE = {
  PRICE: "PRICE",
  PERCENTAGE: "PERCENTAGE",
  COIN: "COIN",
}
export const VOUCHER_EXPIRED_TIME = 1890925151059
export const PLATFORM_APPLY = ["WEB", "ALL"]

export const SERVICE = {
  STOCK: "stock",
  CATALOG: "catalog",
  SHOP: "shop",
  STORAGE: "storage",
  FOOD: "food",
  ANGULAR_CATALOG: "catalog",
  RESTAURANT: "restaurant",
  PROMOTION: "promotion",
  PAYMENT: "payment",
  CART: "cart",
  RATING: "rating",
  SHIPMENT: "shipment",
  ORDER: "order",
  FOOD_ORDER: "food-order",
  ADVERTISING: "advertising",
}
export const TYPELINK = {
  LINK: "link",
  DOMAIN_URL_YOUTUBE: "https://www.youtube.com/",
}

export const RECOMMEND_TIME = {
  MORNING: "BREAKFAST",
  MIDDAY: "LUNCH",
  DINNER: "DINNER",
  POPULAR_NEAR_YOU: "POPULAR_NEAR_YOU",
  MORNING_REPAST: "MORNING_REPAST",
  MIDDAY_DELIGHTS: "MIDDAY_DELIGHTS",
  DINNER_DELIGHTS: "DINNER_DELIGHTS",
}

export const RESERVATION_CODE = {
  CANCEL: "CANCEL",
  CHECK_IN: "CHECK_IN",
  CONFIRMED: "CONFIRMED",
  PENDING: "PENDING",
}

export const ALL_FOOD_CODE = {
  CODE: "ALL_FOOD",
}

export const radius: RadiusType[] = [
  { name: "1km", code: 1 },
  { name: "3km", code: 3 },
  { name: "5km", code: 5 },
  { name: "7km", code: 7 },
  { name: "7km +", code: 20 },
]

export const REPORT_TYPE = {
  RATING: "RATING",
  APPROVER: "SUPERADMIN",
  REASON_TYPE: "OTHER",
  RESTAURANTRATING: "RESTAURANTRATING",
}

export const LOGO = {
  NAME: process.env.REACT_APP_TITLE,
}
