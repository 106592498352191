/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import "./index.scss"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import * as userService from "src/services/user-service"
import { InputText } from "primereact/inputtext"
import { BuyerInfoFormType } from "./types"
export default function BuyerInfoForm(props: BuyerInfoFormType) {
  const { updateUser } = props
  const { t } = useTranslation()
  const authStore = useSelector((state: RootState) => state.auth)
  const dataForm = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required(
        capitalizedFirst(t("book-table.required-field"))
      ),
      email: Yup.string()
        .required(capitalizedFirst(t("book-table.required-field")))
        .matches(/^\S+@\S+\.\S+$/),
      phone: Yup.string().required(
        capitalizedFirst(t("book-table.required-field"))
      ),
    }),
    onSubmit: () => {
      if (dataForm.isValid) {
        dataForm.resetForm()
      }
    },
    validateOnChange: true,
    validateOnMount: true,

  })

  useEffect(() => {
    const { email, full_name, phone} = dataForm.values
    const emailRegex = /^\S+@\S+\.\S+$/
    const isEmail = emailRegex.test(email)
    const isValid = Boolean(isEmail && full_name && phone)
    if (isValid) {
      updateUser(dataForm.values, true)
    } else {
      updateUser(undefined, false)
    }
  }, [dataForm.values])

  useEffect(() => {
    if (authStore?.value?.user?.id) {
      userService
        .getUserInfo(authStore.value.user.id)
        .then((res: any) => {
          if (res?.data?.data) {
            const { full_name, email, phone } = res.data.data
            dataForm.setFieldValue("full_name", full_name)
            dataForm.setFieldValue("email", email)
            dataForm.setFieldValue("phone", phone)
          }
        })
        .catch((err) => err)
    }
  }, [authStore?.value?.user?.id])
  const isInValidField = (name: string) => {
    return !!(
      dataForm?.touched[`${name}` as keyof typeof dataForm.touched] &&
      dataForm?.errors[`${name}` as keyof typeof dataForm.errors]
    )
  }

  return (
    <div className="flex flex-col rounded-3 border border-gray-200">
      <span className="p-3 text-[16px] font-semibold leading-[24px] text-gray-900 ">
        {capitalizedFirst(t("buy-voucher.buyer-information"))}
      </span>
      <div className="flex flex-col border-t-[1px] border-gray-200 p-3">
        <div className="flex flex-col gap-4px">
          <span className="text-[14px] font-medium leading-[20px] text-gray-700">
            {capitalizedFirst(t("global.fullName"))}
            <span className=" text-red-500">{" *"}</span>
          </span>
          <InputText
            className={`h-[44px] ${
              isInValidField("full_name")
                ? " p-invalid border border-[#e24c4c]"
                : ""
            }`}
            name="full_name"
            value={dataForm.values.full_name}
            onChange={(e) => {
              if (!dataForm?.touched?.full_name)
                dataForm.setTouched({
                  ...dataForm.touched,
                  ...{ full_name: true },
                })
              dataForm.setFieldValue("full_name", e?.target?.value)
            }}
          />
          {dataForm.errors.full_name && dataForm.touched.full_name && (
            <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
              {dataForm.errors.full_name}
            </span>
          )}
        </div>
        <div className="mt-3 flex flex-1 flex-col gap-[4px]">
          <span className="text-14 font-medium">
            {capitalizedFirst(t("global.email"))}
            <span className=" text-red-500">{" *"}</span>
          </span>
          <InputText
            className={`h-[44px] ${
              isInValidField("email")
                ? " p-invalid border border-[#e24c4c]"
                : ""
            }`}
            name="email"
            value={dataForm.values.email}
            onChange={(e) => {
              if (!dataForm?.touched?.email)
                dataForm.setTouched({
                  ...dataForm.touched,
                  ...{ email: true },
                })
              dataForm.setFieldValue("email", e?.target?.value.trim())
            }}
          />
          {dataForm.errors.email && dataForm.touched.email && (
            <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
              Please enter the email!
            </span>
          )}
        </div>

        <div className=" mt-3 flex flex-1 flex-col gap-[4px]">
          <label className="text-14 font-medium">
            {capitalizedFirst(t("global.phoneNumber"))}
            <span className=" text-red-500">{" *"}</span>
          </label>
          <InputText
            className={`h-[44px] ${
              isInValidField("phone") ? "p-invalid border border-[#e24c4c]" : ""
            }`}
            name="phone"
            value={dataForm.values.phone}
            onChange={(e) => {
              if (!dataForm?.touched?.phone)
                dataForm.setTouched({
                  ...dataForm.touched,
                  ...{ phone: true },
                })
              dataForm.setFieldValue("phone", e?.target?.value)
            }}
          />
          {dataForm.errors.phone && dataForm.touched.phone && (
            <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
              {dataForm.errors.phone}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
