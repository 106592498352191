/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"
import { BookTableProps } from "./type"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import bookTableCompleteIcon from "src/assets/images/book-table-complete.svg"
import bookTablePhoneIcon from "src/assets/images/booking-table-phone.svg"
import bookTableAddressIcon from "src/assets/images/booking-table-address.svg"
import bookTableEmailIcon from "src/assets/images/booking-table-email.svg"
import { convertTimeCommon } from "src/utils/time-utils"
import { RestaurantControllerApi } from "@soctrip/axios-restaurant-service"
import "./index.scss"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import failOrderIcon from "../../../../assets/images/warning-icon.svg"
import { useNavigate } from "react-router-dom"
export interface RestaurantInfoType {
  name: string
  address: string
  phone: string
  email: string
}
export default function BookTableComplete({
  dataBooking,
  isComplete,
}: BookTableProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [restaurantInfo, setRestaurantInfo] = useState<RestaurantInfoType>({
    name: "",
    address: "",
    phone: "",
    email: "",
  })
  useEffect(() => {
    if (dataBooking?.restaurantId) {
      new RestaurantControllerApi(config(SERVICE.RESTAURANT))
        .restaurantsInfoIdGet(dataBooking.restaurantId)
        .then((res: any) => {
          const result = {
            name: "",
            address: "",
            phone: "",
            email: "",
          }
          if (res?.data?.data) {
            result.name = res?.data?.data?.name || ""
            result.address = res?.data?.data?.address || ""
            result.phone = res?.data?.data?.phone || ""
            result.email = res?.data?.data?.email || ""
          }
          setRestaurantInfo(result)
        })
        .catch((err) => console.log(err))
    }
  }, [dataBooking])

  const handleGoBack = ()=>{
    const path = `/food/restaurant/${dataBooking?.restaurantId}`
    navigate(path)
  }
  const handleGoMyReservation = () => {
    navigate("/food/orders")
  }

  if (!dataBooking) return null

  return (
    <div className="flex w-full  flex-col items-center rounded-t-3 px-2 py-4 ">
      <img
        className="mt-[24px] h-[48px] w-[48px]"
        src={isComplete ? bookTableCompleteIcon : failOrderIcon}
        alt="completeIcon"
      />
      <div className="mt-4 flex flex-col text-center">
        <span className="text-[24px] font-semibold leading-[32px] text-gray-900">
          {capitalizedFirst(t(isComplete ? "book-table.complete-title" : "book-table.fail-title" ))}
        </span>
        <span className="mt-1 whitespace-pre-line	text-[14px] leading-[20px] text-gray-500">
          {capitalizedFirst(t(isComplete ? "book-table.complete-info" : "book-table.fail-info"))}
        </span>
      </div>

      <div className="mt-4 flex w-full flex-col">
        <span className="text-[12px] font-medium leading-[16px] text-gray-400">
          {capitalizedFirst(t("book-table.book-title"))}
        </span>
        <span className="mt-1 text-[16px] font-semibold leading-[24px] text-gray-700">
          {restaurantInfo.name}
        </span>
        {restaurantInfo.address ? (
          <div className="mt-1 flex items-center">
            <img
              className="h-[16px] w-[16px]"
              src={bookTableAddressIcon}
              alt="bookTableAddressIcon"
            />
            <span className="ml-1 text-[14px] leading-[20px] text-gray-700 ">
              {restaurantInfo.address}
            </span>
          </div>
        ) : null}
        {restaurantInfo.phone ? (
          <div className="mt-1 flex items-center">
            <img
              className="h-[16px] w-[16px]"
              src={bookTablePhoneIcon}
              alt="bookTablePhoneIcon"
            />
            <span className="ml-1 text-[14px] leading-[20px] text-gray-700 ">
              {restaurantInfo.phone}
            </span>
          </div>
        ) : null}
        {restaurantInfo.email ? (
          <div className="mt-1 flex items-center">
            <img
              className="h-[16px] w-[16px]"
              src={bookTableEmailIcon}
              alt="bookTableEmailIcon"
            />
            <span className="ml-1 text-[14px] leading-[20px] text-gray-700 ">
              {restaurantInfo.email}
            </span>
          </div>
        ) : null}

        <div className="mt-4 flex items-center gap-3 md:gap-4">
          <div className="flex shrink-0 flex-col">
            <span className="text-[12px] font-medium leading-[16px] text-gray-400">
              {capitalizedFirst(t("book-table.book-time"))}
            </span>
            <span className="mt-[6px] text-[14px] leading-[20px] text-gray-700 ">
              {convertTimeCommon(
                dataBooking.bookingData.bookingInformation.time,
                true
              )}
            </span>
          </div>

          <div className="flex flex-1 flex-col">
            <span className="text-[12px] font-medium leading-[16px] text-gray-400">
              {capitalizedFirst(t("book-table.book-guest"))}
            </span>
            <span className="mt-[6px] text-[14px] leading-[20px] text-gray-700 ">
              {capitalizedFirst(
                t("book-table.book-guest-number", {
                  count: dataBooking.bookingData.bookingInformation.guest,
                })
              )}
            </span>
          </div>
          <div className="flex flex-1 flex-col text-[12px] font-medium leading-[16px]">
            <span className=" text-gray-400">
              {capitalizedFirst(t("book-table.book-status"))}
            </span>
            <div className={`mt-[6px] flex w-[85px] items-center justify-center rounded-3  py-[2px] text-center ${isComplete ? "text-emerald-600 bg-emerald-50" : "bg-red-400 text-white"}`}>
              {capitalizedFirst(t( isComplete ? "book-table.book-status-success" : "book-table.book-status-fail"))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 flex w-full flex-col">
        <span className="text-[12px] font-medium leading-[16px] text-gray-400">
          {capitalizedFirst(t("request-open-page.user-information"))}
        </span>
        <span className="mt-1 text-[16px] font-semibold leading-[24px] text-gray-700">
          {dataBooking?.bookingData.userInformation.fullName}
        </span>
        <div className="mt-1 flex items-center">
          <img
            className="h-[16px] w-[16px]"
            src={bookTablePhoneIcon}
            alt="bookTablePhoneIcon"
          />
          <span className="ml-1 text-[14px] leading-[20px] text-gray-700 ">
            {dataBooking?.bookingData.userInformation.phone}
          </span>
        </div>
        <div className="mt-1 flex items-center">
          <img
            className="h-[16px] w-[16px]"
            src={bookTableEmailIcon}
            alt="bookTableEmailIcon"
          />
          <span className="ml-1 text-[14px] leading-[20px] text-gray-700 ">
            {dataBooking?.bookingData.userInformation.email}
          </span>
        </div>
      </div>
      <div className="mt-4 flex flex-col md:flex-row items-center justify-end gap-1 w-full">
        <button
          type="button"
          onClick={() => handleGoMyReservation()}
          className="w-full md:w-fit rounded-3 border border-gray-200 bg-gray-200 px-20px py-1 text-16 font-semibold leading-24 text-gray-700 hover:bg-gray-100">
          {capitalizedFirst(t("global.my-reservation"))}
        </button>
        <button
          type="button"
          onClick={() => handleGoBack()}
          className=" w-full md:w-fit truncate rounded-3 bg-blue-600 text-center px-20px py-1 text-16 font-semibold leading-24 text-white hover:bg-blue-500">
          {capitalizedFirst(t("global.back-to-restaurant"))}
        </button>
      </div>
    </div>
  )
}
