import React, { useMemo } from "react"
import Empty from "../../../assets/images/empty.png"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { t } from "i18next"

interface PropsType {
  message?: string
  title?: string
}

export default function EmptyPage(props: PropsType) {
  const titleNotFound = useMemo(() => {
    // let results = "No results found"
    let results = capitalizedFirst(t("global.no-results-found"))
    if (props?.title) {
      results = props.title
    }
    return results
  }, [])
  return (
    <div className="flex h-full w-full flex-col items-center justify-center rounded-3 bg-white py-2">
      <img
        src={Empty}
        alt="empty image"
        className="object-cover object-center"
      />
      <div className="text-24 text-center font-semibold text-blue-light-400">
        {titleNotFound}
      </div>
      <div className="text-14 font-medium text-gray-500">{props?.message}</div>
    </div>
  )
}
