import http from "src/config/interceptors"

export const getRestaurantRecommend = (
  city: string,
  pageNum: number,
  pageSize: number
) => {
  return http.get("/food/foods/search", {
    params: {
      pageNum: pageNum,
      pageSize: pageSize,
      filters: city ? `str_address@=${city}` : "",
    },
  })
}

export const getPopularLocation = (provinceId: string, language: string) => {
  const langData = language === "vi" ? "vn" : "en"
  return http.get(
    `/restaurant/restaurants/${provinceId}/locations?language=${langData}`
  )
}
