import React from "react"
import SearchHomeView from "src/components/search-home-view"

export default function SearchPage() {
  return (
    <div className="mx-auto max-w-[1200px]">
      <SearchHomeView />
    </div>
  )
}
