import { Dialog } from "primereact/dialog"
import React from "react"
import "./index.scss"
import { ImgCustom } from "../common"
import VideoCustom from "../orders-view/order-food-rating/video-custom"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { MediaType } from "../ratings-and-reviews-custom/ratings-and-reviews-custom-props"

interface MediaModalProps {
  handleClose: () => void;
  mediaActivated: {
    id: string;
    index: number;
  }
  listImage: MediaType[]
}

export default function MediaModal(props: MediaModalProps) {
  const { handleClose, mediaActivated, listImage } = props

  return <Dialog id="video_full_screen" visible={true} style={{ width: "650px" }} onHide={handleClose}>
    <Swiper
      navigation={true}
      spaceBetween={38}
      modules={[Navigation]}
      slidesPerView={1}
      initialSlide={mediaActivated.index}
    >
      {listImage?.map((image) => (
        <SwiperSlide key={image.id}>
          {image?.isImage ? (
            <div className="h-full w-full cursor-pointer focus:shadow-none ">
              <ImgCustom
                url={image?.id}
                alt="commentImage"
                className="!h-[650px] w-[650px] rounded-4 object-contain p-6px"
              />
            </div>
          ) : (
            <div className=" !h-[650px] w-[650px] cursor-pointer object-contain">
              <VideoCustom type={image?.type} idSource={image?.id} />
            </div>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  </Dialog>
}
