import React from "react"
import starIcon from "src/assets/images/star-icon.svg"
import starUncheckIcon from "src/assets/images/star-un-check.svg"
import { StarType } from "./types"

const StarView = (props: StarType) => {
  const { isCheck, index, updateRate } = props
  const handleClick = () => {
    if (updateRate) updateRate(index + 1)
  }
  return (
    <div
      onClick={handleClick}
      className={`w-6 h-6 md:h-[56px] md:w-[56px] cursor-pointer ${index && "ml-4"}`}
    >
      <img
        className="h-full w-full"
        src={isCheck ? starIcon : starUncheckIcon}
        alt="starIcon"
      />
    </div>
  )
}
export default StarView
