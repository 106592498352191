/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react"
import { Dialog } from "primereact/dialog"
import {
  RatingRestaurantModalTypes,
  NoteReviewType,
  RatingDataSubmit,
} from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import ItemRatingView from "./item-rating-view"
import { handlePromiseAll } from "src/utils/common"
import EmptyPage from "src/components/common/empty"
import {
  RatingControllerApi,
  NoteControllerApi,
} from "@soctrip/axios-rating-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { useToast } from "src/hooks/use-toast"
import i18n from "src/locales"

export default function RatingRestaurantModal(
  props: RatingRestaurantModalTypes
) {
  const showToast = useToast()
  const { t } = useTranslation()
  const {
    isShow,
    handleClose,
    handleConfirm,
    isRated,
    orderId,
    restaurantData,
    restaurantId,
    code,
  } = props
  const [ratingProductData, setRatingProductData] = useState<RatingDataSubmit>()
  const [notesReview, setNotesReview] = useState<NoteReviewType[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const getFactorData = () => {
    return [
      {
        type: "RESERVATION",
        id: orderId,
      },
    ]
  }
  const getNotesSubmit = (data: any) => {
    const result: any[] = []
    if (data.length) {
      data.forEach((i: any) => {
        result.push({
          id: i?.id,
          content: i?.name,
        })
      })
    }
    return result
  }
  const handleSubmit = () => {
    const resData = {
      object_id: restaurantId,
      object_type: "RESTAURANT",
      object_code: code,
      content: ratingProductData?.content || "",
      rating: ratingProductData?.rating || 5,
      factors: getFactorData(),
      notes: getNotesSubmit(ratingProductData?.notes),
      medias: ratingProductData?.medias || [],
    }
    console.log(resData, "resData")
    // return
    setIsSubmitting(true)
    new RatingControllerApi(config(SERVICE.RATING))
      .ratingsPost(resData)
      .then((res) => {
        if (res?.data?.data) {
          showToast({
            detail: capitalizedFirst(t("modal.rating-completed")),
            severity: "success",
          })
          handleConfirm()
        } else {
          showToast({
            detail: "Rating failed. Please try again!",
            severity: "error",
          })
          handleClose()
        }
      })
      .catch(() => {
        showToast({
          detail: "Rating failed. Please try again!",
          severity: "error",
        })
        handleClose()
      })
      .finally(() => setIsSubmitting(false))
  }

  const getNotes = async () => {
    const language = i18n.language
    new NoteControllerApi(config(SERVICE.RATING))
      .notesTypeFirstPageGet("RESTAURANT", 10, language)
      .then((res) => {
        const result: NoteReviewType[] = []
        if (res?.data?.data?.length) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.id || "",
              name: i?.content || "",
            })
          })
        }
        setNotesReview(result)
      })
      .catch((err) => console.log(err))
  }

  const getCurrentRating = () => {
    new RatingControllerApi(config(SERVICE.RATING))
      .ratingsGet([restaurantId])
      .then((res) => {
        if (res?.data?.data?.length) {
          const ratingResult: RatingDataSubmit = {
            rating: 0,
            notes: [],
            content: "",
            medias: [],
          }
          res.data.data.forEach((i: any) => {
            if (i?.factors?.length && i?.factors[0]?.id === orderId) {
              ratingResult.rating = i?.rating || 0
              ratingResult.notes = getCurrentNotes(i?.notes)
              ratingResult.content = i?.content || ""
              ratingResult.medias = i?.medias || []
            }
          })
          setRatingProductData(ratingResult)
        }
      })
  }
  const getCurrentNotes = (data: any) => {
    const result: NoteReviewType[] = []
    if (data?.length) {
      data.forEach((i: any) => {
        result.push({
          id: i?.id || "",
          name: i?.content || "",
        })
      })
    }
    return result
  }
  const initDataRate = () => {
    const ratingResult: RatingDataSubmit = {
      rating: 5,
      notes: [],
      content: "",
      medias: [],
    }
    setRatingProductData(ratingResult)
  }

  useEffect(() => {
    async function fetchData() {
      const apis = []
      if (isRated) apis.push(getCurrentRating())
      apis.push(getNotes())
      handlePromiseAll(apis).then(() => {
        if (!isRated) initDataRate()
      })
    }

    fetchData()
  }, [])

  const isValidForm = useMemo(() => {
    if (ratingProductData) {
      return !(ratingProductData.content?.trim()?.length === 0)
    }
    return false
  }, [ratingProductData])

  const updateRatingItem = (rateItem: RatingDataSubmit) => {
    setRatingProductData(rateItem)
  }

  const FooterContent = (
    <div className="mt-3 flex justify-end text-16 font-semibold leading-24">
      {!isRated ? (
        <div className="flex items-center">
          <button
            className="rounded-3 border border-gray-300  px-18px py-10px text-gray-700 hover:bg-gray-100"
            onClick={() => handleClose()}
          >
            {capitalizedFirst(t("global.cancel"))}
          </button>
          <button
            disabled={!isValidForm || isSubmitting}
            className={`ml-2 flex items-center gap-1 rounded-3 border px-18px py-10px text-white  ${
              isValidForm
                ? "border-blue-500 bg-blue-500 hover:bg-blue-600"
                : "border-blue-300 bg-blue-300"
            }`}
            onClick={handleSubmit}
          >
            {capitalizedFirst(t("global.submit"))}
            {isSubmitting && (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "1rem" }}
              ></i>
            )}
          </button>
        </div>
      ) : (
        <button
          className="rounded-3 border border-gray-300 px-18px py-10px text-gray-700 hover:bg-gray-100"
          onClick={() => handleClose()}
        >
          {capitalizedFirst(t("global.close"))}
        </button>
      )}
    </div>
  )

  if (!isShow) return null
  return (
    <Dialog
      draggable={false}
      header={capitalizedFirst(t("modal.ratingTileRestaurant"))}
      visible={isShow}
      footer={FooterContent}
      onHide={() => handleClose()}
    >
      <div className="flex w-full md:w-[640px] flex-col px-4 pb-4">
        <div className="flex flex-col">
          {!restaurantData || !ratingProductData ? (
            <div className="h-[300px] w-full">
              <EmptyPage message="No data found." />
            </div>
          ) : (
            <ItemRatingView
              isRated={isRated}
              notesReview={notesReview}
              updateRatingItem={updateRatingItem}
              restaurantData={restaurantData}
              ratingProductData={ratingProductData}
            />
          )}
        </div>
      </div>
    </Dialog>
  )
}
