import http from "src/config/interceptors"

export const bookmarkProduct = (
  productId?: string,
  object_category?: string
) => {
  const dto = {
    object_id: productId,
    dag_object: object_category,
  }
  return http.post("/bookmarks/bookmarks", dto)
}

export const deleteBookmark = (id?: string) => {
  return http.delete(`/bookmarks/bookmarks/${id}`)
}

export const getBloomFilter = () => {
  return http.get("/bookmarks/bookmarks/bloom-filter")
}
