export function convertISOToDDMMYYYY(isoDateString: string | number): string {
  const isVietNam = localStorage.getItem("i18nextLng") === "vi" || false
  const isoDate = new Date(isoDateString)

  const day = isoDate.getDate().toString().padStart(2, "0")
  const month = (isoDate.getMonth() + 1).toString().padStart(2, "0")
  const year = isoDate.getFullYear().toString()

  const formattedDate = isVietNam
    ? `${day}/${month}/${year}`
    : `${month}/${day}/${year}`

  return formattedDate
}

export function convertISOToDDMMYYYYHHMM(
  isoDateString: string | number,
  isFullTime?: boolean
): string {
  const isVietNam = localStorage.getItem("i18nextLng") === "vi" || false
  const isoDate = new Date(isoDateString)

  const day = isoDate.getDate().toString().padStart(2, "0")
  const month = (isoDate.getMonth() + 1).toString().padStart(2, "0")
  const year = isoDate.getFullYear().toString()

  const hours =
    isoDate.getHours() < 10 ? `0${isoDate.getHours()}` : isoDate.getHours()
  const minutes =
    isoDate.getMinutes() < 10
      ? `0${isoDate.getMinutes()}`
      : isoDate.getMinutes()
  console.log(isFullTime)
  const formattedDate = isVietNam
    ? `${day}/${month}/${year} ${hours}:${minutes}`
    : `${month}/${day}/${year} ${hours}:${minutes}`

  return formattedDate
}

export const convertTimeCommon = (
  value: string | number,
  isTime?: boolean,
  isFullTime?: boolean
) => {
  return !isTime
    ? convertISOToDDMMYYYY(value)
    : convertISOToDDMMYYYYHHMM(value, isFullTime)
}
