import { BreadCrumb } from "primereact/breadcrumb"
import { MenuItem } from "primereact/menuitem"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import {
  ReservationControllerApi,
  ReservationDetailDTO,
} from "@soctrip/axios-food-order-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import Loading from "src/components/common/loading"
import ReservationInformation from "src/components/reservation-details-view/reservation-information"
import ReservationUserInformation from "src/components/reservation-details-view/reservation-user-information"
import ReservationRestaurantInformation from "src/components/reservation-details-view/reservation-restaurant-information"
import ReservationStatus from "src/components/reservation-details-view/reservation-status"

export default function ReservationDetails() {
  const param = useParams()
  const id = param?.id
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [reservationDetails, setReservationDetails] =
    useState<ReservationDetailDTO>()

  const items: MenuItem[] = [
    {
      label: capitalizedFirst(t("global.my-reservation")),
      className: "font-semibold",
      command: () => {
        navigate("/food/orders")
      },
    },
    {
      label: capitalizedFirst(t("reservation.reservation-details")),
      className: "font-semibold",
      command: () => {
        navigate(`/food/orders/${id}`)
      },
    },
  ]

  const home: MenuItem = {
    icon: "pi pi-home",
    command: () => {
      navigate("/")
    },
  }

  const handleGetReservationDetails = async (id: string) => {
    await new ReservationControllerApi(config(SERVICE.FOOD_ORDER))
      .findById(id)
      .then((response) => {
        setIsLoading(false)
        setReservationDetails(response?.data?.data)
      })
      .catch(() => setIsLoading(false))
  }

  useEffect(() => {
    handleGetReservationDetails(id as string)
  }, [id])

  return (
    <div id="reservationDetail" className="mx-auto w-full max-w-[1200px] md:px-3 ">
      <div className="mb-4 flex w-full flex-col gap-3">
        {/* breadcrumb */}
        <div className="item-center flex w-full">
          <BreadCrumb
            model={items}
            home={home}
            className="flex h-[52px] w-full items-center border-none bg-transparent text-16 px-[0px] md:px-3"
          />
        </div>
        {/* content */}
        {isLoading ? (
          <div className="h-[700px] w-full">
            <Loading />
          </div>
        ) : (
          <div className="flex w-full flex-col gap-3">
            <ReservationInformation reservations={reservationDetails} />
            <div className="flex w-full flex-col gap-4 md:flex-row">
              <div className="w-full md:w-1/2">
                <ReservationUserInformation reservations={reservationDetails} />
              </div>
              <div className="w-full md:w-1/2">
                <ReservationRestaurantInformation
                  reservations={reservationDetails}
                />
              </div>
            </div>
            <div className="flex w-full items-center">
              <ReservationStatus
                reservations={reservationDetails}
                handleReload={() => handleGetReservationDetails(id as string)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
