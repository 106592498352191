import { DataArrayViewProduct } from "./type"
import { handleClickSponsors } from "../sponsor-service"

export const handleCountViewBoth = (restaurantId: string) => {
  // eslint-disable-next-line prefer-const, @typescript-eslint/no-explicit-any
  let originalData: any[] = JSON.parse(
    localStorage.getItem("dataArrayFood") as string
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let newData: any[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let dataArray: DataArrayViewProduct[] = []

  dataArray = [
    {
      m: "RESTAURANT",
      c: "U",
      a: "C",
      d: restaurantId,
      co: 1,
      l: "Restaurant click event",
    },
  ]

  if (originalData?.length) {
    newData = [...originalData, ...dataArray]
  } else {
    newData = dataArray
  }
  localStorage.setItem("dataArrayFood", JSON.stringify(newData))
}

export const handlePostCountViewBoth = () => {
  const timeout = setTimeout(() => {
    const data: DataArrayViewProduct[] = JSON.parse(
      localStorage.getItem("dataArrayFood") as string
    )
    if (data?.length >= 5) {
      const dto = {
        platform: "Web",
        screenSize: "1920x1080",
        createdAt: Date.now(),
        sessionId: "",
        dataArray: data,
      }

      handleClickSponsors(dto)
        .then(() => {
          localStorage.removeItem("dataArrayFood")
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, 500)
  return () => clearTimeout(timeout)
}
