import React, { useEffect, useMemo, useState } from "react"
import HeaderView from "./header-view"
import ShopInformationForm from "./shop-information"
import UserInformationForm from "./user-information"
import { useFormik } from "formik"
import * as Yup from "yup"
import { RestaurantTypeControllerApi } from "@soctrip/axios-restaurant-service"
import { config } from "src/config/interceptors"
import { SERVICE, TYPE_PAGE } from "src/constants/common"
import {
  RestaurantType,
  InputFormType,
  TypeEnum,
  RestaurantCreateDTOCertificateTypeEnum,
  DocumentType,
} from "../types"
import { RestaurantControllerApi } from "@soctrip/axios-restaurant-service"
import { useNavigate } from "react-router-dom"
import { ImageType } from "./upload-image"
import { useToast } from "src/hooks/use-toast"
import { uploadFiles } from "src/services/file-service"
import VerifiedInformation from "./verified-information"
import PolicyModal from "src/components/common/modal/policy-modal"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

const InputForm = (props: InputFormType) => {
  const navigate = useNavigate()
  const { typePage, updateDateComplete } = props
  const [certificates, setCertificates] = useState<ImageType[]>([])
  const [documents, setDocuments] = useState<DocumentType[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isShowPolicyModal, setIsShowPolicyModal] = useState<boolean>(false)
  const [isValidCertificates, setIsValidCertificates] = useState<boolean>(false)
  const [isValidDocument, setIsValidDocument] = useState<boolean>(false)

  const showToast = useToast()
  const { t } = useTranslation()
  const dataForm = useFormik({
    initialValues: {
      shop_name: "",
      shop_address: "",
      sub_shop_address: "",
      shop_country: null,
      shop_city: null,
      shop_district: null,
      shop_ward: null,
      shop_category: [],
      user_name: "",
      user_email: "",
      user_phone: "",
      deposit: null,
      is_deposit: true,
      type: TypeEnum.personal,
      note: "",
      currency: "",
      is_campaign: false,
      opening_time: "",
      closing_time: "",
    },
    validationSchema: Yup.object({
      shop_name: Yup.string().required("Required!"),
      shop_address: Yup.string().required("Required!"),
      sub_shop_address: Yup.string(),
      shop_country: Yup.object().shape({}).required("Required!"),
      shop_city: Yup.object().shape({}).required("Required!"),
      shop_district: Yup.object().shape({}).required("Required!"),
      shop_category: Yup.array().min(1, "Required!").required("Required!"),
      user_name: Yup.string().required("Required!"),
      user_email: Yup.string()
        .required("Required!")
        .email("Please enter the email"),
      user_phone: Yup.string().required("Required!"),
      currency: Yup.string().required("Required!"),
      deposit: Yup.number().required("Required!"),
      opening_time: Yup.string().required("Required!"),
      closing_time: Yup.string().required("Required!"),
    }),
    onSubmit: () => {
      dataForm.resetForm()
    },
    validateOnChange: true,
    validateOnMount: true,
  })

  const [restaurantTypes, setRestaurantTypes] = useState<RestaurantType[]>([])
  const getRestaurantType = () => {
    new RestaurantTypeControllerApi(config(SERVICE.RESTAURANT))
      .restaurantTypesGet()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          setRestaurantTypes(res.data.data.data as RestaurantType[])
        }
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    if (typePage === TYPE_PAGE.FOOD) getRestaurantType()
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getAddressField = (data: any) => {
    return {
      id: data?.id,
      name: data?.name,
      postal_code: data?.postal_code || "00000",
    }
  }

  const getRequestData = () => {
    const wardData = dataForm.values.shop_ward
      ? dataForm.values.shop_ward
      : dataForm.values.shop_district
    let requestData = {
      name: dataForm.values.shop_name,
      country: getAddressField(dataForm.values.shop_country),
      province: getAddressField(dataForm.values.shop_city),
      district: getAddressField(dataForm.values.shop_district),
      ward: getAddressField(wardData),
      street: dataForm.values.shop_address,
      sub_street: dataForm.values.sub_shop_address,

      owner_name: dataForm.values.user_name,
      email: dataForm.values.user_email,
      phone: dataForm.values.user_phone,
      deposit: dataForm.values.deposit || 0,
      is_deposit: dataForm.values.is_deposit,
      certificate_type:
        dataForm.values.type === "INDIVIDUAL"
          ? RestaurantCreateDTOCertificateTypeEnum.INDIVIDUAL
          : RestaurantCreateDTOCertificateTypeEnum.ENTERPRISE,
      other_documents: [
        ...documents.filter((item) => !!item).map((d) => d.data),
      ] as [],
      certificates: [],
      note: dataForm.values.note,
      currency: dataForm.values.currency,
      is_campaign: dataForm.values.is_campaign,
      open_time: new Date(dataForm.values.opening_time),
      closed_time: new Date(dataForm.values.closing_time),
    }
    if (typePage === TYPE_PAGE.FOOD) {
      const categoryFields = {
        restaurant_type_ids: dataForm.values.shop_category?.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (i: any) => i?.id
        ),
        restaurant_types: dataForm.values.shop_category?.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (i: any) => i?.name
        ),
      }
      requestData = { ...requestData, ...categoryFields }
    }
    return requestData
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getAddressShop = (data: any) => {
    let result = ""
    if (data?.street) result = result + data.street
    if (data?.ward?.name && data?.ward?.id !== data?.district?.id)
      result = result + ", " + data.ward.name
    if (data?.district?.name) result = result + ", " + data.district.name
    if (data?.province?.name) result = result + ", " + data.province.name
    if (data?.country?.name) result = result + ", " + data.country.name
    return result
  }

  const createShopFood = () => {
    if (dataForm.values.type == TypeEnum.business) {
      setCertificates([])
    }
    const requestData = getRequestData()
    uploadFiles(certificates.map((c) => c.file)).then((res) => {
      requestData.certificates = res?.data?.data ?? []
      new RestaurantControllerApi(config(SERVICE.RESTAURANT))
        .restaurantsPost(requestData)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data) {
            const dataComplete = {
              shop: {
                name: res.data.data?.name,
                address: getAddressShop(res.data.data),
              },
              user: {
                name: res.data.data?.owner_name,
                email: res.data.data?.email,
                phone: res.data.data?.phone,
              },
              category: res.data.data?.restaurant_types,
            }
            setIsShowPolicyModal(false)
            updateDateComplete(dataComplete)
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          showToast({
            detail: err?.response?.data?.error?.message,
            severity: "error",
          })
          setIsSubmitting(false)
        })
    })
  }

  const isValid = useMemo(() => {
    const idCardValid =
      certificates.length == 2 && certificates[0] && certificates[1]
    const documentValid =
      dataForm.values.type == TypeEnum.business ? documents.length : true
    return dataForm.isValid && idCardValid && documentValid
  }, [dataForm])

  const isValidCertificatesMeno = useMemo(() => {
    const idCardValid = certificates.length < 2
    return idCardValid
  }, [certificates])

  const isValidDocumentMemo = useMemo(() => {
    const documentValid =
      dataForm.values.type == TypeEnum.business &&
      Boolean(documents?.length == 0)
    return documentValid
  }, [documents])

  const handleSubmitForm = () => {
    if (isValid) setIsShowPolicyModal(true)
    else {
      dataForm.handleSubmit()
      setIsSubmitting(true)
    }
  }

  const handleSubmitRequest = () => {
    createShopFood()
  }

  useEffect(() => {
    if (isSubmitting) {
      setIsValidCertificates(isValidCertificatesMeno)
      setIsValidDocument(isValidDocumentMemo)
    }
  }, [isValidCertificatesMeno, isSubmitting, isValidDocumentMemo])
  return (
    <div className="max-w-[1200px]">
      <div className="flex flex-col">
        <HeaderView />
        <ShopInformationForm
          dataForm={dataForm}
          typePage={typePage}
          restaurantType={restaurantTypes}
        />
        <UserInformationForm dataForm={dataForm} />
        <VerifiedInformation
          dataForm={dataForm}
          setCertificates={setCertificates}
          documents={documents}
          setDocuments={setDocuments}
          isValidCertificate={isValidCertificates}
          isValidDocuments={isValidDocument}
        />
        <div className="mb-4 mt-4 flex justify-end px-3">
          <div className="text-16 font-semibold leading-24">
            <button
              onClick={() => navigate("/food")}
              className="rounded-3 border-gray-300 bg-white px-[18px] py-[10px] text-gray-700"
            >
              {capitalizedFirst(t("request-open-page.cancel"))}
            </button>
            <button
              // disabled={!isValid}
              onClick={handleSubmitForm}
              className="ml-2 rounded-3 border-blue-600 bg-blue-600 px-[18px] py-[10px] text-white"
            >
              {capitalizedFirst(t("request-open-page.send-request"))}
            </button>
          </div>
        </div>
      </div>
      {isShowPolicyModal ? (
        <>
          <PolicyModal
            isShow={isShowPolicyModal}
            handleClose={() => setIsShowPolicyModal(false)}
            handleSubmit={handleSubmitRequest}
          />
        </>
      ) : null}
    </div>
  )
}

export default InputForm
