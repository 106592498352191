import React, { useState, useEffect } from "react"
import "./index.scss"
import FilterView from "./filter-view"
import {
  DistrictProps,
  FoodTypeProps,
  Location,
  ParamProps,
  RadiusType,
  RestaurantTypeProps,
} from "./types"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import {
  getAddressNearYou,
  getFoodType,
  getRestaurantPage,
  getRestaurantType,
} from "src/services/filter-restaurant-service"
import { SkeletonLoading } from "../common"
import EmptyPage from "../common/empty"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { getBloomFilter } from "src/services/bookmark-service"
import { FACILITY_TYPE, FOOD_CARD_TYPE, radius } from "src/constants/common"
import { useDebounce } from "use-debounce"
import { PopularFoodType } from "../food-home-view/types"
import FoodCardView from "../food-home-view/food-card-view"
import { RestaurantControllerApi } from "@soctrip/axios-restaurant-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import "./index.scss"
import { handlePromiseAll } from "src/utils/common"
import MapModal from "../common/modal/map-modal"
import i18n from "src/locales"

export default function SearchHomeView() {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [restaurantPage, setRestaurantPage] = useState<number>(0)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [selectLocation, setSelectLocation] = useState<Location | undefined>(
    undefined
  )
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [isFirstLoad, seIsFirstLoad] = useState<boolean>(true)
  const [limitSkeleton, setLimitSkeleton] = useState<number>(5)
  const [message, setMessage] = useState<string>(
    "Data not found. Please try other filter options."
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams("")
  const [searchValue, setSearchValue] = useState(
    searchParams.get("keyword") ?? ""
  )
  const [searchDebounce] = useDebounce(searchValue, 0)
  const [addressList, setAddressList] = useState<DistrictProps[]>([])
  const [dataFilter, setDataFilter] = useState<ParamProps>()
  const [restaurantList, setRestaurantList] = useState<PopularFoodType[]>([])
  const [totalResults, setTotalResults] = useState<number>(0)
  const [filterStrings, setFilterStrings] = useState<string>("")
  const [originalFilter, setOriginalFilter] = useState<ParamProps>()
  const [isGetNearYou, setIsGetNearYou] = useState<boolean>(false)
  const [bloomData, setBloomData] = useState<string>("")
  const [restaurantTypeList, setRestaurantTypeList] = useState<
    RestaurantTypeProps[]
  >([])
  const [foodTypeList, setFoodTypeList] = useState<FoodTypeProps[]>([])
  const [selectedRadius, setSelectedRadius] = useState<RadiusType | null>(null)
  const [isShowRestaurantMap, setIsShowRestaurantMap] = useState<boolean>(false)
  const [restaurantModal, setRestaurantModal] = useState<Location | undefined>(
    undefined
  )

  // handle show filter
  const handleShowFilter = () => {
    setIsShowFilter(!isShowFilter)
  }

  const handleSelectedFilter = (data: ParamProps) => {
    setDataFilter(data)
  }

  useEffect(() => {
    if (isFirstLoad || isGetNearYou) return
    const stringArray: string[] = []
    setRestaurantList([])
    const currentPage = 0
    setRestaurantPage(currentPage)
    // search
    if (searchValue) {
      const textFilterName = `name@=${searchValue}`
      stringArray.push(textFilterName)
    }

    // province
    if (selectLocation) {
      getNearAddress(selectLocation?.province_id)
      const textFilterProvince = `province.id@=${selectLocation?.province_id}`
      stringArray.push(textFilterProvince)
    } else {
      setAddressList([])
    }

    // rating
    if (dataFilter?.rating) {
      const textFilterRating = `rating>=${dataFilter?.rating}`
      stringArray.push(textFilterRating)
    }

    // restaurant categories
    if (dataFilter?.categories?.length) {
      const listIds: string[] = []
      dataFilter.categories?.map((object) => {
        if (object.id) {
          listIds.push(object?.id)
        }
      })
      const textFilterCategories = "restaurant_type_ids==" + listIds?.join("|")
      stringArray.push(textFilterCategories)
    }

    // food categories
    if (dataFilter?.food_categories?.length) {
      const listIds: string[] = []
      dataFilter.food_categories?.map((object) => {
        if (object.id) {
          listIds.push(object?.id)
        }
      })
      const textFilterFoodCategories = "food_type_ids==" + listIds?.join("|")
      stringArray.push(textFilterFoodCategories)
    }

    // district
    if (dataFilter?.districts?.length) {
      const listIds: string[] = []
      dataFilter?.districts?.map((object) => {
        if (object.district_id) {
          listIds.push(object?.district_id)
        }
      })
      const textFilterDistricts = "district.id@=" + listIds?.join("|")
      stringArray.push(textFilterDistricts)
    }

    // facilities
    if (dataFilter?.facilities?.length) {
      dataFilter?.facilities?.map((object) => {
        if (object?.value === FACILITY_TYPE.WIFI_FREE) {
          stringArray.push("is_free_wifi==true")
        }
        if (object.value === FACILITY_TYPE.PARKING_LOT) {
          stringArray.push("is_parking_lot==true")
        }
        if (object.value === FACILITY_TYPE.OPERATING_HOUR) {
          stringArray.push("is_operating_hours==true")
        }
        if (object.value === FACILITY_TYPE.CHARGING) {
          stringArray.push("is_vehicle_charging==true")
        }
      })
    }

    const filterString = stringArray.join(",")
    setFilterStrings(filterString)
    fetchData(currentPage, filterString)
  }, [searchDebounce, originalFilter, selectLocation])

  // handle get restaurant page
  const fetchData = async (resPage: number, stringFilter?: string) => {
    let _filter = ""
    if (stringFilter) {
      _filter = encodeURIComponent(stringFilter)
    }
    setRestaurantList([])
    setLoading(true)
    await getRestaurantPage(resPage, 20, _filter)
      .then((response) => {
        setTotalResults(response?.data?.data?.totalElement)
        if (response?.data?.data?.data?.length) {
          const result: PopularFoodType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          response.data.data.data.forEach((i: any) => {
            result.push({
              id: i?.id,
              title: i?.name,
              imgUrl: i?.avatar?.id,
              point: i?.rating,
              countReview: i?.sum_reviews,
              is_free_wifi: i?.is_free_wifi,
              is_parking_lot: i?.is_parking_lot,
              is_operating_hours: i?.is_operating_hours,
              is_vehicle_charging: i?.is_vehicle_charging,
              address: {
                country: i?.country,
                province: i?.province,
                district: i?.district,
                ward: i?.ward,
                street: i?.street,
              },
              lat: i?.latitude || 0,
              lng: i?.longitude || 0,
            })
          })
          setRestaurantList(result)
        }
      })
      .catch(() => {
        setMessage("Network error. Please refresh page to try again.")
      })

    const timeout = setTimeout(() => {
      setLoading(false)
    }, 100)
    return () => clearTimeout(timeout)
  }

  useEffect(() => {
    if (location?.state) {
      if (location?.state?.provinceSelected) {
        setSelectLocation(location?.state?.provinceSelected)
      }
    }
    const value = searchParams.get("keyword")
    handleGetBloomData()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apis: any[] = []
    apis.push(getRestaurantTypeList())
    apis.push(getFoodTypeList())

    handlePromiseAll(apis).then(() => {
      const stringArray: string[] = []
      if (location?.state && value) {
        const textFilterName = `name@=${value}`
        stringArray.push(textFilterName)
        const textFilterProvince = location?.state?.provinceSelected
          ?.province_id
          ? `province.id@=${location?.state?.provinceSelected?.province_id}`
          : ""
        stringArray.push(textFilterProvince)
      } else {
        if (value) {
          const textFilterName = `name@=${value}`
          stringArray.push(textFilterName)
        } else if (location?.state?.food_type) {
          const textFilterFoodCategories = `food_type_ids==${location?.state?.food_type}`
          stringArray.push(textFilterFoodCategories)
        } else if (location?.state?.restaurant_type) {
          const textFilterFoodCategories = `restaurant_type_ids==${location?.state?.restaurant_type}`
          stringArray.push(textFilterFoodCategories)
        } else if (location?.state?.provinceSelected) {
          const textFilterProvince = location?.state?.provinceSelected
            ?.province_id
            ? `province.id@=${location?.state?.provinceSelected?.province_id}`
            : ""
          stringArray.push(textFilterProvince)
        } else {
          fetchData(restaurantPage).then(() => seIsFirstLoad(false))
        }
      }
      const filterString = stringArray.join(",")
      fetchData(restaurantPage, filterString).then(() => seIsFirstLoad(false))
    })
  }, [])

  // handle get address near you
  const getNearAddress = async (id?: string) => {
    await getAddressNearYou(id)
      .then((response) => {
        setAddressList(response?.data?.data?.districts)
      })
      .catch((error) => error)
  }
  useEffect(() => {
    getRestaurantTypeList()
    getFoodTypeList()
  },[i18n.language])
  
  // handle get restaurant type
  const getRestaurantTypeList = async () => {
    getRestaurantType(0, 1000, i18n.language)
      .then((response) => {
        setRestaurantTypeList(response?.data?.data?.data)
      })
      .catch((error) => error)
  }

  // handle get food type
  const getFoodTypeList = () => {
    getFoodType(0, 1000, i18n.language)
      .then((response) => {
        setFoodTypeList(response?.data?.data?.data)
      })
      .catch((error) => error)
  }

  useEffect(() => {
    if (!isFirstLoad) {
      setOriginalFilter(dataFilter)
      if (!dataFilter?.categories?.length) {
        if (searchValue) {
          if (selectLocation) {
            navigate(`/food/search?keyword=${searchValue}`, {
              state: {
                restaurant_type_ids: "",
                provinceSelected: selectLocation,
              },
            })
          } else {
            navigate(`/food/search?keyword=${searchValue}`, {
              state: {
                restaurant_type_ids: "",
              },
            })
          }
        } else {
          if (selectLocation) {
            navigate("/food/search", {
              state: {
                restaurant_type_ids: "",
                provinceSelected: selectLocation,
              },
            })
          } else {
            navigate("/food/search", { state: { restaurant_type_ids: "" } })
          }
        }
      }
      if (!dataFilter?.food_categories?.length) {
        if (searchValue) {
          if (selectLocation) {
            navigate(`/food/search?keyword=${searchValue}`, {
              state: { food_type_ids: "", provinceSelected: selectLocation },
            })
          } else {
            navigate(`/food/search?keyword=${searchValue}`, {
              state: { food_type_ids: "" },
            })
          }
        } else {
          if (selectLocation) {
            navigate("/food/search", {
              state: { food_type_ids: "", provinceSelected: selectLocation },
            })
          } else {
            navigate("/food/search", { state: { food_type_ids: "" } })
          }
        }
      }
    }
  }, [isFirstLoad, dataFilter])

  // handle view more
  const handleViewMore = () => {
    setLoadingMore(true)
    const current = restaurantPage + 1
    setRestaurantPage(current)
    fetchDataViewMore(current, filterStrings)
  }

  // handle get restaurant page
  const fetchDataViewMore = async (
    currentPage: number,
    stringFilter?: string
  ) => {
    if (!isGetNearYou) {
      let _filter = ""
      if (stringFilter) {
        _filter = encodeURIComponent(stringFilter)
      }
      await getRestaurantPage(currentPage, 20, _filter)
        .then((response) => {
          if (response?.data?.data?.data?.length) {
            const result: PopularFoodType[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            response.data.data.data.forEach((i: any) => {
              result.push({
                id: i?.id,
                title: i?.name,
                imgUrl: i?.avatar?.id,
                point: i?.rating,
                countReview: i?.sum_reviews,
                is_free_wifi: i?.is_free_wifi,
                is_parking_lot: i?.is_parking_lot,
                is_operating_hours: i?.is_operating_hours,
                is_vehicle_charging: i?.is_vehicle_charging,
                address: {
                  country: i?.country,
                  province: i?.province,
                  district: i?.district,
                  ward: i?.ward,
                  street: i?.street,
                },
                lat: i?.latitude || 0,
                lng: i?.longitude || 0,
              })
              setRestaurantList([...restaurantList, ...result])
            })
          }
          setLoadingMore(false)
        })
        .catch(() => {
          setMessage("Network error. Please refresh page to try again.")
        })
    } else {
      const current = restaurantPage + 1
      setRestaurantPage(current)
      handleGetNearYou(current, true)
    }
  }

  const handleViewLess = () => {
    setRestaurantPage(0)
    if (!isGetNearYou) {
      fetchData(0, filterStrings)
    } else {
      handleGetNearYou(0)
    }
  }

  // handle get restaurant near you
  const handleGetNearYou = (page: number, isLoadMore?: boolean) => {
    setIsGetNearYou(true)
    setAddressList([])
    setSelectLocation(undefined)
    setRestaurantPage(0)
    setRestaurantList([])
    const getRestaurant = () => {
      const geolocationAPI = navigator.geolocation
      if (geolocationAPI) {
        setLoading(true)
        geolocationAPI.getCurrentPosition(async (response) => {
          const { coords } = response
          await new RestaurantControllerApi(config(SERVICE.RESTAURANT))
            .restaurantsNearYouGet(
              coords?.latitude,
              coords?.longitude,
              selectedRadius?.code,
              page,
              20
            )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((response: any) => {
              setLoading(false)
              setLoadingMore(false)
              setTotalResults(response?.data?.data?.totalElement)
              if (response?.data?.data?.data?.length) {
                const result: PopularFoodType[] = []
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                response.data.data.data.forEach((i: any) => {
                  result.push({
                    id: i?.id,
                    title: i?.name,
                    imgUrl: i?.avatar?.id,
                    point: i?.rating,
                    countReview: i?.sum_reviews,
                    is_free_wifi: i?.is_free_wifi,
                    is_parking_lot: i?.is_parking_lot,
                    is_operating_hours: i?.is_operating_hours,
                    is_vehicle_charging: i?.is_vehicle_charging,
                    address: {
                      country: i?.country,
                      province: i?.province,
                      district: i?.district,
                      ward: i?.ward,
                      street: i?.street,
                    },
                    lat: i?.latitude || 0,
                    lng: i?.longitude || 0,
                  })
                })
                if (!isLoadMore) {
                  setRestaurantList(result)
                } else {
                  setRestaurantList([...restaurantList, ...result])
                }
              }
            })
            .catch((error) => error)
        })
      }
    }
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((response) => {
          if (response.state === "granted") {
            getRestaurant()
          } else if (response.state === "prompt") {
            getRestaurant()
          } else {
            console.log("Access denied")
          }
        })
        .catch((err) => err)
    }
  }

  useEffect(() => {
    setSelectLocation(location?.state?.provinceSelected)
    if (location?.hash) {
      const isGetNearYou = location?.hash?.split("#")?.[1].split("=")[1]
      const radiusHash = location?.hash?.split("#")?.[2].split("=")[1]
      const currentPage = 0
      setRestaurantPage(currentPage)
      if (isGetNearYou.toUpperCase() === "TRUE") {
        const index = radius.findIndex(
          (item) => item.code === Number(radiusHash)
        )
        setSelectedRadius(radius[index])
        setIsGetNearYou(true)
      } else {
        setIsGetNearYou(false)
      }
    } else {
      setIsGetNearYou(false)
    }
    if (location?.state?.food_type || location?.state?.restaurant_type) {
      setIsShowFilter(true)
    }
  }, [location])

  useEffect(() => {
    if (!isFirstLoad) {
      if (isGetNearYou) {
        const currentPage = 0
        setRestaurantPage(currentPage)
        handleGetNearYou(currentPage)
      }
    }
  }, [selectedRadius, isGetNearYou])

  const handleGetBloomData = async () => {
    await getBloomFilter()
      .then((response) => {
        setBloomData(response?.data?.data?.bloom_filter)
      })
      .catch((error) => error)
  }

  useEffect(() => {
    if (isShowFilter) {
      setLimitSkeleton(4)
    } else {
      setLimitSkeleton(5)
    }
  }, [isShowFilter])

  useEffect(() => {
    const value = searchParams.get("keyword")
    setSearchValue(value as string)
  }, [searchParams])

  const handleOpenRestaurantMap = (restaurantData: PopularFoodType) => {
    setRestaurantModal({
      province_name: "",
      province_id: "",
      longitude: restaurantData.lng || 0,
      latitude: restaurantData.lat || 0,
      locations: 0,
      country_name: "",
    })
    setIsShowRestaurantMap(true)
  }

  return (
    <div className="mx-auto mb-4 mt-3 flex max-w-[1200px] flex-col ">
      <div className="flex h-full w-full flex-col gap-2">
        {/* results text and button filter */}
        <div className="flex w-full items-center justify-between ">
          {!loading ? (
            <div className="flex items-center gap-10px text-16 font-semibold leading-30 text-gray-700">
              {selectLocation && <div>{selectLocation?.province_name}:</div>}
              {isGetNearYou && (
                <div>
                  {capitalizedFirst(
                    t("search-filter-page.restaurants-near-you")
                  )}
                  :
                </div>
              )}
              <div>{`${totalResults} ${t(
                "search-filter-page.locations-found"
              )}`}</div>
            </div>
          ) : (
            <div className="flex"></div>
          )}

          {!isGetNearYou ? (
            <div
              onClick={handleShowFilter}
              className={`flex cursor-pointer items-center gap-2 rounded-3 px-2 py-1 text-14 font-semibold leading-5 text-gray-700 hover:bg-blue-light-50 ${
                isShowFilter ? "bg-blue-light-100" : "bg-white"
              }`}
            >
              <span className="pi pi-filter flex items-center"></span>
              <div className="flex items-center">
                {capitalizedFirst(t("global.filter"))}
              </div>
            </div>
          ) : null}
        </div>
        {/* content */}
        <div className="flex h-full w-full flex-col gap-3 lg:flex-row">
          {/* filter option */}
          {isShowFilter && (
            <div className="top-3 block h-full w-full rounded-3 lg:sticky lg:w-1/3">
              <FilterView
                filterFunction={handleSelectedFilter}
                restaurantTypeList={restaurantTypeList}
                foodTypeList={foodTypeList}
                districtList={addressList}
                originalFilter={originalFilter}
                selectLocation={selectLocation}
              />
            </div>
          )}
          {/* product view */}
          <div
            className={`h-full  w-full ${isShowFilter ? "lg:w-2/3" : "w-full"}`}
          >
            {loading ? (
              <div className="h-full w-full">
                <SkeletonLoading limit={limitSkeleton} rows={3} />
              </div>
            ) : (
              <div className="h-full w-full">
                {restaurantList?.length === 0 ? (
                  <div className="h-[700px] w-full">
                    <EmptyPage message={message} />
                  </div>
                ) : (
                  <div
                    className={`${
                      isShowFilter
                        ? "grid w-full grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3"
                        : "grid w-full grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5"
                    }`}
                  >
                    {restaurantList?.map((item, index) => (
                      <div
                        key={index}
                        className="flex h-fit w-full flex-col rounded-3 border border-gray-200 bg-white shadow-sm md:shadow-none"
                      >
                        <FoodCardView
                          isFoodType={false}
                          isRatingView={true}
                          isAddressView={true}
                          type={FOOD_CARD_TYPE.RESTAURANT}
                          bloom_filter={bloomData}
                          handleReload={handleGetBloomData}
                          isClickShowMap={true}
                          handleShowMap={() => handleOpenRestaurantMap(item)}
                          {...item}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {restaurantList?.length ? (
                  <div className="mt-1 flex w-full items-center justify-center">
                    {restaurantList?.length < totalResults ? (
                      <div>
                        {loadingMore ? (
                          <div className="mt-1 animate-pulse text-14 font-medium text-gray-700">
                            <i
                              className="pi pi-spin pi-spinner text-blue-light-600"
                              style={{ fontSize: "1.5rem" }}
                            ></i>
                          </div>
                        ) : (
                          <div
                            onClick={handleViewMore}
                            className="cursor-pointer text-14 font-medium text-blue-700"
                          >
                            {capitalizedFirst(t("global.view-more"))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {restaurantList?.length > 20 ? (
                          <div
                            onClick={handleViewLess}
                            className="cursor-pointer text-14 font-medium text-blue-700"
                          >
                            {capitalizedFirst(t("shoppingPage.view-less"))}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isShowRestaurantMap && restaurantModal ? (
        <MapModal
          isHighlight={true}
          location={restaurantModal}
          isShow={isShowRestaurantMap}
          handleClose={() => setIsShowRestaurantMap(false)}
          category="Restaurant"
        />
      ) : null}
    </div>
  )
}
