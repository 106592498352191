import React from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { PaymentDetailType } from "../types"
import { convertCurrency } from "src/utils/convert-currency"

const PaymentDetail = (props: PaymentDetailType) => {
  const { t } = useTranslation()
  const { voucherName, total, subTotal } = props
  return (
    <div className="mx-4 mt-3 flex flex-col rounded-3 border border-gray-200">
      <span className="border-b border-gray-200 p-3 text-[16px] font-semibold leading-[24px] text-gray-900">
        {capitalizedFirst(t("buy-voucher.payment-detail-section"))}
      </span>
      <div className="flex w-full justify-between border-b border-gray-200 p-3 text-[14px] font-medium leading-[20px]">
        <span className="text-gray-500">
          {capitalizedFirst(t("buy-voucher.voucher-title"))}
        </span>
        <span className="text-gray-700">{`${voucherName}`}</span>
      </div>

      <div className="flex w-full justify-between border-b border-gray-200 p-3 text-[14px] font-medium leading-[20px]">
        <span className="text-gray-500">
          {capitalizedFirst(t("buy-voucher.sub-total"))}
        </span>
        <span className="text-gray-700">{convertCurrency(subTotal)}</span>
      </div>

      <div className="flex w-full justify-between  p-3 text-[16px] font-semibold leading-[24px]">
        <span className="text-gray-700">
          {capitalizedFirst(t("buy-voucher.total"))}
        </span>
        <span className="text-20px leading-[30px] text-gray-700 text-orange-dark-500">
          {convertCurrency(total)}
        </span>
      </div>
    </div>
  )
}

export default PaymentDetail
