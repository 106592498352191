import React from "react"
import { useTranslation } from "react-i18next"
import OrdersCard from "src/components/orders-view/orders-card"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { OrderDetailsFoodsProps } from "./props"

export default function OrderDetailsFoods({ order }: OrderDetailsFoodsProps) {
  const { t } = useTranslation()

  return (
    <>
      <div className="border-100 flex h-[60px] w-full items-center gap-1 border-b p-3">
        <p className="flex items-center text-18 font-semibold text-gray-800">
          {capitalizedFirst(t("purchase-history.food-list"))}
        </p>
        <div className="flex items-center gap-4px rounded-5 bg-gray-200 px-1 py-2px">
          <span className="pi pi-circle-fill text-[6px] text-gray-500"></span>
          <p className="text-16 font-medium text-gray-700">
            {order?.total_item}
          </p>
          <p className="text-16 font-medium text-gray-700">
            {capitalizedFirst(t("purchase-history.items"))}
          </p>
        </div>
      </div>
      {order?.restaurants && <OrdersCard data={order?.restaurants} />}
    </>
  )
}
