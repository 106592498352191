import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "./index.scss"
import { Pagination, Navigation } from "swiper/modules"
import { ImgCustom } from "../common"
import { cataLogService } from "src/services"
import { useNavigate } from "react-router-dom"

interface ItemViewProps {
  title: string
  imgUrl: string
  id: string
}
const ItemView = (props: ItemViewProps) => {
  const { title, imgUrl, id } = props
  const navigate = useNavigate()
  const handleClickView = () => {
    navigate("/food/search", { state: { food_type: id } })
  }
  return (
    <div
      onClick={handleClickView}
      className="flex cursor-pointer flex-col items-center gap-4px"
    >
      <div className="h-[112px] w-[112px]">
        <ImgCustom
          url={imgUrl}
          alt="imgFoodItemView"
          className="rounded-full border-[4px] border-white border-opacity-70 shadow-lg shadow-blue-light-200"
        />
      </div>
      <span className="font-16 line-clamp-1 w-full py-1 text-center font-medium leading-24 text-gray-700">
        {title}
      </span>
    </div>
  )
}

const FoodToday = () => {
  const [foodType, setFoodType] = React.useState<ItemViewProps[]>([])

  React.useEffect(() => {
    function initView() {
      cataLogService
        .getFoodTodayHomePage()
        .then((res) => {
          if (res?.data?.data?.data?.length) {
            const result: ItemViewProps[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data.data.forEach((i: any) => {
              result.push({
                title: i?.name,
                id: i?.id,
                imgUrl: i?.image?.id,
              })
            })
            setFoodType(result)
          }
        })
        .catch((err) => err)
    }
    initView()
  }, [])

  return (
    <div className="flex w-full justify-center" id="foodToday">
      {foodType.length ? (
        <div className="mt-4 w-full">
          <Swiper
            slidesPerView={5}
            spaceBetween={4}
            navigation={true}
            pagination={true}
            modules={[Navigation, Pagination]}
            breakpoints={{
              0: { slidesPerView: 3, spaceBetween: 16 },
              480: {
                slidesPerView: 4,
                spaceBetween: 16,
              },
              768: {
                slidesPerView: 5,
                spaceBetween: 16,
              },
              1024: {
                slidesPerView: 7,
                spaceBetween: 16,
              },
              1200: {
                slidesPerView: 9,
                spaceBetween: 16,
              },
            }}
          >
            {foodType.map((i, index) => (
              <SwiperSlide key={index}>
                <ItemView {...i} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(FoodToday)
