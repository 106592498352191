import http from "src/config/interceptors"

export const markAllReadNotification = () => {
  const url = "/notification/notifications/all/read"
  return http.post(url)
}

export const getNotifications = (pageNum: number, pageSize: number) => {
  const url = `/notification/notifications/me?pageNum=${pageNum}&pageSize=${pageSize}`
  return http.get(url)
}

export const toggleReadNotification = (id: string) => {
  const url = `/notification/notifications/${id}/read/toggle`
  return http.put(url)
}
export const deleteNotification = (id: string) => {
  const url = `/notification/notifications/${id}`
  return http.delete(url)
}

export const callToActionNotification = (path: string) => {
  const url = `${path}`
  return http.put(url)
}

export const completeCTANotification = (id:string) => {
  const url = `/notification/notifications/${id}/cta/complete`
  return http.put(url)
}

