import React from "react"
import { useParams } from "react-router-dom"
import ApproveInfoView from "src/components/approve-info-view"

export default function IframeApproveShop() {
  const { id } = useParams()
  console.log(id)
  // const [dataList, setData] = useState<any>()

  return (
    <div className="min-h-[500px] w-full rounded-3 bg-white p-3">
      <div className="flex h-full w-full flex-col gap-2 p-3">
        <ApproveInfoView />
      </div>
    </div>
  )
}
