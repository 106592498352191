import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { CartDetail, initialState } from "./type"
import { CartType } from "src/components/order-cart-view/type"
export const shoppingCartSlice = createSlice({
  name: "myCart",
  initialState,
  reducers: {
    updateCart: (state, action: PayloadAction<CartType[]>) => {
      state.cartData = action.payload
    },
    updateStatusSyncData: (state, action: PayloadAction<boolean>) => {
      state.isSyncServer = action.payload
    },
    updateTotalCart: (state, action: PayloadAction<number>) => {
      state.totalCart = action.payload
    },
    updateListDataLocalNeedSync: (state, action: PayloadAction<CartDetail>) => {
      const newCart = action.payload
      if (state.cartLocal.length) {
        const cartCurrent = state.cartLocal?.find(
          (i: CartDetail) => i.id === newCart?.id
        )
        if (cartCurrent) {
          const newResult: CartDetail[] = []
          state.cartLocal.forEach((i: CartDetail) => {
            if (i.id !== cartCurrent.id) {
              newResult.push({ ...i })
            } else {
              newResult.push({ ...i, count: i.count + newCart.count })
            }
          })
          state.cartLocal = [...newResult]
        } else {
          state.cartLocal = [...state.cartLocal, { ...newCart }]
        }
      } else {
        state.cartLocal = [{ ...newCart }]
      }
    },
    deleteItemDataLocal: (state, action: PayloadAction<string>) => {
      const cartItemId = action.payload
      const newResult: CartDetail[] = []
      state.cartLocal.forEach((i: CartDetail) => {
        if (i.id !== cartItemId) {
          newResult.push({ ...i })
        }
      })
      state.cartLocal = [...newResult]
    },
    resetListDataCartLocal: (state) => {
      state.cartData = []
      state.totalCart = 0
    },
  },
})

export const {
  deleteItemDataLocal,
  updateCart,
  updateStatusSyncData,
  updateTotalCart,
  updateListDataLocalNeedSync,
  resetListDataCartLocal,
} = shoppingCartSlice.actions

export default shoppingCartSlice.reducer
