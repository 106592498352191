import { Skeleton } from "primereact/skeleton"
import React from "react"

export default function LoadingApproval() {
  return (
    <div className="w-full">
      <div className="flex w-full flex-col gap-5">
        <Skeleton
          className="mb-2"
          borderRadius="16px"
          height="2rem"
          width="50%"
        ></Skeleton>
        <div className="flex w-full flex-col gap-1">
          <Skeleton className="mb-2" borderRadius="16px"></Skeleton>
          <Skeleton className="mb-2" borderRadius="16px"></Skeleton>
          <Skeleton className="mb-2" borderRadius="16px"></Skeleton>
          <Skeleton className="mb-2" borderRadius="16px"></Skeleton>
        </div>
        <div className="flex w-full gap-1">
          <Skeleton width="50%" height="20rem" borderRadius="16px"></Skeleton>
          <Skeleton width="50%" height="20rem" borderRadius="16px"></Skeleton>
        </div>
        <div className="flex w-full flex-col gap-1">
          <div className="flex w-full items-center">
            <Skeleton shape="circle" size="2rem" className="mr-2"></Skeleton>
            <Skeleton width="20%" borderRadius="16px"></Skeleton>
          </div>
          <div className="flex w-full flex-col items-center">
            <Skeleton className="mb-2" borderRadius="16px"></Skeleton>
            <Skeleton className="mb-2" borderRadius="16px"></Skeleton>
            <Skeleton className="mb-2" borderRadius="16px"></Skeleton>
          </div>
          <div className="flex w-full items-center gap-1">
            <Skeleton size="5rem"></Skeleton>
            <Skeleton size="5rem"></Skeleton>
          </div>
        </div>
      </div>
    </div>
  )
}
