import React, { useEffect, useState } from "react"
import { t } from "i18next"
import { Dialog } from "primereact/dialog"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import "./index.scss"
import { getLogoApp } from "src/utils/app-logo"
import { LOGO } from "src/constants/common"
import { Checkbox } from "primereact/checkbox"
import { pdfjs,Document, Page } from  "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

export interface PolicyModalType {
  isShow: boolean
  handleClose: () => void
  handleSubmit: () => void
  isLoading?: boolean
}
const maxWidth = 800

export default function PolicyModal(props: PolicyModalType) {
  const { isShow, handleClose, handleSubmit, isLoading } = props
  const [isRead, setIsRead] = useState<boolean>(false)
  const [numPages, setNumPages] = useState<number>()
  const [containerWidth, setContainerWidth] = useState<number>()
  
  const footerTemplate = (
    <div className="mt-3">
      <div className="flex gap-1">
        <div>
          <Checkbox
            className="flex items-center justify-center"
            onChange={(e) => setIsRead(e.checked as boolean)}
            checked={isRead}
          />
        </div>
        <span className="text-[14px] leading-[20px] font-medium text-gray-700">{capitalizedFirst(t("policy-modal.agree-label", {name: LOGO.NAME}))}</span>
      </div>
      <div className="mt-[32px] flex gap-1 justify-end">
        <button
          className=" rounded-3 border border-gray-300 px-[20px] py-[12px] text-16 font-semibold text-gray-900 shadow-sm hover:opacity-60"
          onClick={() => handleClose()}
        >
          {capitalizedFirst(t("global.cancel"))}
        </button>
        <button
          disabled={!isRead || isLoading}
          className={`rounded-3 border border-blue-600 px-[20px] py-[12px] text-16 font-semibold text-white bg-blue-600 hover:opacity-60 ${isRead ? "" : "opacity-50"}`}
          onClick={() => handleSubmit()}
        >
          {capitalizedFirst(t("global.submit"))}
        </button>
      </div>
    </div>
  )
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }
  useEffect(() => {
    setContainerWidth(window.innerWidth*90/100)
  }, [])

  return (
    <Dialog
      id="policyModal"
      footer={footerTemplate}
      draggable={false}
      header={capitalizedFirst(t("policy-modal.header"))}
      visible={isShow}
      onHide={handleClose}
      className="w-[90vw] md:w-[786px]"
      closable={false}
    >
      <div className="h-full w-full">
        <div className="bg-blue-700 px-[24px] py-[32px] flex items-center gap-1">
          <img
            className="h-[30px] w-[30px] "
            src={getLogoApp()}
            alt="logoHeader"
          />
          <div className="items-center text-[24px] font-bold leading-[32px] text-white flex">
            {LOGO.NAME}
          </div>
          <div className="flex cursor-pointer items-center gap-1 border border-white text-white text-[14px] leading-[20px] border-[1.5px] font-semibold rounded-[12px] px-1 py-2px">
            {capitalizedFirst(t("policy-modal.partner"))}
          </div>
        </div>
        <div>
          <Document file={`${window.location.origin}/policy.pdf`} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
              />
            ))}
          </Document>
        </div>
      </div>
    </Dialog>
  )
}
