import axios, { AxiosError, AxiosResponse } from "axios"
import { store } from "../app/store"
// eslint-disable-next-line @typescript-eslint/no-namespace
declare module "axios" {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export interface AxiosRequestConfig {
    isToken?: boolean
  }
}
const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

http.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.timeout = 1000 * 60
    const storeData = store.getState()

    if (storeData.auth?.value?.access_token || config?.isToken) {
      config.headers.Authorization = `Bearer ${storeData.auth?.value?.access_token}`
      config.headers.securitySignature = "Security Signature"
    }
    return config
  },

  // Do something with request error
  function (error: AxiosError) {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  function (response: AxiosResponse) {
    return response
  },
  function (error: AxiosError) {
    if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
      console.log("Request timed out")
    }
    return Promise.reject(error)
  }
)

export default http

export const headerConfig = {
  headers: {
    securitySignature: "Security Signature",
    Authorization: localStorage.getItem("accessToken"),
  },
  timeout: 1000 * 60,
}

export const config = (serviceName: string) => {
  const storeData = store.getState()
  return {
    accessToken: storeData.auth?.value?.access_token || "",
    basePath: `${process.env.REACT_APP_API_URL}/${serviceName}`,
  }
}
