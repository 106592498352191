import React from "react"
import { useLocation } from "react-router-dom"

export default function MapResults() {
  const location = useLocation()
  console.log(location)
  return (
    <div className="mx-auto h-screen w-full p-4">
      <div className="h-full w-full bg-white">
        <div className="flex justify-center">Map results</div>
      </div>
    </div>
  )
}
