/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper/modules"
import { RestaurantControllerApi } from "@soctrip/axios-food-service"
import { SERVICE } from "src/constants/common"
import { config } from "src/config/interceptors"
import { ImgCustom } from "src/components/common"
import { useNavigate } from "react-router-dom"
import { convertCurrency } from "src/utils/convert-currency"
import { useTranslation } from "react-i18next"
import "./index.scss"
import { capitalizedFirst } from "src/utils/capitalized-first-character"

interface DataShopInterface {
  data: any
}

export default function FoodOfRestaurant({ data }: DataShopInterface) {
  const [foods, setFoods] = useState<any>([])
  const navigate = useNavigate()
  const { t } = useTranslation()
  useEffect(() => {
    if (data?.id) {
      (async function () {
        try {
          const res = await new RestaurantControllerApi(
            config(SERVICE.FOOD)
          ).restaurantsIdSearchGet(data?.id, 0, 10)
          setFoods(res?.data?.data?.data)
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, [data?.id])

  return (
    <div className="w-full p-2">
      <div className="mb-3 font-semibold">
        <span className="mr-[6px]">{capitalizedFirst(t("merchant-page.also-in"))}</span>
        <span className="text-blue-500">{data?.name}</span>
      </div>
      <div className="min-h-[184px] w-full" id="recommendFoodList">
        <Swiper
          navigation={true}
          pagination={true}
          className=""
          modules={[Navigation, Pagination]}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 8,
            },
            300: {
              slidesPerView: 3,
              spaceBetween: 8,
            },
            520: {
              slidesPerView: 4,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 6,
              spaceBetween: 8,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 8,
            },
            1200: {
              slidesPerView: 3.5,
              spaceBetween: 8,
            },
          }}
          // className="box-border h-full w-full"
        >
          {foods.map((food: any) => (
            <SwiperSlide key={food.id}>
              <div className="flex flex-col gap-[4px]">
                <div className="h-[110px] overflow-hidden rounded-3">
                  <ImgCustom className="w-full" alt={food?.name} url={food?.avatar?.id} />
                </div>
                <div
                  onClick={() => {
                    navigate(`/food/${food.id}`)
                  }}
                  className="line-clamp-2 min-h-[42px] cursor-pointer text-14 font-medium hover:text-blue-600"
                >
                  {food?.name}
                </div>
                <div className="font-semibold text-orange-dark-500">
                  {convertCurrency(food?.price)}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
