import { BreadCrumb } from "primereact/breadcrumb"
import { MenuItem } from "primereact/menuitem"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import OrderDetailsInformation from "src/components/order-details-view/order-details-info"
import OrderDetailsFooter from "src/components/order-details-view/order-details-footer"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import Loading from "src/components/common/loading"
import OrderStatus from "src/components/order-details-view/order-status"
import OrderStatusDetails from "src/components/order-details-view/order-status-details"
import OrderDetailsFoods from "src/components/order-details-view/order-details-foods"
import {
  OrderControllerApi,
  OrderDetailDTO,
} from "@soctrip/axios-food-order-service"
import OrderDetailsPayment from "src/components/order-details-view/order-details-payment"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"

export default function OrderDetailsPage() {
  const navigate = useNavigate()
  const param = useParams()
  const id = param?.id
  const { t } = useTranslation()
  const [orderDetails, setOrderDetails] = useState<OrderDetailDTO | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState(true)
  const items: MenuItem[] = [
    {
      label: capitalizedFirst(t("purchase-history.shopping")),
      command: () => {
        navigate("/shopping")
      },
    },
    {
      label: capitalizedFirst(t("purchase-history.my-order")),
      className: "font-semibold",
      command: () => {
        navigate("/food/orders")
      },
    },
    {
      label: capitalizedFirst(t("purchase-history.button.order-details")),
      className: "font-semibold",
      command: () => {
        navigate(`/food/orders/${id}`)
      },
    },
  ]
  const home: MenuItem = {
    icon: "pi pi-home",
    command: () => {
      navigate("/")
    },
  }

  const fetchOrderDetails = async () => {
    setIsLoading(true)
    try {
      const response = await new OrderControllerApi(
        config(SERVICE.FOOD_ORDER)
      ).getOrderDetail(id as string)
      setOrderDetails(response?.data?.data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchOrderDetails()
  }, [id])

  return (
    <div className="mx-auto w-full max-w-[1200px] px-3">
      <div className="mb-4 flex w-full flex-col">
        {/* breadcrumb */}
        <div className="item-center flex w-full">
          <BreadCrumb
            model={items}
            home={home}
            className="flex h-[52px] w-full items-center border-none text-16"
          />
        </div>
        {/* content */}
        {isLoading ? (
          <div className="h-[700px] w-full">
            <Loading />
          </div>
        ) : (
          <div className="flex h-full w-full flex-col gap-3 overflow-auto">
            <OrderDetailsInformation order={orderDetails} />
            <OrderStatus order={orderDetails} />
            <OrderStatusDetails order={orderDetails} />
            <div className="flex h-full w-full flex-col border-gray-100 bg-white pb-3">
              <OrderDetailsFoods order={orderDetails} />
              <OrderDetailsPayment order={orderDetails} />
            </div>

            <OrderDetailsFooter
              refetch={fetchOrderDetails}
              id={orderDetails?.id as string}
              can_cancel={orderDetails?.status?.status_property?.can_cancel}
              can_receive={orderDetails?.status?.status_property?.can_receive}
              can_refund={orderDetails?.status?.status_property?.can_refund}
              can_repay={orderDetails?.status?.status_property?.can_repay}
            />
          </div>
        )}
      </div>
    </div>
  )
}
