import React, { useMemo } from "react"
import { VideoCustomProps } from "./types"
import "./index.scss"
const VideoCustom = (props: VideoCustomProps) => {
  const { idSource, type, isControls } = props
  const videoSource = useMemo(() => {
    return `${process.env.REACT_APP_API_URL}/storage/files/stream-video/${idSource}.webm?quality=360`
  }, [idSource])
  return (
    <div className="relative h-full w-full">
      {isControls ? (
        <div className="w-ful relative h-full">
          <video className="h-full w-full rounded-3 object-cover">
            <source src={videoSource} type={type} />
          </video>
          <div className=" play-button">
            <span
              id="white-play-icon"
              className="play-icon absolute right-5 top-5 "
            >
              &#9654;
            </span>
          </div>
        </div>
      ) : (
        <video
          className="relative h-full w-full rounded-3 object-cover"
          autoPlay
          controls
        >
          <source src={videoSource} type={type} />
        </video>
      )}
    </div>
  )
}

export default VideoCustom
