import React, { useEffect, useRef, useState } from "react"
import { RestaurantControllerApi } from "@soctrip/axios-restaurant-service"
import { config } from "src/config/interceptors"
import { FOOD_CARD_TYPE, RECOMMEND_TIME, SERVICE } from "src/constants/common"
import { PopularFoodType } from "src/components/food-home-view/types"
import { useSearchParams } from "react-router-dom"
import { SkeletonLoading } from "src/components/common"
import FoodCardView from "src/components/food-home-view/food-card-view"
import { getBloomFilter } from "src/services/bookmark-service"
import useOnScreen from "src/hooks/use-on-screen"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
// import { SponsorView } from "src/components/food-home-view"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"

export default function DelightView() {
  const { t } = useTranslation()
  const loadingRef = useRef(null)
  const isOnScreen = useOnScreen(loadingRef)
  const [isShowLoadingMore, setIsShowLoadingMore] = useState<boolean>(false)
  const [bloomData, setBloomData] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [totalElement, setTotalElement] = useState<number>(0)
  const [type, setType] = useState<string>("")
  const [recommendRestaurants, setRecommendRestaurants] = React.useState<
    PopularFoodType[]
  >([])

  const locationNearYou = useSelector(
    (state: RootState) => state.searchLocationNearYou
  )

  const loadDataDelights = async (type: string, viewMore: boolean) => {
    if (isFirstLoad) setIsLoading(true)
    const currentPage = viewMore ? pageIndex + 1 : 0
    await new RestaurantControllerApi(config(SERVICE.RESTAURANT))
      .restaurantsSellingTimeSellingTimeGet(type, currentPage, 20)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setTotalElement(response?.data?.data?.totalElement)
        setIsLoading(false)
        setIsFirstLoad(false)
        setIsShowLoadingMore(false)
        const result: PopularFoodType[] = []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.data.data.data.forEach((i: any) => {
          result.push({
            id: i?.id,
            title: i?.name,
            imgUrl: i?.avatar?.id,
            point: i?.rating,
            countReview: i?.sum_reviews,
            is_free_wifi: i?.is_free_wifi,
            is_parking_lot: i?.is_parking_lot,
            is_operating_hours: i?.is_operating_hours,
            is_vehicle_charging: i?.is_vehicle_charging,
            address: {
              country: i?.country,
              province: i?.province,
              district: i?.district,
              ward: i?.ward,
              street: i?.street,
            },
          })

          if (viewMore) {
            setPageIndex(currentPage)
            setRecommendRestaurants([...recommendRestaurants, ...result])
          } else {
            setRecommendRestaurants(result)
          }
        })
      })
      .catch(() => setIsLoading(false))
  }

  const loadDataPopularNearYou = async (viewMore = false) => {
    const currentPage = viewMore ? pageIndex + 1 : 0
    setIsLoading(true)
    setIsFirstLoad(false)
    await new RestaurantControllerApi(config(SERVICE.RESTAURANT))
      .restaurantsNearYouGet(
        locationNearYou?.value?.latitude as number,
        locationNearYou?.value?.longitude as number,
        7,
        currentPage,
        20
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        setIsLoading(false)
        setIsShowLoadingMore(false)
        setTotalElement(res?.data?.data?.totalElement)
        if (res?.data?.data?.data?.length) {
          const result: PopularFoodType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.data.forEach((i: any) => {
            result.push({
              id: i?.id,
              title: i?.name,
              imgUrl: i?.avatar?.id,
              point: i?.rating,
              countReview: i?.sum_reviews,
              is_free_wifi: i?.is_free_wifi,
              is_parking_lot: i?.is_parking_lot,
              is_operating_hours: i?.is_operating_hours,
              is_vehicle_charging: i?.is_vehicle_charging,
              address: {
                country: i?.country,
                province: i?.province,
                district: i?.district,
                ward: i?.ward,
                street: i?.street,
              },
            })
          })
          if (viewMore) {
            setPageIndex(currentPage)
            setRecommendRestaurants([...recommendRestaurants, ...result])
          } else {
            setRecommendRestaurants(result)
          }
        }
      })
      .catch(() => {
        setIsLoading(false)
        setIsShowLoadingMore(false)
      })
  }

  useEffect(() => {
    const type = searchParams.get("type")
    setType(type as string)
    if (isFirstLoad) {
      if (type === RECOMMEND_TIME.POPULAR_NEAR_YOU) {
        loadDataPopularNearYou()
      } else {
        loadDataDelights(type as string, false)
      }
      handleGetBloomData()
    }
  }, [])

  const handleGetBloomData = async () => {
    await getBloomFilter()
      .then((response) => {
        setBloomData(response?.data?.data?.bloom_filter)
      })
      .catch((error) => error)
  }

  useEffect(() => {
    if (
      isOnScreen &&
      !isFirstLoad &&
      recommendRestaurants?.length < totalElement
    ) {
      setIsShowLoadingMore(true)
      handleViewMore()
    }
  }, [isOnScreen])

  const handleViewMore = () => {
    if (type === RECOMMEND_TIME.POPULAR_NEAR_YOU) {
      loadDataPopularNearYou(true)
    } else {
      loadDataDelights(type, true)
    }
  }

  return (
    <div className="mt-2 flex flex-col gap-2 px-2 py-4">
      {/* {searchParams?.get("type") !== RECOMMEND_TIME.POPULAR_NEAR_YOU ? (
        <SponsorView
          bloom_filter={bloomData}
          handleReload={handleGetBloomData}
        />
      ) : null} */}

      {isLoading ? (
        <div>
          <SkeletonLoading limit={5} rows={3} />
        </div>
      ) : (
        <div className="grid w-full grid-cols-2 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {recommendRestaurants?.map((item) => (
            <div className="w-full" key={item?.id}>
              <FoodCardView
                isFoodType={false}
                isRatingView={true}
                isAddressView={true}
                type={FOOD_CARD_TYPE.RESTAURANT}
                bloom_filter={bloomData}
                handleReload={handleGetBloomData}
                {...item}
              />
            </div>
          ))}
        </div>
      )}
      {pageIndex < 2 ? (
        <div>
          <div ref={loadingRef}></div>
        </div>
      ) : (
        <>
          {!isShowLoadingMore && (
            <div className="flex items-center justify-center">
              {recommendRestaurants?.length < totalElement ? (
                <div
                  onClick={handleViewMore}
                  className="cursor-pointer py-1 text-14 font-medium text-blue-700"
                >
                  {capitalizedFirst(t("shoppingPage.view-more"))}
                </div>
              ) : null}
            </div>
          )}
        </>
      )}
      {isShowLoadingMore && (
        <div className="flex justify-center py-4">
          <i
            className="pi pi-spin pi-spinner text-blue-500"
            style={{ fontSize: "1.5rem" }}
          ></i>
        </div>
      )}
    </div>
  )
}
