import React from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { convertCurrency } from "src/utils/convert-currency"
import { OrderDetailsPaymentProps } from "./props"
import { useTranslation } from "react-i18next"

export default function OrderDetailsPayment({
  order,
}: OrderDetailsPaymentProps) {
  const { t } = useTranslation()
  return (
    <>
      <div className="h-full w-full border-b border-gray-100">
        <div className="h-[60px] w-full border-b border-gray-100">
          <p className="flex w-full items-center p-3 text-18 font-semibold text-gray-800">
            {capitalizedFirst(t("purchase-history.payment-information"))}
          </p>
        </div>
        <div className="flex h-full w-full flex-col gap-1 py-1">
          <div className="flex items-center justify-between px-3">
            <p className="text-16 font-semibold text-gray-700">
              {capitalizedFirst(t("purchase-history.order-total"))}
            </p>
            <p className="text-18 font-semibold text-gray-700">
              {convertCurrency(order?.payment?.order_total)}
            </p>
          </div>
          <div className="flex w-full flex-col gap-1">
            <div className="flex items-center justify-between px-3">
              <p className="text-14 font-medium text-gray-500">
                {capitalizedFirst(t("purchase-history.payment-method"))}
              </p>
              <p className="text-14 font-normal text-gray-700">
                {/* {order?.payment?.payment_method?.code} */}
                {order?.payment?.payment_method?.name}
              </p>
            </div>
            <div className="flex w-full items-center justify-between px-3">
              <div className="min-w-[150px] truncate text-14 font-medium text-gray-500">
                {capitalizedFirst(t("checkoutPage.vouchers"))}
              </div>
              <div className="flex items-center gap-1">
                {order?.payment?.vouchers?.map((voucher) => (
                  <div
                    key={voucher.id}
                    className="rounded-5 bg-orange-dark-50 px-10px py-4px text-14 font-medium text-orange-700"
                  >
                    {voucher?.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex w-full items-center justify-between px-3">
              <div className="text-14 font-medium text-gray-500">
                {capitalizedFirst(t("shoppingCartPage.discounts"))}
              </div>
              <p className="text-14 font-normal text-gray-700">
                -{convertCurrency(order?.payment?.discounts)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-full w-full items-center justify-between p-3">
        <p className="text-16 font-semibold text-gray-700">
          {capitalizedFirst(t("purchase-history.total-payment"))}
        </p>
        <p className="text-18 font-bold text-orange-dark-500">
          {convertCurrency(order?.payment?.payment_total)}
        </p>
      </div>
    </>
  )
}
