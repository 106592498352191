import React, { useEffect, useState } from "react"
import { FoodTodayView, SearchView } from "../food-home-view"
import { DistrictDropdownType } from "../food-home-view/types"
import { useDispatch } from "react-redux"
import { updateSearchLocation } from "src/features/search-location"
import { getSystemBanner } from "src/services/banner-service"
import { handleCheckTime } from "src/utils/common"
import { BannerType } from "./type"
import { ImgCustom } from "../common"
import { IMAGE_TYPE } from "src/constants/common"
import bannerDefault from "src/assets/images/bannerDefault.webp"

export default function HeaderHomePage() {
  const dispatch = useDispatch()
  const [banner, setBanner] = useState({
    type: IMAGE_TYPE.WEB,
    url: "",
  })
  const [districtSelected, setDistrictSelected] = React.useState<
    DistrictDropdownType | undefined
  >(undefined)

  const changeSelectedAddressSelected = (newValue: DistrictDropdownType) => {
    setDistrictSelected(newValue)
    dispatch(updateSearchLocation(newValue))
  }

  const handleGetBanner = async () => {
    try {
      const res = await getSystemBanner(
        0,
        10,
        encodeURIComponent("category_code==food_hero_banner"),
        encodeURIComponent("-created_at|Long")
      )
      const data: BannerType[] = res?.data?.data?.data
      let isBanner = false
      data?.forEach((item) => {
        if (handleCheckTime(item?.start_time, item?.end_time)) {
          if (item?.medias?.length) {
            setBanner({
              type: IMAGE_TYPE.WEB,
              url: item?.medias?.[0]?.id,
            })
            isBanner = true
          }
        }
      })
      if (!isBanner) {
        setBanner({
          type: IMAGE_TYPE.LOCAL,
          url: bannerDefault,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetBanner()
  }, [])

  return (
    <div className="relative mb-[100px] h-[500px] w-full">
      <div className="relative h-[500px] w-full">
        {banner.url && (
          <ImgCustom
            type={banner.type}
            url={banner.url}
            alt={"top-banner"}
            className="h-[500px] w-full object-cover object-center"
          />
        )}
        <div className="absolute left-1/2 top-1/2 z-[2] flex w-full max-w-[1200px] -translate-x-1/2 -translate-y-1/2 transform">
          <div className="flex flex-col gap-4 px-2">
            <div className="line-clamp-2 flex h-[144px] max-w-[700px] font-[Fahkwang700] text-[38px] leading-[48px] text-white md:text-[48px] md:leading-[65px]">
              Are you looking for a delicious restaurant?
            </div>
            <div className="w-auto">
              <SearchView
                districtSelected={districtSelected}
                changeSelected={changeSelectedAddressSelected}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute -bottom-[100px] left-1/2 flex max-w-[1200px] w-full -translate-x-1/2 transform bg-transparent px-1">
        <FoodTodayView />
      </div>
    </div>
  )
}
