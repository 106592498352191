import React, { useEffect, useMemo, useRef, useState } from "react"
import { Menu } from "primereact/menu"
import { useTranslation } from "react-i18next"
import { Badge } from "primereact/badge"
import { getNotifications, deleteNotification, toggleReadNotification, markAllReadNotification, callToActionNotification, completeCTANotification } from "src/services/notifications-service"
import NotificationItemView, { NotificationItemViewType } from "./notfications-item-view"
import { capitalizedFirst } from "src/utils/capitalized-first-character"

const NotificationsView = () => {
  const userMenuRef = useRef<Menu>(null)
  const notificationMenuRef = useRef<Menu>(null)
  const { t } = useTranslation()
  const [totalNotify, setTotalNotify] = useState<number>(0)
  const [notifications, setNotifications] = useState<
    NotificationItemViewType[]
  >([])
  const getNotificationsData = () => {
    getNotifications(0, 100)
      .then((res) => {
        if (res?.data?.data?.data?.length) {
          const result = res.data.data.data
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          result.sort((a: any, b: any) => {
            return (new Date(b.send_time).getTime() - new Date(a.send_time).getTime()) > 0
          })
          result.reverse()
          setNotifications(result.reverse())
          setTotalNotify(res.data.data?.totalElement || 0)
        }
      })
      .catch((err) => console.log(err))
  }
  const handleDeleteItemNotify = (notifyId: string) => {
    deleteNotification(notifyId).then(() => {
      getNotificationsData()
    }).catch(err => console.log(err))
  }
  const handToggleNotification = (notifyId: string) => {
    toggleReadNotification(notifyId)
      .then(() => {
        getNotificationsData()
      })
      .catch(err => console.log(err))

  }
  const handleClickSetting = () => {
    window.open(
      `${process.env.REACT_APP_HOME_DOMAIN_URL}/account-settings?setting=notifications`,
      "_self"
    )
  }
  const handleClickMarkAll = () => {    
    markAllReadNotification().then(() => {
      getNotificationsData()
    }).catch(err => console.log(err))
  }
  const processAction = (id: string, path: string) => {
    callToActionNotification(path).then(() => {
      completeCTANotification(id).then(() => {
        getNotificationsData()
      })
    }).catch(err => console.log(err))
  }
  useEffect(() => {
    getNotificationsData()
  }, [])
  // get count notify
  const notificationCount = useMemo(() => {
    let result = ""
    if(totalNotify > 0) result = totalNotify >= 100 ? "99+" : `${totalNotify}`
    return result
  }, [totalNotify])

  const keyRender = useMemo(() => {
    return new Date().getTime()
  }, [totalNotify])
  
  const settingItems = [
    {
      template: () => {
        return (
          <div className="flex flex-col">
            <div 
              onClick={(e) =>  {
                notificationMenuRef?.current?.hide(e) 
                handleClickMarkAll()
              }}
              className="flex cursor-pointer items-center gap-1 rounded-3 p-1 font-medium text-gray-700 hover:bg-gray-200">
              <i className="sctr-icon-book-open-01 text-[16px] " />
              <span>{capitalizedFirst(t("notify.mark-as-read"))}</span>
            </div>
            <div
              onClick={handleClickSetting}
              className="flex cursor-pointer items-center gap-1 rounded-3 p-1 font-medium text-gray-700 hover:bg-gray-200">
              <i className="sctr-icon-settings-02 text-[16px] " />
              <span>{capitalizedFirst(t("notify.setting"))}</span>
            </div>
          </div>
        )
      },
    },
  ]

  const items = [
    {
      template: () => {
        return (
          <div className="flex flex-col">
            <div className="flex items-center justify-between p-[16px]">
              <div className="flex items-center gap-1">
                <span className="text-[16px] leading-[24px]">{"Notifications"}</span>
                {
                  notificationCount.length ? (
                    <div className="rounded-full bg-red-500 w-4 h-4 min-w-4 px-[8px] py-[8px] flex items-center justify-center text-[12px] leading-[16px] text-white">
                      {notificationCount}
                    </div>
                  ) : null
                }

              </div>
              <div>
                <i
                  onClick={(e) => notificationMenuRef?.current?.toggle(e)}
                  className="sctr-icon-dots-vertical cursor-pointer text-[18px] text-gray-500"
                />
              </div>
            </div>
            {notifications?.length ? (
              <div className="max-h-[588px] no-scrollbar overflow-x-hidden scroll-auto flex flex-col gap-1">
                {notifications.map((i: NotificationItemViewType, index) => (
                  <NotificationItemView 
                    key={`${keyRender}-${i.id}-${index}`}
                    {...i}
                    handleDeleteItem={(id: string) => handleDeleteItemNotify(id)}
                    toggleNotification={(id: string) => handToggleNotification(id)}
                    processAction={(id: string, path: string) => processAction(id, path)}    
                  />
                ))}
              </div>
            ) : null}

            <div></div>
          </div>
        )
      },
    },
  ]
  return (
    <>
      <div className="hidden flex-1 items-center justify-center md:flex">
        <i
          onClick={(e) => userMenuRef?.current?.toggle(e)}
          className="sctr-icon-bell-01 p-overlay-badge cursor-pointer text-[24px] text-gray-600 hover:text-blue-600"
        >
          {notificationCount.length ? (
            <Badge
              style={{
                minWidth: "20px",
                width:"20px",
                height:"20px",
                fontSize: "8px",
              }}
              className="flex items-center justify-center p-0"
              severity="danger"
              value={notificationCount}
            ></Badge>
          ) : null}
        </i>
      </div>
      <Menu model={items} popup ref={userMenuRef} className="mt-1 w-[390px]" />
      <Menu
        model={settingItems}
        popup
        ref={notificationMenuRef}
        className="mt-2 w-fit p-1"
      />
    </>
  )
}

export default NotificationsView
