import React from "react"
import { Skeleton } from "primereact/skeleton"
import { SkeletonLoadingProps } from "./props"

export default function SkeletonLoading(props: SkeletonLoadingProps) {
  const { limit, rows } = props
  return (
    <div className="flex w-full flex-col">
      {(rows as number) > 2 && (
        <div className="flex h-full w-full">
          <div className="card w-full">
            <div className="border-round border-1 surface-border surface-card p-2">
              <Skeleton width="100%" height="150px"></Skeleton>
              <div className="justify-content-between mt-3 flex">
                <Skeleton height="2rem" className="w-full"></Skeleton>
              </div>
              <div className="mb-3 mt-2 flex w-full">
                <div className="w-full">
                  <Skeleton className="mb-2 w-full"></Skeleton>
                  <Skeleton className="mb-2 w-full"></Skeleton>
                </div>
              </div>
            </div>
          </div>
          {(limit as number) >= 2 && (
            <div className="card w-full">
              <div className="border-round border-1 surface-border surface-card p-2">
                <Skeleton width="100%" height="150px"></Skeleton>
                <div className="justify-content-between mt-3 flex">
                  <Skeleton height="2rem" className="w-full"></Skeleton>
                </div>
                <div className="mb-3 mt-2 flex w-full">
                  <div className="w-full">
                    <Skeleton className="mb-2 w-full"></Skeleton>
                    <Skeleton className="mb-2 w-full"></Skeleton>
                  </div>
                </div>
              </div>
            </div>
          )}

          {(limit as number) >= 3 && (
            <div className="card w-full">
              <div className="border-round border-1 surface-border surface-card p-2">
                <Skeleton width="100%" height="150px"></Skeleton>
                <div className="justify-content-between mt-3 flex ">
                  <Skeleton height="2rem" className="w-full"></Skeleton>
                </div>
                <div className="mb-3 mt-2 flex w-full">
                  <div className="w-full">
                    <Skeleton className="mb-2 w-full"></Skeleton>
                    <Skeleton className="mb-2 w-full"></Skeleton>
                  </div>
                </div>
              </div>
            </div>
          )}

          {(limit as number) >= 4 && (
            <div className="card w-full">
              <div className="border-round border-1 surface-border surface-card p-2">
                <Skeleton width="100%" height="150px"></Skeleton>
                <div className="justify-content-between mt-3 flex ">
                  <Skeleton height="2rem" className="w-full"></Skeleton>
                </div>
                <div className="mb-3 mt-2 flex w-full">
                  <div className="w-full">
                    <Skeleton className="mb-2 w-full"></Skeleton>
                    <Skeleton className="mb-2 w-full"></Skeleton>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(limit as number) >= 5 && (
            <div className="card w-full">
              <div className="border-round border-1 surface-border surface-card p-2">
                <Skeleton width="100%" height="150px"></Skeleton>
                <div className="justify-content-between mt-3 flex ">
                  <Skeleton height="2rem" className="w-full"></Skeleton>
                </div>
                <div className="mb-3 mt-2 flex w-full">
                  <div className="w-full">
                    <Skeleton className="mb-2 w-full"></Skeleton>
                    <Skeleton className="mb-2 w-full"></Skeleton>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="flex h-full w-full">
        <div className="card w-full">
          <div className="border-round border-1 surface-border surface-card p-2">
            <Skeleton width="100%" height="150px"></Skeleton>
            <div className="justify-content-between mt-3 flex">
              <Skeleton height="2rem" className="w-full"></Skeleton>
            </div>
            <div className="mb-3 mt-2 flex w-full">
              <div className="w-full">
                <Skeleton className="mb-2 w-full"></Skeleton>
                <Skeleton className="mb-2 w-full"></Skeleton>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-full">
          <div className="border-round border-1 surface-border surface-card p-2">
            <Skeleton width="100%" height="150px"></Skeleton>
            <div className="justify-content-between mt-3 flex">
              <Skeleton height="2rem" className="w-full"></Skeleton>
            </div>
            <div className="mb-3 mt-2 flex w-full">
              <div className="w-full">
                <Skeleton className="mb-2 w-full"></Skeleton>
                <Skeleton className="mb-2 w-full"></Skeleton>
              </div>
            </div>
          </div>
        </div>
        {(limit as number) >= 3 && (
          <div className="card w-full">
            <div className="border-round border-1 surface-border surface-card p-2">
              <Skeleton width="100%" height="150px"></Skeleton>
              <div className="justify-content-between mt-3 flex">
                <Skeleton height="2rem" className="w-full"></Skeleton>
              </div>
              <div className="mb-3 mt-2 flex w-full">
                <div className="w-full">
                  <Skeleton className="mb-2 w-full"></Skeleton>
                  <Skeleton className="mb-2 w-full"></Skeleton>
                </div>
              </div>
            </div>
          </div>
        )}

        {(limit as number) >= 4 && (
          <div className="card w-full">
            <div className="border-round border-1 surface-border surface-card p-2">
              <Skeleton width="100%" height="150px"></Skeleton>
              <div className="justify-content-between mt-3 flex ">
                <Skeleton height="2rem" className="w-full"></Skeleton>
              </div>
              <div className="mb-3 mt-2 flex w-full">
                <div className="w-full">
                  <Skeleton className="mb-2 w-full"></Skeleton>
                  <Skeleton className="mb-2 w-full"></Skeleton>
                </div>
              </div>
            </div>
          </div>
        )}
        {(limit as number) >= 5 && (
          <div className="card w-full">
            <div className="border-round border-1 surface-border surface-card p-2">
              <Skeleton width="100%" height="150px"></Skeleton>
              <div className="justify-content-between mt-3 flex ">
                <Skeleton height="2rem" className="w-full"></Skeleton>
              </div>
              <div className="mb-3 mt-2 flex w-full">
                <div className="w-full">
                  <Skeleton className="mb-2 w-full"></Skeleton>
                  <Skeleton className="mb-2 w-full"></Skeleton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
