import { OrderControllerApi } from "@soctrip/axios-food-order-service"
import React from "react"
import { useTranslation } from "react-i18next"
import { ORDER_STATE } from "src/constants/order"
import { useToast } from "src/hooks/use-toast"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { translateCapitalize } from "src/utils/translate-with-capitalize"
import { OrderDetailsFooterProps } from "./props"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"

export default function OrderDetailsFooter({
  id,
  can_cancel,
  can_receive,
  can_repay,
  refetch,
}: OrderDetailsFooterProps) {
  const showToast = useToast()
  const { t } = useTranslation()
  const changeStatusOrder = async (id: string, code: string) => {
    try {
      await new OrderControllerApi(
        config(SERVICE.FOOD_ORDER)
      ).changeStatusOrder(id, code)
      showToast({ detail: "Change status successfully", severity: "success" })
      refetch()
    } catch (error) {
      showToast({ detail: "Change status failed", severity: "error" })
    }
  }

  // handle re-pay
  const handleRePay = (id: string) => {
    console.log(id)
  }

  return (
    <div className="mt-[-16px] flex w-full items-center justify-end gap-2 rounded-b-3 border-b bg-white px-3 pb-3">
      <div className="flex cursor-pointer items-center gap-1 rounded-3 border border-blue-600 bg-blue-600 px-10px py-1 text-14 font-semibold text-white">
        <span className="pi pi-comment"></span>
        <p>{capitalizedFirst(t("purchase-history.button.chat-to-shop"))}</p>
      </div>
      {/* cancel order */}
      {can_cancel && (
        <button
          onClick={() => changeStatusOrder(id, ORDER_STATE.CANCELLED_BY_YOU)}
          type="button"
          className="truncate rounded-3 border border-gray-300 px-3 py-10px text-14 font-semibold text-gray-700 hover:bg-gray-100"
        >
          {translateCapitalize("purchase-history.button.cancel-order")}
        </button>
      )}
      {/* receive order */}
      {can_receive && (
        <button
          onClick={() => changeStatusOrder(id, ORDER_STATE.COMPLETED)}
          type="button"
          className="truncate rounded-3 border border-gray-300 px-3 py-10px text-14 font-semibold text-gray-700 hover:bg-gray-100"
        >
          {capitalizedFirst(t("purchase-history.button.received-order"))}
        </button>
      )}

      {/* pay */}
      {can_repay && (
        <button
          onClick={() => handleRePay(id)}
          type="button"
          className="truncate rounded-3 border border-blue-600 bg-blue-600 px-3 py-10px text-14 font-semibold text-white hover:bg-blue-500"
        >
          {capitalizedFirst(t("purchase-history.button.pay"))}
        </button>
      )}
      {/* refund */}
      {can_receive && (
        <button
          onClick={() => changeStatusOrder(id, ORDER_STATE.REFUND)}
          type="button"
          className="truncate rounded-3 border border-blue-600 bg-blue-600 px-3 py-10px text-14 font-semibold text-gray-700 hover:bg-gray-100"
        >
          {translateCapitalize("purchase-history.button.refund-order")}
        </button>
      )}
    </div>
  )
}
