import React, { useMemo } from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { RestaurantInfoType } from "../types"
import { ImgCustom } from "src/components/common"
import locationIcon from "src/assets/images/location-icon.svg"
import openTimeIcon from "src/assets/images/opne-time.svg"
import moment from "moment"

const RestaurantInfo = (props: RestaurantInfoType) => {
  const { t } = useTranslation()
  const { name, rating, address, viewer, url, id, closedTime } = props
  const getRestaurantLink = useMemo(() => {
    return `${process.env.REACT_APP_FOOD_DOMAIN_URL}/food/restaurant/${id}`
  }, [])

  const resultClosedTime = useMemo(() => {
    let time = "10:00 PM"
    if (closedTime) {
      time = moment(closedTime).utc(false).format("hh:mm A")
    }
    return time
  }, [closedTime])

  return (
    <div className="mx-4 flex flex-col rounded-3 border border-gray-200 p-3">
      <div className="flex ">
        <div className="h-[50px] w-[50px]">
          <ImgCustom url={url} alt="restaurantImage" />
        </div>
        <div className="flex flex-1 justify-between">
          <span className="ml-1 text-[16px] font-semibold leading-[24px] text-gray-700">
            {name}
          </span>
          <a href={getRestaurantLink} className="flex h-fit items-center">
            <span className="text-[14px] font-semibold leading-[20px] text-blue-600 hover:underline">
              {capitalizedFirst(t("buy-voucher.restaurant-info-visit"))}
            </span>
            <i className="pi pi-angle-right ml-4px text-[16px] leading-[20px] text-blue-600" />
          </a>
        </div>
      </div>
      <div className="mt-1 flex items-center">
        <i className="pi pi-star-fill text-[16px] text-yellow-400" />
        <span className="ml-1 text-[14px] leading-[20px] text-gray-500">
          {capitalizedFirst(
            t("buy-voucher.restaurant-info-review", {
              rating: rating,
              review: viewer || 0,
            })
          )}
        </span>
      </div>
      <div className="mt-[4px] flex items-center">
        <img
          src={openTimeIcon}
          alt="openTimeIcon"
          className="h-[20px] w-[20px]"
        />
        <span className="ml-1 text-[14px] font-medium leading-[20px] text-green-600">
          {capitalizedFirst(t("buy-voucher.restaurant-open"))}
        </span>
        <span className="ml-4px text-[14px] leading-[20px] text-gray-500">
          {capitalizedFirst(
            t("buy-voucher.restaurant-close-time", { time: resultClosedTime })
          )}
        </span>
      </div>
      <div className="mt-[4px] flex items-center">
        <img
          src={locationIcon}
          alt="locationIcon"
          className="h-[20px] w-[20px]"
        />
        <span className="ml-1 text-[14px] leading-[20px] text-gray-500">
          {address}
        </span>
      </div>
    </div>
  )
}

export default RestaurantInfo
