import React, { useMemo, useState, useEffect } from "react"
import {
  FooterView,
  HeaderView,
  OrderInfo,
  RestaurantInfo,
  VoucherInfo,
} from "src/components/order-voucher"
import BuyerInfo from "src/components/order-voucher/buyer-info"
import PaymentDetail from "src/components/order-voucher/payment-detail"
import { useSearchParams } from "react-router-dom"
import { VoucherControllerApi } from "@soctrip/axios-restaurant-service"
import EmptyPage from "src/components/common/empty"
import { Loading } from "src/components/common"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"

const VOUCHER_PAYMENT_STATUS = {
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
}

export const PAYMENT_STATUS = {
  SUCCESS: "succeeded",
  WAITING: "waiting_for_account",

  CREATED_ORDER: "CREATED_ORDER",
  FALL: "fail",
}

const STATE_VIEW = {
  INIT: "INIT",
  LOADING: "LOADING",
  NOT_FOUND: "NOT_FOUND",
  RENDER: "RENDER",
}
export default function OrderVoucher() {
  const [stateView, setStateView] = useState<string>(STATE_VIEW.INIT)
  const [paymentToken, setPaymentToken] = useState<string>("")
  const [searchParams] = useSearchParams()
  const [transitionId, setTransitionId] = useState<string>("")
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [transitionData, setTransitionData] = useState<any | undefined>(
    undefined
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [paymentData, setPaymentData] = useState<any | undefined>(undefined)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getAddressRestaurant = (data: any) => {
    let result = ""
    if (data?.street) result = result + ` ${data?.street}, `
    if (data?.ward?.name) result = result + ` ${data?.ward?.name}`
    if (data?.district?.name && data?.district?.id !== data?.ward?.id)
      result = result + `, ${data?.district?.name}`
    if (data?.province?.name) result = result + `, ${data?.province?.name}`
    if (data?.country?.name) result = result + `, ${data?.country?.name}`
    return result
  }

  const voucherTransitionData = useMemo(() => {
    if (!transitionData) return undefined
    return {
      voucherInfo: {
        name: transitionData?.voucher_order?.name || "",
        reward: transitionData?.voucher_order?.discount_price || 0,
        description: transitionData?.voucher_order?.description || "",
        qrUrl: transitionData?.voucher_order?.qr_code || "",
      },
      restaurantInfo: {
        id: transitionData?.restaurant?.id || "",
        url: transitionData?.restaurant?.avatar?.id || "",
        name: transitionData?.restaurant?.name || "",
        rating: transitionData?.restaurant?.rating || "",
        viewer: transitionData?.restaurant?.sum_reviews || "",
        address: getAddressRestaurant(transitionData?.restaurant),
        closedTime: transitionData?.restaurant?.closed_time,
      },
      buyerInfo: {
        name: transitionData?.user?.full_name || "",
        email: transitionData?.user?.email || "",
        phone: transitionData?.user?.phone || "",
      },
      PaymentDetail: {
        voucherName:
          `${transitionData?.voucher_order?.quantity}x ${transitionData?.voucher_order?.name}` ||
          "",
        subTotal:
          transitionData?.voucher_order?.voucher_price *
            transitionData?.voucher_order?.quantity || 0,
        total:
          transitionData?.voucher_order?.voucher_price *
            transitionData?.voucher_order?.quantity || 0,
      },
    }
  }, [transitionData])

  const voucherPaymentData = useMemo(() => {
    if (!paymentData) return undefined
    return {
      status:
        paymentData?.status === PAYMENT_STATUS.SUCCESS ||
        paymentData?.status === PAYMENT_STATUS.WAITING
          ? VOUCHER_PAYMENT_STATUS.SUCCESS
          : VOUCHER_PAYMENT_STATUS.FAILED,
      orderInfo: {
        orderId: transitionData?.voucher_order?.transaction_id || "",
        paymentType: paymentData?.payment_method || "",
        orderTime: transitionData?.voucher_order?.created_at
          ? transitionData?.voucher_order?.created_at
          : "",
      },
    }
  }, [paymentData, transitionData])

  const detectToken = () => {
    const tokenParam = searchParams.get("token")
    if (tokenParam) {
      setStateView(STATE_VIEW.LOADING)
      setPaymentToken(tokenParam)
    } else {
      setStateView(STATE_VIEW.NOT_FOUND)
    }
  }
  useEffect(() => {
    detectToken()
  }, [])

  useEffect(() => {
    if (paymentToken) {
      new VoucherControllerApi(config(SERVICE.RESTAURANT))
        .vouchersPaymentTokenStatusGet(paymentToken)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data?.orders?.length && res.data.data.orders[0]?.id) {
            setPaymentData(res?.data?.data)
            setTransitionId(res.data.data.orders[0]?.id)
          } else setStateView(STATE_VIEW.NOT_FOUND)
        })
        .catch(() => {
          setStateView(STATE_VIEW.NOT_FOUND)
        })
    }
  }, [paymentToken])

  useEffect(() => {
    if (transitionId) {
      new VoucherControllerApi(config(SERVICE.RESTAURANT))
        .vouchersTransactionIdGet(transitionId)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data) {
            setTransitionData(res.data.data)
            setStateView(STATE_VIEW.RENDER)
          } else setStateView(STATE_VIEW.NOT_FOUND)
        })
        .catch(() => setStateView(STATE_VIEW.NOT_FOUND))
    }
  }, [transitionId])

  if (
    stateView === STATE_VIEW.INIT ||
    stateView === STATE_VIEW.LOADING ||
    !voucherPaymentData ||
    !voucherTransitionData
  )
    return (
      <div className="h-[600px]">
        <Loading />
      </div>
    )
  if (stateView === STATE_VIEW.NOT_FOUND)
    return (
      <div className="h-[600px]">
        <EmptyPage />
      </div>
    )

  return (
    <div className="mx-auto my-[48px] max-w-[792px] rounded-3 border border-gray-50 bg-white py-4">
      <HeaderView
        isSuccess={voucherPaymentData.status === VOUCHER_PAYMENT_STATUS.SUCCESS}
      />
      <OrderInfo {...voucherPaymentData.orderInfo} />
      <VoucherInfo {...voucherTransitionData.voucherInfo} />
      <RestaurantInfo {...voucherTransitionData.restaurantInfo} />
      <BuyerInfo {...voucherTransitionData.buyerInfo} />
      <PaymentDetail {...voucherTransitionData.PaymentDetail} />
      <FooterView
        restaurantId={voucherTransitionData?.restaurantInfo?.id || ""}
        isSuccess={voucherPaymentData.status === VOUCHER_PAYMENT_STATUS.SUCCESS}
        orderId={transitionId}
      />
    </div>
  )
}
